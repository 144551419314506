import React, { Component, Fragment } from 'react';
//import validator from 'validator';
import { Link } from 'react-router-dom';
import moment from 'moment';
import uuid from "uuid";

import ReactGA from 'react-ga';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

import AuthHelper from '../SharedComponents/AuthHelper';
import { AddressCardBody } from '../SharedComponents/AddressCardBody';
import { Product } from './Product';
import { ReadonlyItem } from './../SharedComponents/ReadonlyItem';
import { NotificationPanel } from '../SharedComponents/NotificationPanel';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import { ColumnAndLabelRadioOptions } from './../SharedComponents/ColumnAndLabelRadioOptions';

const installationsHomeUrl = "/Installations/History";
const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class InstallationView extends Component {
    displayName = InstallationView.name;

    constructor(props) {
        super(props);

        const auth = new AuthHelper();
        this.state = {
            CompanyId: auth.CompanyId,
            CompanyName: '',
            CompletedAfter: '',
            CompletedBefore: '',
            CrumbMap: [{ Title: 'Home', LinkUrl: '/Installations/Home' }, { Title: 'History', LinkUrl: '/Installations/History' }],
            DispatchPostcode: '',
            InstallerReference: '',
            InstallPostcode: '',
            Reference: '',
            //ShowEditButton: auth.CanEditData,
            //ShowDeleteButton: auth.CanDoAdminTask,
            ShowEditButton: false,
            ShowDeleteButton: auth.CanDoAdminTask,
            UsePaging: true,
            displayContent: (<PleaseWait IsLoading={true} />),
            installation: [],
            installationAppliances: [],
            InstallationEvents: [],
            installationId: props.match.params.id,
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            page: 0,
            PageSize: 0,

            RegistrationStatus: '',
            InstallerCompanyRegistrationId: '',
            InstallerType: '',
            IBGProvider: ''
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    renderTable = (installation, installationAppliances, InstallationEvents, InstallerType, IBGProviderId, Conservatory, RoofLine) => {
        return (
            <div className='form-group'>
                <div className='row'>
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-xs-12 col-md-4">
                                <ReadonlyItem textLabel="Installer Reference">{installation.InstallerReference}</ReadonlyItem>
                            </div>
                            <div className="col-xs-12 col-md-4">
                                <ReadonlyItem textLabel="Reference">{installation.Reference}</ReadonlyItem>
                            </div>
                            <div className="col-xs-12 col-md-4">
                                <ReadonlyItem textLabel="Contract Value">{installation.ContractValue}</ReadonlyItem>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-md-4">
                                <ReadonlyItem textLabel="Customer Name">{installation.ContactName}</ReadonlyItem>
                            </div>
                            <div className="col-xs-12 col-md-4">
                                <ReadonlyItem textLabel="Telephone Number">{installation.TelephoneNumber}</ReadonlyItem>
                            </div>
                            <div className="col-xs-12 col-md-4">
                                <ReadonlyItem textLabel="Email">{installation.Email}</ReadonlyItem>
                            </div>

                            <div className="col-xs-12 col-md-4">
                                <ReadonlyItem textLabel="Contract Value">{installation.ContractValue === 0 ? '0' : installation.ContractValue}</ReadonlyItem>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6 text-muted pb-3">
                                <AddressCardBody
                                    AddressLabel={"Property Address"}
                                    Address1={installation.ClientAddressLine1}
                                    Address2={installation.ClientAddressLine2}
                                    Address3={installation.ClientAddressLine3}
                                    CityTown={installation.ClientCityTown}
                                    County={installation.ClientCounty}
                                    Postcode={installation.ClientPostcode}
                                />
                            </div>
                            <div className="col-md-6 text-muted pb-3">
                                <AddressCardBody
                                    AddressLabel={"Dispatch Address"}
                                    Address1={installation.DispatchAddressLine1}
                                    Address2={installation.DispatchAddressLine2}
                                    Address3={installation.DispatchAddressLine3}
                                    CityTown={installation.DispatchCityTown}
                                    County={installation.DispatchCounty}
                                    Postcode={installation.DispatchPostcode}
                                />
                            </div>
                            <div className='col-xs-12 col-md-4'>
                                <ReadonlyItem textLabel="Local Authority">{installation.Authorityname}</ReadonlyItem>
                            </div>
                            <div className='col-xs-12 col-md-4'>
                                <ReadonlyItem textLabel="Sent To LA">{installation.SendToLaDate ? moment(installation.SendToLaDate).format('DD MMM YYYY') : "Pending"}</ReadonlyItem>
                            </div>
                            <div className='col-xs-12 col-md-4'>
                                <ReadonlyItem textLabel="Homeowner Certificate Sent">{installation.SendHomeownerCertificate ? "Sent" : "Pending"}</ReadonlyItem>
                            </div>
                            <div className="col-xs-12 col-md-4">
                                <ReadonlyItem textLabel="Submission Date">{moment(installation.SubmissionDate).format('DD MMM YYYY')}</ReadonlyItem>
                            </div>
                            <div className="col-xs-12 col-md-4">
                                <ReadonlyItem textLabel="Work Completion Date">{moment(installation.WorkCompletionDate).format('DD MMM YYYY')}</ReadonlyItem>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 product-column">
                        <h3>Fensa Products</h3>

                        <div className='row'>
                            <div className="col-xs-12 col-md-3">
                                <ReadonlyItem className="active disabled" textLabel="Total Windows">{installation.NumberOfWindows === 0 ? '0' : installation.NumberOfWindows}</ReadonlyItem>
                            </div>
                            <div className="col-xs-12 col-md-3">
                                <ReadonlyItem className="active disabled" textLabel="Total Doors">{installation.NumberOfDoors === 0 ? '0' : installation.NumberOfDoors}</ReadonlyItem>
                            </div>
                            <div className="col-xs-12 col-md-3">
                                <ReadonlyItem className="active disabled" textLabel="Doors Less 50% glass">{installation.NoDoorsLess50Glass === 0 ? '0' : installation.NoDoorsLess50Glass}</ReadonlyItem>
                            </div>
                            <div className="col-xs-12 col-md-3">
                                <ReadonlyItem className="active disabled" textLabel="Doors More 50% glass">{installation.NoDoorsMore50Glass === 0 ? '0' : installation.NoDoorsMore50Glass}</ReadonlyItem>
                            </div>
                        </div>

                        <div className='card-deck'>
                            {
                                installationAppliances.Count === 0 ?
                                    (
                                        <h4>No products to display</h4>
                                    ) : (
                                        installationAppliances.map((appliance, index) =>
                                        (
                                            <Product
                                                id={appliance.Id}
                                                key={index}
                                                HideRemove={true}
                                                title={appliance.InstallationItemTypeId}
                                                NoItems={appliance.Count}
                                                Rating={appliance.ERPRating}
                                                LicenceNumber={appliance.BFRCLicenceNumber}
                                            />
                                        )
                                        )
                                    )
                            }
                        </div>

                    </div>
                    <div className='show'>

                        <div className={IBGProviderId === 1 && InstallerType === 0 ? 'row' : 'hide'}>
                            <div className="container">
                                <hr />
                                <h3>Non-FENSA Products</h3>
                            </div>
                            <div className='col-12'>
                                <div className='row'>

                                    <div className={'col-xs-12 col-md-6 pt-4'}>
                                        <ColumnAndLabelRadioOptions
                                            id="Conservatory"
                                            name="Conservatory"
                                            textLabel="Conservatory"
                                            optionNo={'No'}
                                            optionYes={'Yes'}
                                            value={Conservatory}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className={'col-xs-12 col-md-6 pt-4'}>
                                        <ColumnAndLabelRadioOptions
                                            id="RoofLine"
                                            name="RoofLine"
                                            textLabel="RoofLine"
                                            optionNo={'No'}
                                            optionYes={'Yes'}
                                            value={RoofLine}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className={'col-xs-12 col-md-6 px-3 pl-5'}>
                                <div className='row'>
                                    <div className="col-xs-12 col-sm-6 pl-4 pr-4">
                                        <ReadonlyItem className="disabled" textLabel="Non Fensa Doors">{installation.NonFENSAWindows === 0 ? '0' : installation.NonFENSAWindows}</ReadonlyItem>
                                    </div>
                                </div>
                            </div>


                            <div className={'col-xs-12 col-md-6 px-3 pl-5'}>
                                <div className='row'>
                                    <div className="col-xs-12 col-sm-6 pl-4 pr-4">
                                        <ReadonlyItem className="disabled" textLabel="Non Fensa Doors">{installation.NonFENSADoors === 0 ? '0' : installation.NonFENSADoors}</ReadonlyItem>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 product-column">
                        <h3>Events</h3>
                        <div >
                            {
                                InstallationEvents.Count === 0 ?
                                    (
                                        <h4>No Events to display</h4>
                                    ) : (
                                        InstallationEvents.map((Events, index) => (
                                            <div className="row">
                                                <div className='col-xs-8 col-md-8'>{Events.InstallationEventTypeNameDesc}</div>
                                                <div className='col-xs-4 col-md-4'>{moment(Events.CreatedOn).format('DD MMM YYYY')}</div>
                                            </div>))
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );

    }

    fetchData = () => {

        var postCompanyData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetInstallerInstallationValidation",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                CompanyId: this.state.CompanyId
            })
        });

        var fetchCompanyItem = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postCompanyData
        });


        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallationGet",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                installationId: this.state.installationId,
                CompanyId: this.state.CompanyId
            })
        });

        var fetchInstallation = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        });


        var appliancesData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerInstallationAppliances",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                installationId: this.state.installationId
            })
        });

        var fetchAppliances = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: appliancesData
        });

        var EventData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerInstallationEvents",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                installationId: this.state.installationId
            })
        });

        var fetchEvents = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: EventData
        });

        Promise.all([fetchInstallation, fetchAppliances, fetchEvents, fetchCompanyItem])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                const data = JSON.parse(responses[0]);
                const appliances = JSON.parse(responses[1]);
                const installData = JSON.parse(data.ReturnData.Payload)[0];
                const installEvents = JSON.parse(responses[2]).message === null ? [] : JSON.parse((JSON.parse(responses[2]).message));
                const applianceData = appliances.ReturnData.Payload === null ? [] : JSON.parse(appliances.ReturnData.Payload);

                const CompanyData = JSON.parse(JSON.parse(responses[3]).ReturnData.Payload);

                if (debugOn) {
                    console.log(this.displayName + '.fetchData[data]', data);
                    console.log(this.displayName + '.fetchData[appliances]', appliances);
                    console.log(this.displayName + '.fetchData[installData]', installData);
                    console.log(this.displayName + '.fetchData[applianceData]', applianceData);
                    console.log(this.displayName + '.fetchData[CompanyData]', CompanyData);
                }

                console.log(this.displayName + '.fetchData[installEvents]', installEvents);

                this.setState({
                    installation: installData,

                    // do not show edit button is the installation is a conservatory
                    ShowEditButton: installData.Conservatory === true ? false : this.state.ShowEditButton,
                    installationAppliances: applianceData,
                    InstallationEvents: installEvents,
                    loading: false,
                    notificationPostfix: data.returncode === 0 ? 'info show mt-3' : 'info hide',
                    notificationMessage: data.returncode === 0 ? ['No Data'] : [],
                    totals: installData.length,

                    RegistrationStatus: CompanyData.RegistrationStatus,
                    InstallerCompanyRegistrationId: CompanyData.CurrenctActiveScheme,
                    InstallerType: CompanyData.InstallerType,
                    IBGProvider: CompanyData.IBGProviderId,

                    displayContent: installData === null ?
                        this.renderNoDataView() :
                        this.renderTable(installData, applianceData, installEvents, CompanyData.InstallerType,
                            CompanyData.IBGProviderId,
                            installData.Conservatory === true ? 1 : 0,
                            installData.RoofLine === true ? 1 : 0)
                });
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData[catch]', data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    displayContent: ''
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'Installation View',
            action: 'View Installation'
        });

        return (
            <Fragment>
                <div className='form-group-fluid-container mr-auto ml-auto'>
                    <div className='form-group-title'>View Installation</div>
                    <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                    {this.state.displayContent}

                    <div className='row py-3'>

                        <div className='col'>

                            <Link className={this.state.ShowEditButton ? 'btn btn-sm btn-primary pull-right mdb-btn-resize' : 'hide'} to={'/Installations/Edit/' + this.state.installationId}>
                                <i className="fa fa-edit pr-2" />Edit
                            </Link>

                            <Link className='btn btn-sm btn-dark pull-right mr-2 mdb-btn-resize' to={installationsHomeUrl}>
                                <i className='fa fa-arrow-left pr-2' />
                                Back to Installations
                            </Link>
                        </div>
                    </div>
                    <div align="center">
                        <p className={"grey-warning"}>As this installation has now been successfully registered, you are unable to make changes.</p>
                        <p className={"grey-warning"}>If the information registered for this installation is incorrect please call FENSA on 02076453700.</p>
                    </div>
                </div>
            </Fragment>
        );
    }
}
