
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import uuid from "uuid";

import AuthHelper from '../SharedComponents/AuthHelper';
import { CardBodyOnly } from '../SharedComponents/CardBodyOnly';
import { NotificationPanel } from '../SharedComponents/NotificationPanel';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import ReactTable from "react-table";
import Moment from 'react-moment';
import { CardFooterButtonsIcons } from '../SharedComponents/CardFooterButtonsIcons';

const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class InvoiceViewInspections extends Component {

    displayName = InvoiceViewInspections.name;

    constructor(props) {
        super(props);

        const auth = new AuthHelper();

        this.state = {
            //...props,
            CompanyId: auth.CompanyId,
            InvoiceId: this.props.InvoiceId,
            Installations: [],
            UsePaging: true,
            loading: true,
            pageSize: 10,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            ShowViewButton: true
        };
    }

    componentWillMount() {
        this.fetchData();
    }

    renderTable = (Installations) => {

        return (
            <Fragment>
                {
                    Installations ?
                        (
                            <ReactTable data={Installations}
                                columns={[
                                    {
                                        key: 'Id',
                                        Header: 'Id',
                                        accessor: 'Id',
                                        show: false
                                    },
                                    {
                                        Header: 'Reference',
                                        accessor: 'InspectionReference'
                                    },
                                    {
                                        Header: 'Installation Reference',
                                        accessor: 'InstallationReference, WorkCompletionDate',
                                        width: 200,
                                        Cell: row => {
                                            //if(row)
                                            return (
                                                <div>
                                                    <Link to={"/Installations/View/" + row.original.InstallationId}>{row.original.InstallationReference}</Link><br />
                                                    <div>
                                                        Installed on <Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.WorkCompletionDate} ></Moment>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        Header: 'Postcode',
                                        accessor: 'Postcode'
                                    },

                                    {
                                        Header: 'Completion Date', // Custom header components!
                                        accessor: 'WorkCompletionDate',
                                        Cell: row => {
                                            return (
                                                <div>
                                                    <Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.WorkCompletionDate} ></Moment>
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        Header: 'Due Inspection', // Custom header components!
                                        accessor: 'DueInspection',
                                        Cell: row => {
                                            return (
                                                <div>
                                                    <Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.DueInspection}></Moment>
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        Header: 'Inspected On', // Custom header components!
                                        accessor: 'InspectedOn',
                                        Cell: row => {
                                            return (
                                                <div>
                                                    <Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.InspectedOn}></Moment>
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        Header: 'Email', // Custom header components!
                                        accessor: 'Email'
                                    },

                                    {
                                        Header: 'InspectionType', // Custom header components!
                                        accessor: 'InspectionType'
                                    },
                                    {
                                        Header: 'InspectionStatus', // Custom header components!
                                        accessor: 'InspectionStatus'
                                    },
                                    {
                                        Header: '',
                                        accessor: 'Edit',
                                        filterable: false,
                                        Cell: row => {
                                            return (
                                                <Fragment>
                                                    <div className='text-center py-1 pr-5'>
                                                        <CardFooterButtonsIcons
                                                            Id={row.original.Id}
                                                            ViewUrl={'/Inspections/View/' + row.original.Id}
                                                            ShowViewButton={this.state.ShowViewButton} // use role priviledge to set visibility

                                                        />
                                                    </div>
                                                </Fragment>
                                            );
                                        }
                                    }

                                ]}
                                className="table-bordered table-striped table-hover table-shadowed"
                                defaultPageSize={this.state.pageSize}
                                minRows={0}
                                nextText='>'
                                previousText='<'

                            />
                        ) : this.renderNoDataView()
                }
            </Fragment>
        );
    }

    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    fetchData = () => {

        var MemberDeclarationData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetInstallerInvoiceInspections",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                CompanyId: this.state.CompanyId,
                InvoiceId: this.state.InvoiceId
            })
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: MemberDeclarationData
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                const installationData = JSON.parse(data.ReturnData.Payload);

                console.log(installationData);

                this.setState({
                    Installations: installationData,
                    loading: false
                });
            })
            .catch(data => {
                if (debugOn) {
                    console.log(data);
                }
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - No Data or service not available']
                });
            });
    };

    render() {
        let contents = this.state.loading
            ? <PleaseWait IsLoading={true} />
            : this.renderTable(this.state.Installations);

        return (
            <div className='fluid-container'>
                <div>
                    <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                    {contents}
                </div>
            </div>
        );
    }
}
