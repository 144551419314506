import React, { Component, Fragment } from 'react';

import { PleaseWait } from '../SharedComponents/PleaseWait';

//import { Col, Grid, Row } from 'react-bootstrap';
//import { Link } from 'react-router-dom';
//import { Glyphicon, Nav, Navbar, NavItem } from 'react-bootstrap';

export class InspectionSearch extends Component {
    displayName = InspectionSearch.name

    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    render() {
        return (
            <div className='form-group'>
                <div className='row'>
                    <div className='col-xs-6 col-md-4' >
                        <input id='Reference' name='Reference' value={this.props.DataProps.Reference} className='form-control' type='text' placeholder='Reference' onChange={this.props.handleChange} />
                    </div>
                    <div className='col-xs-6 col-md-4' >
                        <input id='Postcode' name="Postcode" value={this.props.DataProps.Postcode}  className='form-control' type='text' placeholder='Postcode' onChange={this.props.handleChange} />
                    </div>

                    <div className='col-xs-6 col-md-4' >
                        <select ID="Year" name="Year" className="form-control" onChange={this.props.handleChange} value={this.props.DataProps.Year}>
                            {
                                this.props.Years.map((Years, index) => <option value={Years.ID}>{Years.Name}</option>)
                            }
                        </select>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-6 col-md-4' >
                        <select ID="Month" name="Month" className="form-control" onChange={this.props.handleChange} value={this.props.DataProps.Month}>
                            {
                                this.props.Months.map((Months, index) => <option value={Months.ID}>{Months.Name}</option>)
                            }
                        </select>
                    </div>

                    <div className='col-xs-6 col-md-4' >
                        <select ID="Type" name="Type" className="form-control" onChange={this.props.handleChange} value={this.props.DataProps.Type}>
                            {
                                this.props.InspectionType.map((InspectionType, index) => <option value={InspectionType.ID}>{InspectionType.Name}</option>)
                            }
                        </select>

                    </div>
                    <div className='col-xs-6 col-md-4' >

                        <select ID="Status" name="Status" className="form-control" onChange={this.props.handleChange} value={this.props.DataProps.Status}>
                            {
                                this.props.InsepectionStatusType.map((InsepectionStatusType, index) => <option value={InsepectionStatusType.ID}>{InsepectionStatusType.Name}</option>)
                            }
                        </select>

                    </div>
                </div>
                <div className='row'>

                    <div className='col-md-12'>

                        {/*<a className='btn btn-primary pull-right' onClick={this.props.handleSearch} onChange={this.props.handleChange}>Search</a>*/}
                        <a className='btn btn-success btn-sm pull-right' onClick={this.props.handleSearch}><i className="fa fa-search pr-2" />Search</a>

                        <a className='btn btn-primary btn-sm pull-right' onClick={this.props.handleClear}><i className="fa fa-times-circle pr-2" />Clear</a>


                        <PleaseWait IsLoading={this.props.loading} />
                    </div>
                </div>
            </div>
        );
    }
}