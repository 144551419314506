import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { ReadonlyItem } from '../SharedComponents/ReadonlyItem';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import { NotificationPanel } from '../SharedComponents/NotificationPanel';
import { APIIntegrationDetails } from './APIIntegrationDetails';
import { DownloadPDF } from '../SharedComponents/DownloadPDF';
import './Company.css';

import {
    MDBInput,
    MDBBtn,
    MDBIcon
} from "mdbreact";

import { Link } from 'react-router-dom';
// const detailsHomeUrl = '/Details';
const debugOn = process.env.DEBUG_MODE_ON;

export class APIKey extends Component {
    displayName = APIKey.name;

    constructor(props) {
        super(props);

        const auth = new AuthHelper();

        this.state = {
            CompanyId: auth.CompanyId,
            UserId: auth.UserId,
            CompanyName: '',
            CompletedAfter: '',
            CompletedBefore: '',
            DispatchPostcode: '',
            InstallerReference: '',
            InstallPostcode: '',
            Reference: '',
            UsePaging: true,
            loading: true,
            notificationMessage: '',
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 0,
            payload: [],
            TermsOfUseAgreed: false,
            apiData: null,
            IntegData:''
        };

        this.termsOfUseChanged = this.termsOfUseChanged.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate() { console.log("Component update") }

    termsOfUseChanged() {
        var newState = !this.state.TermsOfUseAgreed;
        this.setState({ TermsOfUseAgreed: newState });
    }  
         
    fetchData = () => {

        var fetchAPIKey = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.GetCompanyAPIKeyDetails",
                //datatype: "BIT.Fensa.InstallerPortal.GetCompanyEditDetails",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyId,
                    UserId: this.state.UserId
                })
            })
        });

        Promise.all([fetchAPIKey])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                console.log(this.displayName + '.fetchData[responses]', responses);
                var data = this.extractData(responses[0]);
                console.log(this.displayName + '.fetchData[data]', data);
                this.setState({
                    payload: data,//JSON.parse(JSON.parse(responses[0]).message),
                    apiData: !data ? null : data[0],
                    loading: false,
                    notificationPostfix: 'info hide',
                    notificationMessage: []
                });
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData[err]', data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - No data or service unavailable'],
                    payload: []
                });
            });
            fetch(process.env.REACT_APP_FENSA_WEB_API + 'installer/GetIntegrationDetails', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
               
            })
                .then(response => response.json())
                .then(data => {
                    //  console.log('rept',data)
                    this.setState({
                        IntegData: data,
          
                    });
                });
    }

    submitAPIKeyRequest = () => {
        console.log("Submit request")

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.CreateCompanyAPIKeyDetails_sp",
                origin: "Installer Portal",
                operation: "INSERT",
                datavalues: JSON.stringify({
                    A_CompanyId: this.state.CompanyId
                })
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                console.log(data);
                var resid = JSON.parse(data.ReturnData.Payload).Id;
                this.setState({
                    loading: false,
                    notificationPostfix: 'success show',
                    //notificationMessage: data.ReturnData.Errors
                    notificationMessage: ['Successfully generated api key']
                });
                this.fetchData();
            })
            .catch(data => {
                if (debugOn) {
                    console.log(this.displayName + '.submitForm[catch->create]', data);
                }
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - Failed to create api key']
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'API Key Details',
            action: 'API Key Details'
        });

        if (!this.state.apiData) return (<div><PleaseWait IsLoading={true} /></div>);

        if (this.state.apiData.APIKey) {
            return (
                <>
                <div className='form-group-container mr-auto ml-auto'>
                    <div className='form-group'>
                        <div className='h3 h3-responsive'>{this.state.apiData.CompanyName} </div>
                    </div>
                    <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                    <div className="form-group">
                        <ReadonlyItem textLabel="Your API Key">{this.state.apiData.APIKey}</ReadonlyItem>
                    </div>
                </div>
                <DownloadPDF EnableDownload={true} DownloadLabel={this.state.IntegData} FileName={'APIIntegration Details.pdf'} Text='APIIntegration Details' />
                <br/>
                <br />
                <br></br>
                <APIIntegrationDetails />
                    </>

            );
        }
        else {
            return (
                <>
                <div className='form-group-container mr-auto ml-auto'>
                    <div className='form-group'>
                        <div className='h3 h3-responsive'>{this.state.apiData.CompanyName} </div>
                    </div>
                    <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                    <h2 className="h2-responsive">Terms of use</h2>
                    <div dangerouslySetInnerHTML={{ __html: this.state.apiData.APIDeclarationOfUse }} />
                    <div className="text-center pt-3">
                        <MDBInput label="I have read and understood to continue" type="checkbox" id="TermsOfUseAgreed" name="TermsOfUseAgreed" onChange={this.termsOfUseChanged} />
                    </div>
                    {this.state.TermsOfUseAgreed ? (
                        <div className="form-group" style={{ height: "36px" }}>
                            <MDBBtn
                                className="pull-right mdb-btn-resize "
                                color="success"
                                type="submit"
                                size="sm"
                                onClick={this.submitAPIKeyRequest}
                            >
                                <MDBIcon icon="save" className="mr-2" />Generate API Key
                                </MDBBtn>
                        </div>
                    ) : null}
                    </div>
                    <APIIntegrationDetails />
                </>
            );
        }
    }
}

/// Handle data retrieval and handle ensuing errors if data is not found
APIKey.prototype.extractData = response => {
    try {
        return JSON.parse(response).returncode !== 0 ? JSON.parse(JSON.parse(response).message) : [];
    } catch (e) {
        console.log(e);
        return [];
    }
};