import React, { Component } from 'react';
import { FooterButtons } from './FooterButtons';

export class CardWithoutImageHeader extends Component {
    displayName = CardWithoutImageHeader.name;

    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
        //console.log(this.displayName + '.ctor: ', auth);
    }

    render() {
        //console.log(this.displayName + '.render', this.state);
        return (
            <div className='card-item-wrapper'>
                <div className={'card' + this.state.CssClass} key={this.state.Id}>
                    <div className='card-header text-capitalize'>
                        {this.state.HeaderIcon ? (<i className={this.state.HeaderIcon} />) : ''}
                        {this.state.Header ? this.state.Header : '.'}
                    </div>
                    <div className='card-body'>
                        <div className='card-title lead text-capitalize'>
                            {this.state.TitleIcon ? (<i className={this.state.TitleIcon} title={this.state.TitleIconTitle} />) : ''}
                            {this.state.Title ? (<strong>{this.state.Title}</strong>) : '.'}
                        </div>
                        <div className='form-group'>
                            <div className='text-muted'>
                                {this.state.children}
                            </div>
                        </div>
                    </div>
                    <div className='card-footer text-right bg-white border-0 justify-content-between pb-3'>
                        {
                            FooterButtons(this.state)
                        }
                    </div>
                </div>
            </div>
        );
    }
}
