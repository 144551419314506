import React, { Component } from 'react';
import { MenuItems } from '../SharedComponents/MenuItems';

import ReactGA from 'react-ga';

export class News extends Component {
    displayName = News.name

    render() {

        ReactGA.event({
            category: 'News',
            action: 'News Home'
        });

        return (
            <MenuItems ParentName="Fensa News" />
        );
    }
}
