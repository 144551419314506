import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import Moment from 'react-moment';
import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { ButtonsJss } from '../SharedComponents/ButtonsJss';
import { CardFooterButtonsIcons } from '../SharedComponents/CardFooterButtonsIcons';
import { CardWithoutImageHeader } from '../SharedComponents/CardWithoutImageHeader';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import { ReactTableFilterToggler } from './../SharedComponents/ReactTableFilterToggler';
//import { ResponsiveTable } from '../SharedComponents/ResponsiveListTable';
import { ViewToggler } from '../SharedComponents/ViewToggler';

import Badge from "@material-ui/core/Badge";

const defaultView = 'address-card';
const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class Schemes extends Component {
    displayName = "Schemes";

    constructor(props) {
        super(props);
        const auth = new AuthHelper(this.displayName);
        this.state = {
            CompanyId: auth.CompanyId,
            ShowEditButton: false,
            ShowDeleteButton: false,
            ShowViewButton: false,
            UsePaging: true,
            installation: [],
            displayContent: (<PleaseWait IsLoading={true} />),
            displayView: auth.DisplayView ? auth.DisplayView : defaultView,
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 10,
            payload: [],
            resized: [],
            sorted: [],
            totals: '',
            useFiltering: (auth.TableFilter && auth.TableFilter === 'On' ? true : false)
        };
    }

    componentDidMount = () => {
        this.fetchData();
    }

    filterCaseInsensitive = ({ id, value }, row) => {
        //console.log(id, value, row[id], row);
        if (id === 'Active') {
            const yes = 'yes';
            const no = 'no';
            return row[id] === 1 ? yes.includes(value.toLowerCase()) : no.includes(value.toLowerCase());
        } else {
            return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : false;
        }
    }

    handleToggleFilter = toggle => {
        this.setState({
            useFiltering: toggle
        });
        this.fetchData();
    }

    parseAsString = (value, nullValue) => {
        try {
            return value ? value : nullValue ? nullValue : '';
        } catch (e) {
            console.log(e);
            return '.';
        }
    }

    renderCards = payload => {
        return (
            <div className='card-deck'>
                {
                    payload.map(contact =>
                        (
                            <div className='card-wrapper' key={contact.Id} title={contact.Active ? contact.SchemeName : 'disabled'}>
                                <CardWithoutImageHeader
                                    CssClass={contact.Enabled ? ' card-item-highlight' : ''}
                                    Id={contact.Id}
                                    Header={this.parseAsString(contact.SchemeName)}
                                    HeaderIcon={contact.Primary ? 'fas fa-user-tag pr-3' : ''}
                                    Title={contact.SchemeName}
                                    ButtonStyle={ButtonsJss.ButtonStyles.icon}
                                    UseDefaultCss={false}
                                    //EditUrl={"/UserManagement/User/Update/"}
                                    //DeleteUrl={"/UserManagement/User/Delete/"}
                                    //ViewUrl={"/UserManagement/User/View/"}
                                    ShowViewButton={this.state.ShowViewButton} // use role priviledge to set visibility
                                    ShowEditButton={this.state.ShowEditButton} // use role priviledge to set visibility
                                    ShowDeleteButton={this.state.ShowDeleteButton}// use role priviledge to set visibility
                                >
                                    <div className='pt-3'>
                                        Status:
                                        <i className="fa fa-1x pr-4" />
                                        {contact.StatusName}</div>
                                    <div className='pt-3'>
                                        Date Started:
                                        <i className="fa fa-1x pr-4" />
                                        <Moment
                                            parse='YYYY-MM-DD HH:mm'
                                            format="DD MMM YYYY"
                                            date={contact.DateStarted}
                                        />
                                    </div>
                                    <div className='pt-3'>
                                        Date Ended:
                                        <i className="fa fa-1x pr-4" />
                                        <Moment
                                            parse='YYYY-MM-DD HH:mm'
                                            format="DD MMM YYYY"
                                            date={contact.DateEnded}
                                        />
                                    </div>
                                    <div className='pt-3'>
                                        Active:
                                        <i className="fa fa-1x pr-4" />
                                        {contact.Active === 1 ? <i className="fas fa-check" /> : <i className="fas fa-times text-center" />}
                                    </div>
                                </CardWithoutImageHeader>
                            </div>
                        )
                    )
                }
            </div>
        );
    }

    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    renderCardViewHandler = viewName => {
        //console.log(viewName);
        this.setState({
            displayView: viewName,
            displayContent: (this.state.payload === undefined || this.state.payload === null || this.state.payload.length === 0) ?
                this.renderNoDataView : this.renderCards(this.state.payload)
        });
        new AuthHelper().CacheDisplayView(this.displayName, viewName);
    }

    renderListViewHandler = viewName => {
        this.setState({
            displayView: viewName,
            displayContent: (this.state.payload === undefined || this.state.payload === null || this.state.payload.length === 0) ?
                this.renderNoDataView : this.renderTable(this.state.payload)
        });
        //store in sessionStorage
        new AuthHelper().CacheDisplayView(this.displayName, viewName);
    }

    renderTable = installation => {
        return (
            <div className='py-0'>
                <ReactTableFilterToggler
                    ParentDisplayName={this.displayName}
                    UseFiltering={this.state.useFiltering}
                    handleToggleFilter={this.handleToggleFilter}
                />
                <small className='text-muted'>When filtering by date fields use year-month-day format <code>e.g.1900-01-01</code></small>
                <ReactTable data={installation}
                    columns={[
                        {
                            Header: 'Id',
                            accessor: 'Id',
                            filterable: false,
                            show: false
                        },
                        {
                            Header: 'Scheme Name',
                            accessor: 'SchemeName',
                            className: 'p-3'
                        },
                        {
                            Header: 'Status Name',
                            accessor: 'StatusName',
                            className: 'p-3'
                        },
                        {
                            Header: 'Active',
                            accessor: 'Active',
                            className: 'p-3',
                            headerStyle: { textAlign: 'centre' },
                            Cell: row => {
                                return (
                                    //row.original.Active === 1 ? <div className="text-center"> <i className="fas fa-check" /></div> : <div className="text-center"><i className="fas fa-times text-center" /></div>
                                    row.original.Active === true ? <div className="text-center">Yes</div> : <div className="text-center">No</div>
                                );
                            }
                        },
                        {
                            Header: 'Date Started', // Custom header components!
                            className: 'p-3',
                            accessor: 'DateStarted',
                            Cell: row => {
                                return (
                                    <Moment
                                        parse='YYYY-MM-DD HH:mm'
                                        format="DD MMM YYYY"
                                        date={row.original.DateStarted}
                                    />
                                );
                            }
                        },
                        {
                            Header: 'Date Ended', // Custom header components!
                            className: 'p-3',
                            accessor: 'DateEnded',
                            Cell: row => {
                                return (
                                    <Moment
                                        parse='YYYY-MM-DD HH:mm'
                                        format="DD MMM YYYY"
                                        date={row.original.DateEnded}
                                    />
                                );
                            }
                        },
                        {
                            Header: '',
                            accessor: 'Id',
                            filterable: false,
                            sortable: false,
                            Cell: row => {
                                return (
                                    /*<div>
                                        <Link className='btn btn-primary' to={"/UserManagement/EditUser/" + row.original.Id}>Edit</Link>
                                    </div>*/
                                    < Fragment >
                                        <div className='text-center py-1'>
                                            <CardFooterButtonsIcons
                                                Id={row.original.Id}
                                                ShowViewButton={this.state.ShowViewButton} // use role priviledge to set visibility
                                                ShowEditButton={this.state.ShowEditButton} // use role priviledge to set visibility
                                                ShowDeleteButton={this.state.ShowDeleteButton}// use role priviledge to set visibility
                                            />
                                        </div>
                                    </Fragment>
                                );
                            }
                        }
                    ]}
                    className="table-bordered table-striped table-hover table-shadowed"
                    defaultPageSize={10}
                    expanded={this.state.expanded}
                    filterable={this.state.useFiltering}
                    minRows={0}
                    nextText='>'
                    previousText='<'
                    defaultFilterMethod={this.filterCaseInsensitive}
                />
            </div>
        );
    }

    fetchData = () => {

        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetInstallerCompanySchemes",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                CompanyId: this.state.CompanyId
            })
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: postData
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                const definedUsers = JSON.parse(data.message).sort(function (a, b) {
                    // make main branch first item in the list
                    //console.log('a:', a);
                    //console.log('b:', b);
                    //if (a.Primary > b.Primary) { return -1; }
                    if (a.Name < b.Name) { return -1; }
                    if (a.Name > b.Name) { return 1; }
                    return 0;
                });
                console.log(definedUsers);
                const view = this.state.displayView;
                //this.setState({
                //    installation: JSON.parse(data.message),
                //    loading: false
                //});
                this.setState({
                    loading: false,
                    payload: definedUsers,
                    totals: definedUsers.length,
                    notificationPostfix: data.returncode === 0 ? 'info show mt-3' : 'info hide',
                    notificationMessage: data.returncode === 0 ? ['No Data'] : [],
                    displayContent: view === defaultView ?
                        this.renderCards(definedUsers) :
                        this.renderTable(definedUsers)
                });
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData[catch]', data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    displayContent: ''
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'Schemes',
            action: 'View Schemes'
        });



        return (
            <div className='fluid-container'>
                <div className='row'>
                    <div className='col-xs-12 col-sm-6 col-md-6'>
                        <h2>
                            <Badge badgeContent={this.state.totals} color="primary">Schemes</Badge>
                        </h2>
                    </div>
                </div>
                <div className='d-flex flex-row-reverse'>
                    <div className='pr-2'>
                        <ViewToggler
                            viewName={this.state.displayView}
                            renderCard={this.renderCardViewHandler}
                            renderList={this.renderListViewHandler}
                        />
                    </div>
                </div>
                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                <div className='col-xs-12 pb-3'>
                    {this.state.displayContent}
                </div>
            </div>
        );
    }

}