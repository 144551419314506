import React from 'react';

import { ButtonsJss } from './ButtonsJss';
import { CardFooterButtonsIcons } from './CardFooterButtonsIcons';
import { CardFooterButtonsText } from './CardFooterButtonsText';
import { CardFooterButtonsTextAndIcons } from './CardFooterButtonsTextAndIcons';

export const FooterButtons = (props) => {
    const deleteCss = props.UseDefaultCss ? ButtonsJss.DefaultDeleteCss : props.DeleteCss;
    const editCss = props.UseDefaultCss ? ButtonsJss.DefaultEditCss : props.EditCss;
    const viewCss = props.UseDefaultCss ? ButtonsJss.DefaultViewCss : props.ViewCss;
    switch (props.ButtonStyle) {
        case ButtonsJss.ButtonStyles.texticon:
            return (
                <CardFooterButtonsTextAndIcons
                    Id={props.Id}
                    DeleteCss={deleteCss}
                    EditCss={editCss}
                    ViewCss={viewCss}
                    DeleteUrl={props.DeleteUrl + props.Id}
                    EditUrl={props.EditUrl + props.Id}
                    ViewUrl={props.ViewUrl + props.Id}
                    ShowViewButton={props.ShowViewButton} // use role priviledge to set visibility
                    ShowEditButton={props.ShowEditButton} // use role priviledge to set visibility
                    ShowDeleteButton={props.ShowDeleteButton}// use role priviledge to set visibility
                />
            );
        case ButtonsJss.ButtonStyles.text:
            return (
                <CardFooterButtonsText
                    Id={props.Id}
                    DeleteCss={deleteCss}
                    EditCss={editCss}
                    ViewCss={viewCss}
                    DeleteUrl={props.DeleteUrl + props.Id}
                    EditUrl={props.EditUrl + props.Id}
                    ViewUrl={props.ViewUrl + props.Id}
                    ShowViewButton={props.ShowViewButton} // use role priviledge to set visibility
                    ShowEditButton={props.ShowEditButton} // use role priviledge to set visibility
                    //ShowDeleteButton={props.ShowDeleteButton}// use role priviledge to set visibility
                />
            );
        default:
            return (
                <CardFooterButtonsIcons
                    Id={props.Id}
                    DeleteCss={deleteCss}
                    EditCss={editCss}
                    ViewCss={viewCss}
                    DeleteUrl={props.DeleteUrl + props.Id}
                    EditUrl={props.EditUrl + props.Id}
                    ViewUrl={props.ViewUrl + props.Id}
                    ShowViewButton={props.ShowViewButton} // use role priviledge to set visibility
                    ShowEditButton={props.ShowEditButton} // use role priviledge to set visibility
                    //ShowDeleteButton={props.ShowDeleteButton}// use role priviledge to set visibility
                />
            );
    }
};
