import React, { Component } from 'react';
import uuid from "uuid";

import { ColumnAndLabel } from './ColumnAndLabel';
import { PleaseWait } from './PleaseWait';

import {
    MDBBtn,
    MDBIcon
} from "mdbreact";

const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class AddressLookup extends Component {

    displayName = AddressLookup.name

    constructor(props) {
        super(props);

        this.state = {
            ...props,
            PostCodeItems: [],
            formValid: false,
            //loading: true,
            shouldHideBFRC: true,
            HideAddress: false,
            HideList: true
        };

        this.onClickLookupAddress = this.onClickLookupAddress.bind(this);
        this.onSelectAddress = this.onSelectAddress.bind(this);
    }

    //componentWillMount() {
    //    //this.setState({ loading: false });
    //    console.log('AddressLookup.componentWillMount',this.state);
    //}

    toggleLookupClick = status => {
        //console.log(this.displayName + '.toggleLookupClick', status);
        this.props.handleLookupClick(status);
    }

    handleAddressNotFound = msg => {
        this.props.handleAddressNotFound(msg);
    }

    onSelectAddress(event) {
        event.preventDefault();

        const target = event.target;
        var Id = target.attributes.getNamedItem('data-moniker').value;
        let obj = this.state.PostCodeItems.find(obj => obj.Moniker === Id);
        var Address = {
            Address1: obj.QAAddress.AddressLine[0].Line,
            Address2: obj.QAAddress.AddressLine[1].Line,
            Address3: obj.QAAddress.AddressLine[2].Line,
            CityTown: obj.QAAddress.AddressLine[3].Line,
            County: obj.QAAddress.AddressLine[4].Line,
            Postcode: obj.QAAddress.AddressLine[5].Line,
            // Fix as Jersey adresses do not return the Local Authority
            // neither it appears do Guernsey or Isle of man !!!
            AuthorityId: obj.QAAddress.AddressLine[5].Line.slice(0, 2) === "JE" ? "JE01"
                : obj.QAAddress.AddressLine[5].Line.slice(0, 2) === "GY" ? "GUER" 
                    : (obj.QAAddress.AddressLine[5].Line.slice(0, 2) === "IM" ? "IM01" : obj.QAAddress.AddressLine[6].Line)
        };

        this.props.HandleAddressSelected(Address);
        //this.state.PostCodeItems.find();
        this.setState({
            HideList: true,
            HideAddress: false
        });
    }

    onClickLookupAddress = (event) => {

        event.preventDefault();

        if (this.props.Postcode === "") {
            //show error modal
            this.handleAddressNotFound('Bad Request - Postcode not supplied');
            return;
        }

        //force please wait into view
        this.toggleLookupClick(true);

        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.PostcodeSearch",
            origin: "search",
            operation: "search",
            datavalues: this.props.Postcode
        });

        //if (debugOn) {
        //    console.log(this.displayName + '.onClickLookupAddress', this.props.Postcode);
        //}

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                const searchData = JSON.parse(data.ReturnData.Payload);
                //if (debugOn) {
                //    console.log(this.displayName + '.onClickLookupAddress.fetch[returncode]', data.returncode);
                //    console.log(this.displayName + '.onClickLookupAddress.fetch[data]', data);
                //    console.log(this.displayName + '.onClickLookupAddress.fetch[searchData]', JSON.parse(data.ReturnData.Payload));
                //    console.log(this.displayName + '.onClickLookupAddress.fetch[searchData.length]', searchData.QASearchResult.QAPicklist.PicklistEntry[0].Postcode);
                //}
                //const searchData = JSON.parse(data.message);
                if (data.returncode === 0 ||
                    (!searchData.QASearchResult ||
                        !searchData.QASearchResult.QAPicklist ||
                        !searchData.QASearchResult.QAPicklist.PicklistEntry ||
                        !searchData.QASearchResult.QAPicklist.PicklistEntry[0].Postcode)) {
                    this.setState({
                        HideList: false,
                        HideAddress: true,
                        PostCodeItems: []
                    });
                    this.handleAddressNotFound('Bad Request - Address not found');
                } else {
                    //if (debugOn) {
                    //    console.log(this.displayName + '.onClickLookupAddress.fetch[data]', data);
                    //    console.log(this.displayName + '.onClickLookupAddress.fetch[data.ReturnData]', JSON.parse(data.ReturnData.Payload));
                    //    console.log(this.displayName + '.onClickLookupAddress.fetch[data.searchData]', searchData.QASearchResult.QAPicklist.PicklistEntry);
                    //}
                    this.setState({
                        HideList: false,
                        HideAddress: true,
                        PostCodeItems: searchData.QASearchResult.QAPicklist.PicklistEntry
                    });
                    this.toggleLookupClick(false);
                }
            })
            .catch(data => {
                if (debugOn) {
                    console.log(this.displayName + '.onClickLookupAddress.fetch.error', data);
                }
                this.setState({
                    HideList: false,
                    HideAddress: true
                });
                this.handleAddressNotFound('Bad Request - Address not found or service not available');
            });
    }

    render() {
        return (
            <div>
                <div className='d-flex'>
                    <div className='d-flex flex-column'>
                        <ColumnAndLabel
                            ErrorMessage={this.props.ErrorMessage}
                            IconLabel="home"
                            Id={this.props.PostcodeId}
                            InputType="text"
                            SizeCss="md"
                            HintLabel=""
                            IsRequired={this.props.IsRequired}
                            TextLabel={this.props.PostcodeText}
                            Validate={this.props.Validate}
                            Value={this.props.Postcode}
                            HandleInputChange={this.props.handleChange}
                            HandleOnInput={this.props.handleOnInput}
                        />
                    </div>
                    <div className='d-flex flex-column pl-2 pt-3'>
                        <MDBBtn
                            className="mdb-btn-resize"
                            color="primary"
                            icon="search"
                            type="button"
                            size="sm"
                            floating
                            onClick={this.onClickLookupAddress}
                        >
                            <MDBIcon icon="search" className="mr-2" />
                        </MDBBtn>
                    </div>
                    <PleaseWait IsLoading={this.props.loading} />
                </div>
                <div hidden={this.state.HideList}>
                    <h5>Address Lookup</h5>
                    <ul className="multi-select-list address-list with-distinctive-items">
                        {
                            this.state.PostCodeItems.map((Address, index) =>
                                (
                                    <li key={index}>
                                        <a
                                            className='text-link'
                                            onClick={this.onSelectAddress}
                                            data-moniker={Address.Moniker}
                                        >
                                            {Address.PartialAddress}
                                        </a>
                                    </li>
                                )
                            )
                        }
                    </ul>
                </div>
            </div>
        );
    }
}