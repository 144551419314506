import React, { Component } from 'react';
import ReactGA from 'react-ga';
import '../Fensa.css';

export class HistoricNews extends Component {
    //constructor(props) {
    //    super(props);
    //}

    //componentDidMount() {
    //}

    render() {

        ReactGA.event({
            category: 'Historic News',
            action: 'Historic News View'
        });

        return (
            <div className="container">
                <div className="row">
                    <div className="col">Historic news section is under construction</div>
                </div>
            </div>
        );
    }
}