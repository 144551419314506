import React, { Component, Fragment } from "react";
import { MDBInput } from "mdbreact";

/*
---------------------
Component Attributes
---------------------
error:      This is the message to display on validation error 
            e.g. Email address is incorrect
group:      Item is part of a form group
            e.g. true
icon:       Font awesome icon to display in control
            e.g. user
iconbrand:  Font awesome icon brand to use
            e.g. fab
id:         Id of control
            e.g. 101
isrequired: Should validation be enforced
            e.g. true
label:      Label and placeholder to display in input control
            e.g. Email Address
onBlur:     Input value change callback
            e.g. handleOnBlur
onChange:   Input value change callback
            e.g. handleInputChange
onInput:    Handle on input in order to implement custom validation
            e.g. handleOnInput
size:       Input control bootstrap size css. 
            e.g. Large input control > 'lg', 
                 Medium input control > 'md'
                 Small input control > 'sm'
success:    This is the message to display on validation success
            e.g. Email address is correct. (May not be have to be supplied)
type:       Input type
            e.g. text, textarea, checkbox....
value:      value of input that is passed back to parent component
            e.g. Joe Bloggs
validate:   Validate input
            e.g true/false
*/
export class ColumnAndLabel extends Component {
    render() {
        return (
            <Fragment>
                <MDBInput
                    error={this.props.ErrorMessage}
                    group={this.props.GroupItem}
                    icon={this.props.IconLabel}
                    id={this.props.Id}
                    hint={this.props.HintLabel}
                    label={this.props.TextLabel}
                    name={this.props.Id}
                    onChange={this.props.HandleInputChange}
                    onBlur={this.props.HandleOnBlur}
                    ref={this.props.UglyHack}
                    required={this.props.IsRequired}
                    size={this.props.SizeCss}
                    success={this.props.SuccessMessage}
                    type={this.props.InputType}
                    value={this.props.Value}
                    outline={false}
                    validate={this.props.Validate}
                    onInput={this.props.HandleOnInput}
                    maxLength={this.props.max === undefined ? 255 : this.props.max}
                >
                    <small className={this.props.Validate && this.props.IsRequired ? 'invalid-input' : ''}>{this.props.Validate && this.props.IsRequired ? this.props.ErrorMessage : null}</small>
                </MDBInput>
            </Fragment>
        );
    }
}