import React, { Component } from 'react';
import { MDBInput } from "mdbreact";

export class ReadonlyItem extends Component {
    displayName = ReadonlyItem.name;

    render() {
        return (
            <MDBInput                
                label={this.props.textLabel}
                hint={this.props.children}
                disabled
                type="text"
            />
        );
    }
}
