import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

export class Product extends Component {
    displayName = Product.name;

    render() {
       //console.log(this.displayName + '.render', this.props);
        return (
            <div className="product">
                <div className="card card-body" key={this.props.id}>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3>{this.props.title === 0 ? 'WINDOW' : 'DOOR'}</h3>
                            <div className='pl-2'><span className='pr-3'>Qty:</span><em>{this.props.NoItems}</em></div>
                            <div className='pl-2'>
                                <label>Rating</label>
                                <span className="pl-3">
                                    <label className='faux-secondary-btn'>
                                        {this.props.Rating ? this.props.Rating : 'None'}
                                    </label>
                                </span></div>
                            <div className={this.props.LicenceNumber === '' ? 'hide' : 'pl-2' }><span className='pr-3'>Licence No:</span><em>{this.props.LicenceNumber}</em></div>
                            <div className={this.props.HideRemove ? "hide" : "show pull-right"}>

                                <button className="border-0 text-link bg-white" onClick={(event) => { if (window.confirm('Are you sure you wish to delete this item?')) this.props.handleDeleteClick(event, this.props.id); }}>
                                    <i className="fa fa-trash text-danger fa-icon-normal-size" />
                                </button>

                            </div>

                        </div>

                        <div className="col-md-12 text-center">
                            <div className="FixedHeight">
                            <Image
                                src=
                                {
                                    require(this.props.title === 'WINDOW' || this.props.title === 0 ?
                                        './window.png' :
                                        this.props.title === 'DOORBELOW' || this.props.title === 2 ? './DoorLess50.png' :
                                            './DoorMore50.png')
                                }
                                alt="my image"
                                name="InstallType"
                                
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
