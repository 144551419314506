import { SET_APPLICATION_ERROR } from "../actions/applicationErrors";
import { CLEAR_APPLICATION_ERROR } from "../actions/applicationErrors";


export default function (state = [], action) {

    switch (action.type) {

        case SET_APPLICATION_ERROR:

            let ErrorList = [...state, action.Item];

            return ErrorList;

        case CLEAR_APPLICATION_ERROR:
            let Errors = [];

            return Errors;

        default:
            return state;
    }
}

