import React, { Component, Fragment } from "react";
import validator from 'validator';
import uuid from "uuid";


import AuthHelper from './AuthHelper';
import { ColumnAndLabel } from './ColumnAndLabel';
import { NotificationPanel } from './NotificationPanel';
import { PleaseWait } from './PleaseWait';

import { MDBBtn, MDBIcon, MDBCard, MDBCardBody } from "mdbreact";

const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;
export class AccountForgottenPwd extends Component {
    displayName = AccountForgottenPwd.name;

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            EmailAddress: '',
            formValid: false,
            formErrors: {
                EmailAddress: 'Username is required'
            },
            loading: false,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            resetCompleted: false
        };
        console.log(this.displayName + '.ctor[state]', this.state);
    }

    togglePleaseWait = status => {
        this.setState({
            loading: status
        });
    }

    handleInputChange = (event) => {

        const target = event.target;
        var value = target.value;
        if (value === undefined) {
            value = target.parentElement.value;
        }
        const name = target.name;
        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        },
            () => { this.validateField(name, value); }
        );
        if (debugOn) {
            console.log('state', this.state);
        }
    }

        handleOnInputValidation = event => {

        let fieldValidationErrors = this.state.formErrors;
        let message = "";
        switch (event.target.name.toLowerCase()) {
            case 'emailaddress':
                fieldValidationErrors.EmailAddress = validator.isEmpty(event.target.value) ? 'Username is required.' : '';
                if (fieldValidationErrors.EmailAddress === "") {
                    fieldValidationErrors.EmailAddress = !validator.isEmail(event.target.value) ? 'Invalid Username detected.' : '';
                }
                message = fieldValidationErrors.EmailAddress;
                break;
            
            default:
                break;
        }

        if (message !== "") {
            this.setState({
                formErrors: fieldValidationErrors
            }, this.validateForm);
            //set custom validation error
            event.target.setCustomValidity(message);
        } else {
            //clear custom validation errors
            event.target.setCustomValidity("");
        }
    }


    handleAbortCallback = () => {
        var auth = new AuthHelper();
        auth.LogOut();
        this.props.handleAbortClick();
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        switch (fieldName.toLowerCase()) {
            case 'emailaddress':
                fieldValidationErrors.EmailAddress = validator.isEmpty(this.state.EmailAddress) ? 'Username is required.' : '';
                fieldValidationErrors.EmailAddress = !validator.isEmail(this.state.EmailAddress) ? 'Invalid Username.' : '';
                break;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid: this.state.formErrors
                && this.state.formErrors.EmailAddress.length === 0
        });
        return this.state.formValid;
    }

    submitForm = (e) => {
        e.preventDefault();

        this.togglePleaseWait(true);

        if (this.validateForm()) {
            var postData = JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.ManageUserLock",
                origin: "Installer Portal",
                operation: "forgottenpwd",
                datavalues: JSON.stringify({
                    Email: this.state.EmailAddress,
                    UserName: this.state.EmailAddress
                })
            });
            console.log(this.displayName + '.submitForm[postData]', postData);
            fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/ManageUserLock', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: postData
            })
                .then(response => response.json())
                //.then(data => JSON.parse(data))
                .then(data => {
                    this.setState({
                        loading: false,
                        notificationPostfix: (data.returncode === 1) ? 'success show' : 'danger show',
                        notificationMessage: (data.returncode === 1) ? ['Password reset request completed. Please check the specified email for more details.'] : [JSON.parse(data.message)],
                        resetCompleted: (data.returncode === 1)
                    });
                })
                .catch(data => {
                    console.log(this.displayName + 'fetchData[err]', data);
                    this.setState({
                        loading: false,
                        ErrorMessage: 'No Data',
                        notificationPostfix: 'danger show',
                        notificationMessage: ['Bad Request - No data or service not available'],
                        resetCompleted: false
                    });
                });
        } else {
            this.setState({
                loading: false,
                ErrorMessage: 'No Data',
                notificationPostfix: 'danger show',
                notificationMessage: ['Bad Request - Form has errors'],
                resetCompleted: false
            });
        }
    }

    render() {
        return (
            <MDBCard>
                <MDBCardBody>
                    {!this.state.resetCompleted ?
                        (
                            <Fragment>
                                <div className='form-group-title pb-3'>Forgotten My Password</div>
                                <small className='text-muted'>Please provide the username for logging in to your account below and click Request Reset.</small>
                            </Fragment>
                        ) : null
                    }
                    <div>
                        <form className='demoForm was-validated' onSubmit={this.submitForm.bind(this)} noValidate>
                            <div className='form-group'>
                                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                                {!this.state.resetCompleted ?
                                    (
                                        <Fragment>
                                            <div className='form-group'>
                                                <ColumnAndLabel
                                                    ErrorMessage={this.state.formErrors.EmailAddress}
                                                    IconLabel=""
                                                    Id="EmailAddress"
                                                    InputType="email"
                                                    IsRequired={true}
                                                    SizeCss="md"
                                                    HintLabel=""
                                                    TextLabel="Your Username"
                                                    Validate={true}
                                                    Value={this.state.EmailAddress}
                                                    HandleInputChange={this.handleInputChange}
                                                    HandleOnInput={this.handleOnInputValidation}
                                                />
                                                <PleaseWait IsLoading={this.state.loading} />
                                            </div>
                                            <div className='text-right py-3'>
                                                <MDBBtn
                                                    className="pull-right mdb-btn-resize"
                                                    color="success"
                                                    type="submit"
                                                    size="sm"
                                                    disabled={!this.state.formValid}
                                                >
                                                    <MDBIcon icon="save" className="mr-2" />Request Reset</MDBBtn>
                                                <MDBBtn
                                                    className="pull-right mdb-btn-resize"
                                                    color="danger"
                                                    type="button"
                                                    size="sm"
                                                    onClick={this.handleAbortCallback}>
                                                    <MDBIcon icon="arrow-left" className="mr-2" />Cancel</MDBBtn>
                                            </div>
                                        </Fragment>
                                    ) :
                                    (
                                        <div className='text-center py-3'>
                                            <MDBBtn
                                                className="mdb-btn-resize"
                                                color="dark"
                                                type="button"
                                                size="sm"
                                                onClick={this.handleAbortCallback}>
                                                <MDBIcon icon="arrow-left" className="mr-2" />Login</MDBBtn>
                                        </div>
                                    )
                                }

                            </div>
                        </form>
                    </div>
                </MDBCardBody>
            </MDBCard>
        );
    }
}
