
import { SET_APPLICATION_INFORMATION } from "../actions/applicationInformation";
import { CLEAR_APPLICATION_INFORMATION } from "../actions/applicationInformation";

export default function (state = [], action) {

    switch (action.type) {

        case SET_APPLICATION_INFORMATION:

            let InformationList = [...state, action.Item];

            return InformationList;

        case CLEAR_APPLICATION_INFORMATION:
            let Information = [];

            return Information;

        default:
            return state;
    }
}
