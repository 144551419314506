import React, { Component } from 'react';
import Accordion from '@material-ui/core/ExpansionPanel';
import { makeStyles } from "@material-ui/core/styles";
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import RegisterInstallation from "./RegisterInstallation.json"
import SingleInstallation from "./SingleInstallation.json"
import MultipleInstallation from "./MultipleInstallation.json"
import UpdateInstallation from "./UpdateInstallation.json"


const expanded=false;
export class APIIntegrationDetails extends Component {
    constructor(props) {
        super(props);

    // this.state = {
    //     expanded: false,
    //     expanded1: false,
    //     expanded2: false,
    //     expanded3: false,
    //     expanded4: false,
    //     expanded5: false
       
    //   }

}

handleChange (event,Collapse) {

    this.setState({
        expanded:!this.state.expanded,
    })
}



render() {
 
  return (
    <div>
      <Accordion >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}aria-controls="panel1bh-content"id="panel1bh-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <p><center><b>Installer API integration</b></center> </p>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p><b> A simple test to ensure your API key is still active.</b></p><br></br>
          GET/api/installer/authTest
          <p><br></br><b>AuthTest(string APIKey) =Takes the API Key and checks if the account that API key belongs to is still active:</b> </p><br></br>
           Return: Code 200 if active
           <p><br></br></p>
           Return: 400 (Bad request) if not active or disabled.
           </Typography>
        </AccordionDetails>
      </Accordion>

      
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}aria-controls="panel2bh-content"id="panel2bh-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <p><br></br><b>A simple method to retrieve to Installers API Key Status Object:</b> </p> <br></br>{"{CompanyId, APIKey,APIKeyStatus}"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p><br></br></p>
          GET/api/installer/keyDetails/{"{APIkey}"}
          <p><br></br><b>KeyDetails(string APIKey) = Similar to the Authentication test, it takes API Key and checks if the account is active and if so returns an </b></p><br></br>
          Return Code 200 
          <p> </p><br></br>
          Response Body:
          <p> </p><br></br>
          <pre>{JSON.stringify({"companyId": "0002", "apiKey": "6C4FA03E-9E6F-4A5D-80B8-D44858763364","apiKeyStatus": true})}</pre> 
          Or
          <p> </p><br></br>
          Return Code 400
          <p> </p><br></br>
          Response body:
          <p> </p><br></br>
          <pre>{JSON.stringify({ "errors": ["Invalid API key"]})}</pre> 
          </Typography>
        </AccordionDetails>
      </Accordion>



      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}aria-controls="panel3bh-content"id="panel3bh-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <p> <br></br><b>Installation Declaration Request</b></p><br></br>
          GET/api/installer/initiateRegistrationRequest/{"{APIKey}"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p><br></br><b>InitiateRegistrationRequest(string APIKey)  =  it takes API Key and checks if the account is active and if so returns an installation</b></p> 
          <p><b>declaration text and declaration reference.The declaration text is the written agreement between the installer and FENSA.</b></p><br></br>
          Return Code 200 
          <p> </p><br></br>
          Response Body:
          <p> </p><br></br>

          {"{"}
          <p> </p><br></br>
          <small>
            "declarationText": "By submitting this notification you are making a legal declaration this installation was completed in a domestic dwelling
              under 18 meters and was:
              <ul>
              <li>Contracted and carried out by our personnel/sub-contractor, and</li>
              <li>is now complete and fully compliant to all current building regulations, and</li>
              <li>All the information on this submission is accurate</li></ul>
              I understand that this installation(s) may be picked up for a ransom inspection and any failures will be rectified as soon as possible. 
              I am aware failures incur additional costs for the re-inspection (see Scheme Fees Schedule).
              I am aware that non-compliance due to delayed notifications and/or future planned works will not be taken into account by the inspector.
              I acknowledge that FENSA can be contacted to clarify any uncertainties BEFORE notifying installations.",
              <p> </p><br></br>
            "declarationReference": "258B7047-03AE-4B74-B0A1-8E062793F52D"
            <p> </p><br></br>
            {"}"}
            </small>
            <p> </p><br></br>
            Or

            <p> </p><br></br>
            Return Code 400
          <p> </p><br></br>
          Response body:
          <p> </p><br></br>
          <pre>{JSON.stringify({ "errors": ["Invalid API key"]})}</pre>        
          </Typography>
          </AccordionDetails>
          </Accordion>


          <Accordion >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}aria-controls="panel4bh-content"id="panel4bh-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <p><br></br><b>Register an installation</b> </p><br></br>
          POST/api/installer/registerInstallation
         </Typography>
        </AccordionSummary>
          <AccordionDetails>
            <Typography>
            <p><br></br><b>  RegisterInstallation(InstallerAPIInstallation request) =This is the installation API that takes in a complete installation the declaration</b></p> 
          <p><b> reference  would need to be retrieve first and included in the request object: </b></p>
          <p> </p><br></br>
          Request Object:
          <p> </p><br></br>
          
        <pre>{JSON.stringify(RegisterInstallation, null, 2)}</pre> 
        </Typography>
        </AccordionDetails>
        </Accordion>

        {/* <Accordion> expanded={this.state.expanded4} onChange={()=>this.handleChange()}> */}
        <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}aria-controls="panel1bh-content"id="panel1bh-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>

          <p><br></br><b>Find previously registered installations by the installation reference(s)</b> </p><br></br>
          POST/api/installer/findInstallationsByInstallationReferences </Typography>
        </AccordionSummary>
          <AccordionDetails>
          <Typography>
        <p> <b>FindInstallationsByInstallationReferences(InstallerAPIFindInstallationsRequest request) =This takes a find installation request object, and tries to the find either a single installation
        by reference or multiple installations by comma delimited installation references.</b></p><br></br>
        <b>Find single installation</b>
        <p> </p><br></br>
        Request Object:
        <p> </p><br></br>
        <pre>{JSON.stringify({ "APIKey": "6C4FA03E-9E6F-4A5D-80B8-D44858763364",  "InstallationReferences": ["12743918"]})}</pre> 
        <p> </p><br></br>
        Return Code 200
        <p> </p><br></br>
        Response body:
        <p> </p><br></br>
        <pre>{JSON.stringify(SingleInstallation, null, 2)}</pre><br></br>
        <b>Find multiple installations</b>
        <p> </p><br></br>
        Request Object:
        <p> </p><br></br>
        <pre>{JSON.stringify({ "APIKey": "6C4FA03E-9E6F-4A5D-80B8-D44858763364","InstallationReferences": ["12743918","12743900"]})}</pre> 
        <p> </p><br></br>
        Return Code 200
        <p> </p><br></br>
        Response body:
        <p> </p><br></br>
        <pre>{JSON.stringify(MultipleInstallation, null, 2)}</pre><br></br>
        </Typography>
        </AccordionDetails>
        </Accordion>
        
        <Accordion >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}aria-controls="panel5bh-content"id="panel5bh-header">
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <p><br></br><b>Update a previously registered installation</b> </p><br></br>
          POST/api/installer/updateInstallation
          </Typography>
        </AccordionSummary>
          <AccordionDetails>
          <Typography>
         <p> <b>UpdateInstallation(InstallerAPIUpdateInstallation request) = This is to update an existing installation, please see the request object below, this API would only update an existing installation if the installation has not been sent to the local authority.</b></p>
        <p> </p><br></br>
        <pre>{JSON.stringify(UpdateInstallation, null, 2)}</pre><br></br>
        <p> </p><br></br>
        Return Code 200
        <p> </p><br></br>
        Response body:
        <p> </p><br></br>
        <pre>{JSON.stringify({"message": "…."})}</pre> 
        <p> </p><br></br>
        Return Code 500
        <p> </p><br></br>
        Response body:
        <p> </p><br></br>
        <pre>{JSON.stringify({"errors": "…."})}</pre> 
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>  );
}
}
