import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import '../Fensa.css';

import uuid from "uuid";

import ReactGA from 'react-ga';

import ReactTable from "react-table";

import AuthHelper from '../SharedComponents/AuthHelper';
import { ButtonsJss } from '../SharedComponents/ButtonsJss';
import { CardBodyOnly } from '../SharedComponents/CardBodyOnly';
import { CardFooterButtonsIcons } from '../SharedComponents/CardFooterButtonsIcons';
import { CardWithoutImageHeader } from '../SharedComponents/CardWithoutImageHeader';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import { ReactTableFilterToggler } from './../SharedComponents/ReactTableFilterToggler';
//import { ResponsiveTable } from '../SharedComponents/ResponsiveListTable';
import { ViewToggler } from '../SharedComponents/ViewToggler';

import Badge from "@material-ui/core/Badge";

const defaultView = 'address-card';
const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class ContactList extends Component {
    displayName = " ContactList";

    constructor(props) {
        super(props);
        const auth = new AuthHelper(this.displayName);
        //console.log(this.displayName + '.ctor: ', auth);
        this.state = {
            CompanyId: auth.CompanyId,
            ShowEditButton: auth.CanEditData,
            ShowDeleteButton: auth.CanDoAdminTask,
            ShowViewButton: true,
            UsePaging: true,
            displayContent: (<PleaseWait IsLoading={true} />),
            displayView: auth.DisplayView ? auth.DisplayView : defaultView,
            filtered: [],
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 10,
            payload: [],
            resized: [],
            sorted: [],
            totals: '',
            totalsLabel: ' item',
            useFiltering: (auth.TableFilter && auth.TableFilter === 'On' ? true : false)
        };
        //console.log(this.displayName, this.props);
    }

    componentDidMount() {
        this.fetchData();
    }

    handleToggleFilter = toggle => {
        this.setState({
            useFiltering: toggle
        });
        this.fetchData();
    }

    filterCaseInsensitive = ({ id, value }, row) => {
        return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : false;
    }


    renderTable = payload => {
        return (
            <div className='py-0'>
                <ReactTableFilterToggler
                    ParentDisplayName={this.displayName}
                    UseFiltering={this.state.useFiltering}
                    handleToggleFilter={this.handleToggleFilter}
                />
                <ReactTable data={payload}
                    columns={[
                        {
                            key: 'Id',
                            Header: 'Reference',
                            accessor: 'Reference',
                            show: false
                        },
                        {
                            Header: '', // Custom header components!
                            accessor: 'MainContact',
                            maxWidth: 45,
                            width: 45,
                            filterable: false,
                            sortable: false,
                            Cell: row => {
                                return (
                                    <div className='p-2'>
                                        {
                                            row.original.MainContact === 1 ?
                                                (
                                                    <span>
                                                        <i className='fas fa-user-tag pr-3' title='Main Authorised Contact' />
                                                    </span>
                                                ) : null
                                        }
                                    </div>
                                );
                            },
                            style: {
                                maxWidth: 45,
                                paddingTop: "5",
                                textAlign: "right",
                                userSelect: "none"
                            }
                        },
                        {
                            key: 'Id',
                            accessor: 'ContactName',
                            className: 'p-3',
                            Header: () => (<span>Name</span>),
                            filterMethod: (filter, row) =>
                                row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
                            Expander: ({ isExpanded }) => (
                                <Fragment>
                                    {
                                        isExpanded ?
                                            (<span>&#x2299;</span>) :
                                            (<span>&#x2295;</span>)
                                    }
                                </Fragment>
                            ),
                            Cell: row => {
                                return (
                                    <Fragment>
                                        {row.original.ContactName}
                                    </Fragment>
                                );
                            },
                            style: {
                                padding: "15",
                                textAlign: "left",
                                userSelect: "none"
                            }
                        },
                        {
                            Header: 'Type', // Custom header components!
                            accessor: 'ContactTypeName',
                            className: 'p-3'
                        },
                        {
                            Header: 'Email', // Custom header components!
                            accessor: 'Email',
                            className: 'p-3',
                            filterMethod: (filter, row) =>
                                row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase())
                        },
                        {
                            Header: 'Mobile', // Custom header components!
                            accessor: 'MobilePhone',
                            className: 'p-3'
                        },
                        {
                            Header: 'Telephone', // Custom header components!
                            accessor: 'Telephone',
                            className: 'p-3'
                        },
                        {
                            Header: '',
                            accessor: 'Edit',
                            filterable: false,
                            sortable: false,
                            Cell: row => {
                                return (
                                    <Fragment>
                                        <div className='text-center py-1'>
                                            <CardFooterButtonsIcons
                                                Id={row.original.Id}
                                                //DeleteCss={ButtonsJss.DefaultDeleteCss}
                                                //EditCss={ButtonsJss.DefaultEditCss}
                                                //ViewCss={ButtonsJss.DefaultViewCss}
                                                //DeleteUrl={'/Contact/Delete/' + row.original.Id}
                                                EditUrl={'/Contact/Update/' + row.original.Id}
                                                ViewUrl={'/Contact/View/' + row.original.Id}
                                                ShowViewButton={this.state.ShowViewButton} // use role priviledge to set visibility
                                                ShowEditButton={this.state.ShowEditButton} // use role priviledge to set visibility
                                                ShowDeleteButton={this.state.ShowDeleteButton}// use role priviledge to set visibility
                                            />
                                        </div>
                                    </Fragment>
                                );
                            }
                        }
                    ]}
                    SubComponent={(row) => (
                        <Fragment>
                            <CardBodyOnly>
                                <div className='pt-3'>
                                    <i className="fa fa-envelope fa-1x pr-2" title='Email Address' />
                                    {row.original.Email}</div>
                                <div className='pt-3'>
                                    <i className="fas fa-mobile fa-1x pr-2" title='Mobile Number' />
                                    {row.original.MobilePhone}</div>
                                <div className='pt-3'>
                                    <i className="fa fa-phone fa-1x pr-2" title='Telephone Number' />
                                    {row.original.Telephone}</div>
                                <div className='pt-3'>
                                    <i className="fa fa-fax fa-1x pr-2" title='Fax Number' />
                                    {row.original.FaxNumber}</div>
                                <div className='pt-3'>
                                    <i className="fa fa-home fa-1x pr-2" title='Located at branch' />
                                    {row.original.BranchName}</div>
                                <div className='pt-3'>
                                    {row.original.PrimaryContact ?
                                        (
                                            <Fragment>
                                                <i className='text-info fas fa-certificate fa-1x pr-2' title='Details shown on Fensa website' />
                                                <em>Details shown on Fensa website</em>
                                            </Fragment>
                                        ) : null
                                    }

                                </div>
                            </CardBodyOnly>
                        </Fragment>
                    )}
                    className="table-bordered table-striped table-hover table-shadowed"
                    defaultPageSize={10}
                    expanded={this.state.expanded}
                    filterable={this.state.useFiltering}
                    minRows={0}
                    nextText='>'
                    previousText='<'
                    defaultFilterMethod={this.filterCaseInsensitive}
                //sorted={this.state.sorted}
                //page={this.state.page}
                //pageSize={this.state.pageSize}
                //resized={this.state.resized}
                //filtered={this.state.filtered}
                // Callbacks
                //onSortedChange={sorted => this.setState({ sorted })}
                //onPageChange={page => this.setState({ page })}
                //onPageSizeChange={(pageSize, page) => this.setState({ page, pageSize })}
                //onExpandedChange={expanded => this.setState({ expanded })}
                //onResizedChange={resized => this.setState({ resized })}
                //onFilteredChange={filtered => this.setState({ filtered })}
                />
            </div>
        );
    }

    renderCards = (payload) => {
        //console.log(payload);
        return (
            <div className='card-deck'>
                {
                    payload.map(contact =>
                        (
                            <div className='card-wrapper' key={contact.Id} title={contact.MainContact === 1 ? 'Main Contact' : ''}>
                                <CardWithoutImageHeader
                                    CssClass={contact.MainContact === 1 ? ' card-item-highlight' : ''}
                                    Id={contact.Id}
                                    Header={this.parseAsString(contact.Title) + ' ' + this.parseAsString(contact.ContactName, '.')}
                                    HeaderIcon={contact.PrimaryContact ? 'fas fa-user-tag pr-3' : ''}
                                    Title={contact.ContactTypeName}
                                    TitleIcon={contact.PrimaryContact ? 'text-info fas fa-certificate fa-1x pr-2' : ''}
                                    TitleIconTitle={contact.PrimaryContact ? 'Details shown on Fensa website' : ''}
                                    ButtonStyle={ButtonsJss.ButtonStyles.icon}
                                    UseDefaultCss={false}
                                    EditUrl={"/Contact/Update/"}
                                    DeleteUrl={"/Contact/Delete/"}
                                    ViewUrl={"/Contact/View/"}
                                    ShowViewButton={this.state.ShowViewButton} // use role priviledge to set visibility
                                    ShowEditButton={this.state.ShowEditButton} // use role priviledge to set visibility
                                    ShowDeleteButton={this.state.ShowDeleteButton}// use role priviledge to set visibility
                                >
                                    <div className='pt-3'>
                                        <i className="fa fa-envelope fa-1x pr-2" />
                                        {contact.Email}</div>
                                    <div className='pt-3'>
                                        <i className="fas fa-mobile fa-1x pr-2" />
                                        {contact.MobilePhone}</div>
                                    <div className='pt-3'>
                                        <i className="fa fa-phone fa-1x pr-2" />
                                        {contact.Telephone}</div>
                                    <div className='pt-3'>
                                        <i className="fa fa-fax fa-1x pr-2" />
                                        {contact.FaxNumber}</div>
                                    <div className='pt-3'>
                                        <i className="fa fa-home fa-1x pr-2" />
                                        {contact.BranchName}</div>
                                </CardWithoutImageHeader>
                            </div>
                        )
                    )
                }
            </div>
        );
    }

    parseAsString = (value, nullValue) => {
        try {
            return value ? value : nullValue ? nullValue : '';
        } catch (e) {
            console.log(e);
            return '.';
        }
    }

    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    renderCardViewHandler = viewName => {
        //console.log(viewName);
        this.setState({
            displayView: viewName,
            displayContent: (this.state.payload === undefined || this.state.payload === null || this.state.payload.length === 0) ?
                this.renderNoDataView : this.renderCards(this.state.payload)
        });
        new AuthHelper().CacheDisplayView(this.displayName, viewName);
    }

    renderListViewHandler = viewName => {
        this.setState({
            displayView: viewName,
            displayContent: (this.state.payload === undefined || this.state.payload === null || this.state.payload.length === 0) ?
                this.renderNoDataView : this.renderTable(this.state.payload)
        });
        //store in sessionStorage
        new AuthHelper().CacheDisplayView(this.displayName, viewName);
    }

    fetchData = () => {
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.InstallerContacts",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyId
                })
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                const definedContacts = JSON.parse(data.message).sort(function (a, b) {
                    // make main branch first item in the list
                    //console.log('a:', a);
                    //console.log('b:', b);
                    if (a.PrimaryContact > b.PrimaryContact) { return -1; }
                    if (a.ContactName < b.ContactName) { return -1; }
                    if (a.ContactName > b.ContactName) { return 1; }
                    return 0;
                });
                //console.log(definedContacts);
                const view = this.state.displayView;
                this.setState({
                    loading: false,
                    payload: definedContacts,
                    totals: definedContacts.length,
                    totalsLabel: definedContacts.length > 1 ? ' items' : ' item',
                    notificationPostfix: data.returncode === 0 ? 'info show mt-3' : 'info hide',
                    notificationMessage: data.returncode === 0 ? ['No Data'] : [],
                    displayContent: view === defaultView ?
                        this.renderCards(definedContacts) :
                        this.renderTable(definedContacts)
                });
            })
            .catch(data => {
                //console.log(this.displayName + '.fetchData[catch]', data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    displayContent: ''
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'Contact List',
            action: 'View Contact List'
        });

        return (
            <div className='fluid-container'>
                <div className='row'>
                    <div className='col-xs-12 col-sm-6 col-md-6'>
                        <h2>
                            <Badge badgeContent={this.state.totals} color="primary">Contacts</Badge>
                        </h2>
                    </div>
                </div>
                <div className='d-flex flex-row-reverse'>
                    <div className={this.state.ShowEditButton ? 'pl-2 pt-2' : 'hide'}>
                        <Link className='btn btn-sm btn-primary mdb-btn-resize' to='/Contact/Update/-1'>
                            <i className="fa fa-plus pr-2" />Add Contact
                        </Link>
                    </div>
                    <div className='pr-2'>
                        <ViewToggler
                            viewName={this.state.displayView}
                            renderCard={this.renderCardViewHandler}
                            renderList={this.renderListViewHandler}
                        />
                    </div>
                </div>
                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                <div className='col-xs-12 pb-3'>
                    {this.state.displayContent}
                </div>
            </div>
        );
    }
}
