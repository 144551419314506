import React,{Component} from 'react';
import { MDBJumbotron, MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import './SubMenuVideos.css';

import ModalVideo from 'react-modal-video';
import "react-modal-video/scss/modal-video.scss";

export default class SubMenuVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle:true,
      isOpen:true
        }
    }
  CloseVideo(event) {this.setState({
    toggle:!this.state.toggle

      })}
    render() {
      const {BenefitsVideoLink}=this.props
     // console.log('submenu',BenefitsVideoLink)
    return (
      <div>
    {this.state.toggle && 
    
<ModalVideo
isOpen={this.state.isOpen}
onClose={() => this.setState({ isOpen: false })}
channel='custom'
url={BenefitsVideoLink}
/>
    }
    </div>
      );
    }
}