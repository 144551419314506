import React, { Component } from 'react';
import { PopularItem } from './PopularItem.js'
import { Table } from '@material-ui/core';
import uuid from "uuid";

import { CardBodyOnly } from './../SharedComponents/CardBodyOnly';
import { MDBJumbotron, MDBContainer, MDBRow, MDBCol } from 'mdbreact';

export class PopularDownloads extends Component {

    static DrawItems(MainMenu, DownloadType) {
        return (
            <MDBRow>
                    <MDBCol className="col-md-12">
                        <CardBodyOnly>
                        <h2 className='h2-responsive pb-3'>{DownloadType}</h2>

                        
                        {MainMenu.map(Menu => (<PopularItem text={Menu.Description} description={Menu.description} url={Menu.UrlRoute} Update={Menu.New}/>))}
                            
                        </CardBodyOnly>
                    </MDBCol>                                        
                </MDBRow>
        );
    }

    displayName = PopularDownloads.name

    constructor(props) {
        super(props);
        this.state = { PopularDownloads: [], loading: true };

        //fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine')
        //    .then(response => response.json())
        //    .then(data => {
        //        this.setState({ PopularDownloads: data, loading: false });
        //    });

        var FensaCert = JSON.stringify({
            DownloadTypeId: this.props.DownloadTypeId
        });

        var DownloadType = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetApplicationDownload",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: FensaCert
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: DownloadType
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    PopularDownloads: JSON.parse(JSON.parse(data).ReturnData.Payload),
                    loading:false
                });

              //  console.log('PopularDownloads',this.state.PopularDownloads)
            });
    }



    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : PopularDownloads.DrawItems(this.state.PopularDownloads, this.props.DownloadType);

        return (
            <div>
                {contents}
            </div>
        );
    }
}