import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { DownloadPDF } from '../SharedComponents/DownloadPDF';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { PleaseWait } from './../SharedComponents/PleaseWait';
import { ReadonlyItem } from './../SharedComponents/ReadonlyItem';


const energyRatingListHomeUrl = "/EnergyRatings/List";
export class EnergyRatingView extends Component {
    displayName = EnergyRatingView.name;

    constructor(props) {
        super(props);
        const auth = new AuthHelper();
        this.state = {
            CompanyId: auth.CompanyId,
            CompanyName: '',
            DownloadLabel: "",
            LicenceDetails: "",
            LicenceImage: "",
            LicenseId: props.match.params.id,
            displayContent: (<PleaseWait IsLoading={true} />),
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide'
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    renderTable = (licDetails, licImage, downloadLabel) => {
        return (
            <Fragment>
                <div className='form-group'>
                    <div className='row'>
                        <div className='col-xs-12 col-md-8'>
                            <div className='col-md-12'>
                                <ReadonlyItem textLabel="Company">{licDetails.CompanyName}</ReadonlyItem>
                            </div>
                            <div className='col-md-12'>
                                <ReadonlyItem textLabel="Company Number">{licDetails.CompanyNumber}</ReadonlyItem>
                            </div>
                            <div className='col-md-12'>
                                <ReadonlyItem textLabel="Licence Number">{licDetails.LicenceNumber}</ReadonlyItem>
                            </div>
                            <div className='col-md-12'>
                                <ReadonlyItem textLabel="Product">{licDetails.ProductName}</ReadonlyItem>
                            </div>
                            <div className='col-md-12'>
                                <ReadonlyItem textLabel="Description">{licDetails.ProductDescription}</ReadonlyItem>
                            </div>
                            <div className='col-md-12'>
                                <ReadonlyItem textLabel="Type">{licDetails.ProductType}</ReadonlyItem>
                            </div>
                            <div className='col-md-12'>
                                <ReadonlyItem textLabel="Material">{licDetails.ProductMaterial}</ReadonlyItem>
                            </div>
                            <div className='col-md-12'>
                                <ReadonlyItem textLabel="Energy Rating">{licDetails.EnergyRating}</ReadonlyItem>
                            </div>
                            <div className='col-md-12 text-center'>
                                <Link
                                    className='btn btn-dark mr-2 mdb-btn-resize pull-right'
                                    to={energyRatingListHomeUrl}>
                                    <i className='fa fa-arrow-left pr-2' />Back to Licenses
                                </Link>
                                
                            </div>
                        </div>
                        <div className='col-md-4 product-column'>
                            {/*<img src={`data:image/jpeg;base64, ${licImage.Certificate}`} alt='License' />*/}
                            <img src={"data:image/jpeg;base64, " + licImage.Certificate} alt='License' />
                            <DownloadPDF
                                className='btn btn-md mr-2 mdb-btn-resize'
                                DownloadLabel={downloadLabel}
                                FileName={'Licence_' + licImage.LicenceNumber + '.pdf'}
                                Text='Download'
                            />
                        </div>

                    </div>
                </div>
            </Fragment>
        );
    }

    fetchData = () => {

        this.setState({
            loading: true
        });

        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerCompanyGet",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                LicenseId: this.state.LicenseId
            })
        });

        var fetchInstallation = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BFRCWebAPI/GetLabelInfo', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        });

        var fetchLabel = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BFRCWebAPI/ShowLabel', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        });

        var fetchDownloadLabel = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BFRCWebAPI/DownloadLabel', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        });

        Promise.all([fetchInstallation, fetchLabel, fetchDownloadLabel])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                const licenseData = JSON.parse(JSON.parse(responses[0].result).message);
                const licenseImage = JSON.parse(JSON.parse(responses[1].result).message).Result;
                const downloadLbl = JSON.parse(responses[2]).message;
                //console.log(this.displayName + '.fetchData[licenseData]', licenseData);
                //console.log(this.displayName + '.fetchData[licenseImage]', licenseImage);
                //console.log(this.displayName + '.fetchData[downloadLbl]', downloadLbl);
                this.setState({
                    CompanyName: licenseData.CompanyName,
                    DownloadLabel: downloadLbl,
                    LicenceDetails: licenseData,
                    LicenceImage: licenseImage,
                    loading: false,
                    totals: licenseData.length,
                    displayContent: (!licenseData) ?
                        this.renderNoDataView() :
                        this.renderTable(licenseData, licenseImage, downloadLbl)
                });
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData[catch]', data);
                this.setState({
                    CompanyName: '',
                    DownloadLabel: null,
                    LicenceDetails: null,
                    LicenceImage: null,
                    displayContent: '',
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['Bad  Request - No Data or service not available']
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'Energy Rating View',
            action: 'View Energy Rating'
        });


        return (
            <div className='form-group-fluid-container mr-auto ml-auto'>
                <div className='h3-responsive form-group-title'>Member Licence {this.state.CompanyName ? " - " + this.state.CompanyName : null}</div>
                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                {this.state.displayContent}
            </div>
        );
    }
}
