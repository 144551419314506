import React, { Component, Fragment } from "react";
import validator from 'validator';
import uuid from "uuid";
import ReactGA from 'react-ga';
import AuthHelper from './AuthHelper';
import { AccountRegisterValidateCompany } from './AccountRegisterValidateCompany';
import { ColumnAndLabel } from './ColumnAndLabel';
import { ColumnAndLabelRadioOptions } from './../SharedComponents/ColumnAndLabelRadioOptions';
import { ColumnAndLabelSelect } from './ColumnAndLabelSelect';
import { ConfirmActionModal } from './ConfirmActionModal';
import { NotificationPanel } from './NotificationPanel';
import { PleaseWait } from './PleaseWait';
import { ReadonlyItem } from './ReadonlyItem';

import { MDBBtn, MDBIcon, MDBCard, MDBCardBody } from "mdbreact";

const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;
export class AccountRegister extends Component {
    displayName = "AccountRegister";

    constructor(props) {
        super(props);
        const auth = new AuthHelper();
        this.state = {
            ...props,
            CompanyVerified: false,
            VerifiedCompany: null,
            //BranchList: [],
            CompanyId: auth.CompanyId,
            ContactTypeId: 0,
            ContactTypeList: [],
            DuplicateUserName: false,
            EmailAddress: props.EmailAddress,
            FensaNumber: null,
            FirstName: props.FirstName,
            HomeBranchId: 0,
            LastName: '',
            MainContact: 0,
            MobilePhone: '',
            Password: '',
            PasswordConfirm: '',
            PrimaryContact: false,
            RoleId: 0,
            RoleList: [],
            Telephone: '',
            UserId: auth.UserId,
            UserName: '',
            UserStatus: 1,

            formValid: false,
            formErrors: {
                ContactTypeId: "Job title selection is required",
                EmailAddress: "Email address is required.",
                FirstName: "First name is required.",
                LastName: "Last name is required.",
                Password: 'Password Is Required',
                PasswordConfirm: 'Password Must Match',
                RoleId: 'Role selection is required',
                Telephone: "Telephone number is required.",
                UserName: "Username is required."
            },
            loading: false,
            loadingContactTypes: true,
            loadingRoles: true,
            loadingBranches: true,
            modal: false,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            registrationComplete: false,
            validatedUsername: false
        };
    }

    toggleModal = toggle => {
        this.setState({
            modal: toggle
        });
    }

    togglePleaseWait = status => {
        this.setState({
            loading: status
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        var value = target.value;
        if (value === undefined) {
            value = target.parentElement.value;
        }
        const name = target.name;
        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        },
            () => { this.validateField(name, value); }
        );
        //if (debugOn) {
        //console.log('state', this.state);
        //}
    }

    handleOnInputValidation = event => {

        let fieldValidationErrors = this.state.formErrors;
        let message = "";
        switch (event.target.name.toLowerCase()) {
            case 'username':
                fieldValidationErrors.UserName = validator.isEmpty(event.target.value) ? 'Username is required.' : '';
                if (fieldValidationErrors.UserName === "") {
                    fieldValidationErrors.UserName = !validator.isEmail(event.target.value) ? 'Invalid Username detected.' : '';
                }
                message = fieldValidationErrors.UserName;
                break;
            case 'firstname':
                fieldValidationErrors.FirstName = validator.isEmpty(event.target.value) ? 'First name is required.' : '';
                if (fieldValidationErrors.FirstName === "") {
                    fieldValidationErrors.FirstName = validator.isLength(event.target.value, { min: 2 }) ? '' : 'First name must be more than 1 character.';
                }
                message = fieldValidationErrors.FirstName;
                break;
            case 'lastname':
                fieldValidationErrors.LastName = validator.isEmpty(event.target.value) ? 'Last name is required.' : '';
                if (fieldValidationErrors.LastName === "") {
                    fieldValidationErrors.LastName = validator.isLength(event.target.value, { min: 2 }) ? '' : 'Last name must be more than 1 character.';
                }
                message = fieldValidationErrors.LastName;
                break;
            case 'emailaddress':
                fieldValidationErrors.EmailAddress = validator.isEmpty(event.target.value) ? 'Email address is required.' : '';
                if (fieldValidationErrors.EmailAddress === "") {
                    fieldValidationErrors.EmailAddress = !validator.isEmail(event.target.value) ? 'Invalid email address.' : '';
                }
                message = fieldValidationErrors.EmailAddress;
                break;

            case 'telephone':
                fieldValidationErrors.Telephone = validator.isEmpty(event.target.value) ? 'Telephone number is required.' : '';
                if (fieldValidationErrors.Telephone === "") {
                    fieldValidationErrors.Telephone = validator.isLength(event.target.value, { min: 10 }) ? '' : `Telephone number must contain a minimum of 10 characters. Missing(${10 - (event.target.value.length)} ${(10 - (event.target.value.length) > 1 ? 'characters' : 'character')})`;
                }
                if (fieldValidationErrors.Telephone === "") {
                    fieldValidationErrors.Telephone = validator.isNumeric(event.target.value) ? '' : 'Telephone number can only contain numbers';
                }
                message = fieldValidationErrors.Telephone;
                break;
            case 'password':
                fieldValidationErrors.Password = validator.isEmpty(event.target.value) ? 'Passwords is required.' : '';
                if (fieldValidationErrors.Password === "") {
                    fieldValidationErrors.Password = validator.isLength(event.target.value, { min: 8 }) ? '' : `Password must contain a minimum of 7 characters. Missing(${8 - (event.target.value.length)} ${(8 - (event.target.value.length) > 1 ? 'characters' : 'character')})`;
                }
                message = fieldValidationErrors.Password;
                break;

            case 'passwordconfirm':
                fieldValidationErrors.PasswordConfirm = validator.isEmpty(event.target.value) ? 'Passwords do not match..' : '';
                if (fieldValidationErrors.PasswordConfirm === "") {
                    fieldValidationErrors.PasswordConfirm = this.state.Password === event.target.value ? '' : 'Passwords do not match.';
                }
                message = fieldValidationErrors.PasswordConfirm;
                break;

            default:
                break;
        }

        if (message !== "") {
            this.setState({
                formErrors: fieldValidationErrors
            }, this.validateForm);
            //set custom validation error
            event.target.setCustomValidity(message);
        } else {
            //clear custom validation errors
            event.target.setCustomValidity("");
        }
    }

    handleAbortClick = () => {
        this.props.handleAbortClick();
    }

    handleCompanyVerifiedCallback = (company) => {
        //console.log(this.displayName + '.handleCompanyVerifiedCallback ', company);
        if (company && company.CompanyId !== null && company.CompanyId > 0) {
            const compId = company.CompanyId;
            const fensNum = company.FensaNumber;
            //console.log(this.displayName + '.handleCompanyVerifiedCallback ', company.CompanyId, compId);
            this.setState({
                CompanyId: compId,
                CompanyVerified: true,
                FensaNumber: fensNum,
                VerifiedCompany: company
            });
            //console.log(this.displayName + '.handleCompanyVerifiedCallback[state]', this.state);
            this.fetchData(compId, fensNum);
        }
    }

    handleRegistrationCallback = (user) => {
        //console.log(this.displayName + '.handleRegistrationCallback', user);
        //const auth = new AuthHelper();
        //auth.SetLoginData(user, true);
        //auth.LogOut();
        //this.props.handleAuthenticated();
        this.props.handleAbortClick();
    }

    handleValidateUsernameCallback = () => {

        // Show please wait
        this.togglePleaseWait(true);

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/VerifyUsername', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.VerifyUsername",
                origin: "Installer Portal",
                operation: "INSERT",
                validate: false,
                datavalues: JSON.stringify({
                    UserName: this.state.UserName
                })
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.returncode === 0) {
                    this.setState({
                        loading: false,
                        notificationPostfix: 'success hide mt-3',
                        notificationMessage: [],
                        validatedUsername: true
                    });
                } else {
                    var msg = JSON.parse(data.message)[0];
                    console.log(this.displayName + '.handleValidateUsernameCallback', msg);
                    this.setState({
                        loading: false,
                        notificationPostfix: 'danger show mt-3',
                        notificationMessage: [msg.Exists],
                        validatedUsername: false
                    });
                }
            })
            .catch(idUser => {
                if (debugOn) {
                    console.log(this.displayName + '.submitForm[catch->createId]', idUser);
                }
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show mt-3',
                    notificationMessage: ['Bad Request - Failed to complete request or service is unavailable.', idUser.message],
                    validatedUsername: false
                });
            });
    }

    handleDuplicateUserNameCallback = cbValue => {
        let fieldValidationErrors = this.state.formErrors;
        fieldValidationErrors.EmailAddress = cbValue ? '' : 'Email address is required';
        const userName = this.state.UserName;
        this.setState({
            DuplicateUserName: cbValue ? 1 : 0,
            EmailAddress: cbValue ? userName : '',
            formErrors: fieldValidationErrors
        });
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        switch (fieldName.toLowerCase()) {
            case 'username':
                fieldValidationErrors.UserName = validator.isEmpty(this.state.UserName) ? 'Email address is required.' : '';
                fieldValidationErrors.UserName = !validator.isEmail(this.state.UserName) ? 'Invalid email address detected.' : '';
                break;
            case 'firstname':
                fieldValidationErrors.FirstName = validator.isEmpty(this.state.FirstName) ? 'First name is required.' : '';
                fieldValidationErrors.FirstName = validator.isLength(this.state.FirstName, { min: 2 }) ? '' : 'First name must be more than 1 character.';
                break;
            case 'lastname':
                fieldValidationErrors.LastName = validator.isEmpty(this.state.LastName) ? 'Last name is required.' : '';
                fieldValidationErrors.LastName = validator.isLength(this.state.LastName, { min: 2 }) ? '' : 'Last name must be more than 1 character.';
                break;
            case 'emailaddress':
                fieldValidationErrors.EmailAddress = validator.isEmpty(this.state.EmailAddress) ? 'Email address is required.' : '';
                fieldValidationErrors.EmailAddress = !validator.isEmail(this.state.EmailAddress) ? 'Invalid email address.' : '';
                break;

            case 'telephone':
                fieldValidationErrors.Telephone = validator.isEmpty(this.state.Telephone) ? 'Telephone number is required.' : '';
                fieldValidationErrors.Telephone = validator.isLength(this.state.Telephone, { min: 10 }) ? '' : 'Telephone number must be more than 10 characters.';
                break;
            case 'password':
                fieldValidationErrors.Password = validator.isEmpty(this.state.Password) ? 'Password is required.' : '';
                fieldValidationErrors.Password = validator.isLength(this.state.Password, { min: 8 }) ? '' : 'Password must be more than 7 characters.';
                break;

            case 'passwordconfirm':
                fieldValidationErrors.PasswordConfirm = this.state.PasswordConfirm !== this.state.Password ? 'Passwords do not match.' : '';
                break;

            case 'contacttypeid':
                fieldValidationErrors.ContactTypeId = validator.isNumeric(this.state.ContactTypeId) && parseInt(this.state.ContactTypeId, 10) > 0 ? '' : 'Job title selection is required.';
                break;

            case 'roleid':
                fieldValidationErrors.RoleId = validator.isNumeric(this.state.RoleId) && parseInt(this.state.RoleId, 10) > 0 ? '' : 'Role selection is required.';
                break;

            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm() {
        //console.log('FirstName: ', this.state.formErrors.FirstName, this.state.formErrors.FirstName.length === 0);
        //console.log('LastName: ', this.state.formErrors.LastName, this.state.formErrors.LastName.length === 0);
        //console.log('EmailAddress: ', this.state.formErrors.EmailAddress, this.state.formErrors.EmailAddress === 0);
        //console.log('Telephone: ', this.state.formErrors.Telephone, this.state.formErrors.Telephone.length === 0);
        //console.log('Password: ', this.state.formErrors.Password, this.state.formErrors.Password.length === 0);
        //console.log('PasswordConfirm: ', this.state.formErrors.PasswordConfirm, this.state.formErrors.PasswordConfirm.length === 0);
        this.setState({
            formValid: this.state.formErrors
                && this.state.formErrors.FirstName.length === 0
                && this.state.formErrors.LastName.length === 0
                && this.state.formErrors.EmailAddress.length === 0
                && this.state.formErrors.Telephone.length === 0
                && this.state.formErrors.Password.length === 0
                && this.state.formErrors.PasswordConfirm.length === 0
                && this.state.formErrors.ContactTypeId.length === 0
                && this.state.formErrors.RoleId.length === 0
        });
        return this.state.formValid;
    }

    submitForm = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            // Show please wait
            this.togglePleaseWait(true);

            var postData = JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.CreateUser",
                origin: "Installer Portal",
                operation: "INSERT",
                validate: false,
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyId,
                    CompanyUserEmail: this.state.EmailAddress,
                    CompanyUserStatus: false,
                    CreatedBy: 0,
                    Deleted: false,
                    Email: this.state.EmailAddress,
                    Enabled: true,
                    ExistingAccount: false,
                    FensaNumber: this.state.FensaNumber,
                    FirstName: this.state.FirstName,
                    GlobalUserStatus: true,
                    JobTypeId: this.state.ContactTypeId,
                    LastName: this.state.LastName,
                    MainAuthUser: false,
                    Mobile: this.state.MobilePhone,
                    Password: this.state.Password,
                    PasswordConfirm: this.state.PasswordConfirm,
                    ResetPwd: false,
                    RoleId: this.state.RoleId,
                    Telephone: this.state.Telephone,
                    UserEmail: this.state.EmailAddress,
                    UserId: 0,
                    UserName: this.state.UserName
                })
            });

            //console.log(this.displayName + '.submitForm[postData]', postData);
            fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/RegisterAccount', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: postData
            })
                .then(response => response.json())
                //.then(data => JSON.parse(data))
                .then(data => {
                    //console.log(this.displayName + '.submit[data]', data);
                    if (data.returncode === 1) {
                        this.setState({
                            loading: false,
                            notificationPostfix: 'success show',
                            notificationMessage: ['Successfully registered account. Please check your email for additional actions to verify your account.'],
                            registrationComplete: true
                        });
                        //console.log(this.displayName + '.submit[regUser]', data.returnData);
                        //const regUser = JSON.stringify(data.returnData.payload);
                        //console.log(this.displayName + '.submit[regUser]', regUser);
                        //this.handleRegistrationCallback(regUser);
                    } else {
                        //tell the developer that update failed
                        //console.log(data.message);
                        this.setState({
                            loading: false,
                            notificationPostfix: 'danger show',
                            notificationMessage: ['Bad Request - Failed to complete registration', data.message]
                        });
                    }
                })
                .catch(idUser => {
                    if (debugOn) {
                        console.log(this.displayName + '.submitForm[catch->createId]', idUser);
                    }
                    this.setState({
                        loading: false,
                        notificationPostfix: 'danger show mt-3',
                        notificationMessage: ['Bad Request - Failed to complete registration or service is unavailable.', idUser.message]
                    });
                });
        }
    }

    /*
    fetchBranches = (companyId, fensNum) => {

        //Branch list
        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerBranches",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                CompanyId: companyId
            })
        });
        //console.log(this.displayName + 'fetchBranches[postData]', postData);
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                if (data.returncode === 1) {
                    const branchData = JSON.parse(data.ReturnData.Payload);
                    //if (debugOn) {
                    //console.log(this.displayName + '.fetchBranches[data]', data);
                    //console.log(this.displayName + '.json.Payload', JSON.parse(data.ReturnData.Payload));
                    //console.log(this.displayName + '.branchData', branchData);
                    //}
                    if (branchData !== null) {
                        this.setState({
                            BranchList: branchData,
                            CompanyId: companyId,
                            FensaNumber: fensNum,
                            loadingBranches: false
                        });
                    } else {
                        this.setState({
                            BranchList: [],
                            CompanyId: companyId,
                            FensaNumber: fensNum,
                            loadingBranches: false
                        });
                    }
                } else {
                    this.setState({
                        BranchList: [],
                        CompanyId: companyId,
                        FensaNumber: fensNum,
                        loadingBranches: false
                    });
                }
            })
            .catch(data => {
                if (debugOn) {
                    console.log(this.displayName + '.fetchBranches.[branches].error', data);
                }
                this.setState({
                    //loading: false,
                    BranchList: [],
                    CompanyId: companyId,
                    FensaNumber: fensNum,
                    loadingBranches: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad request - Data not found or service not available']
                });
            });
    }
    */

    fetchContactTypes = (companyId) => {

        //ContactType (Job Titles)
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.ContactType",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: companyId
                })
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                //if (debugOn) {
                //    console.log(this.displayName + '.fetchContactTypes[responses]', responses);
                //}
                if (data.returncode === 1) {
                    const contactTypeData = JSON.parse(data.ReturnData.Payload);
                    //if (debugOn) {
                    console.log(this.displayName + '.fetchContactTypes[data]', data);
                    //console.log(this.displayName + '.json.Payload', JSON.parse(data.ReturnData.Payload));
                    //console.log(this.displayName + '.contactTypeData', contactTypeData);
                    //}
                    if (contactTypeData !== null) {
                        this.setState({
                            ContactTypeList: contactTypeData,
                            loadingContactTypes: false
                        });
                    } else {
                        this.setState({
                            ContactTypeList: [],
                            loadingContactTypes: false
                        });
                    }
                } else {
                    this.setState({
                        ContactTypeList: [],
                        loadingContactTypes: false
                    });
                }
            })
            .catch(data => {
                if (debugOn) {
                    console.log(this.displayName + '.fetchContactTypes.[contactType].error', data);
                }
                this.setState({
                    //loading: false,
                    ContactTypeList: [],
                    loadingContactTypes: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - No Content Types']
                });
            });
    }

    fetchRoles = (companyId) => {

        //Roles
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.Roles",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: companyId
                })
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                //if (debugOn) {
                //    console.log(this.displayName + '.fetchRoles[responses]', responses);
                //}
                if (data.returncode === 1) {
                    const roleData = JSON.parse(data.ReturnData.Payload);
                    //if (debugOn) {
                    console.log(this.displayName + '.fetchRoles[data]', data);
                    //console.log(this.displayName + '.json.Payload', JSON.parse(data.ReturnData.Payload));
                    //console.log(this.displayName + '.roleData', roleData);
                    //}
                    if (roleData !== null) {
                        this.setState({
                            RoleList: roleData,
                            loadingRoles: false
                        });
                    } else {
                        this.setState({
                            RoleList: [],
                            loadingRoles: false
                        });
                    }
                } else {
                    this.setState({
                        RoleList: [],
                        loadingRoles: false
                    });
                }
            })
            .catch(data => {
                if (debugOn) {
                    console.log(this.displayName + '.fetchRoles.[role].error', data);
                }
                this.setState({
                    RoleList: [],
                    loadingRoles: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['No Data']
                });
            });
    }

    fetchData = (companyId, fensNum) => {
        this.fetchContactTypes(companyId);
        this.fetchRoles(companyId);
        //this.fetchBranches(companyId, fensNum);
    }

    render() {

        ReactGA.event({
            category: 'Account Register',
            action: 'Account Register View'
        });

        return (
            <Fragment>
                {!this.state.CompanyVerified ?
                    (
                        <AccountRegisterValidateCompany
                            handleCompanyVerifiedClick={this.handleCompanyVerifiedCallback}
                            handleLogoutClick={this.handleAbortClick}
                        />
                    ) :
                    (
                        <MDBCard>
                            <MDBCardBody>
                                {
                                    !this.state.registrationComplete ?
                                        (
                                            <Fragment>
                                                <form className='demoForm needs-validation was-validated' onSubmit={this.submitForm.bind(this)} noValidate>
                                                    <p className="h4 text-center py-4">Register</p>
                                                    <ReadonlyItem>{this.state.VerifiedCompany.CompanyName}</ReadonlyItem>
                                                    <ReadonlyItem>{this.state.VerifiedCompany.CompanyAddress}</ReadonlyItem>
                                                    {/*<ReadonlyItem textLabel='Registration'>{this.state.VerifiedCompany.RegistrationStatusType}</ReadonlyItem>*/}
                                                    <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                                                    {
                                                        !this.state.validatedUsername ?
                                                            (
                                                                <Fragment>
                                                                    <div className="grey-text pt-5">
                                                                        <h5>Username MUST BE a VALID email address</h5>
                                                                        <div className='row'>
                                                                            <div className='col-xs-12 col-md-6'>
                                                                                <ColumnAndLabel
                                                                                    ErrorMessage={this.state.formErrors.UserName}
                                                                                    IconLabel="user"
                                                                                    Id="UserName"
                                                                                    InputType="text"
                                                                                    IsRequired={true}
                                                                                    SizeCss="md"
                                                                                    HintLabel="name@domain.com"
                                                                                    TextLabel="Username"
                                                                                    Validate={true}
                                                                                    Value={this.state.UserName}
                                                                                    HandleInputChange={this.handleInputChange}
                                                                                    HandleOnInput={this.handleOnInputValidation}
                                                                                />
                                                                            </div>
                                                                            <div className='col-xs-6 col-md-1'>
                                                                                <MDBBtn
                                                                                    className="pull-right mdb-btn-resize"
                                                                                    color="primary"
                                                                                    type="submit"
                                                                                    size="sm"
                                                                                    floating
                                                                                    onClick={this.handleValidateUsernameCallback}
                                                                                    disabled={this.state.formErrors && this.state.formErrors.UserName.length !== 0}
                                                                                >
                                                                                    <MDBIcon icon="check" className="mr-2" /></MDBBtn>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            ) : (
                                                                <Fragment>
                                                                    <ReadonlyItem textLabel="Username">{this.state.UserName}</ReadonlyItem>
                                                                    <div className="grey-text pt-1">
                                                                        <ColumnAndLabelRadioOptions
                                                                            id="DuplicateUserName"
                                                                            name="DuplicateUserName"
                                                                            textLabel="Copy to Email"
                                                                            optionNo={'No'}
                                                                            optionYes={'Yes'}
                                                                            value={this.state.DuplicateUserName}
                                                                            handleChange={this.handleDuplicateUserNameCallback} />
                                                                        <ColumnAndLabel
                                                                            ErrorMessage={this.state.formErrors.EmailAddress}
                                                                            IconLabel="envelope"
                                                                            Id="EmailAddress"
                                                                            InputType="text"
                                                                            IsRequired={true}
                                                                            SizeCss="md"
                                                                            HintLabel=""
                                                                            TextLabel="Email"
                                                                            Validate={true}
                                                                            Value={this.state.EmailAddress}
                                                                            HandleInputChange={this.handleInputChange}
                                                                            HandleOnInput={this.handleOnInputValidation}
                                                                        />
                                                                        <ColumnAndLabel
                                                                            ErrorMessage={this.state.formErrors.FirstName}
                                                                            IconLabel="user"
                                                                            Id="FirstName"
                                                                            InputType="text"
                                                                            IsRequired={true}
                                                                            SizeCss="md"
                                                                            HintLabel=""
                                                                            TextLabel="First Name"
                                                                            Validate={true}
                                                                            Value={this.state.FirstName}
                                                                            HandleInputChange={this.handleInputChange}
                                                                            HandleOnInput={this.handleOnInputValidation}
                                                                        />
                                                                        <ColumnAndLabel
                                                                            ErrorMessage={this.state.formErrors.LastName}
                                                                            IconLabel="user"
                                                                            Id="LastName"
                                                                            InputType="text"
                                                                            IsRequired={true}
                                                                            SizeCss="md"
                                                                            HintLabel=""
                                                                            TextLabel="Last Name"
                                                                            Validate={true}
                                                                            Value={this.state.LastName}
                                                                            HandleInputChange={this.handleInputChange}
                                                                            HandleOnInput={this.handleOnInputValidation}
                                                                        />
                                                                        <ColumnAndLabel
                                                                            ErrorMessage={this.state.formErrors.Password}
                                                                            IconLabel="lock"
                                                                            Id="Password"
                                                                            InputType="password"
                                                                            IsRequired={true}
                                                                            SizeCss="md"
                                                                            HintLabel=""
                                                                            TextLabel="Password"
                                                                            Validate={true}
                                                                            Value={this.state.Password}
                                                                            HandleInputChange={this.handleInputChange}
                                                                            HandleOnInput={this.handleOnInputValidation}
                                                                        />
                                                                        <ColumnAndLabel
                                                                            ErrorMessage={this.state.formErrors.PasswordConfirm}
                                                                            IconLabel="lock"
                                                                            Id="PasswordConfirm"
                                                                            InputType="password"
                                                                            IsRequired={true}
                                                                            SizeCss="md"
                                                                            HintLabel=""
                                                                            TextLabel="Confirm Password"
                                                                            Validate={true}
                                                                            Value={this.state.PasswordConfirm}
                                                                            HandleInputChange={this.handleInputChange}
                                                                            HandleOnInput={this.handleOnInputValidation}
                                                                        />
                                                                        <ColumnAndLabel
                                                                            ErrorMessage={this.state.formErrors.Telephone}
                                                                            IconLabel="phone"
                                                                            Id="Telephone"
                                                                            InputType="text"
                                                                            IsRequired={true}
                                                                            SizeCss="md"
                                                                            HintLabel=""
                                                                            TextLabel="Telephone"
                                                                            Validate={true}
                                                                            Value={this.state.Telephone}
                                                                            HandleInputChange={this.handleInputChange}
                                                                            HandleOnInput={this.handleOnInputValidation}
                                                                        />
                                                                        <ColumnAndLabel
                                                                            ErrorMessage={this.state.formErrors.MobilePhone}
                                                                            IconLabel="mobile"
                                                                            Id="MobilePhone"
                                                                            InputType="text"
                                                                            IsRequired={false}
                                                                            SizeCss="md"
                                                                            HintLabel=""
                                                                            TextLabel="MobilePhone"
                                                                            Validate={false}
                                                                            Value={this.state.MobilePhone}
                                                                            HandleInputChange={this.handleInputChange}
                                                                        //HandleOnInput={this.handleOnInputValidation}
                                                                        />
                                                                        <ColumnAndLabelSelect
                                                                            id="ContactTypeId"
                                                                            name="ContactTypeId"
                                                                            textLabel="Job Title"
                                                                            value={this.state.ContactTypeId}
                                                                            handleChange={this.handleInputChange}
                                                                            optionIdField="ID"
                                                                            optionTextField="Name"
                                                                            optionsPayload={this.state.ContactTypeList}
                                                                            validate={true}
                                                                            isRequired={true}
                                                                            errorMessage={this.state.formErrors.ContactTypeId}
                                                                        >
                                                                            {
                                                                                this.state.ContactTypeList.map(contactType =>
                                                                                    <option value={contactType.ID} key={contactType.ID}>{contactType.Name}</option>)
                                                                            }
                                                                        </ColumnAndLabelSelect>
                                                                        <ColumnAndLabelSelect
                                                                            id="RoleId"
                                                                            name="RoleId"
                                                                            textLabel="Role"
                                                                            value={this.state.RoleId}
                                                                            handleChange={this.handleInputChange}
                                                                            optionIdField="Id"
                                                                            optionTextField="Name"
                                                                            optionsPayload={this.state.RoleList}
                                                                            prompt="Select Role"
                                                                            validate={true}
                                                                            isRequired={true}
                                                                            errorMessage={this.state.formErrors.RoleId}
                                                                        >
                                                                            <option value={0}>Select Role</option>
                                                                            {
                                                                                this.state.RoleList.map(role =>
                                                                                    <option value={role.Id} key={role.Id}>{role.Name}</option>)
                                                                            }
                                                                        </ColumnAndLabelSelect>
                                                                    </div>
                                                                    <PleaseWait IsLoading={this.state.loading} />
                                                                    <div className="text-center py-4 mt-3">
                                                                        <MDBBtn
                                                                            className="pull-right mdb-btn-resize"
                                                                            color="success"
                                                                            type="submit"
                                                                            size="sm"
                                                                            disabled={!this.state.formValid || this.state.processingSubmit}
                                                                        >
                                                                            <MDBIcon icon="save" className="mr-2" />Register</MDBBtn>
                                                                        <MDBBtn
                                                                            className="pull-right mdb-btn-resize"
                                                                            color="danger"
                                                                            type="button"
                                                                            size="sm"
                                                                            onClick={this.toggleModal}
                                                                        >
                                                                            <MDBIcon icon="arrow-left" className="mr-2" />Cancel</MDBBtn>
                                                                    </div>
                                                                    <ConfirmActionModal
                                                                        ActionName="Abort"
                                                                        ActionMessage="You are about to abort the registration process. Click Ok to continue otherwise cancel to abort."
                                                                        handleToggleAction={this.toggleModal}
                                                                        handleModalAction={this.handleAbortClick}
                                                                        modalOpen={this.state.modal}
                                                                    />
                                                                    <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                                                                </Fragment>
                                                            )
                                                    }
                                                </form>
                                            </Fragment>
                                        ) :
                                        (
                                            <Fragment>
                                                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                                                <div className="text-center py-4 mt-3">
                                                    <MDBBtn
                                                        className="mdb-btn-resize"
                                                        color="info"
                                                        type="button"
                                                        size="sm"
                                                        onClick={this.handleAbortClick}>
                                                        <MDBIcon icon="arrow-left" className="mr-2" />Close</MDBBtn>
                                                </div>
                                            </Fragment>
                                        )
                                }
                            </MDBCardBody>
                        </MDBCard>
                    )
                }
            </Fragment>
        );
    }
}