import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator';
import { MDBDatePicker } from 'mdbreact';

import moment from 'moment';
import uuid from "uuid";

import ReactGA from 'react-ga';

import { ButtonToolbar, ButtonGroup, Button, Image } from 'react-bootstrap';

import { SingleDatePickerControl } from '../SharedComponents/SingleDatePickerControl';

import AuthHelper from '../SharedComponents/AuthHelper';
import { AddressCardBody } from '../SharedComponents/AddressCardBody';
import { AddressLookup } from '../SharedComponents/AddressLookup';
import { ColumnAndLabel } from '../SharedComponents/ColumnAndLabel';
import { ColumnAndLabelRadioOptions } from './../SharedComponents/ColumnAndLabelRadioOptions';
import { NotificationPanel } from '../SharedComponents/NotificationPanel';
import { Product } from './Product';
import { ProductAdd } from './AddProduct';
import { ReadonlyItem } from './../SharedComponents/ReadonlyItem';
import { ColumnAndLabelSelect } from './../SharedComponents/ColumnAndLabelSelect';

import { MDBBtn, MDBIcon } from "mdbreact";


const installationHistoryHomeUrl = '/Installations/Home';
const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class InstallationEdit extends Component {
    displayName = InstallationEdit.name;

    constructor(props) {
        super(props);
        const auth = new AuthHelper();
        this.state = {
            //ShowEditButton: auth.CanEditData,
            ShowEditButton: true,
            ShowDeleteButton: auth.CanDoAdminTask,
            BfrcCertRequested: false,
            CompanyId: auth.CompanyId,
            CompanyName: '',
            CompletedAfter: '',
            CompletedBefore: '',
            CrumbMap: [{ Title: 'Home', LinkUrl: '/Installations/Home' }, { Title: 'History', LinkUrl: '/Installations/History' }],
            InstallerReference: '',
            InstallPostcode: '',
            Reference: '',
            UsePaging: true,
            UserId: auth.UserId,
            installationId: props.match.params.id,
            installation: {
                ContactName: ''
            },
            installationAppliances: [],
            loading: true,
            page: 0,
            PageSize: 0,
            formErrors: {
                CustomerName: "",
                CustomerEmail: "",
                CompletionDate: "",
                ReasonForAmmendment: ""
            },
            formValid: false,

            SendToLaDate: '',

            // Ammendable fields
            ContractValue: '',
            CustomerName: '',
            AuthorityId: '',

            OriginalPropertyAddressLAcode: '',

            HidePropertyAddress: true,
            PropertyAddressId: '',
            PropertyAddressLine1: '',
            PropertyAddressLine2: '',
            PropertyAddressLine3: '',
            PropertyCityTown: '',
            PropertyCounty: '',
            PropertyPostcode: '',
            PropertyAddressChanged: false,

            OriginalAddressLine1: '',
            OriginalAddressLine2: '',
            OriginalAddressLine3: '',
            OriginalCityTown: '',
            OriginalCounty: '',
            OriginalPostcode: '',

            ReasonForAmmendment: '',

            HideDispatchAddress: true,
            DispatchAddressId: '',
            DispatchAddressLine1: '',
            DispatchAddressLine2: '',
            DispatchAddressLine3: '',
            DispatchCityTown: '',
            DispatchCounty: '',
            DispatchPostcode: '',
            DispatchAddressChanged: false,

            CompletionDate: null,
            NewProducts: [],
            InstallTypes: [
                { InstallTypeId: 0, InstallType: 'WINDOW' },
                { InstallTypeId: 1, InstallType: 'DOORABOVE' },
                { InstallTypeId: 2, InstallType: 'DOORBELOW' }
            ],
            LicenceDetails: [],
            shouldHideBFRC: true,
            LicenceValid: false,
            NumberOfWindows: 0,
            RatingType: 'none',
            InstallType: 'WINDOW',

            InvalidReasons: [],
            InvalidReasonId: -1,
            notificationMessage: [],
            showInstallationEditCompleted: false,
            IsCompletionDateValid: true,
            Focused: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.PropertyAddressSelected = this.PropertyAddressSelected.bind(this);
        this.DespatchAddressSelected = this.DespatchAddressSelected.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.onHandleProductAdd = this.onHandleProductAdd.bind(this);
        this.handleOutsideDateRange = this.handleOutsideDateRange.bind(this);
    }

    componentDidMount() {
        this.FetchData();
    }

    handleDeleteClick(event, index) {
        event.preventDefault();
        // Clone the State data
        var newProducts = this.state.installationAppliances;

        console.log('delete clicked');
        console.log(newProducts);

        for (var i = 0; i < newProducts.length; i++) {
            if (newProducts[i].Id === index) {
                // if it is a NewAppliance added, then just remove it from the array
                if (newProducts[i].NewProduct === true) {
                    newProducts.splice(i, 1);
                    console.log('New Product added :' + newProducts[i]);
                }
                // otherwise set the state to deleted
                else {
                    newProducts[i].Deleted = true;
                    console.log('Prpduct deleted :' + newProducts[i]);
                }
            }
        }
        let totalWindows = 0;
        let totalDoorsAbove = 0;
        let totalDoorsBelow = 0;

        // Calculate total number of windows and doors
        for (let i = 0; i < newProducts.length; i++) {
            if (newProducts[i].InstallationItemTypeId === 0 && newProducts[i].Deleted === false) { totalWindows += parseInt(newProducts[i].Count, 10); }
            else if (newProducts[i].InstallationItemTypeId === 1 && newProducts[i].Deleted === false) { totalDoorsAbove += parseInt(newProducts[i].Count, 10); }
            else if (newProducts[i].InstallationItemTypeId === 2 && newProducts[i].Deleted === false) { totalDoorsBelow += parseInt(newProducts[i].Count, 10); }
        }

        this.setState({
            installation: {
                NumberOfWindows: totalWindows,
                NoDoorsMore50Glass: totalDoorsAbove,
                NoDoorsLess50Glass: totalDoorsBelow,
                NumberOfDoors: totalDoorsAbove + totalDoorsBelow
            },

            installationAppliances: newProducts
        }, this.validateForm);
        console.log('state: installation Appliances', this.state.installationAppliances);
    }

    handleConservatoryCallback = cbValue => {
        this.setState({
            Conservatory: cbValue ? true : false
        });
    }

    handleRoofLineCallback = cbValue => {
        this.setState({
            RoofLine: cbValue ? true : false
        });
    }

    handleDateChange(value) {
        let date = moment(value);
        this.setState({
            CompletionDate: value,
            IsCompletionDateValid: date.isValid(),
        }, () => { this.isValidInputField('CompletionDate'); });
    }

    handleOutsideDateRange(day) {
        return day.isAfter(moment(), 'day') || day.isBefore(moment().subtract(2, "year"));
    }

    handleInputChange(event) {
        console.log("On Change", this.state.installationAppliances);

        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        if (value === undefined) {
            value = target.parentElement.value;
        }
        const name = target.name;

        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        },
            () => { this.isValidInputField(name); }
        );
    }

    handleOnInputValidation = event => {
        let message = this.isValidInputField(event.target.name);
        if (message !== "") {
            //set custom validation error
            event.target.setCustomValidity(message);
        } else {
            //clear custom validation errors
            event.target.setCustomValidity("");
        }
    }

    isValidInputField = fieldName => {
        let fieldValidationErrors = this.state.formErrors;
        let message = "";
        switch (fieldName.toLowerCase()) {
            case 'customername':
                fieldValidationErrors.CustomerName = validator.isEmpty(this.state.CustomerName) ? 'Customer name is required.' : '';
                fieldValidationErrors.CustomerName = validator.isLength(this.state.CustomerName, { min: 4 }) ? '' : 'Customer name must be more than 3 characters.';
                break;

            case "ReasonForAmmendment":
                fieldValidationErrors.ReasonForAmmendment = validator.isEmpty(this.state.ReasonForAmmendment) ? 'Reason is Required' : '';
                break;

            case "propertypostcode":
                fieldValidationErrors.PropertyPostcode = validator.isEmpty(this.state.PropertyPostcode) ? 'Postode is Required' : '';
                break;

            case "completiondate":
                fieldValidationErrors.CompletionDate = !this.state.IsCompletionDateValid ? 'A valid Completion Date is required! dd/mm/yyyy' : '';
                if (fieldValidationErrors.CompletionDate === "") {
                    let isValid = moment(this.state.CompletionDate.toDate(), 'YYYY-MM-DD').isBefore(moment().add(1, 'day'), 'day');
                    if (this.state.IsCompletionDateValid) {
                        this.setState({
                            IsCompletionDateValid: isValid,
                        });
                    }
                }
                message = fieldValidationErrors.CompletionDate;
                break;

            case "contractvalue":
                //fieldValidationErrors.ContractValue = validator.isEmpty(this.state.ContractValue) ? '' : 'Contract Value is Required';
                //fieldValidationErrors.ContractValue = validator.isFloat(this.state.ContractValue) ? '' : 'Contract Value must be a number';
                break;

            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);

        return message;
    }

    PropertyAddressSelected(Address) {
        this.setState({
            PropertyAddressChanged: true,
            PropertyAddressLine1: Address.Address1,
            PropertyAddressLine2: Address.Address2,
            PropertyAddressLine3: Address.Address3,
            PropertyCityTown: Address.CityTown,
            PropertyCounty: Address.County,
            PropertyPostcode: Address.Postcode,
            AuthorityId: Address.AuthorityId,
            HidePropertyAddress: false
        });
    }

    DespatchAddressSelected(Address) {

        this.setState({
            DispatchAddressChanged: true,
            DispatchAddressLine1: Address.Address1,
            DispatchAddressLine2: Address.Address2,
            DispatchAddressLine3: Address.Address3,
            DispatchCityTown: Address.CityTown,
            DispatchCounty: Address.County,
            DispatchPostcode: Address.Postcode,
            HideDispatchAddress: false
        });
    }

    togglePleaseWait = status => {
        this.setState({
            loading: status
        });
    }

    lookupClickHandler = status => {
        this.togglePleaseWait(status);
    }

    validateForm() {
        this.setState({
            formValid: this.state.ReasonForAmmendment.length !== 0
                && this.state.IsCompletionDateValid === true
        });
        return this.state.formValid;
    }

    handleAddressNotFound = msg => {
        this.setState({
            loading: false,
            notificationPostfix: 'danger show',
            notificationMessage: [msg]
        });
    }

    onHandleProductAdd(Products) {
        let totalWindows = 0;
        let totalDoorsAbove = 0;
        let totalDoorsBelow = 0;

        // Calculate total number of windows and doors
        for (let i = 0; i < this.state.installationAppliances.length; i++) {
            if (this.state.installationAppliances[i].InstallationItemTypeId === 0 && this.state.installationAppliances[i].Deleted === false) { totalWindows += parseInt(this.state.installationAppliances[i].Count, 10); }
            else if (this.state.installationAppliances[i].InstallationItemTypeId === 1 && this.state.installationAppliances[i].Deleted === false) { totalDoorsAbove += parseInt(this.state.installationAppliances[i].Count, 10); }
            else if (this.state.installationAppliances[i].InstallationItemTypeId === 2 && this.state.installationAppliances[i].Deleted === false) { totalDoorsBelow += parseInt(this.state.installationAppliances[i].Count, 10); }
        }

        console.log(totalDoorsAbove, totalDoorsBelow);

        this.setState({
            installation: {
                NumberOfWindows: totalWindows,
                NoDoorsMore50Glass: totalDoorsAbove,
                NoDoorsLess50Glass: totalDoorsBelow,
                NumberOfDoors: totalDoorsAbove + totalDoorsBelow
            },

            installationAppliances: Products
        });
    }

    handleReturnMessageCallback = (style, msg) => {
        this.setState({
            notificationMessage: [msg],
            notificationPostfix: style
        });
    }

    handleProductQuantityChangeCallback = value => {
        this.setState({
            FreezeNonFensaProductInput: value > 0
        });
    }

    handleReturnMessageCallback = (style, msg) => {
        this.setState({
            notificationMessage: [msg],
            notificationPostfix: style
        });
    }

    handleProductQuantityChangeCallback = value => {
        this.setState({
            FreezeNonFensaProductInput: value > 0
        });
    }

    isProductExistByType(typeName) {
        var result = this.state.installationAppliances.findIndex((x) => {
            return x.RatingType === typeName || x.BFRCLicenceNumber !== ''
        }) > -1
        return result;
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;

        switch (fieldName) {
            case 'LastName':
                //fieldValidationErrors.LastName = validator.isEmpty(this.state.LastName) ? 'Customer name is required.' : '';
                //fieldValidationErrors.LastName = validator.isLength(this.state.LastName, { min: 2 }) ? '' : 'Last name must be more than 1 character.';
                break;

            case 'ContactEmail':
                //fieldValidationErrors.ContactEmail = validator.isEmpty(this.state.ContactEmail) ? 'Email is Required' : '';
                //fieldValidationErrors.ContactEmail = validator.isEmail(this.state.ContactEmail) ? '' : 'Email is Required';
                break;

            case 'ReasonForAmmendment':
                //fieldValidationErrors.ContactEmail = validator.isEmpty(this.state.ContactEmail) ? 'Email is Required' : '';
                //fieldValidationErrors.ContactEmail = validator.isEmail(this.state.ContactEmail) ? '' : 'Email is Required';
                break;

            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    FetchData() {

        var postCompanyData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetInstallerInstallationValidation",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                CompanyId: this.state.CompanyId
            })
        });

        var fetchCompanyItem = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postCompanyData
        });

        var DataInstallation = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallationGet",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                installationId: this.state.installationId,
                CompanyId: this.state.CompanyId
            })
        });

        var fetchInstallation = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: DataInstallation
        });


        var appliancesData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerInstallationAppliances",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                installationId: this.state.installationId
            })
        });

        var fetchAppliances = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: appliancesData
        });

        var InvalidReasonsData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetInstallerInstallationInvalidReasonCategory",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                installationId: this.state.installationId
            })
        });

        var fetchInvalidReasons = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: InvalidReasonsData
        });

        Promise.all([fetchInstallation, fetchAppliances, fetchInvalidReasons, fetchCompanyItem])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                const data = JSON.parse(responses[0]);
                const installData = JSON.parse(data.ReturnData.Payload)[0];
                const applianceData = JSON.parse(responses[1]).message === null ? [] : JSON.parse(JSON.parse(responses[1]).message);
                const InvalidReasons = JSON.parse(responses[2]).message === null ? [] : JSON.parse(JSON.parse(responses[2]).message);

                const CompanyData = JSON.parse(JSON.parse(responses[3]).ReturnData.Payload);

                if (debugOn) {
                    console.log(this.displayName + '.fetchData[data]', data);
                    console.log(this.displayName + '.fetchData[installData]', installData);
                    console.log(this.displayName + '.fetchData[applianceData]', applianceData);
                    console.log(this.displayName + '.fetchData[CompanyData]', CompanyData);
                }

                this.setState({
                    installation: JSON.parse(JSON.parse(responses[0]).message)[0],
                    installationAppliances: JSON.parse(responses[1]).message === null ? [] : JSON.parse(JSON.parse(responses[1]).message),
                    InvalidReasons: InvalidReasons,
                    loading: false,
                    BfrcCertRequested: installData["BfrcCertRequested"],

                    // editable fields that need to be read into state values
                    // so that they can be changed.
                    installationId: JSON.parse(JSON.parse(responses[0]).message)[0].Id,
                    PropertyAddressId: JSON.parse(JSON.parse(responses[0]).message)[0].ClientAddressId,
                    DispatchAddressId: JSON.parse(JSON.parse(responses[0]).message)[0].DispatchAddressId,

                    ContractValue: JSON.parse(JSON.parse(responses[0]).message)[0].ContractValue,

                    OriginalPropertyAddressLAcode: JSON.parse(JSON.parse(responses[0]).message)[0].AuthorityId,

                    PropertyAddressLine1: JSON.parse(JSON.parse(responses[0]).message)[0].ClientAddressLine1,
                    PropertyAddressLine2: JSON.parse(JSON.parse(responses[0]).message)[0].ClientAddressLine2,
                    PropertyAddressLine3: JSON.parse(JSON.parse(responses[0]).message)[0].ClientAddressLine3,
                    PropertyCityTown: JSON.parse(JSON.parse(responses[0]).message)[0].ClientCityTown,
                    PropertyCounty: JSON.parse(JSON.parse(responses[0]).message)[0].ClientCounty,
                    PropertyPostcode: JSON.parse(JSON.parse(responses[0]).message)[0].ClientPostcode,

                    OriginalAddressLine1: JSON.parse(JSON.parse(responses[0]).message)[0].ClientAddressLine1,
                    OriginalAddressLine2: JSON.parse(JSON.parse(responses[0]).message)[0].ClientAddressLine2,
                    OriginalAddressLine3: JSON.parse(JSON.parse(responses[0]).message)[0].ClientAddressLine3,
                    OriginalCityTown: JSON.parse(JSON.parse(responses[0]).message)[0].ClientCityTown,
                    OriginalCounty: JSON.parse(JSON.parse(responses[0]).message)[0].ClientCounty,
                    OriginalPostcode: JSON.parse(JSON.parse(responses[0]).message)[0].ClientPostcode,

                    DispatchAddressLine1: JSON.parse(JSON.parse(responses[0]).message)[0].DispatchAddressLine1,
                    DispatchAddressLine2: JSON.parse(JSON.parse(responses[0]).message)[0].DispatchAddressLine2,
                    DispatchAddressLine3: JSON.parse(JSON.parse(responses[0]).message)[0].DispatchAddressLine3,
                    DispatchCityTown: JSON.parse(JSON.parse(responses[0]).message)[0].DispatchCityTown,
                    DispatchCounty: JSON.parse(JSON.parse(responses[0]).message)[0].DispatchCounty,
                    DispatchPostcode: JSON.parse(JSON.parse(responses[0]).message)[0].DispatchPostcode,

                    CompletionDate: moment(JSON.parse(JSON.parse(responses[0]).message)[0].WorkCompletionDate),
                    CustomerName: JSON.parse(JSON.parse(responses[0]).message)[0].ContactName,
                    AuthorityId: JSON.parse(JSON.parse(responses[0]).message)[0].AuthorityId,

                    InstallerReference: JSON.parse(JSON.parse(responses[0]).message)[0].InstallerReference,

                    SendToLaDate: JSON.parse(JSON.parse(responses[0]).message)[0].SendToLaDate,

                    Conservatory: JSON.parse(JSON.parse(responses[0]).message)[0].Conservatory === true ? 1 : 0,
                    RoofLine: JSON.parse(JSON.parse(responses[0]).message)[0].RoofLine === true ? 1 : 0,
                    NonFENSAWindows: JSON.parse(JSON.parse(responses[0]).message)[0].NonFENSAWindows,
                    NonFENSADoors: JSON.parse(JSON.parse(responses[0]).message)[0].NonFENSADoors,

                    IBGProviderId: CompanyData.IBGProviderId,
                    InstallerType: CompanyData.InstallerType

                });
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData[catch]', data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    displayContent: ''
                });
            });
    }

    submitForm = (e) => {
        e.preventDefault();

        if (this.validateForm()) {

            // reset the New products list, otherwise it will just keep appanding to it.
            this.setState({
                loading: true,
                NewProducts: []
            });

            let totalWindows = 0;
            let totalDoorsAbove = 0;
            let totalDoorsBelow = 0;

            // Calculate total number of windows and doors
            for (let i = 0; i < this.state.installationAppliances.length; i++) {
                if (this.state.installationAppliances[i].InstallationItemTypeId === 0 && this.state.installationAppliances[i].Deleted === false) { totalWindows += parseInt(this.state.installationAppliances[i].Count, 10); }
                else if (this.state.installationAppliances[i].InstallationItemTypeId === 1 && this.state.installationAppliances[i].Deleted === false) { totalDoorsAbove += parseInt(this.state.installationAppliances[i].Count, 10); }
                else if (this.state.installationAppliances[i].InstallationItemTypeId === 2 && this.state.installationAppliances[i].Deleted === false) { totalDoorsBelow += parseInt(this.state.installationAppliances[i].Count, 10); }
            }

            for (let i = 0; i < this.state.installationAppliances.length; i++) {
                if (this.state.installationAppliances[i].NewProduct === true) {

                    this.state.NewProducts.push({
                        __table__: "InstallationAppliance",
                        IA_InstallationItemTypeId: this.state.installationAppliances[i].InstallationItemTypeId,
                        IA_Count: this.state.installationAppliances[i].Count,
                        IA_UValue: this.state.installationAppliances[i].UValue,
                        IA_ERPRating: this.state.installationAppliances[i].ERPRating,
                        IA_BFRCLicenceNumber: this.state.installationAppliances[i].BFRCLicenceNumber,
                        IA_ComplienceType: this.state.installationAppliances[i].ComplienceType,
                        IA_Deleted: this.state.installationAppliances[i].Deleted,
                        IA_CreateDate: moment(),
                        IA_CreatedBy: this.state.UserId
                    });
                }
                else {
                    this.state.NewProducts.push({
                        __table__: "InstallationAppliance",
                        IA_Id: this.state.installationAppliances[i].Id,
                        IA_InstallationItemTypeId: this.state.installationAppliances[i].InstallationItemTypeId,
                        IA_Count: this.state.installationAppliances[i].Count,
                        IA_UValue: this.state.installationAppliances[i].UValue,
                        IA_ERPRating: this.state.installationAppliances[i].ERPRating,
                        IA_BFRCLicenceNumber: this.state.installationAppliances[i].BFRCLicenceNumber,
                        IA_ComplienceType: this.state.installationAppliances[i].ComplienceType,
                        IA_Deleted: this.state.installationAppliances[i].Deleted,
                        IA_UpdateDate: moment(),
                        IA_UpdatedBy: this.state.UserId
                    });
                }
            }

            var datavalues = JSON.stringify(
                {
                    __table__: "Installation",
                    I_Id: this.state.installationId,
                    CompanyId: this.state.CompanyId,
                    CustomerName: this.state.CustomerName,
                    CustomerTelephone: this.state.CustomerTelephone,
                    CustomerEmail: this.state.CustomerEmail,
                    AuthorityId: this.state.AuthorityId,
                    InstallerReference: this.state.InstallerReference,
                    CompletionDate: this.state.CompletionDate,
                    ContractValue: this.state.ContractValue,
                    NumberOfWindows: totalWindows,
                    NumberOfDoors: totalDoorsAbove + totalDoorsBelow,
                    NumberOfDoorsMore50: totalDoorsAbove,
                    NumberOfDoorsless50: totalDoorsBelow,
                    InstallationUpdatedBy: this.state.UserId,
                    InstallationUpdatedAt: moment(),
                    SameDispatchAddress: this.state.SameDispatchAddress,
                    I_UpdatedBy: this.state.UserId,
                    I_UpdateDate: moment(),

                    // added these properties so that web API can check and
                    // run business logic associated with changing Installation Addess
                    PropertyAddressSameLAcode: this.state.PropertyAddressSameLAcode,
                    PropertyAddressChanged: this.state.PropertyAddressChanged,
                    ReasonForAmmendment: this.state.ReasonForAmmendment,
                    OriginalPropertyAddressLAcode: this.state.OriginalPropertyAddressLAcode,
                    InvalidReasonId: this.state.InvalidReasonId,
                    SendToLaDate: this.state.SendToLaDate,
                    InstallationReference: this.state.installation.Reference,
                    BfrcCertRequested: this.state.BfrcCertRequested,

                    OriginalAddressLine1: this.state.OriginalAddressLine1,
                    OriginalAddressLine2: this.state.OriginalAddressLine2,
                    OriginalAddressLine3: this.state.OriginalAddressLine3,
                    OriginalCityTown: this.state.OriginalCityTown,
                    OriginalCounty: this.state.OriginalCounty,
                    OriginalPostcode: this.state.OriginalPostcode,

                    PropertyAddress:
                    {
                        __table__: "Address",
                        A_Id: this.state.PropertyAddressId,
                        AddressLine1: this.state.PropertyAddressLine1,
                        AddressLine2: this.state.PropertyAddressLine2,
                        AddressLine3: this.state.PropertyAddressLine3,
                        CityTown: this.state.PropertyCityTown,
                        County: this.state.PropertyCounty,
                        Postcode: this.state.PropertyPostcode,
                        AddressCreatedBy: this.state.UserId,
                        AddressCreatedAt: moment(),
                        A_UpdatedBy: this.state.UserId,
                        A_UpdateDate: moment(),
                        AddressDeleted: 0
                    },
                    DispatchAddress:
                    {
                        __table__: "Address",
                        A_Id: this.state.DispatchAddressId,
                        AddressLine1: this.state.SameDispatchAddress === true ? this.state.PropertyAddressLine1 : this.state.DispatchAddressLine1,
                        AddressLine2: this.state.SameDispatchAddress === true ? this.state.PropertyAddressLine2 : this.state.DispatchAddressLine2,
                        AddressLine3: this.state.SameDispatchAddress === true ? this.state.PropertyAddressLine3 : this.state.DispatchAddressLine3,
                        CityTown: this.state.SameDispatchAddress === true ? this.state.PropertyCityTown : this.state.DispatchCityTown,
                        County: this.state.SameDispatchAddress === true ? this.state.PropertyCounty : this.state.DispatchCounty,
                        Postcode: this.state.SameDispatchAddress === true ? this.state.PropertyPostcode : this.state.DispatchPostcode,
                        AddressCreatedBy: this.state.UserId,
                        AddressCreatedAt: moment(),
                        A_UpdatedBy: this.state.UserId,
                        A_UpdateDate: moment(),
                        AddressDeleted: 0
                    },
                    products: this.state.NewProducts,

                    // CD Added conservatory, roofline and none fensa items
                    // NB don't allow updating of Conservatory
                    RoofLine: this.state.RoofLine,
                    NoneFensaWindows: this.state.NonFENSAWindows,
                    NoneFensaDoors: this.state.NonFENSADoors
                });

            console.log(this.state.NewProducts);
            var postData = JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.UpdateInstallation",
                origin: "Installer Portal",
                operation: "UPDATE",
                datavalues: datavalues
            });

            this.setState({formValid:false});
            fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/UpdateInstallation', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: postData
            })
                .then(response => response.json())
                .then(data => JSON.parse(data))
                .then(data => {
                    this.setState({
                        //ErrorMessage: 'No Data',
                        loading: false,
                        notificationPostfix: 'danger show',
                        notificationMessage: [data.message],
                        showInstallationEditCompleted: true
                    });
                })
                .catch(data => {
                    console.log(this.displayName + '.submitForm[err]', data);
                    var arrMsg = data.ReturnData.Errors;
                    console.log(this.displayName + '.submitForm[arrMsg]', arrMsg);
                    arrMsg.splice(0, 0, 'Bad Request - No Data or service not available');
                    this.setState({
                        //ErrorMessage: 'No Data',
                        loading: false,
                        notificationPostfix: 'danger show',
                        notificationMessage: arrMsg,
                        showInstallationEditCompleted: false,
                        formValid:true
                    });
                });
        } else {
            this.setState({
                loading: false,
                notificationPostfix: 'danger show',
                notificationMessage: ['Form has errors.'],
                showInstallationEditCompleted: true,
                uploadNotificationMessage: null
            });
        }
    }

    render() {

        ReactGA.event({
            category: 'Installation Edit',
            action: 'Installation Edit View'
        });

        return (
            <div className='form-group-fluid-container mr-auto ml-auto'>
                <div className='form-group-title'>Edit Installation</div>
                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                <form className='demoForm was-validated' onSubmit={this.submitForm.bind(this)} noValidate>
                    <div className='form-group'>
                        <div className='row'>
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-xs-12 col-md-4">
                                        <ColumnAndLabel
                                            ErrorMessage={this.state.formErrors.InstallerReference}
                                            Id="InstallerReference"
                                            IconLabel="pencil"
                                            InputType="text"
                                            //IsRequired={true}
                                            SizeCss="md"
                                            HintLabel=""
                                            TextLabel="Installer Reference"
                                            Validate={true}
                                            Value={this.state.InstallerReference}
                                            HandleInputChange={this.handleInputChange}
                                            HandleOnInput={this.handleOnInputValidation}
                                        />
                                    </div>
                                    <div className="col-xs-12 col-md-4">
                                        <ReadonlyItem textLabel="Reference">{this.state.installation.Reference}</ReadonlyItem>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-md-4">
                                        <ColumnAndLabel
                                            ErrorMessage={this.state.formErrors.ContactName}
                                            IconLabel="pencil"
                                            Id="CustomerName"
                                            InputType="text"
                                            IsRequired={true}
                                            SizeCss="md"
                                            HintLabel=""
                                            TextLabel="Customer Name"
                                            Validate={true}
                                            Value={this.state.CustomerName}
                                            HandleInputChange={this.handleInputChange}
                                            HandleOnInput={this.handleOnInputValidation}
                                        />
                                    </div>
                                    <div className="col-xs-12 col-md-4">
                                        <ReadonlyItem textLabel="Telephone Number">{this.state.installation.TelephoneNumber}</ReadonlyItem>
                                    </div>
                                    <div className="col-xs-12 col-md-4">
                                        <ReadonlyItem textLabel="Email">{this.state.installation.Email}</ReadonlyItem>
                                    </div>

                                    <div className="col-xs-12 col-md-4">
                                        <ColumnAndLabel
                                            ErrorMessage={this.state.formErrors.ContractValue}
                                            IconLabel="pencil"
                                            Id="ContractValue"
                                            InputType="number"
                                            IsRequired={true}
                                            SizeCss="md"
                                            HintLabel=""
                                            TextLabel="Contract Value"
                                            Validate={true}
                                            Value={this.state.ContractValue}
                                            HandleInputChange={this.handleInputChange}
                                            HandleOnInput={this.handleOnInputValidation}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 text-muted pb-3">
                                        <AddressLookup PostcodeId="PropertyPostcode" PostcodeText="Property Address"
                                            Postcode={this.state.PropertyPostcode}
                                            handleChange={this.handleInputChange}
                                            handleLookupClick={this.lookupClickHandler}
                                            HandleAddressSelected={this.PropertyAddressSelected}
                                            handleAddressNotFound={this.handleAddressNotFound}
                                        />
                                        <div>
                                            {
                                                this.state.PropertyAddressChanged === true && this.state.SendToLaDate !== null ?
                                                    (
                                                        <div className="error text-danger">
                                                            <p>As you have changed the address from the original address, we are unable to simply amend this record. However, you can invalidate this installation and re-register the installation with the correct details. Your customer will then receive a FENSA certificate with the correct details.</p>

                                                            <ColumnAndLabelSelect
                                                                id="InvalidReasonId"
                                                                name="InvalidReasonId"
                                                                textLabel="Invalid Reason"
                                                                value={this.state.InvalidReasonId}
                                                                handleChange={this.handleInputChange}
                                                                optionIdField="ID"
                                                                optionTextField="Name"
                                                                optionsPayload={this.state.InvalidReasons}
                                                            >
                                                                {
                                                                    this.state.InvalidReasons.map(contactType =>
                                                                        <option value={contactType.ID} key={contactType.ID}>{contactType.ReasonDescription}</option>)
                                                                }
                                                            </ColumnAndLabelSelect>

                                                        </div>
                                                    ) : ''
                                            }
                                            <br />
                                            Authority {this.state.installation.Authorityname}<br />
                                            <AddressCardBody
                                                AddressLabel={"Property Address"}
                                                Address1={this.state.PropertyAddressLine1}
                                                Address2={this.state.PropertyAddressLine2}
                                                Address3={this.state.PropertyAddressLine3}
                                                CityTown={this.state.PropertyCityTown}
                                                County={this.state.PropertyCounty}
                                                Postcode={this.state.PropertyPostcode}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-muted pb-3">
                                        <AddressLookup PostcodeId="DispatchPostcode" PostcodeText="Dispatch Address"
                                            Postcode={this.state.DispatchPostcode}
                                            handleChange={this.handleInputChange}
                                            handleLookupClick={this.lookupClickHandler}
                                            HandleAddressSelected={this.DespatchAddressSelected}
                                            handleAddressNotFound={this.handleAddressNotFound}
                                        />
                                        <br />
                                        <br />
                                        <AddressCardBody
                                            AddressLabel={"Dispatch Address"}
                                            Address1={this.state.DispatchAddressLine1}
                                            Address2={this.state.DispatchAddressLine2}
                                            Address3={this.state.DispatchAddressLine3}
                                            CityTown={this.state.DispatchCityTown}
                                            County={this.state.DispatchCounty}
                                            Postcode={this.state.DispatchPostcode}
                                        />
                                    </div>
                                    <div className='col-xs-12 col-md-4'>
                                        <ReadonlyItem textLabel="Local Authority">{this.state.installation.Authorityname}</ReadonlyItem>
                                    </div>
                                    <div className='col-xs-12 col-md-4'>
                                        <ReadonlyItem textLabel="Sent To LA">{this.state.installation.SendToLaDate ? moment(this.state.installation.SendToLaDate).format('DD MMM YYYY') : "Pending"}</ReadonlyItem>
                                    </div>
                                    <div className='col-xs-12 col-md-4'>
                                        <ReadonlyItem textLabel="Send Homeowner Certificate">{this.state.installation.SendHomeownerCertificate ? "Sent" : "Pending"}</ReadonlyItem>
                                    </div>
                                    <div className="col-xs-12 col-md-4">
                                        <ReadonlyItem textLabel="Submission Date">{moment(this.state.installation.SubmissionDate).format('DD MMM YYYY')}</ReadonlyItem>
                                    </div>
                                    <div className="col-xs-12 col-md-4">
                                        <SingleDatePickerControl
                                            ErrorMessage={this.state.formErrors.CompletionDate}
                                            Id='CompletionDate'
                                            IconPosition="after"
                                            Block={false}
                                            Date={this.state.CompletionDate}
                                            PlaceHolderText="Completion Date"
                                            HandleOutsideDateRange={this.handleOutsideDateRange}
                                            IsRequired={true}
                                            HandleDateChange={this.handleDateChange}
                                            Focused={this.state.Focused}
                                            PopUpDirection="down"
                                            NumberOfMonths={1}
                                            DateDisplayFormat="DD MMM YYYY"
                                            IsValidate={this.state.IsCompletionDateValid}
                                        />
                                    </div>
                                    <div //className="col-xs-12 col-md-4 pt-4" 
                                        className="hide">
                                        <ColumnAndLabelRadioOptions
                                            id="RequestNewCertificate"
                                            name="RequestNewCertificate"
                                            textLabel="Request New Certificate"
                                            optionNo={'No'}
                                            optionYes={'Yes'}
                                            value={this.state.RequestNewCertificate}
                                            handleChange={this.handleRequestNewCertificate} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <h3>Current Products</h3>
                                <div className='row'>
                                    <div className="col-xs-12 col-md-3">
                                        <ReadonlyItem textLabel="Total Windows">{this.state.installation.NumberOfWindows === 0 ? '0' : this.state.installation.NumberOfWindows}</ReadonlyItem>
                                    </div>
                                    <div className="col-xs-12 col-md-3">
                                        <ReadonlyItem textLabel="Total Doors">{this.state.installation.NumberOfDoors === 0 ? '0' : this.state.installation.NumberOfDoors}</ReadonlyItem>
                                    </div>
                                    <div className="col-xs-12 col-md-3">
                                        <ReadonlyItem textLabel="Doors Less 50% glass">{this.state.installation.NoDoorsLess50Glass === 0 ? '0' : this.state.installation.NoDoorsLess50Glass}</ReadonlyItem>
                                    </div>
                                    <div className="col-xs-12 col-md-3">
                                        <ReadonlyItem textLabel="Doors More 50% glass">{this.state.installation.NoDoorsMore50Glass === 0 ? '0' : this.state.installation.NoDoorsMore50Glass}</ReadonlyItem>
                                    </div>
                                </div>
                                <div className='card-deck'>
                                    {
                                        this.state.installationAppliances.Count === 0 ?
                                            (
                                                <h4>No products to display</h4>
                                            ) : (
                                                this.state.installationAppliances.map((appliance, index) =>
                                                (
                                                    appliance.Deleted === false ?
                                                        <Product
                                                            id={appliance.Id}
                                                            key={index}
                                                            HideRemove={false}
                                                            title={appliance.InstallationItemTypeId}
                                                            NoItems={appliance.Count}
                                                            Rating={appliance.ERPRating}
                                                            LicenceNumber={appliance.BFRCLicenceNumber}
                                                            handleDeleteClick={this.handleDeleteClick}
                                                        />
                                                        : ''
                                                )
                                                )
                                            )
                                    }
                                </div>

                                <ProductAdd Products={this.state.installationAppliances} HandleProductAdd={this.onHandleProductAdd} HandleReturnMessage={this.handleReturnMessageCallback} HandleProductQuantityChange={this.handleProductQuantityChangeCallback} />
                            </div>


                            <div className='show'>
                                <div className={this.state.IBGProviderId === 1 && this.state.InstallerType === 0 ? 'row' : 'hide'}>
                                    <div className="container">
                                        <hr />
                                        <h3>Add Non-FENSA Product</h3>
                                    </div>
                                    <div className='col-12'>
                                        <div className='row'>
                                            {/*!this.state.FreezeNonFensaProductInput ? 'show' : 'hide'*/}
                                            {/*!this.state.conservatoryDisabled ? 'show' : 'hide'*/}
                                            <div className={'col-xs-12 col-md-6 pt-4'}>
                                                <ColumnAndLabelRadioOptions
                                                    id="Conservatory"
                                                    name="Conservatory"
                                                    textLabel="Conservatory"
                                                    optionNo={'No'}
                                                    optionYes={'Yes'}
                                                    value={this.state.Conservatory}
                                                    readOnly={true}
                                                />
                                            </div>
                                            <div className={'col-xs-12 col-md-6 pt-4'}>
                                                <ColumnAndLabelRadioOptions
                                                    id="RoofLine"
                                                    name="RoofLine"
                                                    textLabel="RoofLine"
                                                    optionNo={'No'}
                                                    optionYes={'Yes'}
                                                    value={this.state.RoofLine}
                                                    handleChange={this.handleRoofLineCallback}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 text-danger hide'><small>If Conservatory is enabled, all selected products will be cleared</small></div>
                                        </div>
                                    </div>


                                    <div className={'col-xs-12 col-md-6 px-3 pl-5 product-type'}>
                                        <div className='row'>
                                            <div className="col-xs-6 col-md-2 py-4">
                                                <Image className='product-small' src={require('./non-fensa-window.png')} alt="Non-Fensa Window" name="NonFensaWindow" />
                                                <p className="non-fensa">Window</p>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 pl-4 pr-4">
                                                <input
                                                    className='slider'
                                                    type="range"
                                                    id="NonFENSAWindows"
                                                    name="NonFENSAWindows"
                                                    min="0"
                                                    max="30"
                                                    onInput={this.handleInputChange}
                                                    onBlur={this.handleInputChange}
                                                    value={this.state.NonFENSAWindows}
                                                    list="steplist1"
                                                />
                                                <datalist id="steplist1">
                                                    <option>0</option>
                                                    <option>10</option>
                                                    <option>20</option>
                                                    <option>30</option>
                                                </datalist>
                                                <ColumnAndLabel
                                                    ErrorMessage=""
                                                    IconLabel=""
                                                    Id="NonFENSAWindows"
                                                    name="NonFENSAWindows"
                                                    InputType="number"
                                                    IsRequired={true}
                                                    SizeCss="sm"
                                                    HintLabel=""
                                                    Validate={false}
                                                    Value={this.state.NonFENSAWindows}
                                                    HandleInputChange={this.handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className='col-xs-12 col-md-6 px-3 pl-5 product-type'>
                                        <div className='row'>
                                            <div className="col-xs-12 col-md-2 py-4">
                                                <Image className='product-small' src={require('./non-fensa-door.png')} alt="Non-Fensa Door" name="NonFensaDoor" />
                                                <p className="non-fensa">Door</p>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 pl-4 pr-4">
                                                <input
                                                    className='slider'
                                                    type="range"
                                                    id="NonFENSADoors"
                                                    name="NonFENSADoors"
                                                    min="0"
                                                    max="30"
                                                    onInput={this.handleInputChange}
                                                    onBlur={this.handleInputChange}
                                                    value={this.state.NonFENSADoors}
                                                    list="steplist2"
                                                />
                                                <datalist id="steplist2">
                                                    <option>0</option>
                                                    <option>10</option>
                                                    <option>20</option>
                                                    <option>30</option>
                                                </datalist>
                                                <ColumnAndLabel
                                                    ErrorMessage=""
                                                    IconLabel=""
                                                    Id="NonFENSADoors"
                                                    name="NonFENSADoors"
                                                    InputType="number"
                                                    IsRequired={false}
                                                    SizeCss="sm"
                                                    HintLabel=""
                                                    Validate={false}
                                                    Value={this.state.NonFENSADoors}
                                                    HandleInputChange={this.handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='col-md-12 pb-2'>
                                <div className='text-left'>
                                    <ColumnAndLabel
                                        ErrorMessage={this.state.formErrors.ReasonForAmmendment}
                                        Id="ReasonForAmmendment"
                                        InputType="text"
                                        IsRequired={true}
                                        SizeCss="md"
                                        HintLabel=""
                                        TextLabel="Reason for Amendment"
                                        Validate={true}
                                        Value={this.state.ReasonForAmmendment}
                                        HandleInputChange={this.handleInputChange}
                                        HandleOnInput={this.handleOnInputValidation}
                                    />
                                    {!this.state.showInstallationEditCompleted && this.state.ShowEditButton ?
                                        (
                                            <MDBBtn
                                                color="success"
                                                icon="save"
                                                type="submit"
                                                size="sm"
                                                disabled={!this.state.formValid}
                                                className={!this.state.showInstallationHistoryButton ? "mdb-btn-resize pull-right show" : "hide"}>
                                                <MDBIcon icon="save" className="pr-2" />Submit</MDBBtn>
                                        ) : (
                                            <Link
                                                className="btn btn-dark pull-right mr-2"
                                                to={installationHistoryHomeUrl}>
                                                <i className='fa fa-arrow-left pr-2' />Back to Installations</Link>
                                        )
                                    }
                                </div>
                            </div>
                            <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
