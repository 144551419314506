import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { ReadonlyItem } from '../SharedComponents/ReadonlyItem';
import { PleaseWait } from '../SharedComponents/PleaseWait';

import './Company.css';

import { Link } from 'react-router-dom';
// const detailsHomeUrl = '/Details';
const debugOn = process.env.DEBUG_MODE_ON;

export class CompanyDetails extends Component {
    displayName = CompanyDetails.name;

    constructor(props) {
        super(props);

        const auth = new AuthHelper();

        this.state = {
            CompanyId: auth.CompanyId,
            UserId: auth.UserId,
            CompanyName: '',
            CompletedAfter: '',
            CompletedBefore: '',
            DispatchPostcode: '',
            InstallerReference: '',
            InstallPostcode: '',
            Reference: '',
            UsePaging: true,
            displayContent: (<PleaseWait IsLoading={true} />),
            loading: true,
            notificationMessage: '',
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 0,
            payload: []
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    renderNoDataView = () => {
        return (
            <div>
                <div className='h3 h3-responsive'>My Details</div>
                <p><em>No data to display</em></p>
            </div>
        );
    }

    renderTable = companyDetailsData => {
        return (
            companyDetailsData === null ?
                'Bad Request' : (
                    <div className='form-group-container mr-auto ml-auto'>
                        <div className='form-group'>
                            <div className='h3 h3-responsive'>{companyDetailsData.CompanyName} </div>
                            <div className='form-group'>
                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="FENSA ID">{companyDetailsData.fensaNumber}</ReadonlyItem>
                                        </div>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="Registration Status">{companyDetailsData.RegistrationStatus}</ReadonlyItem>
                                        </div>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="Trading Name">{companyDetailsData.TradingName}</ReadonlyItem>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="VAT Number">{companyDetailsData.VATnumber}</ReadonlyItem>
                                        </div>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="Main Contact">{companyDetailsData.MainContactName}</ReadonlyItem>
                                        </div>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="Email">{companyDetailsData.MainContactEmail}</ReadonlyItem>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="Phone">{companyDetailsData.MainContactPhone}</ReadonlyItem>
                                        </div>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="Mobile">{companyDetailsData.MainContactMobile}</ReadonlyItem>
                                        </div>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="Website">{companyDetailsData.WebSite}</ReadonlyItem>
                                        </div>
                                    </div>
                                </div>
                                <div class="h3 h3-responsive">How you're seen on our website:</div>

                                <div className="col-md-12 no-padding local-address">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <h5>
                                                {companyDetailsData.CompanyName}
												</h5>
                                        </div>
                                        <div className="col-md-4 registered">
                                            <p>FENSA Registered No. - <span>{companyDetailsData.fensaNumber}</span></p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <p>{companyDetailsData.CompanyAddressLine1}</p>
                                            <p>{companyDetailsData.CompanyAddressLine2}</p>
                                            <p>{companyDetailsData.CompanyAddressLine3}</p>
                                            <p>{companyDetailsData.CompanyCityTown}</p>
                                            <p>{companyDetailsData.CompanyCounty}</p>
                                            <p>{companyDetailsData.CompanyPostcode}</p>
                                        </div>
                                                <div className="col-md-4 details">
                                                    <p>
                                                <em className="fa fa-phone" aria-hidden="true"/>
                                                {companyDetailsData.MainContactPhone}
												</p>
                                                    <p>
                                                <em className="fa fa-envelope" aria-hidden="true"/>
                                                <a href={'mailto:' + companyDetailsData.MainContactEmail}>Email Company</a>
                                                    </p>
                                                    <p>
                                                <em className="fa fa-globe" aria-hidden="true"/>
                                                <a href={'http://' + companyDetailsData.WebSite} target="_blank">View Website</a>
                                                    </p>
                                                </div>                                                
										</div>
                                    </div>                                

                            </div>
                        </div>
                    </div>
                )
        );
    }

    fetchData = () => {

        var fetchInstallation = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.GetCompanyEditDetails",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyId,
                    UserId: this.state.UserId
                })
            })
        });

        Promise.all([fetchInstallation])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                console.log(this.displayName + '.fetchData[responses]', responses);
                var data = this.extractData(responses[0]);
                console.log(this.displayName + '.fetchData[data]', data);
                this.setState({
                    payload: data,//JSON.parse(JSON.parse(responses[0]).message),
                    loading: false,
                    displayContent: !data ? this.renderNoDataView() : this.renderTable(data[0]),
                    notificationPostfix: 'info hide',
                    notificationMessage: []
                });
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData[err]', data);
                this.setState({
                    displayContent: '',
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - No data or service unavailable'],
                    payload: []
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'Company Details',
            action: 'Company Details'
        });


        return (
            <div>
                {this.state.displayContent}
            </div>
        );
    }
}

/// Handle data retrieval and handle ensuing errors if data is not found
CompanyDetails.prototype.extractData = response => {
    try {
        return JSON.parse(response).returncode !== 0 ? JSON.parse(JSON.parse(response).message) : [];
    } catch (e) {
        console.log(e);
        return [];
    }
};