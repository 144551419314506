import React, { Component } from 'react';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol, MDBListGroup, MDBListGroupItem } from 'mdbreact';

export default class DebugInfo extends Component {

    render() {
        return (
            <div className="container">
                <MDBCol>
                    <MDBCard style={{ maxWidth: 400 }}>
                        <MDBCardHeader>Debug Information</MDBCardHeader>
                        <MDBCardImage className="img-fluid card-img-top" src="https://source.unsplash.com/random/350x350?code" waves />
                        <MDBCardBody>
                            <MDBListGroup>
                                <MDBListGroupItem className="disabled"><small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small></MDBListGroupItem>
                                <MDBListGroupItem><small>Wesite: {process.env.REACT_APP_WEBAPP_TITLE}</small></MDBListGroupItem>
                                <MDBListGroupItem><small>Endpoint: {process.env.REACT_APP_FENSA_WEB_API}</small></MDBListGroupItem>
                                <MDBListGroupItem><small>Debug on: {process.env.REACT_APP_DEBUG_MODE_ON}</small></MDBListGroupItem>
                            </MDBListGroup>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </div>
        );
    }
}