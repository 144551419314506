import React, { Component, Fragment } from "react";
import uuid from "uuid";

import { MDBCard, MDBCardBody} from "mdbreact";

import AuthHelper from './AuthHelper';
import { NotificationPanel } from './NotificationPanel';
import { ReadonlyItem } from './ReadonlyItem';

export class AccountAuthorise extends Component {
    displayName = 'AccountAuthorise';

    constructor(props) {
        super(props);
        const auth = new AuthHelper();
        this.state = {
            ...props,
            UserDisplayName: '',
            UserEmail: '',
            UserName: auth.Username,
            VerificationCode: auth.AuthoriseAccountUser,

            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            authorisationComplete: false
        };
    }

    componentDidMount = () => {
        this.fetchData();
    }

    togglePleaseWait = status => {
        this.setState({
            loading: status
        });
    }

    fetchData = () => {
        //get the user account details
        //perform account lookup from userId
        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.AuthoriseUser",
            origin: "Installer Portal",
            operation: "identitygetuserbyid",
            datavalues: JSON.stringify({
                UserName: this.state.UserName,
                VerificationCode: this.state.VerificationCode
            })
        });
        console.log(this.displayName + '.submitForm[postData]', postData);
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/AuthoriseUser', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        })
            .then(response => response.json())
            //.then(data => JSON.parse(data))
            .then(data => {
                console.log(this.displayName + '.fetchData(data)', data);
                if (data.returncode === 1) {
                    var user = JSON.parse(data.returnData.payload);
                    this.setState({
                        UserDisplayName: user.DisplayName,
                        UserEmail: user.Email,
                        loading: false,
                        notificationPostfix: 'success show',
                        notificationMessage: ['Successfully authorised user access'],
                        authorisationComplete: true
                    });
                } else {
                    this.setState({
                        EmailAddress: '',
                        UserDisplayName: '',
                        Username: '',
                        expiredToken: true,
                        loading: false,
                        notificationPostfix: 'danger show',
                        notificationMessage: [data.message],
                        authorisationComplete: false
                    });
                }
            })
            .catch(data => {
                console.log(this.displayName + 'fetchData[err]', data);
                this.setState({
                    loading: false,
                    ErrorMessage: 'No Data',
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - No data or service not available'],
                    authorisationComplete: false
                });
            });
    }

    render() {
        return (
            <MDBCard>
                <MDBCardBody>
                    <div className="h4 text-center py-4">Access Authorisation</div>
                    <div>
                        <div className='form-group'>
                            <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                            <div className="grey-text">
                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col'>
                                            <ReadonlyItem textLabel="Email">{this.state.EmailAddress}</ReadonlyItem>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MDBCardBody>
            </MDBCard>
        );
    }
}
