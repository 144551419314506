import React, { Component } from 'react';

export class NotificationPanel extends Component {
    displayName = NotificationPanel.name;

    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
        //console.log('NotificationPanel.ctor', this.state);
    }

    componentWillReceiveProps = nextProps => {
        //console.log('NotificationPanel.componentWillReceiveProps', nextProps);
        this.setState({
            ...nextProps
        });
    }

    render() {
        return (
            <div className={'call-out alert alert-' + this.state.CssClassPostFix}>
                <div>
                    {
                        this.state.NotificationMessage.map((msg, index) => <p key={index}>{msg}</p>)
                    }
                </div>
                {/*If additional child elements render here...*/}
                {this.state.children}
            </div>
        );
    }
}