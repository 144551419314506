import { DataFetch } from "js-helpers";

import { SET_APPLICATION_ERROR } from "../actions/applicationErrors";
import { SET_APPLICATION_INFORMATION } from "../actions/applicationInformation";

export const GET_MATERIAL_TYPE = "GET_MATERIAL_TYPE";
export const GET_SERVICE_TYPE = "GET_SERVICE_TYPE";
export const GET_PROPERTY_TYPE = "GET_PROPERTY_TYPE";
export const GET_RADIUS_TYPE = "GET_RADIUS_TYPE";
export const GET_MINIMUMBUDGET_TYPE = "GET_MINIMUMBUDGET_TYPE";

export const INSERT_INSTALLER_WORK_DATA = "INSERT_INSTALLER_WORK_DATA";

export const GET_INSTALLER_WORK_DATA = "GET_INSTALLER_WORK_DATA";
export const GET_INSTALLER_WORK_OPENING_HOURS = "GET_INSTALLER_WORK_OPENING_HOURS";
export const GET_INSTALLER_WORK_MATERIALS = "GET_INSTALLER_WORK_MATERIALS";
export const GET_INSTALLER_WORK_SERVICE = "GET_INSTALLER_WORK_SERVICE";
export const GET_INSTALLER_WORK_PROPERTY_TYPE = "GET_INSTALLER_WORK_PROPERTY_TYPE";

export const SET_INSTALLER_WORK_DATA = "SET_INSTALLER_WORK_DATA";
export const SET_INSTALLER_WORK_OPENING_HOURS = "SET_INSTALLER_WORK_OPENING_HOURS";
export const SET_INSTALLER_WORK_MATERIALS = "SET_INSTALLER_WORK_MATERIALS";
export const SET_INSTALLER_WORK_SERVICE = "SET_INSTALLER_WORK_SERVICE";
export const SET_INSTALLER_WORK_PROPERTY_TYPE = "SET_INSTALLER_WORK_PROPERTY_TYPE";

export const SET_INSTALLER_COMPANY_DESCRIPTION = "SET_INSTALLER_COMPANY_DESCRIPTION";


export const setWorkMaterials = (FilterName, Value) => ({
    type: SET_INSTALLER_WORK_MATERIALS,
    FilterName,
    Value
});

export const setWorkServices = (FilterName, Value) => ({
    type: SET_INSTALLER_WORK_SERVICE,
    FilterName,
    Value
});

export const setPropertyType = (FilterName, Value) => ({
    type: SET_INSTALLER_WORK_PROPERTY_TYPE,
    FilterName,
    Value
});

export const setOpeningHours = (DayId, NotOpen, OpenTime, CloseTime) => ({
    type: SET_INSTALLER_WORK_OPENING_HOURS,
    DayId,
    NotOpen,
    OpenTime,
    CloseTime

});

export const setWorkData = (CompanyId, Description, IsManufacturer, LocalAuthorities, OfferFinance, HasShowroom, ContactEmail, AdditionalContactEmail, RadiusId, MinimumBudgetId, CallOut24Hours) => ({
    type: SET_INSTALLER_WORK_DATA,
    CompanyId,
    Description,
    IsManufacturer,
    LocalAuthorities,
    OfferFinance,
    HasShowroom,
    ContactEmail,
    AdditionalContactEmail,
    RadiusId,
    MinimumBudgetId,
    CallOut24Hours

});


export const GetInstallerWorkPropertyTypes = (CompanyId, Url) => dispatch => {

    let DatatypeRequested = 'BIT.Fensa.InstallerPortal.GetInstallerWorkPropertyTypes';
    let DatatypeURL = process.env.REACT_APP_FENSA_WEB_API + "BITEngine";

    new DataFetch()
        .PerformEngineFetch(
            JSON.stringify(
                {
                    CompanyId: CompanyId
                }),
            DatatypeRequested,
            DatatypeURL
        )
        .then(response => response.json())
        .then(data => {

            const Result = JSON.parse(data);

            let InstallerWorkPropertyTypes = Result.returncode === 1 ? JSON.parse(Result.message) : null;

            if (Result.returncode === 0) {

                dispatch({
                    type: SET_APPLICATION_ERROR,
                    Item: {
                        Route: Url,
                        Function: 'GetInstallerWorkPropertyTypes',
                        DatatypeRequested: DatatypeRequested,
                        DatatypeURL: DatatypeURL,
                        Message: [Result.message],
                        Trace: Result.ReturnData.DeclaringTypes
                    }
                });

            }

            dispatch({
                type: GET_INSTALLER_WORK_PROPERTY_TYPE,
                InstallerWorkPropertyTypes: InstallerWorkPropertyTypes === null ? [] : InstallerWorkPropertyTypes
            });
        });
};

export const GetInstallerWorkServices = (CompanyId, Url) => dispatch => {

    let DatatypeRequested = 'BIT.Fensa.InstallerPortal.GetInstallerWorkServices';
    let DatatypeURL = process.env.REACT_APP_FENSA_WEB_API + "BITEngine";

    new DataFetch()
        .PerformEngineFetch(
            JSON.stringify(
                {
                    CompanyId: CompanyId
                }),
            DatatypeRequested,
            DatatypeURL
        )
        .then(response => response.json())
        .then(data => {

            const Result = JSON.parse(data);

            let InstallerWorkServices = Result.returncode === 1 ? JSON.parse(Result.message) : null;

            if (Result.returncode === 0) {

                dispatch({
                    type: SET_APPLICATION_ERROR,
                    Item: {
                        Route: Url,
                        Function: 'GetInstallerWorkServices',
                        DatatypeRequested: DatatypeRequested,
                        DatatypeURL: DatatypeURL,
                        Message: [Result.message],
                        Trace: Result.ReturnData.DeclaringTypes
                    }
                });

            }

            dispatch({
                type: GET_INSTALLER_WORK_SERVICE,
                InstallerWorkServices: InstallerWorkServices === null ? [] : InstallerWorkServices
            });
        });
};

export const GetInstallerWorkMaterials = (CompanyId, Url) => dispatch => {

    let DatatypeRequested = 'BIT.Fensa.InstallerPortal.GetInstallerWorkMaterials';
    let DatatypeURL = process.env.REACT_APP_FENSA_WEB_API + "BITEngine";

    new DataFetch()
        .PerformEngineFetch(
            JSON.stringify(
                {
                    CompanyId: CompanyId
                }),
            DatatypeRequested,
            DatatypeURL
        )
        .then(response => response.json())
        .then(data => {

            const Result = JSON.parse(data);

            let InstallerWorkMaterials = Result.returncode === 1 ? JSON.parse(Result.message) : null;

            if (Result.returncode === 0) {

                dispatch({
                    type: SET_APPLICATION_ERROR,
                    Item: {
                        Route: Url,
                        Function: 'GetInstallerWorkMaterials',
                        DatatypeRequested: DatatypeRequested,
                        DatatypeURL: DatatypeURL,
                        Message: [Result.message],
                        Trace: Result.ReturnData.DeclaringTypes
                    }
                });

            }

            dispatch({
                type: GET_INSTALLER_WORK_MATERIALS,
                InstallerWorkMaterials: InstallerWorkMaterials === null ? [] : InstallerWorkMaterials
            });
        });
};

export const GetInstallerOpeningHours = (CompanyId, Url) => dispatch => {

    let DatatypeRequested = 'BIT.Fensa.InstallerPortal.GetInstallerOpeningHours';
    let DatatypeURL = process.env.REACT_APP_FENSA_WEB_API + "BITEngine";

    new DataFetch()
        .PerformEngineFetch(
            JSON.stringify(
                {
                    CompanyId: CompanyId
                }),
            DatatypeRequested,
            DatatypeURL
        )
        .then(response => response.json())
        .then(data => {

            const Result = JSON.parse(data);

            let InstallerOpeningHours = Result.returncode === 1 ? JSON.parse(Result.message) : null;

            if (Result.returncode === 0) {

                dispatch({
                    type: SET_APPLICATION_ERROR,
                    Item: {
                        Route: Url,
                        Function: 'GetInstallerOpeningHours',
                        DatatypeRequested: DatatypeRequested,
                        DatatypeURL: DatatypeURL,
                        Message: [Result.message],
                        Trace: Result.ReturnData.DeclaringTypes
                    }
                });

            }

            dispatch({
                type: GET_INSTALLER_WORK_OPENING_HOURS,
                InstallerOpeningHours: InstallerOpeningHours === null ? [] : InstallerOpeningHours
            });
        });
};


export const GetInstallerWorkData = (CompanyId, Url) => dispatch => {

    let DatatypeRequested = 'BIT.Fensa.InstallerPortal.GetInstallerWorkData';
    let DatatypeURL = process.env.REACT_APP_FENSA_WEB_API + "BITEngine";

    new DataFetch()
        .PerformEngineFetch(
            JSON.stringify(
                {
                    CompanyId: CompanyId
                }),
            DatatypeRequested,
            DatatypeURL
        )
        .then(response => response.json())
        .then(data => {

            const Result = JSON.parse(data);

            let InstallerWorkData = Result.returncode === 1 ? JSON.parse(Result.message) : null;

            if (Result.returncode === 0) {

                dispatch({
                    type: SET_APPLICATION_ERROR,
                    Item: {
                        Route: Url,
                        Function: 'GetInstallerWorkData',
                        DatatypeRequested: DatatypeRequested,
                        DatatypeURL: DatatypeURL,
                        Message: [Result.message],
                        Trace: Result.ReturnData.DeclaringTypes
                    }
                });

            }

            dispatch({
                type: GET_INSTALLER_WORK_DATA,
                InstallerWorkData: InstallerWorkData === null ? null : InstallerWorkData[0]
            });
        });
};

export const GetInstallerMaterialType = (Url) => dispatch => {

    let DatatypeRequested = 'BIT.Fensa.InstallerPortal.GetInstallerMaterialType';
    let DatatypeURL = process.env.REACT_APP_FENSA_WEB_API + "BITEngine";

    new DataFetch()
        .PerformEngineFetch(
            JSON.stringify(''),
            DatatypeRequested,
            DatatypeURL
        )
        .then(response => response.json())
        .then(data => {

            const Result = JSON.parse(data);

            let MaterialType = Result.returncode === 1 ? JSON.parse(Result.message) : null;        

            if (Result.returncode === 0) {

                dispatch({
                    type: SET_APPLICATION_ERROR,
                    Item: {
                        Route: Url,
                        Function: 'GetInstallerMaterialType',
                        DatatypeRequested: DatatypeRequested,
                        DatatypeURL: DatatypeURL,
                        Message: [Result.message],
                        Trace: Result.ReturnData.DeclaringTypes
                    }
                });

            }

            dispatch({
                type: GET_MATERIAL_TYPE,
                List: MaterialType === null ? [] : MaterialType
            });
        });
};

export const GetInstallerServiceType = (Url) => dispatch => {

    let DatatypeRequested = 'BIT.Fensa.InstallerPortal.GetInstallerServiceType';
    let DatatypeURL = process.env.REACT_APP_FENSA_WEB_API + "BITEngine";

    new DataFetch()
        .PerformEngineFetch(
            JSON.stringify(''),
            DatatypeRequested,
            DatatypeURL
        )
        .then(response => response.json())
        .then(data => {

            const Result = JSON.parse(data);

            let ServiceType = Result.returncode === 1 ? JSON.parse(Result.message) : null;

            if (Result.returncode === 0) {

                dispatch({
                    type: SET_APPLICATION_ERROR,
                    Item: {
                        Route: Url,
                        Function: 'GetInstallerServiceType',
                        DatatypeRequested: DatatypeRequested,
                        DatatypeURL: DatatypeURL,
                        Message: [Result.message],
                        Trace: Result.ReturnData.DeclaringTypes
                    }
                });

            }

            dispatch({
                type: GET_SERVICE_TYPE,
                List: ServiceType === null ? [] : ServiceType
            });
        });
};

export const GetInstallerPropertyType = (Url) => dispatch => {

    let DatatypeRequested = 'BIT.Fensa.InstallerPortal.GetInstallerPropertyType';
    let DatatypeURL = process.env.REACT_APP_FENSA_WEB_API + "BITEngine";

    new DataFetch()
        .PerformEngineFetch(
            JSON.stringify(''),
            DatatypeRequested,
            DatatypeURL
        )
        .then(response => response.json())
        .then(data => {

            const Result = JSON.parse(data);

            let PropertyType = Result.returncode === 1 ? JSON.parse(Result.message) : null;

            if (Result.returncode === 0) {

                dispatch({
                    type: SET_APPLICATION_ERROR,
                    Item: {
                        Route: Url,
                        Function: 'GetInstallerPropertyType',
                        DatatypeRequested: DatatypeRequested,
                        DatatypeURL: DatatypeURL,
                        Message: [Result.message],
                        Trace: Result.ReturnData.DeclaringTypes
                    }
                });

            }

            dispatch({
                type: GET_PROPERTY_TYPE,
                List: PropertyType === null ? [] : PropertyType
            });
        });
};

export const GetInstallerRadiusType = (Url) => dispatch => {

    let DatatypeRequested = 'BIT.Fensa.InstallerPortal.GetInstallerRadiusType';
    let DatatypeURL = process.env.REACT_APP_FENSA_WEB_API + "BITEngine";

    new DataFetch()
        .PerformEngineFetch(
            JSON.stringify(''),
            DatatypeRequested,
            DatatypeURL
        )
        .then(response => response.json())
        .then(data => {

            const Result = JSON.parse(data);

            let RadiusType = Result.returncode === 1 ? JSON.parse(Result.message) : null;

            if (Result.returncode === 0) {

                dispatch({
                    type: SET_APPLICATION_ERROR,
                    Item: {
                        Route: Url,
                        Function: 'GetInstallerRadiusType',
                        DatatypeRequested: DatatypeRequested,
                        DatatypeURL: DatatypeURL,
                        Message: [Result.message],
                        Trace: Result.ReturnData.DeclaringTypes
                    }
                });

            }

            dispatch({
                type: GET_RADIUS_TYPE,
                List: RadiusType === null ? [] : RadiusType
            });
        });
};

export const GetInstallerMinimumbudgetType = (Url) => dispatch => {

    let DatatypeRequested = 'BIT.Fensa.InstallerPortal.GetInstallerMinimumbudgetType';
    let DatatypeURL = process.env.REACT_APP_FENSA_WEB_API + "BITEngine";

    new DataFetch()
        .PerformEngineFetch(
            JSON.stringify(''),
            DatatypeRequested,
            DatatypeURL
        )
        .then(response => response.json())
        .then(data => {

            const Result = JSON.parse(data);

            let MinimumBudgetType = Result.returncode === 1 ? JSON.parse(Result.message) : null;

            if (Result.returncode === 0) {

                dispatch({
                    type: SET_APPLICATION_ERROR,
                    Item: {
                        Route: Url,
                        Function: 'GetInstallerMinimumbudgetType',
                        DatatypeRequested: DatatypeRequested,
                        DatatypeURL: DatatypeURL,
                        Message: [Result.message],
                        Trace: Result.ReturnData.DeclaringTypes
                    }
                });

            }

            dispatch({
                type: GET_MINIMUMBUDGET_TYPE,
                List: MinimumBudgetType === null ? [] : MinimumBudgetType
            });
        });

};

export const InsertInstallerWorkData = ( WorkData, Url) => dispatch => {

    let DatatypeRequested = 'BIT.Fensa.InstallerPortal.UpdateInstallerWorkData';
    let DatatypeURL = process.env.REACT_APP_FENSA_WEB_API + "BITEngine/InsertObject";
    
    console.log('InsertInstallerWorkData');


    new DataFetch()
        .PerformEngineFetch(
            JSON.stringify(WorkData),
            DatatypeRequested,
            DatatypeURL
        )
        .then(response => response.json())
        .then(data => {

            const Result = JSON.parse(data);

            let Message = Result.returncode === 1 ? JSON.parse(Result.message) : null;

            if (Result.returncode === 0) {

                dispatch({
                    type: SET_APPLICATION_ERROR,
                    Item: {
                        Route: Url,
                        Function: 'InsertInstallerWorkData',
                        DatatypeRequested: DatatypeRequested,
                        DatatypeURL: DatatypeURL,
                        Message: [Result.message],
                        Trace: Result.ReturnData.DeclaringTypes
                    }
                });

            }

            dispatch({
                type: INSERT_INSTALLER_WORK_DATA,
                Result: Message === null ? [] : Result
            });

            if (Result.returncode === 1) {

                dispatch({
                    type: SET_APPLICATION_INFORMATION,
                    Item: {
                        Route: Url,
                        Function: 'InsertInstallerWorkData',
                        DatatypeRequested: DatatypeRequested,
                        DatatypeURL: DatatypeURL,
                        Message: ['Your updates were successful'],
                        Trace: Result.ReturnData.DeclaringTypes
                    }
                });

            }
            console.log(Result);
        });
};


