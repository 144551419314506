import React, { Component } from 'react';
import { HomeItem } from '../SharedComponents/HomeItem';

export class UsefulInformation extends Component {

    static DrawItems(MainMenu) {
        return (
            <div className="ui-layout-content">

                <p>You will find lots of useful information here.</p>

                <div className='row'>
                    {
                        MainMenu.map(Menu => <HomeItem text={Menu.text} baseurl={Menu.baseurl} description={Menu.description} url={Menu.url} />)
                    }
                </div>

            </div>
        );
    }

    displayName = UsefulInformation.name

    constructor(props) {
        super(props);
        this.state = { HomeMenuItems: [], loading: true };

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'UsefulLinks')
            .then(response => response.json())
            .then(data => {
                this.setState({ UsefulLinkItem: data, loading: false });
            });
    }


    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : UsefulInformation.DrawItems(this.state.UsefulLinkItem);

        return (
            <div>
                <h1>Main Menu</h1>
                {contents}
            </div>
        );
    }
}
