
import React, { Component } from 'react';
import uuid from "uuid";

import AuthHelper from '../SharedComponents/AuthHelper';
import { PleaseWait } from '../SharedComponents/PleaseWait';


export class DocumentView extends Component {
    displayName = "DocumentView";

    constructor(props) {
        super(props);
        const auth = new AuthHelper();
        this.state = {
            CompanyId: auth.CompanyId,
            ContentType: this.props.ContentType,
            DocumentDescription: this.props.DocumentDescription,
            FileName: this.props.FileName,
            Identifier: this.props.Identifier,
            OperativeDocument: '',

            displayContent: (<PleaseWait IsLoading={true} PleaseWaitText="Processing" />),
            loading: true
        };
    }

    componentWillMount() {
        this.fetchData();
    }

     renderView = (operativeDocument, fileName, contentType, documentDescription) => {
        return (
            <div className='row py-1'>
                <div className='col-md-12'>
                    <i className='fas fa-download pr-2'/><a className='link-text pr-3' target='_blank' href={`data:${contentType} ;base64,${operativeDocument}`} download={fileName}>
                        {fileName}
                    </a>
                    {documentDescription}
                </div>
            </div>
        );
    }

    fetchData = () => {
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/DownloadImage', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.MongoDB.Evidence",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyReference,
                    OperativeId: this.state.OperativeId,
                    Identifier: this.state.Identifier
                })
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                const file = this.state.FileName;
                const contentType = this.state.ContentType;
                const docDesc = this.state.DocumentDescription;
                this.setState({
                    OperativeDocument: data.message,
                    displayContent: this.renderView(data.message, file, contentType, docDesc),
                    loading: false
                });
            });
    };

    render() {
        return (
            <div>
                <div>
                    {this.state.displayContent}
                </div>
            </div>
        );
    }
}
