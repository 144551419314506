import React, { Component } from 'react';
import { MenuItems } from '../SharedComponents/MenuItems';

import ReactGA from 'react-ga';


//menuid: 10
export class ActionsRequired extends Component {
    
    render() {
        ReactGA.event({
            category: 'Actions Required',
            action: 'View List'
        });

        return (
            <MenuItems ParentName="Actions Required" NoDataPrompt="Actions required section is under construction" />
        );
    }
}