import React, { Component } from 'react';

import '../Fensa.css';

export class ReportSomeNews extends Component {
    displayName = ReportSomeNews.name;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">Report news section is under construction</div>
                </div>
            </div>
        );
    }
}