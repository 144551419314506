import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import '../Installation/DatePicker.css';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';

export class SingleDatePickerControl extends Component {

    constructor(props) {
        super(props);
        // control its own local state as passing 
        // this to the parent create recusive focus 
        this.state = {
            focused: false,
        }
    }
    render() {
        return <>
            <SingleDatePicker
                id={this.props.Id}
                inputIconPosition={this.props.IconPosition}
                block={this.props.Block}
                date={this.props.Date}
                placeholder={this.props.PlaceHolderText}
                isOutsideRange={this.props.HandleOutsideDateRange}
                required={this.props.IsRequired}
                onDateChange={this.props.HandleDateChange}
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ "focused": focused })}
                openDirection={this.props.PopUpDirection}
                numberOfMonths={this.props.NumberOfMonths}
                hideKeyboardShortcutsPanel
                displayFormat={this.props.DateDisplayFormat} showClearDate />

            <div><p className={this.props.IsRequired ? 'valid-completion-date' : ''}>{!this.props.IsValidate ? this.props.ErrorMessage : null}</p></div>
        </>
    }
}
