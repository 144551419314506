import validator from 'validator';
import { AuthResponseType } from "./AuthResponseType";
import { QuerystringHook } from './QuerystringHook';

export default class AuthHelper {
    constructor(props) {
        //let crumbMap;
        let authoriseAccountMode = false;
        let companyFensaNumber;
        let companyId;
        let companyName;
        let confirmAccountMode = false;
        let defaultcoyfensanumber;
        let displayName;
        let email;
        let forgottenPwdMode = false;
        let InstallerCompanyRegistrationId;
        let isAuthenticated = false;
        let isFensaAdmin = false;
        let isMainAuthUser = false;
        let registerMode = false;
        let resetPwdMode = false;
        let selectCompanyMode = false;
        let token;
        let userdisplayname;
        let userId;
        let username;

        //console.log('InSelectCompanyMode', sessionStorage.getItem('InSelectCompanyMode'));
        //console.log('UserName', sessionStorage.getItem('UserName'));
        //console.log('UserDisplayName', sessionStorage.getItem('UserDisplayName'));
        //console.log('CompanyId', sessionStorage.getItem('CompanyId'));
        //console.log('UserEmail', sessionStorage.getItem('UserEmail'));
        //console.log('CompanyFensaNumber', sessionStorage.getItem('CompanyFensaNumber'));
        //console.log('IsFensa', sessionStorage.getItem('IsFensa'));

        //anything on the querystrings?
        //console.log('authhelper: ', this.props.location.pathname);
        //console.log('authhelper: ', this.props);

        //console.log('authhelper: ', window.location.pathname);
        if (this.hasQerystringHook('op=' + QuerystringHook.resetPwd)) {
            resetPwdMode = true;
        }
        else if (this.hasQerystringHook('op=' + QuerystringHook.activateAccount)) {
            confirmAccountMode = true;
        }
        else if (this.hasQerystringHook('op=' + QuerystringHook.authoriseAccount)) {
            authoriseAccountMode = true;
        }
        else if (sessionStorage.getItem('UserName')) {
            // if a name is supplied use it otherwise use sessionstorage
            authoriseAccountMode = false;
            companyFensaNumber = sessionStorage.getItem('CompanyFensaNumber');
            companyId = sessionStorage.getItem('CompanyId');
            companyName = sessionStorage.getItem('CompanyName');
            confirmAccountMode = false;
            defaultcoyfensanumber = sessionStorage.getItem('DefaultCompanyFensaNumber');
            displayName = props ? props : sessionStorage.getItem('DisplayName');
            email = sessionStorage.getItem('UserEmail');
            InstallerCompanyRegistrationId = sessionStorage.getItem('InstallerCompanyRegistrationId');
            isAuthenticated = true;
            var isfadmin = sessionStorage.getItem('IsFensa');
            isFensaAdmin = isfadmin === null || isfadmin === undefined ? false : validator.toBoolean(sessionStorage.getItem('IsFensa'));
            var ismainuser = sessionStorage.getItem('IsMainAuthorisedUser');
            isMainAuthUser = ismainuser === null || ismainuser === undefined ? false : validator.toBoolean(sessionStorage.getItem('IsMainAuthorisedUser'));
            registerMode = false;
            resetPwdMode = false;//this.hasQerystringHook('op=' + QuerystringHook.resetPwd);
            selectCompanyMode = sessionStorage.getItem('InSelectCompanyMode');
            //console.log('selectCompanyMode: ', selectCompanyMode);
            if (selectCompanyMode === undefined || selectCompanyMode === null) {
                selectCompanyMode = false;
            }
            //console.log('l8r:selectCompanyMode: ', selectCompanyMode);
            if (selectCompanyMode === true) {
                isAuthenticated = false;
            }
            //console.log('l8r.isAuthenticated: ', isAuthenticated);
            token = sessionStorage.getItem('Token');
            userdisplayname = sessionStorage.getItem('UserDisplayName');
            userId = sessionStorage.getItem('UserId');
            username = sessionStorage.getItem('UserName');
        } else if (sessionStorage.getItem('InRegisterMode')) {
            //in register mode?
            //console.log('In registermode: ', sessionStorage.getItem('InRegisterMode'));
            registerMode = sessionStorage.getItem('InRegisterMode');
            //registerMode = registerMode === undefined ? false : registerMode;
            if (registerMode === undefined || registerMode === null) {
                registerMode = false;
            }
            //console.log('In registermode: registerMode', registerMode);
        } else if (sessionStorage.getItem('InForgottenPwdMode')) {
            //in register mode?
            //console.log('In registermode: ', sessionStorage.getItem('InRegisterMode'));
            forgottenPwdMode = sessionStorage.getItem('InForgottenPwdMode');
            //forgottenPwdMode = forgottenPwdMode === undefined ? false : forgottenPwdMode;
            if (forgottenPwdMode === undefined || forgottenPwdMode === null) {
                forgottenPwdMode = false;
            }
            //console.log('In registermode: forgottenPwdMode', forgottenPwdMode);
        } else if (sessionStorage.getItem('InResetPasswordMode')) {
            //in reset password mode?
            //console.log('In registermode: ', sessionStorage.getItem('InResetPasswordMode'));
            resetPwdMode = sessionStorage.getItem('InResetPasswordMode');
            if (resetPwdMode === undefined || resetPwdMode === null) {
                resetPwdMode = false;
            }
            //console.log('In registermode: resetPwdMode', resetPwdMode);
        } else if (sessionStorage.getItem('InConfirmAccountMode')) {
            //in confirm account mode?
            confirmAccountMode = sessionStorage.getItem('InConfirmAccountMode');
            if (confirmAccountMode === undefined || confirmAccountMode === null) {
                confirmAccountMode = false;
            }
        } else if (sessionStorage.getItem('InAuthoriseAccountMode')) {
            //in confirm account mode?
            authoriseAccountMode = sessionStorage.getItem('InAuthoriseAccountMode');
            if (authoriseAccountMode === undefined || authoriseAccountMode === null) {
                authoriseAccountMode = false;
            }
        } else if (sessionStorage.getItem('InSelectCompanyMode')) {
            //in confirm account mode?
            selectCompanyMode = sessionStorage.getItem('InSelectCompanyMode');
            if (selectCompanyMode === undefined || selectCompanyMode === null) {
                selectCompanyMode = false;
            }
        }


        this.state = {
            //CrumbMap: crumbMap,
            Authenticated: isAuthenticated,
            CompanyFensaNumber: companyFensaNumber,
            CompanyId: companyId,
            CompanyName: companyName,
            DefaultCompanyFensaNumber: defaultcoyfensanumber,
            DisplayName: displayName,
            IsFensaAdmin: isFensaAdmin,
            InConfirmAccountMode: confirmAccountMode,
            InAuthoriseAccountMode: authoriseAccountMode,
            InRegisterMode: registerMode,
            InForgottenPwdMode: forgottenPwdMode,
            InResetPasswordMode: resetPwdMode,
            InSelectCompanyMode: selectCompanyMode,
            InstallerCompanyRegistrationId: InstallerCompanyRegistrationId,
            IsMainAuthorisedUser: isMainAuthUser,
            Token: token,
            UserEmail: email,
            UserId: userId,
            UserDisplayName: userdisplayname,
            Username: username
        };
        //console.log("AuthHelper.Ctor > state ", this.state);
        //console.log("AuthHelper.Ctor > props ", this.props);
        this.handleAuthenticated = this.handleAuthenticated.bind(this);
    }

    handleAuthenticated(authenticated, CompanyName, UserEmail) {
        this.setState({
            Authenticated: authenticated,
            CompanyName: CompanyName,
            UserEmail: UserEmail
        });
    }

    get CanEditData() {
        return this.state.IsFensaAdmin || this.state.IsMainAuthorisedUser;
    }

    get CanDoAdminTask() {
        return this.state.IsFensaAdmin;
    }

    /// Check if an authenticated session exists
    get IsAuthenticated() {
        // console.log(this.state);
        return this.state.Authenticated;
    }

    /// Check if an authenticated user is a fensa admin
    get IsFensaAdmin() {
        // console.log(this.state);
        return this.state.IsFensaAdmin;
    }

    get IsMainAuthorisedUser() {
        return this.state.IsMainAuthorisedUser;
    }

    /// Check if in confirm account mode
    get InConfirmAccountMode() {
        // console.log(this.state);
        return this.state.InConfirmAccountMode;
    }


    /// Check if in confirm account mode
    get InAuthoriseAccountMode() {
        // console.log(this.state);
        return this.state.InAuthoriseAccountMode;
    }

    /// Check if in reset password mode
    get InResetPasswordMode() {
        //console.log(this.state);
        return this.state.InResetPasswordMode;
    }

    /// Check if in register mode
    get InRegisterMode() {
        // console.log(this.state);
        return this.state.InRegisterMode;
    }
    /// Check if in forgotten password mode
    get InForgottenPwdMode() {
        // console.log(this.state);
        return this.state.InForgottenPwdMode;
    }
    /// Check if in select company mode
    get InSelectCompanyMode() {
        // console.log(this.state);
        return validator.toBoolean(this.state.InSelectCompanyMode.toString());
    }

    /// Get the company Id associated wit the login
    get CanShowPortal() {
        return this.state.IsFensaAdmin || (!this.state.IsFensaAdmin && this.state.CompanyId > 0);
    }

    /// Get the company Id associated wit the login
    get CompanyId() {
        return this.state.CompanyId;
    }

    /// Get the company name associated with the login
    get CompanyName() {
        return this.state.CompanyName;
    }

    get DefaultCompanyFensaNumber() {
        return sessionStorage.getItem('DefaultCompanyFensaNumber');
    }

    //get CrumbMap() {
    //    return this.state.CrumbMap;
    //}

    get DisplayView() {
        return sessionStorage.getItem(this.state.DisplayName + '-ViewName');
    }

    get TableFilter() {
        return sessionStorage.getItem(this.state.DisplayName + '-TableFilter');
    }

    /// Get authenticated token from session
    get Token() {
        return this.state.Token;
    }

    /// Get the Email associated with the login
    get UserEmail() {
        return this.state.UserEmail;
    }

    /// Get the userId associated with the login
    get UserId() {
        return this.state.UserId;
    }

    get AuthoriseAccountUser() {
        return sessionStorage.getItem('AuthoriseAccountUser');
    }

    get ResetPasswordUser() {
        return sessionStorage.getItem('ResetPasswordUser');
    }

    get ConfirmAccountUser() {
        return sessionStorage.getItem('ConfirmAccountUser');
    }

    get SignInNotificationMessages() {
        return sessionStorage.getItem('SignInNotificationMessages');
    }

    /// Get the user display associated with the login
    get UserDisplayName() {
        return this.state.UserDisplayName;
    }

    /// Get the username associated with the login
    get Username() {
        return this.state.Username;
    }

    get CompanyReference() {
        return this.state.CompanyFensaNumber;
    }

    get CompanyFensaNumber() {
        return this.state.CompanyFensaNumber;
    }

    get InstallerCompanyRegistrationId() {
        return this.state.InstallerCompanyRegistrationId;
    }

    /// Perform logout
    LogOut = () => {
        // remove authentication token from session
        sessionStorage.clear();
    }

    LogOutAndReload = () => {
        // remove authentication token from session
        sessionStorage.clear();

        var url = window.location.origin;
        //console.log('AuthHelper.handleLogoutClick', url, window.location);
        if (url) {
            //replace the querystring and go to home
            //console.log('AuthHelper.url', url, window.location.pathname);
            window.location.href = url;
        }
    }

    ReloadOnly = () => {
        var url = window.location.origin;
        //console.log('AuthHelper.handleLogoutClick', url, window.location);
        if (url) {
            //replace the querystring and go to home
            //console.log('AuthHelper.url', url, window.location.pathname);
            window.location.href = url;
        }
    }

    ReloadUrl = url => {
        //console.log('AuthHelper.handleLogoutClick', url, window.location);
        if (url) {
            //replace the querystring and go to home
            //console.log('AuthHelper.url', url, window.location.pathname);
            window.location.href = url;
        }
    }


    /// Perform login and set authentication token/data in session
    Login = (postData) => {
        //console.log(postData);
        // perform login here...
        return fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/Authenticate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        })
            .then(response => response.json())
            //.then(data => JSON.parse(data))
            .then(data => {
                console.log('AuthHelper.Login: ', data);
                this.setSessionItem('SignInNotificationMessages', data.returnData.errors);
                if (data.returncode === 1) {
                    // set token if successful
                    //var user = JSON.parse(data.message);
                    var user = JSON.parse(data.returnData.payload);
                    // Check if we have an identity user created
                    // and prompt for additional login information
                    console.log('user', user);
                    //console.log('CredentialViolation: ' + data.opid + ' == ' + AuthResponseType.CredentialViolation);
                    //console.log('AccountDeleted: ' + data.opid + ' == ' + AuthResponseType.AccountDeleted);
                    //console.log('AccountDisabled: ' + data.opid + ' == ' + AuthResponseType.AccountDisabled);
                    //console.log('InvalidCompanyData: ' + data.opid + ' == ' + AuthResponseType.InvalidCompanyData);
                    //console.log('MissingCompanyData: ' + data.opid + ' == ' + AuthResponseType.MissingCompanyData);
                    //console.log('MissingAccount: ' + data.opid + ' == ' + AuthResponseType.MissingCompanyData);
                    //console.log('MissingRole: ' + data.opid + ' == ' + AuthResponseType.MissingRole);
                    //console.log('MissingPermission: ' + data.opid + ' == ' + AuthResponseType.MissingPermission);
                    //console.log('PendingPasswordReset: ' + data.opid + ' == ' + AuthResponseType.PendingPasswordReset);
                    //console.log('PendingVerification: ' + data.opid + ' == ' + AuthResponseType.PendingVerification);
                    switch (data.opid) {
                        case AuthResponseType.CredentialViolation:
                            break;
                        case AuthResponseType.AccountDeleted:
                            //show info area or message
                            //this.SetUserDataOnly(user);
                            break;
                        case AuthResponseType.AccountDisabled:
                            //show info area or message
                            //this.SetUserDataOnly(user);
                            break;
                        case AuthResponseType.InvalidCompanyData:
                        case AuthResponseType.MissingCompanyData:
                            //show info area or message
                            //this.setSessionItem('InSelectCompanyMode', true);
                            this.StartSelectCompanyMode(true);
                            this.SetLoginData(user, false);
                            break;
                        case AuthResponseType.MissingAccount:
                            //show info area or message
                            break;
                        case AuthResponseType.MissingRole:
                            break;
                        case AuthResponseType.MissingPermission:
                            break;
                        case AuthResponseType.PendingPasswordReset:
                            this.setSessionItem('InResetPasswordMode', true);
                            this.SetLoginData(user, false);
                            break;

                        case AuthResponseType.PendingVerification:
                            //show account password reset and set session vars
                            this.setSessionItem('InConfirmAccountMode', true);
                            this.SetLoginData(user, false);
                            break;
                        default:
                            this.SetLoginData(user, true);
                            break;
                    }
                    return data;
                }
                else {
                    //this.setSessionItem('UserEmail', '');
                    this.setSessionItem('UserDisplayName', '');
                    this.setSessionItem('UserName', '');
                    this.setSessionItem('UserId', '');
                    this.setSessionItem('CompanyId', '');
                    this.setSessionItem('Token', '');
                    this.setSessionItem('CompanyName', '');
                    this.setSessionItem('IsFensa', '');
                    this.setSessionItem('IsMainAuthorisedUser', '');

                    //set session
                    this.refreshState(false, '', '', '', '', '', '', '', '');
                }
                return data;
            });
    }


    //Check if there's a hook in querystrings
    hasQerystringHook = (key) => {
        //supported qs
        var qs = window.location.pathname;
        if (qs) {
            if (qs.indexOf(key) > 0) {
                console.log('hook', key, qs);
                switch (key) {
                    case 'op=' + QuerystringHook.resetPwd:
                        //do reset/verify password
                        this.setSessionItem('InResetPasswordMode', true);
                        this.setSessionItem('ResetPasswordUser', qs.replace('/' + key + '&code=', ''));
                        return true;

                    case 'op=' + QuerystringHook.confirmAccountMode:
                        //do confirm account
                        this.setSessionItem('InConfirmAccountMode', true);
                        this.setSessionItem('ConfirmAccountUser', qs.replace('/' + key + '&code=', ''));
                        return true;

                    case 'op=' + QuerystringHook.authoriseAccount:
                        //do confirm account
                        this.setSessionItem('InAuthoriseAccountMode', true);
                        this.setSessionItem('AuthoriseAccountUser', qs.replace('/' + key + '&code=', ''));
                        return true;

                    default:
                        break;
                }
            }
        }
        return false;
    }

    refreshState = (isAuthenticated, companyId, companyName, token, email, userId, username, userdisplayname, fensanumber) => {
        this.state = {
            Authenticated: isAuthenticated,
            CompanyFensaNumber: fensanumber,
            CompanyId: companyId,
            CompanyName: companyName,
            Token: token,
            UserEmail: email,
            UserId: userId,
            UserDisplayName: userdisplayname,
            Username: username
        };
    }

    ResetDefaultCompany = (companyId, companyName, fensaNum) => {
        this.setSessionItem('CompanyId', companyId);
        this.setSessionItem('CompanyName', companyName);
        this.setSessionItem('CompanyFensaNumber', fensaNum);
        this.setSessionItem('DefaultCompanyFensaNumber', fensaNum);
    }

    SetUserDataOnly = (user) => {
        this.setSessionItem('UserName', user.UserName);
        this.setSessionItem('UserDisplayName', user.DisplayName);
        this.setSessionItem('UserId', user.UserId);
        this.setSessionItem('UserEmail', user.Email);
    }

    SetLoginData = (user, mode) => {

        console.log('AuthHelper.setLoginData', mode, user);

        //this.setSessionItem('UserName', user.UserName);
        //this.setSessionItem('UserDisplayName', user.DisplayName);
        //this.setSessionItem('UserId', user.UserId);
        //this.setSessionItem('UserEmail', user.Email);
        this.SetUserDataOnly(user);
        this.setSessionItem('Token', user.Token);
        this.setSessionItem('CompanyId', user.DefaultCompanyId);
        this.setSessionItem('CompanyName', user.DefaultCompanyName);
        this.setSessionItem('CompanyFensaNumber', user.DefaultCompanyFensaNumber);
        this.setSessionItem('DefaultCompanyData', user.CompanyData);
        this.setSessionItem('IsFensa', user.IsFensaAdmin);
        this.setSessionItem('IsMainAuthorisedUser', user.MainAuthUser);

        //set default company number...
        this.setSessionItem('DefaultCompanyFensaNumber', user.DefaultCompanyFensaNumber);

        //if no company selected then

        //this.setSessionItem('CompanyId', user.CompanyId);
        //this.setSessionItem('CompanyName', user.CompanyName);
        //this.setSessionItem('CompanyFensaNumber', user.CompanyReference);
        //this.setSessionItem('InstallerCompanyRegistrationId', user.InstallerCompanyRegistrationId);

        //set session 
        this.refreshState(mode,
            user.DefaultCompanyId,
            user.DefaultCompanyName,
            user.Token,
            user.Email,
            user.UserId,
            user.UserName,
            user.UserDisplayName,
            user.DefaultCompanyFensaNumber);


        if (user.IsFensaAdmin) {
            this.StartSelectCompanyMode(true);
        }

        this.SetToken(user.Token);
    }

    setSessionItem = (item, value) => {
        sessionStorage.setItem(item, value);
        //console.log('item and value stored in session',JSON.Stringify(item), value);
    }

    /// Set authentication token in session
    SetToken = (token) => {
        //console.log(token);
        this.setSessionItem('Token', token);
    }

    CacheDisplayView = (displayName, viewName) => {
        //console.log('AuthHelper.CacheDisplayView: ', viewName, displayName);
        this.setSessionItem('DisplayName', displayName);
        const viewItemName = displayName + '-ViewName';
        //console.log('viewItemName', viewItemName);
        this.setSessionItem(viewItemName, viewName);
    }

    CacheTableFiltering = (displayName, filter) => {
        //console.log('AuthHelper.CacheTableFiltering: ', filter, displayName);
        this.setSessionItem('DisplayName', displayName);
        const tableFilterName = displayName + '-TableFilter';
        //console.log('tableFilterName', tableFilterName);
        this.setSessionItem(tableFilterName, filter);
    }

    CacheDefaultCompanyData = (companyData) => {
        this.setSessionItem('DefaultCompanyData', companyData);
    }

    SetDefaultCompany = (company) => {
        //console.log('AuthHelper.SetDefaultCompany', company);
        if (company) {
            this.setSessionItem('CompanyId', company.CompanyId);
            this.setSessionItem('CompanyName', company.CompanyName);
            this.setSessionItem('CompanyFensaNumber', company.FensaNumber);
            this.StartSelectCompanyMode(false);

            //force reload
            this.refreshState(true,
                company.CompanyId,
                company.CompanyName,
                this.Token,
                this.UserEmail,
                this.UserId,
                this.Username,
                this.UserDisplayName,
                company.CompanyFensaNumber);

            //set default company
            //this.ResetDefaultCompany(company.CompanyId, company.CompanyName, company.FensaNumber);
        }
    }

    StartConfirmAccountMode = (mode) => {
        //console.log('StartConfirmAccountMode.mode', mode);
        this.setSessionItem('InConfirmAccountMode', mode);
    }

    StartRegisterMode = (mode) => {
        //console.log('StartRegisterMode.mode', mode);
        this.setSessionItem('InRegisterMode', mode);
    }

    StartResetPasswordMode = (mode) => {
        //console.log('StartResetPasswordMode.mode', mode);
        this.setSessionItem('InResetPasswordMode', mode);
    }
    StartForgottenPwdMode = (mode) => {
        //console.log('StartForgottenPwdMode.mode', mode);
        this.setSessionItem('InForgottenPwdMode', mode);
    }

    StartSelectCompanyMode = (mode) => {
        //console.log('StartSelectCompanyMode.mode', mode);
        this.setSessionItem('InSelectCompanyMode', mode);
    }

    //ClearCrumbMap = () => {
    //    this.setSessionItem('CrumbMap', '[]');
    //}

    //SetCrumbMap = (contextMap) => {
    //    this.setSessionItem('CrumbMap', contextMap);
    //}
}