import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { MenuItems } from '../SharedComponents/MenuItems';

import ReactGA from 'react-ga';


export class FinanceServices extends Component {
    displayName = FinanceServices.name

    render() {

        ReactGA.event({
            category: 'Finance Services',
            action: 'View Finance Services'
        });


        return (
            /*<FinanceMenuItems />*/
            <>       
             { <MenuItems ParentName="Finance Services" ParentId="4" /> } 
            </>
        );
    }
}
