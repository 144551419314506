import React, { Component } from 'react';
import { MenuItems } from '../SharedComponents/MenuItems';

import ReactGA from 'react-ga';


export class UsefulResources extends Component {
    displayName = "UsefulResources";

    render() {

        ReactGA.event({
            category: 'Useful Resources',
            action: 'Useful Resources Home'
        });

        return (
         
            <MenuItems ParentName="Useful Resource Tools" />
           
        );
    }
}
