import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Moment from 'react-moment';
import moment from 'moment';
import uuid from "uuid";

import ReactGA from 'react-ga';

import 'react-datepicker/dist/react-datepicker.css';

import AuthHelper from '../SharedComponents/AuthHelper';
import { NotificationPanel } from '../SharedComponents/NotificationPanel';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import { ReadonlyItem } from './../SharedComponents/ReadonlyItem';

const inspectionsHomeUrl = "/inspections/History";
export class InspectionView extends Component {
    displayName = InspectionView.name;

    constructor(props) {
        super(props);
        const auth = new AuthHelper(this.displayName);
        this.state = {
            InspectionId: props.match.params.id,
            InstallationId: '',
            Inspection: [],
            UsePaging: true,
            CompanyId: auth.CompanyId,
            displayContent: (<PleaseWait IsLoading={true} />),
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 0,
            payload: []
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    renderTable = inspection => {
        return (
            <div className='form-group'>
                <div className='row'>
                    <div className="col-xs-12 col-md-6">
                        <ReadonlyItem textLabel="Company Name">{inspection.CompanyName}</ReadonlyItem>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <ReadonlyItem textLabel="Reference">{inspection.Reference}</ReadonlyItem>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <ReadonlyItem textLabel="Assessment Status">{inspection.InspectionStatus}</ReadonlyItem>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <ReadonlyItem textLabel="Assessment Type">{inspection.InspectionTypeName}</ReadonlyItem>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <ReadonlyItem textLabel="Assessor">{inspection.Inspector}</ReadonlyItem>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <ReadonlyItem textLabel="Assessed On">{moment(inspection.InspectedOn).format('D MMM Y')}</ReadonlyItem>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <ReadonlyItem textLabel="Due Assessment">{moment(inspection.DueInspection).format('D MMM Y')}</ReadonlyItem>
                    </div>
                    <div className='col-md-12 text-right'>
                        <small>Installed on <Moment element="span" parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={inspection.InstallationDate} /></small>
                    </div>
                </div>
            </div>
        );
    }

    fetchData = () => {

        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InspectionGet",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                InspectionId: this.state.InspectionId
            })
        });

        var fetchInspection = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        });

        Promise.all([fetchInspection])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                const data = JSON.parse(responses[0]);
                const inspectData = JSON.parse(data.ReturnData.Payload)[0];
                console.log(this.displayName + 'fetchData[data]', data);
                console.log(this.displayName + 'fetchData[inspectData]', inspectData);
                this.setState({
                    InstallationId: inspectData.InstallationId,
                    displayContent: inspectData === null ?
                        this.renderNoDataView() :
                        this.renderTable(inspectData),
                    loading: false,
                    notificationPostfix: data.returncode === 0 ? 'info show mt-3' : 'info hide',
                    notificationMessage: data.returncode === 0 ? ['No Data'] : [],
                    payload: inspectData,
                    totals: inspectData.length
                });
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData[catch]', data);
                this.setState({
                    displayContent: '',
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    payload: []
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'Assessment View',
            action: 'View Assessment'
        });


        return (
            <div className='form-group-fluid-container mr-auto ml-auto'>
                <div className='form-group-title'>View Assessment</div>
                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                {this.state.displayContent}
                <div className='row py-3'>
                    <div className='col'>
                        <Link className='btn btn-sm btn-primary pull-right mr-2 mdb-btn-resize' to={'/Installations/View/' + this.state.InstallationId}>
                            <i className='fa fa-arrow-right pr-2' />
                            View Installation
                        </Link>
                        <Link className='btn btn-sm btn-dark pull-right mr-2 mdb-btn-resize' to={inspectionsHomeUrl}>
                            <i className='fa fa-arrow-left pr-2' />
                            Back to List
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
