import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { CardFooterButtonsIcons } from './../SharedComponents/CardFooterButtonsIcons';
import { EnergyRatingSearch } from './EnergyRatingSearch';
import { PleaseWait } from './../SharedComponents/PleaseWait';
import { ReactTableFilterToggler } from './../SharedComponents/ReactTableFilterToggler';

//menuid: 12
export class EnergyRatings extends Component {
    displayName = EnergyRatings.name;

    constructor(props) {
        super(props);
        const auth = new AuthHelper(this.displayName);
        this.state = {
            BFRCLables: [],
            CompanyNumber: "AR1614",
            LicenceNumber: "",
            ShowEditButton: false,
            ShowDeleteButton: false,
            ShowViewButton: true,
            displayContent: (<PleaseWait IsLoading={true} />),
            installation: [],
            loading: true,
            page: 0,
            pageSize: 10,
            resized: [],
            sorted: [],
            totals: 'No',
            useFiltering: (auth.TableFilter && auth.TableFilter === 'On' ? true : false)
        };
        this.handleSearch = this.handleSearch.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    handleSearch(event) {
        this.fetchData();
        event.preventDefault();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        });
    }

    handleToggleFilter = toggle => {
        this.setState({
            useFiltering: toggle
        });
        this.fetchData();
    }

    renderItems = labels => {
        return (
            <div className="ui-layout-content">
                <ReactTableFilterToggler
                    ParentDisplayName={this.displayName}
                    UseFiltering={this.state.useFiltering}
                    handleToggleFilter={this.handleToggleFilter}
                />
                <ReactTable data={labels}
                    columns={[
                        {
                            key: 'LicenceId',
                            Header: 'Id',
                            accessor: 'LicenceId',
                            show: false
                        },
                        {
                            Header: 'LicenceNumber:',
                            accessor: 'LicenceNumber'
                        },
                        {
                            Header: 'Material', // Custom header components!
                            accessor: 'ProductMaterial'
                        },
                        {
                            Header: 'Product', // Custom header components!
                            accessor: 'ProductName'
                        },
                        {
                            Header: 'Company', // Custom header components!
                            accessor: 'CompanyName'
                        },
                        {
                            Header: 'Type', // Custom header components!
                            accessor: 'ProductType'
                        },
                        {
                            Header: 'Description', // Custom header components!
                            accessor: 'ProductDescription'
                        },
                        {
                            Header: 'Rating', // Custom header components!
                            accessor: 'EnergyRating'
                        },
                        {
                            Header: '',
                            accessor: '',
                            filterable: false,
                            sortable: false,
                            Cell: row => {
                                return (
                                    <Fragment>
                                        <div className='text-center py-1 pr-5'>
                                            <CardFooterButtonsIcons
                                                Id={row.original.Id}
                                                //DeleteCss={ButtonsJss.DefaultDeleteCss}
                                                //EditCss={ButtonsJss.DefaultEditCss}
                                                //ViewCss={ButtonsJss.DefaultViewCss}
                                                //DeleteUrl={'/EnergyRatings/Delete/' + row.original.Id}
                                                //EditUrl={'/EnergyRatings/Update/' + row.original.Id}
                                                ViewUrl={'/EnergyRatings/View/' + row.original.LicenceId}
                                                ShowViewButton={this.state.ShowViewButton} // use role priviledge to set visibility
                                                ShowEditButton={this.state.ShowEditButton} // use role priviledge to set visibility
                                                ShowDeleteButton={this.state.ShowDeleteButton}// use role priviledge to set visibility
                                            />
                                        </div>
                                    </Fragment>
                                );
                            }
                        }
                    ]}
                    className="table-bordered table-striped table-hover table-shadowed"
                    defaultPageSize={this.state.pageSize}
                    expanded={this.state.expanded}
                    filterable={this.state.useFiltering}
                    minRows={0}
                    nextText='>'
                    previousText='<'

                //onPageChange={(page) => this.setPageId(page)}
                //page={this.state.page}
                //page={this.state.page}
                //pageSize={this.state.pageSize}
                //resized={this.state.resized}
                //filtered={this.state.filtered}
                // Callbacks
                //onSortedChange={sorted => this.setState({ sorted })}
                //onPageChange={page => this.setState({ page })}
                //onPageSizeChange={(pageSize, page) => this.setState({ page, pageSize })}
                //onExpandedChange={expanded => this.setState({ expanded })}
                //onResizedChange={resized => this.setState({ resized })}
                //onFilteredChange={filtered => this.setState({ filtered })}
                />

            </div>
        );
    }

    fetchData = () => {

        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerContacts",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                LicenceNumber: this.state.LicenceNumber,
                CompanyNumber: this.state.CompanyNumber
            })
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BFRCWebAPI/GetCompanyLabelsInfo', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        })
            .then(response => response.json())
            .then(data => JSON.parse(data.result))
            .then(data => {
                this.setState({
                    BFRCLables: JSON.parse(data.message),
                    loading: false
                });
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData[catch]', data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    displayContent: ''
                });
            });
    }

    render() {
        //let contents = this.state.loading
        //    ? <p><em>Loading...</em></p>
        //    : this.renderItems(this.state.BFRCLables);

        ReactGA.event({
            category: 'Energy Ratings',
            action: 'View Energy Ratings'
        });


        return (
            <div>
                <h1 className="h1-responsive">Energy Ratings</h1>
                <div className='row'>
                    <div className='col-sm-12 col-md-8'>
                        <div className="pb-3">Thank you for registering with FENSA Energy Ratings. Here you can download the following: </div>
                        <div className="py-2">
                            <h3 className="h3-responsive">Your FENSA Energy Rating Labels</h3>
                            Click on the Energy Ratings table below.
                        </div>
                        <div className="py-2">
                            <h3 className="h3-responsive">FENSA Energy Rating Logo</h3>
                            <div><a href="@publicSiteUrl/images/default-source/default-album/fensa_wers_lr_cmyk.jpg?sfvrsn=2" download>Logo</a> in high resolution for print/offline (e.g. letterheads, leaflets, posters, receipts, invoices).</div>
                            <div><a href="@publicSiteUrl/images/default-source/default-album/fensa_wers_lr_rgb.jpg?sfvrsn=2" download>Logo</a> in low resolution for internet/online usage (e.g. website, email signature). </div>
                            <div> Usage <a href="@publicSiteUrl/docs/default-source/support-documents/fensa-energy-rating-logo-guidelines.pdf?sfvrsn=2" download>Guidelines</a></div>
                        </div>
                        <div className="py-2">
                            <h3 className="h3-responsive">Permanent Mark <a href="@publicSiteUrl/docs/default-source/support-documents/permanent-mark-guidelines.pdf?sfvrsn=2f" download>Guidance</a></h3>
                            <div>Please read this important information.</div>
                            <div>Permanent Mark usage is checked during your inspections.</div>
                        </div>
                        <div className="py-2">
                            <h3 className="h3-responsive">BFRC scheme <a href="@publicSiteUrl/docs/default-source/support-documents/bfrc-scheme-rules.pdf?sfvrsn=2" download>rules</a></h3>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-4'>
                        <img src={require('./WER Label_Specimen.jpg')} alt="WER Label Specimen" className="energy-label" />
                    </div>
                    <div className='col-sm-12 col-md-12 py-3'>
                        <h3 className="h3-responsive">Important: Energy Rating Label Usage</h3>
                        <div>You must use the correct specification with the appropriate energy rating. If you are unsure please contact your frame manufacturer who will be able to advise you. Alternatively please contact BFRC</div>
                    </div>
                    <div className='col-sm-12 col-md-12 py-3'>
                        <h3 className="h3-responsive">Getting Help</h3>
                        <div>To apply for additional Energy Ratings please contact BFRC by <a href=" mailto:enquiries@bfrc.org">email</a> or phone 020 7403 9200.</div>
                    </div>
                </div>
                {/*
                <EnergyRatingSearch
                    handleSearch={this.handleSearch}
                    handleChange={this.handleInputChange}
                />
                {contents}
                */}
                <Link className='btn orange pull-right' to='/EnergyRatings/List'>View Energy Rating Licenses<i className="fa fa-arrow-right pl-3" /></Link>
            </div>
        );
    }
}
