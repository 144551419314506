import React, { Component } from 'react';

import ReactGA from 'react-ga';
import '../Fensa.css';
import './UsefulResources.css';

import { ApprovedDownloads } from '../Downloads/ApprovedDownloads';
import { CardBodyOnly } from './../SharedComponents/CardBodyOnly';
import { PopularDownloads } from '../Downloads/PopularDownloads';

import { MDBJumbotron, MDBContainer, MDBRow, MDBCol } from 'mdbreact';


export class UsefulMembershipResources extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {

        ReactGA.event({
            category: 'Useful Membership Resources',
            action: 'Useful Membership Resources List'
        });


        return (
            <MDBContainer>
                <MDBRow>
                    <MDBCol>
                        <MDBJumbotron fluid>
                            <div className='pb-5 px-5'>
                                <h1 className='h1 h1-responsive'>Useful Membership Resources</h1>
                                <p className='lead text-left'>Here you will be able to download or print off useful documents such as your company registration certificate, inspection regime, FENSA logo, order forms for free van and showroom stickers, technical guidance notes and other useful information.</p>
                            </div>
                        </MDBJumbotron>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="col-md-6">
                        <CardBodyOnly>
                            <h2 className='h2-responsive pb-3'>Popular Downloads</h2>
                            <PopularDownloads />
                        </CardBodyOnly>
                    </MDBCol>
                    <MDBCol className="col-md-6">
                        <CardBodyOnly>
                            <h2 className='h2-responsive pb-3'>Approved Downloads</h2>
                            <ApprovedDownloads />
                        </CardBodyOnly>
                    </MDBCol>
                </MDBRow >
            </MDBContainer>
        );
    }
}