import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
// import { MDBDatePicker } from 'mdbreact';
import validator from 'validator';
import uuid from "uuid";

import ReactGA from 'react-ga';

import { SingleDatePickerControl } from '../SharedComponents/SingleDatePickerControl';

import { Image } from 'react-bootstrap';


import AuthHelper from '../SharedComponents/AuthHelper';
import { AddressCardBody } from '../SharedComponents/AddressCardBody';
import { AddressLookup } from '../SharedComponents/AddressLookup';
import { ColumnAndLabel } from '../SharedComponents/ColumnAndLabel';
import { ColumnAndLabelRadioOptions } from './../SharedComponents/ColumnAndLabelRadioOptions';
import { ConfirmActionModal } from '../SharedComponents/ConfirmActionModal';
import { InstallationDeclaration } from './InstallationDeclaration';
import { NotificationPanel } from '../SharedComponents/NotificationPanel';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import { Product } from './Product';
import { ProductAdd } from './AddProduct';
import { ReadonlyItem } from './../SharedComponents/ReadonlyItem';

import { MDBBtn, MDBIcon, MDBCollapse, MDBSwitch } from "mdbreact";

const installationHistoryHomeUrl = '/Installations/Home';
const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class RegisterInstallation extends Component {
    displayName = "RegisterInstallation";

    constructor(props) {
        super(props);

        const auth = new AuthHelper(this.displayName);
        this.state = {
            AuthorityId: '',

            BfrcCertRequested: false,
            CompanyId: auth.CompanyId,
            CompanyName: auth.CompanyName,
            CustomerEmail: '',
            CustomerName: '',
            CustomerTelephone: '',
            InstallerCompanyRegistrationId: auth.InstallerCompanyRegistrationId,
            InstallerReference: '',
            Token: auth.Token,
            UserId: auth.UserId,

            HidePropertyAddress: true,
            PropertyAddressLine1: '',
            PropertyAddressLine2: '',
            PropertyAddressLine3: '',
            PropertyCityTown: '',
            PropertyCounty: '',
            PropertyPostcode: '',

            DispatchAddressLine1: '',
            DispatchAddressLine2: '',
            DispatchAddressLine3: '',
            DispatchCityTown: '',
            DispatchCounty: '',
            DispatchPostcode: '',
            HideDispatchAddress: true,

            Reference: '',

            CompletionDate: null,
            CompletionDay: '',
            CompletionMonth: '',
            CompletionYear: '',
            Focused: false,
            IsCompletionDateValid: false,

            // None Fensa Stuff
            Conservatory: false,
            NonFensaDoors: 0,
            NonFensaWindows: 0,
            RoofLine: false,

            ContractValue: '',
            DeclarationAgreed: false,
            InstallType: 'WINDOW',
            LicenceNumber: '',
            NumberOfDoorsless50: 0,
            NumberOfDoorsMore50: 0,
            NumberOfItems: 0,
            NumberOfWindows: 0,
            ProductType: '',
            Rated: '',
            Rating: '',
            RatingType: 'none',

            PostCodeItems: [],

            LicenceDetails: [],
            LicenceValid: false,
            NewInstallationId: '',
            NewInstallationUrl: '',
            Result: [],
            SameDispatchAddress: true,
            UploadErrors: [],
            UploadFile: null,
            UploadResult: [],
            ViewDeclarationOnly: true,
            ViewSingleInstallation: false,
            ViewUploadInstallation: false,
            ViewUploadButton: true,

            HideNonFensaProductInput: false,
            FreezeNonFensaProductInput: false,

            // details about the company ()
            IBGProvider: null,
            InstallerType: null,
            RegistrationStatus: null,
            UploadSuccess: false,
            NewProducts: [],

            canAddProducts: true,
            conservatoryDisabled: false,
            collapseID: true,
            deleteActionMessage: "You are about to delete all selected products. Click Ok to continue otherwise cancel to abort.",
            formErrors: {
                CompletionDate: 'Completion Date is required dd/mm/yyyy',
                ContractValue: 'Contract value is required',
                CustomerName: 'Customer Name Required',
                CustomerTelephone: 'Telephone number is Required',
                DeclarationAgreed: 'You must agree the declaration',
                CustomerEmail: '',
                //InstallerReference: 'Installer Reference is required',
                PropertyPostcode: 'Postcode is Required',
                DispatchPostcode: 'Dispatch Postcode is Required'
            },
            formValid: false,
            IsLoading: true,
            modal: false,
            notificationPostfix: 'info hide',
            errorNotificationPostfix: 'info hide',
            notificationMessage: [],
            errorNotificationHeader: [],
            products: [],
            selectedInstallationProducts: {
                NumberOfWindows: 0,
                NumberOfDoors: 0,
                NumberOfDoorsLess50Glass: 0,
                NumberOfDoorsMore50Glass: 0
            },
            shouldHideBFRC: true,
            showInstallationHistoryButton: false,
            showFensaProducts: true,
            uploadNotificationMessage: null,
            errorNotificationMessage: null,

            // Added ability to update new build for certain installer types
            NewBuild: 1
            

        };

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleDespatchAddressSelected = this.handleDespatchAddressSelected.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleMemberDeclarationId = this.handleMemberDeclarationId.bind(this);
        this.handleOnFileUpload = this.handleOnFileUpload.bind(this);
        this.handlePropertyAddressSelected = this.handlePropertyAddressSelected.bind(this);
        this.handleRegisterSingle = this.handleRegisterSingle.bind(this);
        this.handleRegisterUpload = this.handleRegisterUpload.bind(this);
        this.submitUpload = this.submitUpload.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.onHandleProductAdd = this.onHandleProductAdd.bind(this);
        this.isProductExistByType = this.isProductExistByType.bind(this);
        this.handleOutsideDateRange = this.handleOutsideDateRange.bind(this);

        //console.log(this.displayName, this.props);
    }

    componentWillMount() {
        this.setState({ IsLoading: false });
    }

    componentDidMount() {
        this.fetchData();
        
        
    }

    handleDateChange(value) {
        let date = moment(value);
        this.setState({
            CompletionDate: value,
            IsCompletionDateValid: date.isValid(),
        }, () => { this.isValidInputField('CompletionDate'); });
    }

    handleOutsideDateRange(day) {
        return day.isAfter(moment(), 'day') || day.isBefore(moment().subtract(2, "year"));
    }

    handleSameDispatchAddress = cbValue => {
        console.log(this.displayName + ".handleSameDispatchAddress", cbValue);
        this.setState({
            SameDispatchAddress: this.state.SameDispatchAddress === true ? false : true
        });
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        if (value === undefined) {
            value = target.parentElement.value;
        }
        const name = target.name;
        if (name === 'ContractValue') {           
            value = value > 0 ? value : '';
        }
        //this state is only relevant locally - so no need for put it in redux.       
        this.setState({
            [name]: value
        },
            () => { this.isValidInputField(name); }
        );
        if (name === 'NonFensaWindows' || name === 'NonFensaDoors') {
            this.resetSelectedInstallationProducts(this.state.products);
        }
    }

    clearFensaProducts = () => {
        this.setState({
            Conservatory: true,
            NonFensaDoors: 0,
            NonFensaWindows: 0,
            NumberOfWindows: 0,
            NumberOfDoors: 0,
            NumberOfDoorsLess50Glass: 0,
            NumberOfDoorsMore50Glass: 0,
            HideNonFensaProductInput: true,
            products: [],
            showFensaProducts: false
        });
    }

    handleConservatoryCallback = cbValue => {
        ////if clearing the product list then a prompt should be displayed
        const productsList = cbValue ? [] : this.state.products;
        const nonFDoors = cbValue ? 0 : this.state.NonFensaDoors;
        const nonFWins = cbValue ? 0 : this.state.NonFensaWindows;
        const summaryNWin = cbValue ? 0 : this.state.NumberOfWindows;
        const summaryNDoor = cbValue ? 0 : this.state.NumberOfDoors;
        const summaryNDoorLess = cbValue ? 0 : this.state.NumberOfDoorsLess50Glass;
        const summaryNDoorMore = cbValue ? 0 : this.state.NumberOfDoorsMore50Glass;

        this.setState({
            Conservatory: cbValue ? true : false,
            HideNonFensaProductInput: cbValue ? true : false,
            NonFensaDoors: nonFDoors,
            NonFensaWindows: nonFWins,
            NumberOfDoors: summaryNDoor,
            NumberOfDoorsLess50Glass: summaryNDoorLess,
            NumberOfDoorsMore50Glass: summaryNDoorMore,
            NumberOfWindows: summaryNWin,

            products: productsList,
            showFensaProducts: cbValue ? false : true
        }
            ,
            () => {
                this.validateForm();
                //this.validateField(name, value);
            })
            ;


        //this.toggleModal(cbValue);
        //this.clearFensaProducts(cbValue);
    }

    handleNewBuildCallback = cbValue => {
        this.setState({
            NewBuild: cbValue ? true : false
        });
    }

    handleRoofLineCallback = cbValue => {
        this.setState({
            RoofLine: cbValue ? true : false
        });
    }

    isProductExistByType(typeName) {
        var result = this.state.products.findIndex((prod) => {
            return prod.RatingType === typeName
        }) > -1
        return result;
    }

    handleNonFensaProductChange = (event) => {
        //case 'nonFensadoors':
        //       fieldValidationErrors.NonFensaDoors = validator.isEmpty(event.target.value) ? 'First name is required.' : '';
        //       if (fieldValidationErrors.NonFensaDoors === "") {
        //           fieldValidationErrors.NonFensaDoors = validator.isLength(event.target.value, { min: 2 }) ? '' : 'First name must be more than 1 character.';
        //       }
        //       message = fieldValidationErrors.FirstName;
        //       break;

        //   case 'nonfensawindows':
        //       fieldValidationErrors.NonFensaWindows = validator.isEmpty(event.target.value) ? 'Last name is required.' : '';
        //       if (fieldValidationErrors.NonFensaWindows === "") {
        //           fieldValidationErrors.NonFensaWindows = validator.isLength(event.target.value, { min: 2 }) ? '' : 'Last name must be more than 1 character.';
        //       }
        //       message = fieldValidationErrors.NonFensaWindows;
        //       break;
    }

    handleOnInputValidation = event => {
        let fieldValidationErrors = this.state.formErrors;
        let message = "";
        switch (event.target.name.toLowerCase()) {
            //case 'installerreference':
            //    fieldValidationErrors.InstallerReference = validator.isEmpty(event.target.value) ? 'Installer reference is required.' : '';
            //    message = fieldValidationErrors.InstallerReference;
            //    break;

            case 'customername':
                fieldValidationErrors.CustomerName = validator.isEmpty(event.target.value) ? 'Customer name is required.' : '';
                if (fieldValidationErrors.CustomerName === "") {
                    fieldValidationErrors.CustomerName = validator.isLength(event.target.value, { min: 4 }) ? '' : 'Customer name must be more than 3 characters.';
                }
                message = fieldValidationErrors.CustomerName;
                break;

            case 'customertelephone':
                fieldValidationErrors.CustomerTelephone = validator.isEmpty(event.target.value) ? 'Telephone number is Required' : '';
                if (fieldValidationErrors.CustomerTelephone === "") {
                    fieldValidationErrors.CustomerTelephone = validator.isMobilePhone(event.target.value) ? '' : 'Telephone number is Required';
                }
                message = fieldValidationErrors.CustomerTelephone;
                break;

            case 'customeremail':
                fieldValidationErrors.CustomerEmail = '';
                if (event.target.value != '') {
                    fieldValidationErrors.CustomerEmail = validator.isEmail(event.target.value) ? '' : 'Email is not in required format';
                }
                message = fieldValidationErrors.CustomerEmail;
                break;

            case "propertypostcode":
                fieldValidationErrors.PropertyPostcode = validator.isEmpty(event.target.value) ? 'Postode is Required' : '';
                message = fieldValidationErrors.PropertyPostcode;
                break;

            case "dispatchpostcode":
                fieldValidationErrors.DispatchPostcode = validator.isEmpty(event.target.value) ? 'Postode is Required' : '';
                message = fieldValidationErrors.DispatchPostcode;
                break;

            case "completiondate":
                fieldValidationErrors.CompletionDate = validator.isEmpty(event.target.value) ? 'Completion Date is Required' : '';
                if (fieldValidationErrors.CompletionDate === "") {
                    fieldValidationErrors.CompletionDate = moment(moment(event.target.value)).isAfter(moment(), 'day') ? 'Completion Date cannot be after todays date' : '';
                }
                message = fieldValidationErrors.CompletionDate;
                break;

            case "contractvalue":
                if ((this.state.InstallerType === 1 || this.state.InstallerType === 2 || this.state.InstallerType === 5)) {
                    fieldValidationErrors.ContractValue = '';
                }
                else {
                    fieldValidationErrors.ContractValue = validator.isCurrency(event.target.value) ? '' : 'Contract Value must be a number';
                }

                message = fieldValidationErrors.ContractValue;
                break;

            default:
                break;
        }

        if (message !== "") {
            this.setState({
                formErrors: fieldValidationErrors
            }, this.validateForm);
            //set custom validation error
            event.target.setCustomValidity(message);
        } else {
            //clear custom validation errors
            event.target.setCustomValidity("");
        }
    }

    isValidInputField = fieldName => {
        let fieldValidationErrors = this.state.formErrors;
        let message = "";
        switch (fieldName.toLowerCase()) {
            //case 'installerreference':
            //    fieldValidationErrors.InstallerReference = validator.isEmpty(this.state.InstallerReference) ? 'Installer reference is required.' : '';
            //    message = fieldValidationErrors.InstallerReference;
            //    break;

            case 'customername':
                fieldValidationErrors.CustomerName = validator.isEmpty(this.state.CustomerName) ? 'Customer name is required.' : '';
                if (fieldValidationErrors.CustomerName === "") {
                    fieldValidationErrors.CustomerName = validator.isLength(this.state.CustomerName, { min: 4 }) ? '' : 'Customer name must be more than 3 characters.';
                }
                message = fieldValidationErrors.CustomerName;
                break;

            case 'customertelephone':
                fieldValidationErrors.CustomerTelephone = validator.isEmpty(this.state.CustomerTelephone) ? 'Telephone number is Required' : '';
                if (fieldValidationErrors.CustomerTelephone === "") {
                    fieldValidationErrors.CustomerTelephone = validator.isMobilePhone(this.state.CustomerTelephone) ? '' : 'Telephone number is Required';
                }
                message = fieldValidationErrors.CustomerTelephone;
                break;

            case 'customeremail':
                fieldValidationErrors.CustomerEmail = '';
                if (this.state.CustomerEmail != '') {
                    fieldValidationErrors.CustomerEmail = validator.isEmail(this.state.CustomerEmail) ? '' : 'Email is not in required format';
                }
                message = fieldValidationErrors.CustomerEmail;
                break;

            case "propertypostcode":
                fieldValidationErrors.PropertyPostcode = validator.isEmpty(this.state.PropertyPostcode) ? 'Postode is Required' : '';
                message = fieldValidationErrors.PropertyPostcode;
                break;

            case "dispatchpostcode":
                fieldValidationErrors.DispatchPostcode = validator.isEmpty(this.state.DispatchPostcode) ? 'Postode is Required' : '';
                message = fieldValidationErrors.DispatchPostcode;
                break;

            case "completiondate":
                fieldValidationErrors.CompletionDate = !this.state.IsCompletionDateValid ? 'A valid Completion Date is required! dd/mm/yyyy' : '';
                if (fieldValidationErrors.CompletionDate === "") {
                    let isValid = moment(this.state.CompletionDate.toDate(), 'YYYY-MM-DD').isBefore(moment().add(1, 'day'), 'day');
                    if (this.state.IsCompletionDateValid) {
                        this.setState({
                            IsCompletionDateValid: isValid,
                        });
                    }
                }
                message = fieldValidationErrors.CompletionDate;
                break;

            case "contractvalue":
                if ((this.state.InstallerType === 1 || this.state.InstallerType === 2 || this.state.InstallerType === 5)) {
                    fieldValidationErrors.ContractValue = '';
                }
                else {
                    fieldValidationErrors.ContractValue = validator.isFloat(this.state.ContractValue) ? '' : 'Contract Value must be a number';
                }

                message = fieldValidationErrors.ContractValue;
                break;

            case "declarationagreed":
                fieldValidationErrors.DeclarationAgreed = this.state.DeclarationAgreed === true ? '' : 'You must agree the declaration';
                message = fieldValidationErrors.DeclarationAgreed;
                break;

            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
        return message;
    }

    resetSelectedInstallationProducts = (products) => {

        console.log(this.displayName + '.resetSelectedInstallationProducts', products);
        //now start rebuilding it
        let selectedProducts = this.state.selectedInstallationProducts;
        selectedProducts.NumberOfWindows = 0;
        selectedProducts.NumberOfDoors = 0;
        selectedProducts.NumberOfDoorsLess50Glass = 0;
        selectedProducts.NumberOfDoorsMore50Glass = 0;
        //let products = this.state.products;
        for (let i = 0; i < products.length; i++) {
            //what type of item is this?
            let product = products[i];
            console.log(this.displayName + '.resetSelectedInstallationProducts[product]', product);
            switch (product.InstallationItemTypeId) {
                case 1:
                    selectedProducts.NumberOfDoorsMore50Glass += parseInt(product.Count, 10);
                    break;
                case 2:
                    selectedProducts.NumberOfDoorsLess50Glass += parseInt(product.Count, 10);
                    break;
                default:
                    selectedProducts.NumberOfWindows += parseInt(product.Count, 10);
                    break;
            }
        }

        selectedProducts.NumberOfDoors = selectedProducts.NumberOfDoorsMore50Glass + selectedProducts.NumberOfDoorsLess50Glass;

        // Do not include none fensa windows and doors in the total counts

        //selectedProducts.NumberOfWindows += parseInt(this.state.NonFensaWindows, 10);
        //selectedProducts.NumberOfDoors = parseInt(this.state.NonFensaDoors, 10);

        this.setState({
            selectedInstallationProducts: selectedProducts
        });
    }

    toggleModal = toggle => {
        this.setState({
            modal: toggle
        });
    }

    onHandleProductAdd(Products) {
        this.resetSelectedInstallationProducts(Products);
        this.setState({
            FreezeNonFensaProductInput: false,
            conservatoryDisabled: Products.length > 0,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            products: Products
        }, this.validateForm);
    }

    validateField(fieldName, value) {
        this.isValidInputField(fieldName);
        this.validateForm();
    }

    handlePropertyAddressSelected = (Address) => {
        let fieldValidationErrors = this.state.formErrors;
        fieldValidationErrors.PropertyPostcode = validator.isEmpty(this.state.PropertyPostcode) ? 'Postode is Required' : '';

        this.setState({
            AuthorityId: Address.AuthorityId,
            HidePropertyAddress: false,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            PropertyAddressLine1: Address.Address1,
            PropertyAddressLine2: Address.Address2,
            PropertyAddressLine3: Address.Address3,
            PropertyCityTown: Address.CityTown,
            PropertyCounty: Address.County,
            PropertyPostcode: Address.Postcode,
            SameDispatchAddress: true,
            formErrors: fieldValidationErrors
        });
        //console.log(this.state);
    }

    handleDespatchAddressSelected = (Address) => {
        let fieldValidationErrors = this.state.formErrors;
        fieldValidationErrors.DispatchPostcode = validator.isEmpty(this.state.DispatchPostcode) ? 'Postode is Required' : '';
        this.setState({
            DispatchAddressLine1: Address.Address1,
            DispatchAddressLine2: Address.Address2,
            DispatchAddressLine3: Address.Address3,
            DispatchCityTown: Address.CityTown,
            DispatchCounty: Address.County,
            DispatchPostcode: Address.Postcode,
            HideDispatchAddress: false,
            formErrors: fieldValidationErrors,
            notificationMessage: [],
            notificationPostfix: 'info hide'
        });
    }

    handleRegisterSingle(event) {
        event.preventDefault();

        if (this.state.DeclarationAgreed === false) {
            //ENHANCEMENT POINT
            alert("please accept the Declaration before continuing.");
        }
        else {
            this.setState({
                ViewDeclarationOnly: false,
                ViewUploadInstallation: false,
                ViewSingleInstallation: true
            });
        }
    }

    handleRegisterUpload(event) {
        event.preventDefault();

        if (this.state.DeclarationAgreed === false) {
            //ENHANCEMENT POINT
            alert("Please accept the Declaration before continuing.");
        }
        else {
            this.setState({
                ViewDeclarationOnly: false,
                ViewSingleInstallation: false,
                ViewUploadInstallation: true
            });
        }
    }

    handleDeleteClick(event, index) {

        event.preventDefault();
        this.state.products.splice(index, 1);
        this.resetSelectedInstallationProducts(this.state.products);

        this.setState({
            conservatoryDisabled: this.state.products.length > 0,
            products: this.state.products,
            BfrcCertRequested: false,
        },
            this.validateForm);
    }

    checkContractValueForInstallerType = () => {
        if (!(this.state.InstallerType === 1
            || this.state.InstallerType === 2
            || this.state.InstallerType === 5)
            && this.state.ContractValue > 0) {
            return true;
        }

        if (this.state.InstallerType === 1
            || this.state.InstallerType === 2
            || this.state.InstallerType === 5) {
            return true;
        }

        return false;
    }

    validateForm() {
        this.setState({
            formValid:
                //this.state.formErrors.InstallerReference.length === 0
                //&&
                (this.state.formErrors.CustomerName.length === 0)
                && (this.state.formErrors.CustomerEmail.length === 0)
                // if conservatory is selected ignore the number of products
                && (this.state.Conservatory === true ? true : (this.state.products.length > 0))
                && this.state.DeclarationAgreed === true
                && (this.state.SameDispatchAddress === true ? this.state.PropertyPostcode.length !== 0 : this.state.DispatchPostcode.length !== 0)
                && (this.state.IsCompletionDateValid === true)
                && this.checkContractValueForInstallerType()
        });
        return this.state.formValid;
    }

    handleOnFileUpload(e) {
        this.setState({ UploadFile: e.target.files[0] });
        let files = e.target.files;
        let reader = new FileReader();
        reader.onloadend = (e) => {
            //ENHANCEMENT POINT
            alert(e.target.result);
        };

        this.setState({
            ViewUploadButton: true
        });
    }

    renderValidateSuccess = uploadResult => {

        if (!uploadResult || uploadResult.length === 0) {
            return '';
        }

        return uploadResult.map((result, index) =>
        (
            // bit of a cheat, but index starts at 0 and excel sheets start at 1
            // so index does not reflect the excel row number so add 1 to it so that it does !!!
            <div className='row pl-3 pt-1' key={index + 1}>
                <div className='col-xs-1'>{result.RecordNumber},</div>
                <div className='col-xs-3'>{result.InstallAddress1}, </div>
                <div className='col-xs-3'>{result.InstallPostcode} </div>
            </div>
        )
        );
    }

    togglePleaseWait = status => {
        this.setState({
            IsLoading: status
        });
    }

    lookupClickHandler = status => {
        this.togglePleaseWait(status);
    }

    // function to return the MemberDeclarationId from the 
    // component
    handleMemberDeclarationId = MemberDeclarationId => {
        this.setState({
            MemberDeclarationId: MemberDeclarationId
        });
    }

    handleAddressNotFound = msg => {
        //show the message if not found
        this.setState({
            IsLoading: false,
            notificationPostfix: 'danger show',
            notificationMessage: [msg]
        });
    }

    handleProductQuantityChange = event => {
        const target = event.target;
        const value = target.value;
        this.setState({
            NumberOfWindows: value
        });
    }

    handleNonFensaProductQuantityChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
        this.resetSelectedInstallationProducts(this.state.products);
    }

    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }

    renderUploadError = uploadErrors => {
        //console.log(this.displayName + '.renderUploadError', uploadErrors);
        if (!uploadErrors || uploadErrors.length === 0) {
            return '';
        }
        //console.log(this.displayName + '.renderUploadError', uploadErrors);
        return uploadErrors.map((err, index) => {
            // key~list of validation errors as string
            // e.g. Address Details~Error message 1.|Error message 2.
            if (err.indexOf('~') <= 0 && err.split('|') <= 0) {
                return (
                    <div className='row pl-3 py-3' key={index}>{err}</div>
                );
            } else {

                //var RecordNumer = err.split('~')[0];
                //var addr = err.split('~')[1];
                var arrMsg = err.split('|');

                //console.log("Addr = " + addr);
                //console.log("arrMsg = " + arrMsg);
                return (
                    <div className='row pl-3 pt-1' key={index}>
                        {/*
                         <div className='col-xs-12 col-sm-1'>{RecordNumer}</div>
                        <div className='col-xs-12 col-sm-5'>{addr}</div>
                        */}
                        <div className='col-xs-12 col-sm-12'>
                            {
                                arrMsg.map(msg => <p>{msg}</p>)
                            }
                        </div>
                    </div>
                );
            }
        });
    }

    renderUploadSuccess = uploadResult => {
        //console.log(this.displayName + '.renderUploadSuccess[b4]', uploadResult);
        if (!uploadResult || uploadResult.length === 0) {
            return '';
        }
        //console.log(this.displayName + '.renderUploadSuccess[l8r]', uploadResult);
        return uploadResult.map((result, index) =>
        (
            <div className='row pl-3 pt-1' key={index}>
                <div className='col-xs-6'>Row: {result.RecordNumber} Reference: {result.InstallationReference}  Property Address: {result.InstallAddress1} {result.InstallPostcode}</div>
                <div className='col-xs-6 vi-6'>
                    <Link className="btn btn-primary pull-right mr-2" to={"/Installations/View/" + result.InstallationId}>
                        <i className="fa fa-arrow-right pr-2" />View Installation</Link>
                </div>
            </div>
        )
        );
    }

    handleReturnMessageCallback = (style, msg) => {
        this.setState({
            notificationMessage: [msg],
            notificationPostfix: style
        });
    }

    handleProductQuantityChangeCallback = cbvalue => {
        this.setState({
            FreezeNonFensaProductInput: cbvalue > 0
        });
    }

    submitForm = (e) => {
        e.preventDefault();

        if(this.state.IsLoading)
            return;

        if (this.validateForm()) {

            this.setState({
                IsLoading: true,
                NewProducts: []
            });

            let totalWindows = 0;
            let totalDoorsAbove = 0;
            let totalDoorsBelow = 0;

            // Calculate total number of windows and doors
            if (this.state.products && this.state.products.length > 0) {
                for (let i = 0; i < this.state.products.length; i++) {
                    if (this.state.products[i].InstallationItemTypeId === 0) { totalWindows += parseInt(this.state.products[i].Count, 10); }
                    else if (this.state.products[i].InstallationItemTypeId === 1) { totalDoorsAbove += parseInt(this.state.products[i].Count, 10); }
                    else { totalDoorsBelow += parseInt(this.state.products[i].Count, 10); }
                }

                // Map the products over:
                for (let i = 0; i < this.state.products.length; i++) {

                    if (this.state.products[i].NewProduct === true) {

                        this.state.NewProducts.push({
                            __table__: "InstallationAppliance",
                            IA_InstallationItemTypeId: this.state.products[i].InstallationItemTypeId,
                            IA_Count: this.state.products[i].Count,
                            IA_UValue: this.state.products[i].UValue,
                            IA_ERPRating: this.state.products[i].ERPRating,
                            IA_BFRCLicenceNumber: this.state.products[i].BFRCLicenceNumber,
                            IA_ComplienceType: this.state.products[i].ComplienceType,
                            IA_Deleted: this.state.products[i].Deleted,
                            IA_CreateDate: moment(),
                            IA_CreatedBy: this.state.UserId

                        });
                    }
                }
            }

            var datavalues = JSON.stringify(
                {
                    __table__: "Installation",
                    CustomerName: this.state.CustomerName,
                    CustomerTelephone: this.state.CustomerTelephone,
                    CustomerEmail: this.state.CustomerEmail,
                    PropertyAddress:
                    {
                        __table__: "Address",
                        AddressLine1: this.state.PropertyAddressLine1,
                        AddressLine2: this.state.PropertyAddressLine2,
                        AddressLine3: this.state.PropertyAddressLine3,
                        CityTown: this.state.PropertyCityTown,
                        County: this.state.PropertyCounty,
                        Postcode: this.state.PropertyPostcode,
                        AddressCreatedBy: this.state.UserId,
                        AddressCreatedAt: moment(),
                        AddressDeleted: 0
                    },
                    DispatchAddress:
                    {
                        __table__: "Address",
                        AddressLine1: this.state.SameDispatchAddress === true ? this.state.PropertyAddressLine1 : this.state.DispatchAddressLine1,
                        AddressLine2: this.state.SameDispatchAddress === true ? this.state.PropertyAddressLine2 : this.state.DispatchAddressLine2,
                        AddressLine3: this.state.SameDispatchAddress === true ? this.state.PropertyAddressLine3 : this.state.DispatchAddressLine3,
                        CityTown: this.state.SameDispatchAddress === true ? this.state.PropertyCityTown : this.state.DispatchCityTown,
                        County: this.state.SameDispatchAddress === true ? this.state.PropertyCounty : this.state.DispatchCounty,
                        Postcode: this.state.SameDispatchAddress === true ? this.state.PropertyPostcode : this.state.DispatchPostcode,
                        AddressCreatedBy: this.state.UserId,
                        AddressCreatedAt: moment(),
                        AddressDeleted: 0
                    },
                    InstallationReference: 123456,
                    AuthorityId: this.state.AuthorityId,
                    InstallerReference: this.state.InstallerReference,
                    CompletionDate: this.state.CompletionDate,
                    NoneFensaWindows: this.state.NonFensaWindows,
                    NoneFensaDoors: this.state.NonFensaDoors,
                    ContractValue: this.state.ContractValue,
                    InstallType: this.state.InstallType,
                    NumberOfWindows: totalWindows,
                    NumberOfDoors: totalDoorsAbove + totalDoorsBelow,
                    NumberOfDoorsMore50: totalDoorsAbove,
                    NumberOfDoorsless50: totalDoorsBelow,
                    BuildingHasPostcode: this.state.PropertyPostcode.length === 0 ? 0 : 1,
                    NewBuild: this.state.NewBuild,
                    CompanyId: this.state.CompanyId,
                    InstallationCreatedBy: this.state.UserId,
                    InstallationCreatedAt: moment(),
                    Valid: 1,
                    SendHomeownerCertificate: true,
                    BfrcCertRequested: this.state.BfrcCertRequested,
                    DeclarationAgreed: this.state.DeclarationAgreed,
                    MemberDeclarationId: this.state.MemberDeclarationId,
                    DeclarationAgreedDate: moment(),
                    SubmissionDate: moment(),
                    InstallationDeleted: 0,
                    SameDispatchAddress: this.state.SameDispatchAddress,
                    Conservatory: this.state.Conservatory,
                    RoofLine: this.state.RoofLine,
                    products: this.state.NewProducts,
                    ImportSource:3 //single installation added via installer portal (JS)
                });

            if (debugOn) {
                console.log(this.displayName + '.submitForm[datavalues]', datavalues);
            }

            var postData = JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.InstallerInstallationInsert",
                origin: "Installer Portal",
                operation: "INSERT",
                datavalues: datavalues
            });

            fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/InsertInstallation', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: postData
            })
                .then(response => response.json())
                .then(data => JSON.parse(data))
                .then(data => {
                    if (data.returncode === 1) {
                        this.setState({
                            Result: [],
                            NewInstallationId: JSON.parse(data.message).Id,
                            NewInstallationUrl: '/Installations/View/' + JSON.parse(data.message).Id,
                            IsLoading: false,
                            notificationPostfix: 'success show',
                            notificationMessage: ['Successfully registered installation. ID: ' + JSON.parse(data.message).Reference],
                            showInstallationHistoryButton: true
                        });

                        // NB. please note this has to go through the SQL Service to stored procedure InsertInstallationRegistration
                        // as the table does not have a primary key !!!
                        // This the DataLayer cannot insert the data
                        var dataRegistration = JSON.stringify(
                            {
                                __table__: "InstallationRegistration",
                                IICR_InstallationId: JSON.parse(data.message).Id,
                                IICR_InstallerCompanyRegistrationId: this.state.InstallerCompanyRegistrationId
                            });

                        var postRegistration = JSON.stringify({
                            seqno: uuid.v4(),
                            datatype: "BIT.Fensa.InstallerPortal.InstallationRegistration",
                            origin: "Installer Portal",
                            operation: "INSERT",
                            datavalues: dataRegistration,
                            validate: false
                        });

                        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/InsertObject', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: postRegistration
                        });

                    } else {
                        var arrMsg = data.ReturnData.Errors;
                        arrMsg.splice(0, 0, 'Errors have occured, please fix these before proceeding');
                        this.setState({
                            Result: arrMsg,
                            NewInstallationId: '',
                            IsLoading: false,
                            notificationPostfix: 'danger show',
                            notificationMessage: this.renderUploadError(arrMsg)
                        });
                    }
                })
                .catch(data => {
                    console.log(this.displayName + '.submitForm[err]', data);
                    var arrMsg = data.ReturnData.Errors;
                    console.log(this.displayName + '.submitForm[arrMsg]', arrMsg);
                    arrMsg.splice(0, 0, 'Bad Request - No Data or service not available');
                    this.setState({
                        //ErrorMessage: 'No Data',
                        IsLoading: false,
                        notificationPostfix: 'danger show',
                        notificationMessage: arrMsg
                    });
                });
        } else {
            //alert("Form has errors.");
            this.setState({
                IsLoading: false,
                notificationPostfix: 'danger show',
                notificationMessage: ['Form has errors.'],
                uploadNotificationMessage: null
            });
        }
    }

    submitUpload(e) {
        e.preventDefault();

        if (!this.state.UploadFile) {
            this.setState({
                IsLoading: false,
                notificationPostfix: 'danger show',
                notificationMessage: ['Please select a file to upload and try again.'],
                uploadNotificationMessage: null
            });
            return;
        }
        this.setState({
            IsLoading: true,
            notificationPostfix: 'info hide',
            notificationMessage: [],
            uploadNotificationMessage: null
        });

        const formData = new FormData();

        formData.append('CompanyId', this.state.CompanyId);
        formData.append('UserId', this.state.UserId);
        formData.append('DeclarationAgreed', this.state.DeclarationAgreed);

        formData.append('File', this.state.UploadFile);
        formData.append('FileName', this.state.UploadFile.name);

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/UploadInstallationFile', {
            method: 'POST',
            headers: {
            },
            body: formData
        })
            .then(response => response.json())
            .then(response => {
                const success = JSON.parse(response.ReturnData.Payload);
                const errors = response.ReturnData.Errors;

                if (response.returncode === 1) {
                    if (success) {
                        let msgSuccess = ['File was successfully uploaded.'];
                        this.setState({
                            UploadResult: success,
                            UploadErrors: false,
                            UploadSuccess: true,
                            IsLoading: false,
                            notificationPostfix: 'success show pt-3',
                            notificationMessage: msgSuccess,
                            uploadNotificationMessage: this.renderUploadSuccess(success)

                        });
                    } else {
                        this.setState({
                            UploadResult: null,
                            UploadErrors: null,
                            UploadSuccess: false,
                            IsLoading: false,
                            notificationPostfix: 'danger show pt-3',
                            notificationMessage: ['Failed to complete upload or file is empty'],
                            uploadNotificationMessage: null
                        });
                    }
                }
                if (errors && errors.length > 0) {
                    this.setState({
                        UploadErrors: errors,
                        UploadSuccess: false,
                        IsLoading: false,
                        errorNotificationPostfix: 'danger show pt-3',
                        errorNotificationHeader: ['Errors have occured, on the following row(s) on the file, please fix and retry these row(s) again!'],
                        errorNotificationMessage: this.renderUploadError(errors)
                    });
                }
            })
            .catch(data => {
                console.log(this.displayName + '.submitUpload[err]', data);
                this.setState({
                    IsLoading: false,
                    ErrorMessage: 'No Data',
                    notificationPostfix: 'warning show pt-3',
                    notificationMessage: ['Bad Request - No data or service does not available']
                });
            });
    }

    VerifyUpload(e) {
        e.preventDefault();

        if (!this.state.UploadFile) {
            this.setState({
                IsLoading: false,
                notificationPostfix: 'danger show',
                notificationMessage: ['Please select a file to upload and try again.'],
                uploadNotificationMessage: null
            });
            return;
        }
        this.setState({
            IsLoading: true,
            notificationPostfix: 'info hide',
            notificationMessage: [],
            uploadNotificationMessage: null
        });

        const formData = new FormData();

        formData.append('CompanyId', this.state.CompanyId);
        formData.append('UserId', this.state.UserId);
        formData.append('DeclarationAgreed', this.state.DeclarationAgreed);

        formData.append('File', this.state.UploadFile);
        formData.append('FileName', this.state.UploadFile.name);

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/VerifyInstallationFile', {
            method: 'POST',
            headers: {
            },
            body: formData
        })
            .then(response => response.json())
            .then(response => {
                if (response.returncode === 0) {
                    let arrMsg = ['Errors have occured, please fix these before proceeding'];
                    const errors = response.ReturnData.Errors;

                    if (errors && errors.length > 0) {
                        //errors.map(msg => arrMsg.push(msg));
                    } else {
                        arrMsg.push('Failed to complete upload or file is empty');
                    }
                    this.setState({
                        //UploadResult: JSON.parse(response.ReturnData.Payload),
                        UploadErrors: response.ReturnData.Errors,
                        UploadSuccess: false,
                        IsLoading: false,
                        notificationPostfix: 'danger show pt-3',
                        notificationMessage: arrMsg,
                        uploadNotificationMessage: this.renderUploadError(errors)
                    });
                } else {
                    const uploadResult = JSON.parse(response.ReturnData.Payload);
                    if (uploadResult) {
                        let msgSuccess = ['File was successfully Verified.'];
                        this.setState({
                            ViewUploadButton: true,
                            UploadResult: uploadResult,
                            UploadErrors: null,
                            UploadSuccess: true,
                            IsLoading: false,
                            notificationPostfix: 'success show pt-3',
                            notificationMessage: msgSuccess,
                            uploadNotificationMessage: this.renderValidateSuccess(uploadResult)
                        });
                    } else {
                        this.setState({
                            UploadResult: null,
                            UploadErrors: null,//['Failed to complete upload or file is empty'],
                            UploadSuccess: false,
                            IsLoading: false,
                            notificationPostfix: 'danger show pt-3',
                            notificationMessage: ['Failed to complete upload or file is empty'],
                            uploadNotificationMessage: null
                        });
                    }
                }
            })
            .catch(data => {
                console.log(this.displayName + '.submitUpload[err]', data);
                this.setState({
                    IsLoading: false,
                    ErrorMessage: 'No Data',
                    notificationPostfix: 'warning show pt-3',
                    notificationMessage: ['Bad Request - No data or service does not available']
                });
            });
    }

    fetchData = () => {

        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetInstallerInstallationValidation",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                CompanyId: this.state.CompanyId
            })
        });

        var fetchBranchItem = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        });

        Promise.all([fetchBranchItem])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                this.setState({
                    RegistrationStatus: JSON.parse(JSON.parse(responses[0]).message).RegistrationStatus,
                    InstallerCompanyRegistrationId: JSON.parse(JSON.parse(responses[0]).message).CurrenctActiveScheme,
                    InstallerType: JSON.parse(JSON.parse(responses[0]).message).InstallerType,
                    IBGProvider: JSON.parse(JSON.parse(responses[0]).message).IBGProviderId,
                    IsLoading: false,
                    NewBuild:(JSON.parse(JSON.parse(responses[0]).message).InstallerType===2)?0:1
                });
               
               
            })
            .catch(data => {
                this.setState({
                    IsLoading: false,
                    ErrorMessage: 'No Data',
                    notificationPostfix: 'warning show',
                    notificationMessage: ['No Data']
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'Register Installation',
            action: 'Register Installation View'
        });

        return (
            <div>
                <div className='row' id="BFRCLicense">
                    <div className={this.state.ViewDeclarationOnly ? '' : 'hide'}>
                        <InstallationDeclaration
                            DeclarationAgreed={this.state.DeclarationAgreed}
                            Error={this.state.formErrors.DeclarationAgreed}
                            MemberDeclarationId={this.state.MemberDeclarationId}
                            handleInputChange={this.handleInputChange}
                            handleMemberDeclarationId={this.handleMemberDeclarationId}
                        />
                        <div className={this.state.DeclarationAgreed ? 'show text-center' : 'hide'}>
                            <Link className='btn orange' to='/Installations/Register' onClick={this.handleRegisterSingle}>
                                <i className="fa fa-arrow-right pr-2" />Register Single Installation
                            </Link>
                            <Link className='btn orange' to='MyDetails' onClick={this.handleRegisterUpload}>
                                <i className="fas fa-upload pr-2" />Upload Installations
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={this.state.ViewUploadInstallation ? 'form-group-fluid-container mr-auto ml-auto' : 'hide'}>
                    <div className='form-group-title h3-responsive'>Bulk upload installations</div>
                    <div className="row">
                        <div className="col">
                            <span className="text-link" onClick={this.toggleCollapse("basicCollapse")}>Click here to download template files</span>
                        </div>
                    </div>
                    <div className={this.state.IBGProvider === 1 ? 'row' : 'hide'}>
                        <a id="ctl00_ContentPlaceHolder1_lnkCsvTemplateFile" className="embolden btn btn-info btn-sm" download href="/GGFiInstallerUploadTemplatev2.csv" title="Click here to download CSV format template file"><i className="fas fa-download pr-2" />(CSV) </a>&nbsp;
                            <a id="ctl00_ContentPlaceHolder1_lnkCsvTemplateFile" className="embolden btn btn-info btn-sm" download href="/GGFiInstallerUploadTemplatev2.xlsx" title="Click here to download Excel format template file"><i className="fas fa-download pr-2" />(Excel) </a>
                        <a id="ctl00_ContentPlaceHolder1_lnkCsvTemplateFile" className="embolden btn btn-info btn-sm" download href="/User%20Guide%20For%20Bulk%20Installation%20upload%20v2.pdf" title="Click here to download the user guide for uploading bulk installations"><i className="fas fa-download pr-2" />User guide for uploading bulk installations</a>&nbsp;
                        </div>
                    <div className={this.state.IBGProvider !== 1 ? 'row' : 'hide'}>
                        <a id="ctl00_ContentPlaceHolder1_lnkCsvTemplateFile" className="embolden btn btn-info btn-sm" download href="/NonGGFiInstallerUploadTemplatev2.csv" title="Click here to download CSV format template file"><i className="fas fa-download pr-2" />(CSV) </a>&nbsp;
                            <a id="ctl00_ContentPlaceHolder1_lnkCsvTemplateFile" className="embolden btn btn-info btn-sm" download href="/NonGGFiInstallerUploadTemplatev2.xlsx" title="Click here to download Excel format template file"><i className="fas fa-download pr-2" />(Excel) </a>
                        <a id="ctl00_ContentPlaceHolder1_lnkCsvTemplateFile" className="embolden btn btn-info btn-sm" download href="/User%20Guide%20For%20Bulk%20Installation%20upload%20v2.pdf" title="Click here to download the user guide for uploading bulk installations"><i className="fas fa-download pr-2" />User guide for uploading bulk installations</a>&nbsp;
                        </div>
                    {/*
                    <form name="UploadFile" encType="multipart/form-data" method="post" onSubmit={this.VerifyUpload.bind(this)}>
                        <input className='btn orange form-control-file' type='file' name='File' onChange={(e) => this.handleOnFileUpload(e)} />
                        <button type="Submit" className={this.state.ViewUploadButton === false ? "btn btn-primary btn-sm" : "hide"} name="InstallType" value="Submit"><i className="fas fa-upload pr-2" />Verify File</button>
                        <PleaseWait IsLoading={this.state.IsLoading} />
                    </form>
                    */}
                    <form name="UploadFile" encType="multipart/form-data" method="post" onSubmit={this.submitUpload.bind(this)}>
                        <input className='btn orange form-control-file' type='file' name='File' onChange={(e) => this.handleOnFileUpload(e)} />
                        <button type="Submit" className={this.state.ViewUploadButton === true ? "btn btn-primary btn-sm" : "hide"} name="InstallType" value="Submit"><i className="fas fa-upload pr-2" />Upload File</button>
                        <PleaseWait IsLoading={this.state.IsLoading} />
                    </form>

                    <div className="pt-3">
                        <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage}>
                            {this.state.uploadNotificationMessage}
                        </NotificationPanel>
                    </div>
                    <div className="pt-3">
                        <NotificationPanel CssClassPostFix={this.state.errorNotificationPostfix} NotificationMessage={this.state.errorNotificationHeader}>
                            {this.state.errorNotificationMessage}
                        </NotificationPanel>
                    </div>
                </div>
                <div className={this.state.ViewSingleInstallation ? 'form-group-fluid-container mr-auto ml-auto' : 'hide'}>
                    <div className="">
                        <div className='form-group-title'><h3>Register installation</h3></div>
                        <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                        <form className='demoForm was-validated' onSubmit={this.submitForm.bind(this)} noValidate>
                            <div className='form-group'>
                                <div className='row'>
                                    <div className="col-xs-12 col-md-12">
                                        <ReadonlyItem textLabel="Company">{this.state.CompanyName}</ReadonlyItem>
                                    </div>
                                    <div className="col-xs-12 col-md-6 col-lg-4">
                                        <ColumnAndLabel
                                            ErrorMessage={this.state.formErrors.CustomerName}
                                            IconLabel=""
                                            Id="CustomerName"
                                            InputType="text"
                                            IsRequired={true}
                                            SizeCss="md"
                                            HintLabel=""
                                            TextLabel="Customer"
                                            Validate={true}
                                            Value={this.state.CustomerName}
                                            HandleInputChange={this.handleInputChange}
                                            HandleOnInput={this.handleOnInputValidation}
                                        />
                                    </div>
                                    <div className="col-xs-12 col-md-6 col-lg-4">
                                        <ColumnAndLabel
                                            ErrorMessage={this.state.formErrors.CustomerTelephone}
                                            IconLabel=""
                                            Id="CustomerTelephone"
                                            InputType="text"
                                            IsRequired={false}
                                            SizeCss="md"
                                            HintLabel=""
                                            TextLabel="Customer Telephone"
                                            Validate={false}
                                            Value={this.state.CustomerTelephone}
                                            HandleInputChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div className="col-xs-12 col-md-6 col-lg-4">
                                        <ColumnAndLabel
                                            ErrorMessage={this.state.formErrors.CustomerEmail}
                                            IconLabel=""
                                            Id="CustomerEmail"
                                            InputType="email"
                                            IsRequired={this.state.CustomerEmail}
                                            SizeCss="md"
                                            HintLabel=""
                                            TextLabel="Email"
                                            Validate={true}
                                            Value={this.state.CustomerEmail}
                                            HandleInputChange={this.handleInputChange}
                                            HandleOnInput={this.handleOnInputValidation}
                                        />
                                    </div>
                                    <div className="col-xs-12 col-md-6 col-lg-4">
                                        <ColumnAndLabel
                                            //ErrorMessage={this.state.formErrors.InstallerReference}
                                            ErrorMessage=""
                                            IconLabel=""
                                            Id="InstallerReference"
                                            InputType="text"
                                            IsRequired={false}
                                            SizeCss="md"
                                            HintLabel=""
                                            TextLabel="Installer Reference"
                                            Validate={false}
                                            Value={this.state.InstallerReference}
                                            HandleInputChange={this.handleInputChange}
                                            HandleOnInput={this.handleOnInputValidation}
                                        />
                                    </div>
                                    <div className={!(this.state.InstallerType === 1 || this.state.InstallerType === 2 || this.state.InstallerType === 5) ? "col-xs-12 col-md-6 col-lg-4" : "hide"} >

                                        <ColumnAndLabel
                                            ErrorMessage={this.state.formErrors.ContractValue}
                                            IconLabel=""
                                            Id="ContractValue"
                                            InputType="number"
                                            IsRequired={true}
                                            SizeCss="md"
                                            HintLabel=""
                                            TextLabel="Contract Value inc VAT"
                                            Validate={true}
                                            Value={this.state.ContractValue}
                                            HandleInputChange={this.handleInputChange}
                                            HandleOnInput={this.handleOnInputValidation}

                                        />
                                    </div>
                                    <div className={(this.state.InstallerType === 1 || this.state.InstallerType === 2 || this.state.InstallerType === 5) ? "col-xs-12 col-md-6 col-lg-4" : "hide"} >

                                        <ColumnAndLabel
                                            ErrorMessage={this.state.formErrors.ContractValue}
                                            IconLabel=""
                                            Id="ContractValue"
                                            InputType="number"
                                            IsRequired={false}
                                            SizeCss="md"
                                            HintLabel=""
                                            TextLabel="Contract Value"
                                            Validate={true}
                                            Value={this.state.ContractValue}
                                            HandleInputChange={this.handleInputChange}
                                            HandleOnInput={this.handleOnInputValidation}
                                        />

                                    </div>
                                    <div className="col-xs-12 col-md-6 col-lg-4">
                                        <SingleDatePickerControl
                                            ErrorMessage={this.state.formErrors.CompletionDate}
                                            Id='CompletionDate'
                                            IconPosition="after"
                                            Block={false}
                                            Date={this.state.CompletionDate}
                                            PlaceHolderText="Completion Date"
                                            HandleOutsideDateRange={this.handleOutsideDateRange}
                                            IsRequired={true}
                                            HandleDateChange={this.handleDateChange}
                                            Focused={this.state.Focused}
                                            PopUpDirection="up"
                                            NumberOfMonths={1}
                                            DateDisplayFormat="DD MMM YYYY"
                                            IsValidate={this.state.IsCompletionDateValid}
                                        />
                                        <div><small>Completion dates more than 21 days before today's date will be reviewed by FENSA after submission</small></div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-xs-12 col-md-6">
                                        <AddressLookup
                                            ErrorMessage={this.state.formErrors.PropertyPostcode}
                                            HandleAddressSelected={this.handlePropertyAddressSelected}
                                            IsRequired={true}
                                            Postcode={this.state.PropertyPostcode}
                                            PostcodeId="PropertyPostcode"
                                            PostcodeText="Property Postcode"
                                            Validate={true}
                                            handleAddressNotFound={this.handleAddressNotFound}
                                            handleChange={this.handleInputChange}
                                            handleLookupClick={this.lookupClickHandler}
                                            handleOnInput={this.handleOnInputValidation}
                                            loading={this.state.IsLoading}
                                        />
                                        <div hidden={this.state.HidePropertyAddress}>
                                            <AddressCardBody
                                                AddressLabel={"Property Address"}
                                                Address1={this.state.PropertyAddressLine1}
                                                Address2={this.state.PropertyAddressLine2}
                                                Address3={this.state.PropertyAddressLine3}
                                                CityTown={this.state.PropertyCityTown}
                                                County={this.state.PropertyCounty}
                                                Postcode={this.state.PropertyPostcode}
                                            >
                                                <span><span className="pr-2"><strong>Property Address</strong></span><em>LA({this.state.AuthorityId})</em></span>
                                            </AddressCardBody>
                                            <div className="py-3">
                                                <label>Dispatch Address same as Property Address</label>
                                                <div className="btn-group btn-group-toggle pl-3" data-toggle="buttons">
                                                    <MDBSwitch
                                                        checked={this.state.SameDispatchAddress}
                                                        color="primary"
                                                        onChange={this.handleSameDispatchAddress}
                                                        labelLeft=""
                                                        labelRight=""
                                                    />
                                                    {this.state.SameDispatchAddress === true ? 'Yes' : 'No'}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className={this.state.SameDispatchAddress ? 'hide' : 'col-xs-12 col-md-6'} >
                                        <AddressLookup
                                            ErrorMessage={this.state.formErrors.DispatchPostcode}
                                            HandleAddressSelected={this.handleDespatchAddressSelected}
                                            IsRequired={true}
                                            Postcode={this.state.DispatchPostcode}
                                            PostcodeId="DispatchPostcode"
                                            PostcodeText="Delivery Address"
                                            Validate={true}
                                            handleAddressNotFound={this.handleAddressNotFound}
                                            handleChange={this.handleInputChange}
                                            handleLookupClick={this.lookupClickHandler}
                                            handleOnInput={this.handleOnInputValidation}
                                            loading={this.state.IsLoading}
                                        />
                                        <div hidden={this.state.HideDispatchAddress} className='pb-3'>
                                            <AddressCardBody
                                                AddressLabel={"Delivery Address"}
                                                Address1={this.state.DispatchAddressLine1}
                                                Address2={this.state.DispatchAddressLine2}
                                                Address3={this.state.DispatchAddressLine3}
                                                CityTown={this.state.DispatchCityTown}
                                                County={this.state.DispatchCounty}
                                                Postcode={this.state.DispatchPostcode}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className={this.state.InstallerType === 1 ||  this.state.InstallerType === 5 ? 'col-xs-12 col-md-6 pt-4' : 'hide'}>
                                    
                                        <ColumnAndLabelRadioOptions
                                            id="NewBuild"
                                            name="NewBuild"
                                            textLabel="Commercial Build"
                                            optionNo={'No'}
                                            optionYes={'Yes'}
                                            value={this.state.NewBuild}
                                            handleChange={this.handleNewBuildCallback}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className={this.state.InstallerType === 2 ? 'col-xs-12 col-md-6 pt-4' : 'hide'}>
                                        <ColumnAndLabelRadioOptions
                                            id="NewBuild"
                                            name="NewBuild"
                                            textLabel="Commercial Build"
                                            optionNo={'No'}
                                            optionYes={'Yes'}
                                            value={false}
                                            handleChange={this.handleNewBuildCallback}
                                            readOnly={false}
                                        />
                                    </div>
                                </div>
                                <div className={this.state.showFensaProducts ? 'container pt-4' : 'hide'}>

                                    <ProductAdd Products={this.state.products}
                                        HandleProductAdd={this.onHandleProductAdd}
                                        HandleReturnMessage={this.handleReturnMessageCallback}
                                        HandleProductQuantityChange={this.handleProductQuantityChangeCallback} />

                                    <div className={this.state.products && this.state.products.length > 0 ? 'row' : 'hide'}>
                                        <div className='card-deck'>
                                            {
                                                this.state.products.map((products, index) =>
                                                (
                                                    <Product
                                                        id={index}
                                                        title={products.InstallationItemTypeId}
                                                        NoItems={products.Count}
                                                        Rated={products.Rated}
                                                        Rating={products.ERPRating}
                                                        LicenceNumber={products.BFRCLicenceNumber}
                                                        handleDeleteClick={this.handleDeleteClick}
                                                    />
                                                )
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='container pt-4'>
                                        <h3>Fensa Product Summary</h3>
                                        <div className='row'>
                                            <div className="col-xs-12 col-md-3">
                                                <ReadonlyItem textLabel="Total Windows">{this.state.selectedInstallationProducts.NumberOfWindows === 0 ? '0' : this.state.selectedInstallationProducts.NumberOfWindows}</ReadonlyItem>
                                            </div>
                                            <div className="col-xs-12 col-md-3">
                                                <ReadonlyItem textLabel="Total Doors">{this.state.selectedInstallationProducts.NumberOfDoors === 0 ? '0' : this.state.selectedInstallationProducts.NumberOfDoors}</ReadonlyItem>
                                            </div>
                                            <div className="col-xs-12 col-md-3">
                                                <ReadonlyItem textLabel="Doors Less 50% glass">{this.state.selectedInstallationProducts.NumberOfDoorsLess50Glass === 0 ? '0' : this.state.selectedInstallationProducts.NumberOfDoorsLess50Glass}</ReadonlyItem>
                                            </div>
                                            <div className="col-xs-12 col-md-3">
                                                <ReadonlyItem textLabel="Doors More 50% glass">{this.state.selectedInstallationProducts.NumberOfDoorsMore50Glass === 0 ? '0' : this.state.selectedInstallationProducts.NumberOfDoorsMore50Glass}</ReadonlyItem>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='show'>
                                    <div className={this.state.IBGProvider === 1 && this.state.InstallerType !== 4 ? 'row' : 'hide'}>
                                        <div className="container">
                                            <hr />
                                            <h3>Add Non-FENSA Product</h3>
                                        </div>
                                        <div className='col-12'>
                                            <div className='row'>
                                                {/*!this.state.FreezeNonFensaProductInput ? 'show' : 'hide'*/}
                                                {/*!this.state.conservatoryDisabled ? 'show' : 'hide'*/}
                                                <div className={!this.state.conservatoryDisabled ? 'col-xs-12 col-md-6 pt-4' : 'hide'}>
                                                    <ColumnAndLabelRadioOptions
                                                        id="Conservatory"
                                                        name="Conservatory"
                                                        textLabel="Conservatory"
                                                        optionNo={'No'}
                                                        optionYes={'Yes'}
                                                        value={this.state.Conservatory}
                                                        handleChange={this.handleConservatoryCallback}
                                                    />
                                                </div>
                                                <div className={!this.state.HideNonFensaProductInput ? 'col-xs-12 col-md-6 pt-4' : 'hide'}>
                                                    <ColumnAndLabelRadioOptions
                                                        id="RoofLine"
                                                        name="RoofLine"
                                                        textLabel="RoofLine"
                                                        optionNo={'No'}
                                                        optionYes={'Yes'}
                                                        value={this.state.RoofLine}
                                                        handleChange={this.handleRoofLineCallback}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12 text-danger hide'><small>If Conservatory is enabled, all selected products will be cleared</small></div>
                                            </div>
                                        </div>
                                        <div className={!this.state.HideNonFensaProductInput ? 'col-xs-12 col-md-6 px-3 pl-5 product-type' : 'hide'}>
                                            <div className='row'>
                                                <div className="col-xs-6 col-md-2 py-4">
                                                    <Image className='product-small' src={require('./non-fensa-window.png')} alt="Non-Fensa Window" name="NonFensaWindow" />
                                                    <p className="non-fensa">Window</p>
                                                </div>
                                                <div className="col-xs-12 col-sm-6 pl-4 pr-4">
                                                    <input
                                                        className='slider'
                                                        type="range"
                                                        id="NonFensaWindows"
                                                        name="NonFensaWindows"
                                                        min="0"
                                                        max="30"
                                                        onInput={this.handleInputChange}
                                                        onBlur={this.handleInputChange}
                                                        value={this.state.NonFensaWindows}
                                                        list="steplist1"
                                                    />
                                                    <datalist id="steplist1">
                                                        <option>0</option>
                                                        <option>10</option>
                                                        <option>20</option>
                                                        <option>30</option>
                                                    </datalist>
                                                    <ColumnAndLabel
                                                        ErrorMessage=""
                                                        IconLabel=""
                                                        Id="NonFensaWindows"
                                                        InputType="number"
                                                        IsRequired={true}
                                                        SizeCss="sm"
                                                        HintLabel=""
                                                        Validate={false}
                                                        Value={this.state.NonFensaWindows}
                                                        HandleInputChange={this.handleNonFensaProductQuantityChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={!this.state.HideNonFensaProductInput ? 'col-xs-12 col-md-6 px-3 pl-5 product-type' : 'hide'}>
                                            <div className='row'>
                                                <div className="col-xs-12 col-md-2 py-4">
                                                    <Image className='product-small' src={require('./non-fensa-door.png')} alt="Non-Fensa Door" name="NonFensaDoor" />
                                                    <p className="non-fensa">Door</p>
                                                </div>
                                                <div className="col-xs-12 col-sm-6 pl-4 pr-4">
                                                    <input
                                                        className='slider'
                                                        type="range"
                                                        id="NonFensaDoors"
                                                        name="NonFensaDoors"
                                                        min="0"
                                                        max="30"
                                                        onInput={this.handleInputChange}
                                                        onBlur={this.handleInputChange}
                                                        value={this.state.NonFensaDoors}
                                                        list="steplist2"
                                                    />
                                                    <datalist id="steplist2">
                                                        <option>0</option>
                                                        <option>10</option>
                                                        <option>20</option>
                                                        <option>30</option>
                                                    </datalist>
                                                    <ColumnAndLabel
                                                        ErrorMessage=""
                                                        IconLabel=""
                                                        Id="NonFensaDoors"
                                                        InputType="number"
                                                        IsRequired={false}
                                                        SizeCss="sm"
                                                        HintLabel=""
                                                        Validate={false}
                                                        Value={this.state.NonFensaDoors}
                                                        HandleInputChange={this.handleNonFensaProductQuantityChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                            <div className='row pb-1'>
                                <PleaseWait IsLoading={this.state.IsLoading} />
                            </div>
                            <div className='row pb-2'>
                                <div className='col text-right'>
                                    <MDBBtn
                                        color="success"
                                        icon="save"
                                        type="submit"
                                        size="sm"

                                        disabled={!this.state.formValid || this.state.IsLoading}
                                        className={!this.state.showInstallationHistoryButton ? "mdb-btn-resize pull-right show" : "hide"}>
                                        <MDBIcon
                                            icon="save"
                                            className="pr-2"
                                        />Submit
                                    </MDBBtn>
                                    <Link className={this.state.showInstallationHistoryButton ? "btn btn-primary pull-right mr-2" : "hide"} to={this.state.NewInstallationUrl}><i className="fa fa-arrow-right pr-2" />View Installation</Link>
                                    <Link className={this.state.showInstallationHistoryButton ? "btn btn-dark pull-right mr-2" : "hide"} to={installationHistoryHomeUrl}><i className="fa fa-arrow-left pr-2" />Back to Installations</Link>
                                    <Link className={!this.state.showInstallationHistoryButton ? "btn btn-danger btn-md pull-right mr-2" : "hide"} to={installationHistoryHomeUrl}><i className='fa fa-arrow-left pr-2' />Cancel</Link>
                                </div>
                            </div>
                            <ConfirmActionModal
                                ActionName="Delete"
                                ActionMessage={this.state.deleteActionMessage}
                                handleToggleAction={this.toggleModal}
                                handleModalAction={this.clearFensaProducts}
                                modalOpen={this.state.modal}
                            />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}