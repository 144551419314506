import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { ReadonlyItem } from '../SharedComponents/ReadonlyItem';
import { PleaseWait } from '../SharedComponents/PleaseWait';

import moment from 'moment';

import '../Fensa.css';

// const detailsHomeUrl = '/Details';
const debugOn = process.env.DEBUG_MODE_ON;

export class Insurances extends Component {
    displayName = Insurances.name;

    constructor(props) {
        super(props);

        const auth = new AuthHelper();

        this.state = {
            CompanyId: auth.CompanyId,
            CompanyName: auth.CompanyName,
            UsePaging: true,
            displayContent: (<PleaseWait IsLoading={true} />),
            loading: true,
            notificationMessage: '',
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 0,
            payload: [],
            IBGEdit: false,
            ShowEditButton: true
        };

        this.HandleIBGClick = this.HandleIBGClick.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    HandleIBGClick(event) {

        event.preventDefault();

        this.setState({
            IBGEdit: true
        });

        alert(this.state.IBGEdit);
    }

    fetchData = () => {

        var fetchInstallation = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.GetInstallerCompanyInsurance",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyId
                })
            })
        });

        Promise.all([fetchInstallation])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                console.log(this.displayName + '.fetchData[responses]', responses);
                var data = this.extractData(responses[0]);
                console.log(this.displayName + '.fetchData[data]', data);
                this.setState({
                    payload: data,//JSON.parse(JSON.parse(responses[0]).message),
                    loading: false,
                    displayContent: !data ? this.renderNoDataView() : this.renderTable(data[0]),
                    notificationPostfix: 'info hide',
                    notificationMessage: []
                });
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData[err]', data);
                this.setState({
                    displayContent: '',
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - No data or service unavailable'],
                    payload: []
                });
            });
    }


    renderTable = companyDetailsData => {
        return (
            companyDetailsData === null ?
                'Bad Request' : (
                    <div className='form-group-container mr-auto ml-auto'>
                        <div className='form-group'>
                            <div className='h3 h3-responsive'>Insurance Details - {companyDetailsData.CompanyName}</div>
                            <div className='form-group'>

                                <div className='h4 h4-responsive'>IBG Details</div>
                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="Provider">{companyDetailsData.IBGName}</ReadonlyItem>
                                        </div>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="Date IBG Verified by Fensa">{moment(companyDetailsData.IBGVerifiedDate).format('DD MMM YYYY')}</ReadonlyItem>
                                        </div>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="IBG Membership Number">{companyDetailsData.CompanyGuaranteePolicyNumber}</ReadonlyItem>
                                        </div>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="Installer Type">{companyDetailsData.InstallerTypeName}</ReadonlyItem>
                                        </div>
                                    </div>
                                </div>

                                <div className='h4 h4-responsive'>Public Liability</div>
                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="Public Liability Insurance Company">{companyDetailsData.PublicLiabilityCompany}</ReadonlyItem>
                                        </div>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="Public Liability Policy Number">{companyDetailsData.PublicLiabilityPolicyNumber}</ReadonlyItem>
                                        </div>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="Public Liability Expiry Date">{moment(companyDetailsData.PublicLiabilityExpiryDate).format('DD MMM YYYY')}</ReadonlyItem>
                                        </div>
                                    </div>
                                </div>

                                <div className='h4 h4-responsive'>Employer Liability</div>
                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="Employer Liability Insurance Company">{companyDetailsData.EmployerLiabilityCompany}</ReadonlyItem>
                                        </div>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="Employer Liability Policy Number">{companyDetailsData.EmployerLiabilityPolicyNumber}</ReadonlyItem>
                                        </div>
                                        <div className='col-md-4'>
                                            <ReadonlyItem textLabel="Employer Liability Expiry Date">{moment(companyDetailsData.EmployerLiabilityExpiryDate).format('DD MMM YYYY')}</ReadonlyItem>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='row py-3'>

                                <div className='col'>

                                    <Link className={this.state.ShowEditButton ? 'btn btn-sm btn-primary pull-right mdb-btn-resize' : 'hide'} to={'/Details/InsuranceEdit/' + this.state.CompanyId}>
                                        <i className="fa fa-edit pr-2" />Edit
                            </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                )
        );
    }

    render() {

        ReactGA.event({
            category: 'Insurances',
            action: 'View Insurances'
        });


        return (
            <div>
                {this.state.displayContent}
            </div>
        );
    }


}

/// Handle data retrieval and handle ensuing errors if data is not found
Insurances.prototype.extractData = response => {
    try {
        return JSON.parse(response).returncode !== 0 ? JSON.parse(JSON.parse(response).message) : [];
    } catch (e) {
        console.log(e);
        return [];
    }
};