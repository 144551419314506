import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";

import ReactGA from 'react-ga';

import InformationView from './InformationView';
//import DatePicker from 'react-datepicker';

import AuthHelper from '../SharedComponents/AuthHelper';
import { PleaseWait } from './../SharedComponents/PleaseWait';
import { ReduxNotificationPanel } from './../SharedComponents/ReduxNotificationPanel';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';

import { ColumnAndLabel } from '../SharedComponents/ColumnAndLabel';

export default class ComingSoon extends Component {
    displayName = "ComingSoon";

    constructor(props) {
        super(props);
        const auth = new AuthHelper();

        this.state = {
            ...props,
            CompanyId: auth.CompanyId
        };

    }

 
    render() {

        return (
            <div>
                <div className="h2 h2-responsive">
                    Coming Soon

                </div>


            </div>
        );
    }
}

