import React, { Component, Fragment } from 'react';

import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import uuid from "uuid";

import ReactGA from 'react-ga';


import '../Fensa.css';

import AuthHelper from '../SharedComponents/AuthHelper';
import { ButtonsJss } from '../SharedComponents/ButtonsJss';
import { CardBodyOnly } from '../SharedComponents/CardBodyOnly';
import { CardFooterButtonsIcons } from '../SharedComponents/CardFooterButtonsIcons';
import { CardWithoutImageHeader } from '../SharedComponents/CardWithoutImageHeader';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import { ReactTableFilterToggler } from './../SharedComponents/ReactTableFilterToggler';
//import { ResponsiveTable } from '../SharedComponents/ResponsiveListTable';
import { ViewToggler } from '../SharedComponents/ViewToggler';

import Badge from "@material-ui/core/Badge";

const defaultView = 'address-card';
const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class Branches extends Component {
    displayName = "Branches";

    constructor(props) {
        super(props);
        const auth = new AuthHelper(this.displayName);
        //console.log(this.displayName + '.ctor', auth);
        this.state = {
            CompanyId: auth.CompanyId,
            ShowEditButton: auth.CanEditData,
            ShowDeleteButton: auth.CanDoAdminTask,
            ShowViewButton: true,
            UsePaging: true,
            displayContent: (<PleaseWait IsLoading={true} />),
            displayView: auth.DisplayView ? auth.DisplayView : defaultView,
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 0,
            payload: [],
            resized: [],
            sorted: [],
            totals: '',
            totalsLabel: ' item',
            useFiltering: (auth.TableFilter && auth.TableFilter === 'On' ? true : false)
        };
        console.log(this.displayName, this.props);
    }

    componentDidMount() {
        this.fetchData();
    }

    filterCaseInsensitive = ({ id, value }, row) => {
        return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : false;
    }

    handleToggleFilter = toggle => {
        this.setState({
            useFiltering: toggle
        });
        this.fetchData();
    }

    parseAsString = (value, nullValue) => {
        try {
            return value ? value : nullValue ? nullValue : '';
        } catch (e) {
            console.log(e);
            return '.';
        }
    }

    renderTable = payload => {
        return (
            <div>
                <ReactTableFilterToggler
                    ParentDisplayName={this.displayName}
                    UseFiltering={this.state.useFiltering}
                    handleToggleFilter={this.handleToggleFilter}
                />
                <ReactTable data={payload}
                    columns=
                    {
                        [
                            {
                                Header: '', // Custom header components!
                                accessor: 'MainBranch',
                                maxWidth: 45,
                                width: 45,
                                //className: 'text-center',
                                //padding: '5',
                                filterable: false,
                                sortable: false,
                                Cell: row => {
                                    return (
                                        <div className='p-2'>
                                            {
                                                row.original.MainBranch ?
                                                    (
                                                        <span>
                                                            <i className='fas fa-code-branch pr-3' title='Main Branch' />
                                                        </span>
                                                    ) : null
                                            }
                                        </div>
                                    );
                                },
                                style: {
                                    maxWidth: 45,
                                    paddingTop: "5",
                                    textAlign: "right",
                                    userSelect: "none"
                                }
                            },
                            {
                                //Header: 'Branch Name',
                                accessor: 'Name',
                                id: "Name",
                                className: 'p-3',
                                filterMethod: (filter, row) =>
                                    row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
                                //filterAll: true,

                                Header: () => <span>Name</span>,
                                Expander: ({ isExpanded }) =>
                                    (
                                        <span>
                                            {
                                                isExpanded ?
                                                    (<span>&#x2299;</span>) :
                                                    (<span>&#x2295;</span>)
                                            }
                                        </span>
                                    ),
                                Cell: row => {
                                    return (
                                        <Fragment>
                                            {row.original.Name}
                                        </Fragment>
                                    );
                                },
                                style: {
                                    padding: "15",
                                    textAlign: "left",
                                    userSelect: "none"
                                }
                            },
                            {
                                Header: 'Address',
                                accessor: 'AddressLine1',
                                width: 200,
                                filterMethod: (filter, row) =>
                                    row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
                                Cell: row => {
                                    return (
                                        <Fragment>
                                            <span>{row.original.AddressLine1}</span>
                                        </Fragment>
                                    );
                                }
                            },
                            {
                                Header: 'Contact', // Custom header components!
                                accessor: 'ContactName',
                                className: 'p-3',
                                filterMethod: (filter, row) =>
                                    row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
                            },
                            {
                                Header: 'Postcode', // Custom header components!
                                accessor: 'Postcode',
                                className: 'p-3',
                                filterMethod: (filter, row) =>
                                    row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase())
                            },
                            {
                                Header: '',
                                accessor: 'Id',
                                filterable: false,
                                sortable: false,
                                Cell: row => {
                                    return (
                                        <Fragment>
                                            <div className='text-center py-1'>
                                                <CardFooterButtonsIcons
                                                    Id={row.original.Id}
                                                    //DeleteCss={ButtonsJss.DefaultDeleteCss}
                                                    //EditCss={ButtonsJss.DefaultEditCss}
                                                    //ViewCss={ButtonsJss.DefaultViewCss}
                                                    DeleteUrl={'/Branch/Delete/' + row.original.Id}
                                                    EditUrl={'/Branch/Update/' + row.original.Id}
                                                    ViewUrl={'/Branch/View/' + row.original.Id}
                                                    ShowViewButton={this.state.ShowViewButton} // use role priviledge to set visibility
                                                    ShowEditButton={this.state.ShowEditButton} // use role priviledge to set visibility
                                                    ShowDeleteButton={this.state.ShowDeleteButton}// use role priviledge to set visibility
                                                />
                                            </div>
                                        </Fragment>
                                    );
                                }
                            }
                        ]
                    }
                    SubComponent={(row) => (
                        <Fragment>
                            <CardBodyOnly>
                                <div style={{ paddingTop: '10px' }}>
                                    <i className="fa fa-home fa-1x pr-4" />
                                    {row.original.AddressLine1}
                                </div>
                                <div className='pl-5'>{row.original.AddressLine2}</div>
                                <div className='pl-5'>{row.original.AddressLine3}</div>
                                <div className='pl-5'>{row.original.CityTown}</div>
                                <div className='pl-5'>{row.original.County}</div>
                                <div className='pl-5'>{row.original.postcode}</div>

                                <div style={{ paddingTop: '10px' }}>
                                    <i className="fa fa-globe fa-1x pr-1" />
                                    {
                                        row.original.BranchUrl ?
                                            (
                                                <Link to={row.original.BranchUrl} target='_blank'>{row.original.BranchUrl}</Link>
                                            ) : ''
                                    }
                                </div>

                                <div style={{ paddingTop: '10px' }}>
                                    <i className="fa fa-globe fa-1x pr-4" />
                                    {row.original.Telephone}
                                </div>
                            </CardBodyOnly>
                        </Fragment>
                    )}
                    className="table-bordered table-striped table-light table-hover table-shadowed"
                    defaultPageSize={10}
                    expanded={this.state.expanded}
                    filterable={this.state.useFiltering}
                    minRows={0}
                    nextText='>'
                    previousText='<'
                    defaultFilterMethod={this.filterCaseInsensitive}

                //sorted={this.state.sorted}
                //onSortedChange={sorted => this.setState({ sorted })}
                //page={this.state.page}
                //pageSize={this.state.pageSize}
                //resized={this.state.resized}
                //filtered={this.state.filtered}
                // Callbacks
                //onPageChange={page => this.setState({ page })}
                //onPageSizeChange={(pageSize, page) => this.setState({ page, pageSize })}
                //onExpandedChange={expanded => this.setState({ expanded })}
                //onResizedChange={resized => this.setState({ resized })}
                //onFilteredChange={filtered => this.setState({ filtered })}
                />
            </div>
        );
    }

    renderCards = payload => {
        return (
            <div className='row'>
                {
                    payload.map(branch =>
                        (
                            <div className='' key={branch.Id} title={branch.MainBranch === 1 ? 'Main Branch' : ''} >
                                <CardWithoutImageHeader
                                    CssClass={branch.MainBranch === 1 ? ' card-item-highlight' : ''}
                                    Id={branch.Id}
                                    Header={branch.Name}
                                    HeaderIcon={branch.MainBranch === 1 ? 'fas fa-code-branch pr-3' : ''}
                                    Title={branch.ContactName}
                                    ButtonStyle={ButtonsJss.ButtonStyles.icon}
                                    UseDefaultCss={false}
                                    EditUrl={"/Branch/Update/"}
                                    DeleteUrl={"/Branch/Delete/"}
                                    ViewUrl={"/Branch/View/"}
                                    ShowViewButton={this.state.ShowViewButton} // use role priviledge to set visibility
                                    ShowEditButton={this.state.ShowEditButton} // use role priviledge to set visibility
                                    ShowDeleteButton={this.state.ShowDeleteButton}// use role priviledge to set visibility
                                >
                                    <div className='pt-3'>
                                        <i className="fa fa-home fa-1x pr-2" />
                                        {branch.AddressLine1 ? branch.AddressLine1 : '.'}</div>
                                    <div className='pl-5'>{branch.AddressLine2}</div>
                                    <div className='pl-5'>{branch.AddressLine3}</div>
                                    <div className='pl-5'>{branch.CityTown}</div>
                                    <div className='pl-5'>{branch.County}</div>
                                    <div className='pl-5'>{branch.Postcode}</div>
                                    <div className='pt-3'>
                                        <i className="fa fa-phone fa-1x pr-2" />
                                        {branch.Telephone}</div>
                                    <div className='pt-3'>
                                        <i className="fa fa-envelope fa-1x pr-2" />
                                        {branch.BranchEmail}</div>
                                    <div className='pt-3 card-item-text'>
                                        <i className="fa fa-globe fa-1x pr-2" />
                                        {branch.BranchUrl ? (
                                            <Link to={branch.BranchUrl} target='_blank'>{branch.BranchUrl}</Link>
                                        ) : ''}</div>
                                </CardWithoutImageHeader>
                            </div>
                        )
                    )
                }
            </div>
        );
    }

    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    renderCardViewHandler = viewName => {
        //console.log(viewName);
        this.setState({
            displayView: viewName,
            displayContent: (this.state.payload === undefined || this.state.payload === null || this.state.payload.length === 0) ?
                this.renderNoDataView : this.renderCards(this.state.payload)
        });
        new AuthHelper().CacheDisplayView(this.displayName, viewName);
    }

    renderListViewHandler = viewName => {
        //console.log(viewName);
        this.setState({
            displayView: viewName,
            displayContent: (this.state.payload === undefined || this.state.payload === null || this.state.payload.length === 0) ?
                this.renderNoDataView : this.renderTable(this.state.payload)
        });

        //store in sessionStorage
        new AuthHelper().CacheDisplayView(this.displayName, viewName);
    }

    fetchData = () => {
        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerBranches",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                CompanyId: this.state.CompanyId
            })
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                //if (debugOn) {
                //    console.log(this.displayName + '.fetchData[data]: ', data);
                //}
                if (data.returncode === 0) {
                    this.setState({
                        loading: false,
                        payload: [],
                        totals: 0,
                        totalsLabel: ' item',
                        notificationPostfix: 'warning show mt-3',
                        notificationMessage: ['No Data'],
                        displayContent: ''
                    });
                    return;
                }
                const branches = JSON.parse(data.ReturnData.Payload).sort(function (a, b) {
                    // make main branch first item in the list
                    if (a.MainBranch) { return -1; }
                    if (b.MainBranch > a.MainBranch) { return 1; }
                    if (a.Name < b.Name) { return -1; }
                    if (a.Name > b.Name) { return 1; }
                    return 0;
                });
                // console.log('sorted', branches);
                const view = this.state.displayView;
                //if (debugOn) {
                //    console.log(this.displayName + '.fetchData[view, branches]: ', view, branches);
                //}
                this.setState({
                    loading: false,
                    payload: branches,
                    totals: branches.length,
                    totalsLabel: branches.length > 1 ? ' items' : ' item',
                    notificationPostfix: data.returncode === 0 ? 'info show mt-3' : 'info hide',
                    notificationMessage: data.returncode === 0 ? ['No Data'] : [],
                    displayContent: view === defaultView ?
                        this.renderCards(branches) :
                        this.renderTable(branches)
                });
                // console.log(data);
                // console.log(JSON.parse(data.message));
            })
            .catch(data => {
                console.log(data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    displayContent: '',
                    payload: []
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'Branch List',
            action: 'View List'
        });

        return (
            <div className='fluid-container'>
                <div className='row'>
                    <div className='col-xs-12 col-sm-6 col-md-6'>
                        <h2>
                            <Badge badgeContent={this.state.totals} color="primary">Branches</Badge>
                        </h2>
                    </div>
                </div>
                <div className='d-flex flex-row-reverse'>
                    <div className={this.state.ShowEditButton ? 'pl-2 pt-2' : 'hide'}>
                        <Link className='btn btn-sm btn-primary mdb-btn-resize' to='/Branch/Update/-1'>
                            <i className="fa fa-plus pr-2" />Add Branch
                        </Link>
                    </div>
                    <div className='pr-2'>
                        <ViewToggler
                            viewName={this.state.displayView}
                            renderCard={this.renderCardViewHandler}
                            renderList={this.renderListViewHandler}
                        />
                    </div>
                </div>
                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                <div className='col-xs-12 pb-3'>
                    {this.state.displayContent}
                </div>
            </div>
        );
    }
}
