import React, { Component } from 'react';

export class CardBodyOnly extends Component {
    displayName = CardBodyOnly.name;

    render() {
        return (
            <div className='card card-body text-muted'>
                {this.props.children}
            </div>
        );
    }
}
