import React, { Component } from 'react';
import ReactGA from 'react-ga';


import uuid from "uuid";
import { UsefulMarketingReport} from './UsefulMarketingReport';
import { PopularDownloads } from '../Downloads/PopularDownloads';
import { ApprovedDownloads } from '../Downloads/ApprovedDownloads';
//import { Table, ButtonToolbar, Button } from 'react-bootstrap';
//import { Table } from 'react-bootstrap';

import '../Fensa.css';
import './UsefulResources.css';

import AuthHelper from '../SharedComponents/AuthHelper';
//import { PleaseWait } from '../SharedComponents/PleaseWait';
import { CardBodyOnly } from './../SharedComponents/CardBodyOnly';
import { DownloadPDF } from '../SharedComponents/DownloadPDF';

import { MDBJumbotron, MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import moment from 'moment';

import ModalVideo from 'react-modal-video';
import "react-modal-video/scss/modal-video.scss";
import { Button } from 'react-bootstrap/lib/InputGroup';

export class UsefulInstallationResources extends Component {
    constructor(props) {
        super(props);

        const auth = new AuthHelper();
        this.state = {
            CompanyId: auth.CompanyId,
            DDMandate: "",
            FensaCert: "",
            loading: true,
            CompanyName: '',
            Address: '',
            CertificationDate: '',
            ExpiryDate: '',
            MemberNo: '',
            DownloadType: [],
            EnableDDMandate: false,
            EnableFensaCert: false,
            isOpen: false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {

        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.ReportGetDdMandate",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                CompanyId: this.state.CompanyId
            })
        });

       fetch(process.env.REACT_APP_FENSA_WEB_API + 'FensaReporting/GetDdMandate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    DDMandate: data.FileContents,
                    EnableDDMandate: true
                });
            });


        var FensaCert = JSON.stringify({
            CompanyId: this.state.CompanyId
        });

        var DataFensaCert = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.ReportGetFensaCert",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: FensaCert
        });


        //fetch('http://fensawebapi.boroughit.com/api/Reporting/GetFensaCert', {        
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'FensaReporting/GetFensaCert', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: DataFensaCert
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    FensaCert: data.FileContents,
                    EnableFensaCert: true
                });
            });


        var DownloadType = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetApplicationDownloadType",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: FensaCert
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: DownloadType
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ DownloadType: JSON.parse(JSON.parse(data).ReturnData.Payload) });
            });

    }

    //fetchDataOld = () => {

    //    var postCompanyData = JSON.stringify({
    //        seqno: uuid.v4(),
    //        datatype: "BIT.Fensa.InstallerPortal.GetInstallerInstallationValidation",
    //        origin: "Installer Portal",
    //        operation: "Search",
    //        datavalues: JSON.stringify({
    //            CompanyId: this.state.CompanyId
    //        })
    //    });



    //    fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
    //        method: 'POST',
    //        headers: {
    //            'Accept': 'application/json',
    //            'Content-Type': 'application/json'
    //        },
    //        body: postCompanyData
    //    })
    //        .then(response => response.json())
    //        .then(data => JSON.parse(data))
    //        .then(data => {

    //            let myDetailsData = JSON.parse(data.message);


    //            console.log(myDetailsData);

    //            this.setState({
    //                CompanyName: myDetailsData.CompanyName,
    //                Address: myDetailsData.AddressLine1
    //                    + ',%0A'
    //                    + (myDetailsData.AddressLine2 === undefined ? '' : myDetailsData.AddressLine2 + ',%0A')
    //                    + (myDetailsData.AddressLine3 === undefined ? '' : myDetailsData.AddressLine3 + ',%0A')
    //                    + myDetailsData.CityTown + ',%0A '
    //                    + myDetailsData.County + ',%0A '
    //                    + myDetailsData.Postcode,

    //                CertificationDate: myDetailsData.DateStarted,
    //                ExpiryDate: myDetailsData.DateEnded,
    //                MemberNo: myDetailsData.Reference
    //            });

    //            return myDetailsData;
    //        })
    //        .then(data => {

    //            console.log(data);

    //            var postData = JSON.stringify({
    //                seqno: uuid.v4(),
    //                datatype: "BIT.Fensa.Report.FensaCertificate",
    //                origin: "Installer Portal",
    //                operation: "Search",
    //                datavalues: JSON.stringify({
    //                    "CompanyName": data.CompanyName,
    //                    "Address": data.AddressLine1 + ',' + '\n'
    //                        + (data.AddressLine2 === undefined ? '' : data.AddressLine2 + ',' + '\n') 
    //                        + (data.AddressLine3 === undefined ? '' : data.AddressLine3 + ',' + '\n')
    //                        + data.CityTown + ', ' + '\n'
    //                        + data.County + ', ' + '\n'
    //                        + data.Postcode,

    //                    "CertificationDate": data.DateStarted === undefined ? 'N/A' : moment(data.DateStarted).format('DD MMM YYYY'),
    //                    "ExpiryDate": data.DateEnded === undefined ? 'N/A' : moment(data.DateEnded).format('DD MMM YYYY'),
    //                    "MemberNo": data.Reference
    //                })
    //            });
    //            fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/GetFensaCert', {
    //                method: 'POST',
    //                headers: {
    //                    'Accept': 'application/json',
    //                    'Content-Type': 'application/json'
    //                },
    //                body: postData
    //            })
    //                .then(response => response.json())
    //                .then(data => JSON.parse(data))
    //                .then(data => {
    //                    this.setState({ DDMandate: data.message });
    //                });
    //            }
    //        );
    //}

    // fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/GetMarketingReport', {
            //     method: 'POST',
            //     headers: {
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json; charset=utf-8'
            //     },
            //     body: postData

            // })
            //     .then(response => response.json())
            //     .then(data => {
            //         console.log('rept',data)
            //         this.setState({
            //             trap: data,
            //             EnableTraining: true                      
            //         });                   
            //     });

    render() {

        ReactGA.event({
            category: 'Useful Installation Resources',
            action: 'Useful Installation Resources List'
        });

        return (
            <div>
                <MDBContainer>
                    <MDBRow>
                        <MDBCol>
                            <MDBJumbotron fluid>
                                <div className='px-5'>
                                    <h1 className='h1 h1-responsive'>Useful Resources</h1>
                                    <p className='lead text-left'>Here you will be able to download or print off useful documents such as your company registration certificate, inspection regime, FENSA logo, order forms for free van and showroom stickers, technical guidance notes and other useful information.</p>
                                </div>
                            </MDBJumbotron>

                            <Button type="button" className='btn orange pull-left' onClick={() => this.setState({ isOpen: true })}>
                                <i className="fa fa-play pr-2" />How to download your Approved Installer Certificate</Button>

                            <DownloadPDF EnableDownload={this.state.EnableDDMandate} DownloadLabel={this.state.DDMandate} FileName={'DDmandate_' + this.state.CompanyId + '.pdf'} Text='Direct Debit Mandate' />
                            <DownloadPDF EnableDownload={this.state.EnableFensaCert} DownloadLabel={this.state.FensaCert} FileName={'Certificate_' + this.state.CompanyId + '.pdf'} Text='Fensa Certificate' />
                            {/* <DownloadPDF EnableDownload={this.state.EnableTraining} DownloadLabel={this.state.trap} FileName={'Report.pdf'} Text='Report' />*/}
                            <br />
                            <br />
                        </MDBCol>
                    </MDBRow>
                    <UsefulMarketingReport/>
                    {this.state.DownloadType.map(DownloadType => <PopularDownloads DownloadType={DownloadType.Name} DownloadTypeId={DownloadType.Id} />)}

                </MDBContainer>
                <ModalVideo
                    isOpen={this.state.isOpen}
                    onClose={() => this.setState({ isOpen: false })}
                    channel='custom'
                    url='./How to download your Approved Installer Certificate.mp4'
                />
            </div>
        );
    }
}