import React, { Component } from 'react';
import { MenuItems } from '../SharedComponents/MenuItems';

import ReactGA from 'react-ga';

export class Installation extends Component {
    displayName = Installation.name;

    render() {
        ReactGA.event({
            category: 'Installation',
            action: 'View List'
        });

        return (
            <MenuItems ParentName="Installations" />
        );
    }
}
