import React, { Component } from 'react';

import '../Fensa.css';

export class ChangePermissions extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">Change permissions section is under construction</div>
                </div>
            </div>
        );
    }
}