export const SET_APPLICATION_INFORMATION = "SET_APPLICATION_INFORMATION";
export const CLEAR_APPLICATION_INFORMATION = "CLEAR_APPLICATION_INFORMATION";

export const setApplicationInformation = (Url, Value) => ({
    type: SET_APPLICATION_INFORMATION,
    Url,
    Value
});

export const ClearApplicationInformation = (Url, Value) => ({
    type: CLEAR_APPLICATION_INFORMATION,
    Url,
    Value
});




