import React, { Component } from 'react';

import ReactGA from 'react-ga';

import '../Fensa.css';

export class Invoices extends Component {
    displayName = Invoices.name;
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {

        ReactGA.event({
            category: 'Invoices',
            action: 'View Invoices'
        });


        return (
            <div className="container">
                <div className="row">
                    <div className="col">Invoices section is under construction</div>
                </div>
            </div>
        );
    }
}