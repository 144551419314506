import React, { Component, Fragment } from "react";
import validator from 'validator';
import uuid from "uuid";


import AuthHelper from './AuthHelper';
import { DefaultCompanySearch } from './DefaultCompanySearch';
import { PleaseWait } from './PleaseWait';

import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";


const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;
export class DefaultCompanyList extends Component {
    displayName = "DefaultCompanyList";

    constructor(props) {
        super(props);
        const auth = new AuthHelper();
        this.state = {
            ...props,
            CompanyData: [],
            CurrentCompanyId: auth.CompanyId,
            DefaultCompanyFensaNumber: auth.DefaultCompanyFensaNumber,
            EmailAddress: auth.UserEmail,
            UserDisplayName: auth.UserDisplayName,
            UserId: auth.UserId,
            Username: auth.Username,
            displayContent: '',
            isFensaAdmin: auth.IsFensaAdmin,
            loading: true,
            notificationMessage: auth.SignInNotificationMessages ? auth.SignInNotificationMessages : [],
            notificationPostfix: 'info hide'
        };

        //console.log(this.displayName + '.ctor: auth > ', auth);
        //console.log(this.displayName + '.ctor: state > ', this.state);
    }

    componentDidMount = () => {
        this.fetchData();
    }

    togglePleaseWait = status => {
        this.setState({
            loading: status
        });
    }


    handleSearchCompanyCallback = (data) => {
        //now pass the data to the list
        //console.log(this.displayName + '.handleSearchCompanyCallback', data);
        if (data) {
            this.setState({
                CompanyData: data
            });
        }
        this.fetchData();
    }

    renderNoDataView = () => {
        return (
            <div>
                <p><em>{this.state.notificationMessage}</em></p>
            </div>
        );
    }

    handleDefaultCompanyCallback = (company) => {
        //console.log(this.displayName + '.handleDefaultCompanyCallback', company);
        //set current company
        this.setState({
            CurrentCompanyId: company.CompanyId
        });
        //set default company
        const auth = new AuthHelper();
        auth.SetDefaultCompany(company);
        this.props.handleDefaultCompany();

        this.fetchData();
    }

    handleResetDefaultCompanyCallback = (company) => {

        this.togglePleaseWait(true);

        //console.log(this.displayName + '.handleResetDefaultCompanyCallback', company);

        //set as default company so user defaults to this company at next signin
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/InsertJsonObject', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.InstallerSetDefaultCompany",
                origin: "Installer Portal",
                operation: "INSERT",
                validate: false,
                datavalues: JSON.stringify({
                    CompanyId: company.CompanyId,
                    UserId: this.state.UserId
                })
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                //console.log(this.displayName + '.handleResetDefaultCompanyCallback[data]', data);
                if (data.returncode === 1) {
                    const auth = new AuthHelper();
                    auth.ResetDefaultCompany(company.CompanyId, company.CompanyName, company.FensaNumber);
                    const companyData = JSON.parse(data.ReturnData.Payload);
                    //console.log(this.displayName + '.handleResetDefaultCompanyCallback[companyData]', companyData);
                    this.setState({
                        DefaultCompanyFensaNumber: company.FensaNumber,
                        displayContent: companyData && companyData.length > 0 ?
                            this.renderItems(companyData, company.FensaNumber.toString()) :
                            this.renderNoDataView(),
                        loading: false,
                        notificationMessage: [],
                        notificationPostfix: 'info hide'
                    });
                    this.props.handleDefaultCompany(company);
                } else {
                    this.setState({
                        loading: false,
                        notificationMessage: data.ReturnData.Errors ? data.ReturnData.Errors : [],
                        notificationPostfix: data.ReturnData.Errors ? 'danger show' : 'info hide'
                    });
                }
            })
            .catch(data => {
                if (debugOn) {
                    console.log(this.displayName + '.handleResetDefaultCompanyCallback[catch]', data);
                }
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show mt-3',
                    notificationMessage: ['Bad Request - Failed to complete update']
                });
            });
    }

    renderItems = (companies, defFensaNumber) => {
        return (
            <MDBTable striped borderless hover responsive>
                <MDBTableHead>
                    <tr>
                        <th>Company</th>
                        <th className="text-center">Registration</th>
                        {/*<th className="text-center">Expired</th>*/}
                        {!this.state.isFensaAdmin ?
                            (
                                <th className="text-center">Default</th>
                            ) : null
                        }
                        <th>&nbsp;</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {
                        companies.map(company =>
                            (
                                <tr key={company.CompanyId}>
                                    <td>{company.CompanyName}</td>
                                    {/*<td className="text-center"><i className={company.ValidRegistration === 1 ? "fas fa-check" : "fas fa-times"} /></td>*/}
                                    <td className="text-center text-muted">{company.ValidRegistrationStatus}</td>
                                    {/*<td className="text-center"><i className={company.RegistrationExpired === 1 ? "fas fa-check" : "fas fa-times"} /></td>*/}
                                    {
                                        !this.state.isFensaAdmin ?
                                            (
                                                <td className="text-center">
                                                    {
                                                        //if a default company exists, then don't show set default company icon if default company is current
                                                        validator.isNumeric(defFensaNumber) && company.FensaNumber === validator.toInt(defFensaNumber) ?
                                                            (
                                                                <span>Default</span>
                                                            ) : (
                                                                company.ValidRegistration === 1 ?
                                                                    (
                                                                        <a className="text-link text-success"
                                                                            title="Click here to set as default company"
                                                                            onClick={() => this.handleResetDefaultCompanyCallback(company)}>
                                                                            <i className="fas fa-wrench pr-2" title="Set as default" />Set Default
                                                                        </a>
                                                                    ) : (
                                                                        <span>{company.ValidRegistrationStatus}</span>
                                                                    )
                                                            )
                                                    }
                                                </td>
                                            ) : null
                                    }
                                    <td className="text-center">
                                        { //if this is the current default company then don't show the link
                                            company.ValidRegistration === 0 ? null : (
                                                validator.toInt(company.CompanyId.toString()) !== validator.toInt(this.state.CurrentCompanyId.toString()) ?
                                                    (
                                                        <a className="text-link text-success"
                                                            title="Switch to company"
                                                            onClick={() => this.handleDefaultCompanyCallback(company)}>
                                                            <i className="fas fa-arrow-right text-success pr-2" />Switch</a>
                                                    ) :
                                                    //if is fensa admin user and no default company
                                                    this.state.isFensaAdmin && validator.toInt(this.state.CurrentCompanyId) === 0 ?
                                                        (
                                                            <a className="text-link text-success"
                                                                title="Switch to company"
                                                                onClick={() => this.handleDefaultCompanyCallback(company)}>
                                                                <i className="fas fa-arrow-right text-success pr-2" />Switch</a>
                                                        ) : (
                                                            <span>Current</span>
                                                        )
                                            )
                                        }
                                    </td>
                                </tr>
                            )
                        )
                    }
                </MDBTableBody>
            </MDBTable>
        );
    }

    fetchData = () => {
        //if not fensa admin then load companies user can access
        if (!this.state.isFensaAdmin) {

            this.togglePleaseWait(true);
            //Branch list
            var postData = JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.InstallerCompanyGet",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    UserId: this.state.UserId
                })
            });
            console.log(this.displayName + '.fetchData[postData]', postData);
            fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: postData
            })
                .then(response => response.json())
                .then(data => JSON.parse(data))
                .then(data => {
                    if (data.returncode === 1) {
                        const companyData = JSON.parse(data.ReturnData.Payload);
                        //if (debugOn) {
                        console.log(this.displayName + '.fetchData[data]', data);
                        //console.log(this.displayName + '.json.Payload', JSON.parse(data.ReturnData.Payload));
                        console.log(this.displayName + '.companyData', companyData);
                        //}
                        if (companyData !== null && companyData.length > 0) {
                            this.setState({
                                CompanyData: companyData,
                                displayContent: companyData && companyData.length > 0 ?
                                    this.renderItems(companyData, this.state.DefaultCompanyFensaNumber) :
                                    this.renderNoDataView(),
                                loading: false
                            });
                        } else {
                            this.setState({
                                CompanyData: [],
                                displayContent: this.renderNoDataView(),
                                loading: false
                            });
                        }
                    } else {
                        this.setState({
                            CompanyData: [],
                            displayContent: this.renderNoDataView(),
                            loading: false
                        });
                    }
                })
                .catch(data => {
                    if (debugOn) {
                        console.log(this.displayName + '.fetchData.[companies].error', data);
                    }
                    this.setState({
                        //loading: false,
                        CompanyData: [],
                        loading: false,
                        notificationPostfix: 'danger show',
                        notificationMessage: ['Bad request - Data not found or service not available']
                    });
                });
        } else {
            //render list if any
            this.setState({
                displayContent: this.state.CompanyData && this.state.CompanyData.length > 0 ?
                    this.renderItems(this.state.CompanyData) :
                    this.renderNoDataView(),
                loading: false
            });
        }
    }

    render() {
        return (
            <Fragment>
                <div className="grey-text">
                    <div className='py-3'>
                        <i className={this.state.isFensaAdmin ? 'fas fa-user-cog pr-2' : 'fa fa-user pr-2'} />
                        {this.state.UserDisplayName}
                        <i className='fa fa-envelope pl-5 pr-2' /> {this.state.Username}
                    </div>
                    <PleaseWait IsLoading={this.state.loading} />
                    {
                        this.state.isFensaAdmin ?
                            (
                                <DefaultCompanySearch
                                    handleCompanySearch={this.handleSearchCompanyCallback}
                                />
                            )
                            : null
                    }
                    {this.state.displayContent}
                </div>
            </Fragment >
        );
    }
}
