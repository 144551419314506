import React, { Component, Fragment } from 'react';
import { MDBNotification } from "mdbreact";

export class NotificationToast extends Component {
    displayName = "NotificationToast";

    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
        //console.log(this.displayName + '.ctor', this.state);
    }

    componentWillReceiveProps = nextProps => {
        console.log(this.displayName + '.componentWillReceiveProps', nextProps);
        this.setState({
            ...nextProps
        });
    }

    getNotificationMessage = () => {
        console.log(this.displayName + '.NotificationMessage', this.state.NotificationMessage);
        return (
            this.state.NotificationMessage.map((msg, index) => <p key={index}>{msg}</p>)
        );
    }

    render() {
        return (
            <Fragment>

                <MDBNotification
                    autohide={this.props.ToastTimeout ? this.props.ToastTimeout : 5000} // by default = ∞ ms
                    bodyClassName="p-5 font-weight-bold white-text"
                    className="stylish-color-dark"
                    closeClassName="blue-grey-text"
                    fade
                    icon="bell"
                    iconClassName="blue-grey-text"
                    message={() => this.getNotificationMessage}
                    show
                    //text="11 mins ago"
                    title={this.props.NotificationTitle}
                    titleClassName="elegant-color-dark white-text"
                    style={{
                        width: "auto",
                        position: "fixed",
                        top: "10px",
                        right: "10px",
                        zIndex: 9999
                    }}
                />
            </Fragment>
        );
    }
}