import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import Moment from 'react-moment';
import uuid from "uuid";
import ReactGA from 'react-ga';


import AuthHelper from '../SharedComponents/AuthHelper';
import { ButtonsJss } from '../SharedComponents/ButtonsJss';
import { CardFooterButtonsIcons } from '../SharedComponents/CardFooterButtonsIcons';
import { CardWithoutImageHeader } from '../SharedComponents/CardWithoutImageHeader';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import { ReactTableFilterToggler } from './../SharedComponents/ReactTableFilterToggler';
import { ViewToggler } from '../SharedComponents/ViewToggler';

import Badge from "@material-ui/core/Badge";

const defaultView = 'address-card';
const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class ListUsers extends Component {
    displayName = "ListUsers"

    constructor(props) {
        super(props);
        const auth = new AuthHelper(this.displayName);
        this.state = {
            CompanyId: auth.CompanyId,
            IsFensaAdmin: auth.IsFensaAdmin,
            ShowDeleteButton: auth.CanDoAdminTask,
            ShowEditButton: auth.CanEditData,
            ShowViewButton: true,
            UsePaging: true,

            displayContent: (<PleaseWait IsLoading={true} />),
            displayView: auth.DisplayView ? auth.DisplayView : defaultView,
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 10,
            payload: [],
            resized: [],
            sorted: [],
            totals: '',
            useFiltering: (auth.TableFilter && auth.TableFilter === 'On' ? true : false)
        };
    }

    componentDidMount = () => {
        this.fetchData();
    }

    filterCaseInsensitive = ({ id, value }, row) => {
        //console.log(id, value, row[id], row);
        if (id === 'CompanyUserStatus') {
            var yes = 'yes';
            return row[id] ? row[id] === true && yes.includes(value.toLowerCase()) : true;
        } else {
            return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;
        }
    }

    handleToggleFilter = toggle => {
        this.setState({
            useFiltering: toggle
        });
        this.fetchData();
    }

    parseAsString = (value, nullValue) => {
        try {
            return value ? value : nullValue ? nullValue : '';
        } catch (e) {
            console.log(e);
            return '.';
        }
    }

    renderCards = payload => {
        return (
            <div className='card-deck'>
                {
                    payload.map(user =>
                        (
                            <div className='card-wrapper' key={user.Id} title={user.CompanyUserStatus ? user.ResetPwd ? user.Name + ': account locked.' : user.Name : 'account disabled'}>
                                <CardWithoutImageHeader
                                    CssClass={user.CompanyUserStatus ? ' card-user-highlight' : ''}
                                    Id={user.Id}
                                    Header={this.parseAsString(user.Name, '.')}
                                    HeaderIcon={user.MainAuthUser ? 'fas fa-user-tag pr-3' : ''}
                                    Title={user.RoleName}
                                    TitleIcon={user.ResetPwd ? 'fas fa-user-lock text-danger pr-3' : ''}
                                    ButtonStyle={ButtonsJss.ButtonStyles.icon}
                                    UseDefaultCss={false}
                                    EditUrl={"/UserManagement/User/Update/"}
                                    DeleteUrl={"/UserManagement/User/Delete/"}
                                    ViewUrl={"/UserManagement/User/View/"}
                                    ShowViewButton={this.state.ShowViewButton} // use role priviledge to set visibility
                                    ShowEditButton={this.state.ShowEditButton} // use role priviledge to set visibility
                                    ShowDeleteButton={this.state.ShowDeleteButton}// use role priviledge to set visibility
                                >
                                    <div className='pt-3'>
                                        <i className="fa fa-envelope fa-1x pr-4" />
                                        {user.Email}</div>
                                    <div className='pt-3'>
                                        <i className="fas fa-mobile fa-1x pr-4" />
                                        {user.MobilePhone}</div>
                                    <div className='pt-3'>
                                        <i className="fa fa-phone fa-1x pr-4" />
                                        {user.Telephone}</div>
                                    {/* <div className='pt-3'>
                                        <i className="fa fa-fax fa-1x pr-4" />
                                        {user.FaxNumber}</div> */}
                                    <div className={this.state.IsFensaAdmin && !user.GlobalUserStatus ? 'pt-3' : 'hide'}>
                                        <i className='fa fa-cog fa-1x text-danger pr-4' />
                                        Global Status - Deactivated</div>
                                </CardWithoutImageHeader>
                            </div>
                        )
                    )
                }
            </div>
        );
    }

    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    renderCardViewHandler = viewName => {
        //console.log(viewName);
        this.setState({
            displayView: viewName,
            displayContent: (this.state.payload === undefined || this.state.payload === null || this.state.payload.length === 0) ?
                this.renderNoDataView : this.renderCards(this.state.payload)
        });
        new AuthHelper().CacheDisplayView(this.displayName, viewName);
    }

    renderListViewHandler = viewName => {
        this.setState({
            displayView: viewName,
            displayContent: (this.state.payload === undefined || this.state.payload === null || this.state.payload.length === 0) ?
                this.renderNoDataView : this.renderTable(this.state.payload)
        });
        //store in sessionStorage
        new AuthHelper().CacheDisplayView(this.displayName, viewName);
    }

    renderTable = definedUsers => {
        return (
            <div className='py-0'>
                <ReactTableFilterToggler
                    ParentDisplayName={this.displayName}
                    UseFiltering={this.state.useFiltering}
                    handleToggleFilter={this.handleToggleFilter}
                />
                <ReactTable data={definedUsers}
                    columns={[
                        {
                            Header: 'Id',
                            accessor: 'Id',
                            filterable: false,
                            show: false
                        },
                        {
                            Header: '', // Custom header components!
                            accessor: 'MainAuthUser',
                            maxWidth: 45,
                            width: 45,
                            filterable: false,
                            sortable: false,
                            Cell: row => {
                                return (
                                    <div className='p-2'>
                                        {
                                            row.original.MainAuthUser ?
                                                (
                                                    <span>
                                                        <i className='fas fa-user-tag pr-3' title='Main Authorised User' />
                                                    </span>
                                                ) : null
                                        }
                                    </div>
                                );
                            },
                            style: {
                                maxWidth: 45,
                                paddingTop: "5",
                                textAlign: "right",
                                userSelect: "none"
                            }
                        },
                        {
                            Header: 'Name',
                            accessor: 'Name',
                            className: 'p-3'
                        },
                        {
                            Header: 'Job Title',
                            accessor: 'JobTypeName',
                            className: 'p-3'
                        },
                        {
                            Header: 'Role',
                            accessor: 'RoleName',
                            className: 'p-3'
                        },
                        {
                            Header: 'Status',
                            accessor: 'CompanyUserStatus',
                            className: 'p-3',
                            headerStyle: { textAlign: 'centre' },
                            Cell: row => {
                                return (
                                    //row.original.Enabled === true ? <div className="text-center"> <i className="fas fa-check" /></div> : <div className="text-center"><i className="fas fa-times text-center" /></div>
                                    //row.original.Enabled === true ? <div className="text-center">Yes</div> : <div className="text-center">No</div>
                                    row.original.CompanyUserStatus === true ? (<div className="text-center">Active</div>) : (<div className="text-center">Deactivated</div>)
                                );
                            }
                        },
                        {
                            Header: 'Global',
                            accessor: 'GlobalUserStatus',
                            className: 'p-3',
                            show: this.state.IsFensaAdmin,
                            headerStyle: { textAlign: 'centre' },
                            Cell: row => {
                                return (
                                    row.original.GlobalUserStatus === true ? (<div className="text-center">Active</div>) : (<div className="text-center">Deactivated</div>)
                                );
                            }
                        },
                        {
                            Header: 'Last Login', // Custom header components!
                            className: 'p-3',
                            accessor: 'Last Login',
                            Cell: row => {
                                return (
                                    row.original.LastLogin !== '' && row.original.LastLogin !== undefined ?
                                        (<Moment
                                            parse='YYYY-MM-DD HH:mm'
                                            format="DD MMM YYYY"
                                            date={row.original.LastLogin}
                                        />
                                        ) : ('Not Set')
                                );
                            }
                        },
                        {
                            Header: '',
                            accessor: 'Id',
                            filterable: false,
                            sortable: false,
                            Cell: row => {
                                return (
                                    /*<div>
                                        <Link className='btn btn-primary' to={"/UserManagement/EditUser/" + row.original.Id}>Edit</Link>
                                    </div>*/
                                    < Fragment >
                                        <div className='text-center py-1'>
                                            <CardFooterButtonsIcons
                                                Id={row.original.Id}
                                                //DeleteCss={ButtonsJss.DefaultDeleteCss}
                                                //EditCss={ButtonsJss.DefaultEditCss}
                                                //ViewCss={ButtonsJss.DefaultViewCss}
                                                //DeleteUrl={'/UserManagement/User/Delete/' + row.original.Id}
                                                EditUrl={'/UserManagement/User/Update/' + row.original.Id}
                                                ViewUrl={'/UserManagement/User/View/' + row.original.Id}
                                                ShowViewButton={this.state.ShowViewButton} // use role priviledge to set visibility
                                                ShowEditButton={this.state.ShowEditButton} // use role priviledge to set visibility
                                                ShowDeleteButton={this.state.ShowDeleteButton}// use role priviledge to set visibility
                                            />
                                        </div>
                                    </Fragment>
                                );
                            }
                        }
                    ]}
                    className="table-bordered table-striped table-hover table-shadowed"
                    defaultPageSize={10}
                    expanded={this.state.expanded}
                    filterable={this.state.useFiltering}
                    minRows={0}
                    nextText='>'
                    previousText='<'
                    defaultFilterMethod={this.filterCaseInsensitive}

                //sorted={this.state.sorted}
                //page={this.state.page}
                //pageSize={this.state.pageSize}
                //resized={this.state.resized}
                //filtered={this.state.filtered}
                // Callbacks
                //onSortedChange={sorted => this.setState({ sorted })}
                //onPageChange={page => this.setState({ page })}
                //onPageSizeChange={(pageSize, page) => this.setState({ page, pageSize })}
                //onExpandedChange={expanded => this.setState({ expanded })}
                //onResizedChange={resized => this.setState({ resized })}
                //onFilteredChange={filtered => this.setState({ filtered })}
                />
            </div>
        );
    }

    fetchData = () => {

        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerGetInstallerCompanyUsers",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                CompanyId: this.state.CompanyId
            })
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                const definedUsers = JSON.parse(data.message).sort(function (a, b) {
                    // make main branch first item in the list
                    //console.log('a:', a);
                    //console.log('b:', b);
                    //if (a.Primary > b.Primary) { return -1; }
                    if (a.Name < b.Name) { return -1; }
                    if (a.Name > b.Name) { return 1; }
                    return 0;
                });
                //console.log(definedUsers);
                const view = this.state.displayView;
                this.setState({
                    loading: false,
                    payload: definedUsers,
                    totals: definedUsers.length,
                    notificationPostfix: data.returncode === 0 ? 'info show mt-3' : 'info hide',
                    notificationMessage: data.returncode === 0 ? ['No Data'] : [],
                    displayContent: view === defaultView ?
                        this.renderCards(definedUsers) :
                        this.renderTable(definedUsers)
                });
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData[catch]', data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    displayContent: ''
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'Company Users',
            action: 'Company Users List'
        });

        return (
            <div className='fluid-container'>
                <div className='row'>
                    <div className='col-xs-12 col-sm-6 col-md-6'>
                        <h2>
                            <Badge badgeContent={this.state.totals} color="primary">Company Users</Badge>
                        </h2>
                    </div>
                </div>
                <div className='d-flex flex-row-reverse'>
                    <div className={this.state.ShowEditButton ? 'pl-2 pt-2' : 'hide'}>
                        <Link className='btn btn-sm btn-primary mdb-btn-resize' to='/UserManagement/User/Update/-1'>
                            <i className="fa fa-plus pr-2" />Add User
                        </Link>
                    </div>
                    <div className='pr-2'>
                        <ViewToggler
                            viewName={this.state.displayView}
                            renderCard={this.renderCardViewHandler}
                            renderList={this.renderListViewHandler}
                        />
                    </div>
                </div>
                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                <div className='col-xs-12 pb-3'>
                    {this.state.displayContent}
                </div>
            </div>
        );
    }
}
