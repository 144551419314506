
export const AvatarJss = {
    avatar: {
        margin: 10
    },
    smAvatar: {
        margin: 5,
        width: 20,
        height: 20
    },
    mdAvatar: {
        margin: 5,
        width: 25,
        height: 25
    },
    lgAvatar: {
        margin: 5,
        width: 35,
        height: 35
    },
    title: {
        //margin: 5,
        //width: 35,
        //height: 35
        marginRight: 5,
        color: '#ffffff'
    }
};