import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { ButtonsJss } from './ButtonsJss';

export class CardFooterButtonsText extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    componentWillUpdate = (prevProps, prevState) => {
        //console.log("CardFooterButtonsIcons.componentWillUpdate[prevProps]", prevProps);
        //console.log("CardFooterButtonsIcons.componentWillUpdate[prevState]", prevState);
        if (prevProps.Id !== prevState.Id) {
            this.setState({
                ...prevProps
            });
        }
    }

    render() {
        return (
            <Fragment>
                {
                    this.state.CancelUrl && this.state.ShowCancelButton ?
                        (
                            <span className='pr-2'>
                                <Link className={this.state.CancelCss + ' border-0 btn-normal-size'} to={this.state.CancelUrl} title='Cancel'>CANCEL</Link>
                            </span>
                        ) : null
                }
                {
                    this.state.ViewUrl && this.state.ShowViewButton ?
                        (
                            <span className='pr-2'>
                                <Link className={this.state.ViewCss ? this.state.ViewCss + ' border-0 btn-normal-size' : ButtonsJss.DefaultViewTextCss + ' border-0 btn-normal-size'} to={this.state.ViewUrl} title='View'>VIEW</Link>
                            </span>
                        ) : null
                }
                {
                    this.state.EditUrl && this.state.ShowEditButton ?
                        (
                            <span className='pr-2'>
                                <Link className={this.state.EditCss ? this.state.EditCss + ' border-0 btn-normal-size' : ButtonsJss.DefaultEditTextCss + ' border-0 btn-normal-size'} to={this.state.EditUrl} title='Edit'>EDIT</Link>
                            </span>
                        ) : null
                }
                {
                    this.state.DeleteUrl && this.state.ShowDeleteButton ?
                        (
                            <span className='pr-2'>
                                <Link className={this.state.DeleteCss ? this.state.DeleteCss + ' border-0 btn-normal-size' : ButtonsJss.DefaultDeleteTextCss + ' border-0 btn-normal-size' } to={this.state.DeleteUrl} title='Delete'>DELETE</Link>
                            </span>
                        ) : null
                }
            </Fragment>
        );
    }
}