// Properties:
//  
// <FileUpload Title="Photos" Buttontext="Upload Photo" HandleOnUpload="" 
//            IncludeDescription=true/false
//            DataType = "BIT.Fensa.InstallerPortal.MongoDB.Photo" 
//            MtcPreRegistrationDetailId = { this.state.MtcPreRegistrationDetailId } />
//
//
//

import React, { Component, Fragment } from 'react';
import uuid from "uuid";

import AuthHelper from '../SharedComponents/AuthHelper';
import { ColumnAndLabel } from '../SharedComponents/ColumnAndLabel';
import { NotificationPanel } from '../SharedComponents/NotificationPanel';
import { PleaseWait } from '../SharedComponents/PleaseWait';
//import { NotificationToast } from '../SharedComponents/NotificationToast';

import { MDBTooltip, MDBBtn, MDBIcon, MDBFileInput } from 'mdbreact';

const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;
export class FileUpload extends Component {

    displayName = "FileUpload";

    constructor(props) {
        super(props);

        const auth = new AuthHelper();
        this.state = {
            ...props,
            CompanyId: auth.CompanyId,
            DocumentDescription: '',
            FileProcessing: false,
            UploadErrors: [],
            UploadFileArray: [],
            UploadSuccess: false,
            UserName: auth.Username,

            loading: false,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            toastMessage: []
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        //console.log(this.displayName + '.ctor', this.state);
    }

    componentWillMount() {
        //this.fetchData();
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        if (value === undefined) {
            value = target.parentElement.value;
        }
        const name = target.name;
        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        });
    }

    handleFileUploadCallback = fileList => {
        console.log(this.displayName + '.handleFileUploadCallback', fileList);
        if (fileList.length > 0) {
            if (fileList.length > 5) {
                this.setState({
                    FileProcessing: false,
                    notificationMessage: [`Selected ${fileList.length} files but a maximum 5 files can be uploaded at a time.`],
                    notificationPostfix: 'danger show'
                });
                this.props.UpdateNotificationMessage(`Selected ${fileList.length} files but a maximum 5 files can be uploaded at a time.`, false);
            } else {
                this.setState({ UploadFileArray: fileList });
            }
        }
    }

    handleSubmitUploadFileCallback = (e) => {
        e.preventDefault();
        this.setState({
            FileProcessing: true
        });

        if (this.props.IncludeDescription === true && this.state.DocumentDescription === '') {
            this.setState({
                FileProcessing: false,
                notificationMessage: ['Document description is required'],
                notificationPostfix: 'danger show'
            });
            this.props.UpdateNotificationMessage('Document description is required', false);
            return;
        }

        if (!this.state.UploadFileArray || this.state.UploadFileArray.length === 0) {
            this.setState({
                FileProcessing: false,
                notificationMessage: ['Please select a file to Upload.'],
                notificationPostfix: 'danger show'
            });
            this.props.UpdateNotificationMessage('Please select a file to Upload.', false);
        } else {
            //handle multiple files
            let uploadResults = [];
            let uploadErrors = [];
            for (var i = 0; i < this.state.UploadFileArray.length; i++) {
                const upload = this.state.UploadFileArray[i];
                const file = upload.name;
                const formData = new FormData();
                formData.append('datatype', this.props.DataType);
                formData.append('MtcPreRegistrationDetailId', this.props.MtcPreRegistrationDetailId);
                formData.append('UserName', this.state.UserName);
                formData.append('DocumentDescription', this.state.DocumentDescription);
                formData.append('File', upload);
                formData.append('FileName', file);
                console.log(this.displayName + '.handleSubmitUploadFileCallback[upload]', file, upload);
                console.log(this.displayName + '.handleSubmitUploadFileCallback[formData]', formData);
                fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/UploadFile', {
                    method: 'POST',
                    headers: {},
                    body: formData
                })
                    .then(response => response.json())
                    .then(response => {
                        console.log(this.displayName + '.handleSubmitUploadFileCallback[response]', response);
                        if (response.returncode === 1) {
                            uploadResults.push(`Successfully uploaded ${file}`);
                        } else {
                            uploadErrors.push(response.message);
                        }
                        console.log(this.displayName + '.handleSubmitUploadFileCallback[uploadResults]', uploadResults);
                    });
            }

            this.setState({
                DocumentDescription: '',
                FileProcessing: false,
                UploadErrors: uploadErrors,
                UploadFileArray: [],
                UploadSuccess: uploadErrors.length === 0 ? true : false,

                loading: false,
                notificationMessage: uploadErrors.length > 0 ? uploadErrors : uploadResults,
                notificationPostfix: uploadErrors.length > 0 ? 'danger show' : 'success show'
            });
            this.props.UpdateNotificationMessage(uploadErrors.length > 0 ? uploadErrors : uploadResults, uploadResults.length > 0);
        }
    }

    renderTable = () => {
        return (
            <Fragment>
                <h1>{this.props.Title}
                    <MDBTooltip placement="top">
                        <MDBBtn
                            className="mdb-btn-resize"
                            color="dark"
                            icon="info"
                            type="button"
                            size="sm"
                            floating>
                            <MDBIcon icon="info" className="mr-2" />
                        </MDBBtn>
                        <div>
                            {this.props.InformationText}
                        </div>
                    </MDBTooltip>
                    <PleaseWait IsLoading={this.state.FileProcessing} PleaseWaitText="Processing" />
                </h1>
                <form name={this.props.UploadName} encType="multipart/form-data" method="post" onSubmit={this.handleSubmitUploadFileCallback.bind(this)}>
                    <div className='row'>
                        <div className='col-xs-12 col-md-8'>
                            <MDBFileInput
                                btnColor="warning"
                                btn-size="sm"
                                multiple={this.props.MultipleMode}
                                btnTitle={this.props.UploadButtonText}
                                textFieldTitle={this.props.UploadFieldText}
                                getValue={this.handleFileUploadCallback}
                            />
                        </div>
                        {
                            this.props.IncludeDescription === true ?
                                (
                                    <Fragment>
                                        <div className='col-xs-12 col-md-4' />
                                        <div className='col-xs-12 col-md-8'>
                                            <ColumnAndLabel
                                                ErrorMessage=""
                                                IconLabel=""
                                                Id="DocumentDescription"
                                                InputType="text"
                                                IsRequired={false}
                                                SizeCss="sm"
                                                HintLabel=""
                                                TextLabel="Document description"
                                                Validate={false}
                                                Value={this.state.DocumentDescription}
                                                HandleInputChange={this.handleInputChange}
                                            />
                                        </div>
                                    </Fragment>
                                ) :
                                null
                        }
                        <div className='col-xs-12 col-md-4 pt-3'>
                            <MDBBtn className="mdb-btn-resize"
                                color="primary"
                                icon="upload"
                                type="submit"
                                size="sm">
                                <MDBIcon icon="upload" className="mr-2" />{this.props.Buttontext}
                            </MDBBtn>
                        </div>
                        <div className={this.state.UploadSuccess === false ? 'col-xs-12 col-md-12' : 'hide'}>
                            {
                                this.state.UploadErrors.map((Error) => <p>{Error}</p>)
                            }
                        </div>
                    </div>
                </form>
            </Fragment>
        );
    }

    render() {
        let contents = this.state.loading
            ? <PleaseWait IsLoading={true} />
            : this.renderTable();

        return (
            <div className='fluid-container'>
                <div>
                    {/*<NotificationToast ToastTimeout={3000} NotificationTitle={this.props.UploadFieldText} NotificationMessage={this.state.notificationMessage} />*/}
                    <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                    {contents}
                </div>
            </div>
        );
    }
}
