import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import uuid from "uuid";


import ReactGA from 'react-ga';

import validator from 'validator';
import TextField from '@material-ui/core/TextField';
import 'react-datepicker/dist/react-datepicker.css';
import { NotificationPanel } from '../SharedComponents/NotificationPanel';
import AuthHelper from '../SharedComponents/AuthHelper';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import { ColumnAndLabel } from '../SharedComponents/ColumnAndLabel';
import '../Fensa.css';

import {
    MDBBtn,
    MDBIcon
} from "mdbreact";

const inspectionsHomeUrl = '/Inspections/Home';
const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class ContactTechSupport extends Component {

    displayName = ContactTechSupport.name;

    constructor(props) {
        super(props);

        const auth = new AuthHelper();

        this.state = {
            //...props,
            CompanyId: auth.CompanyId,
            Email: auth.Username,
            loading: false,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            Subject: '',
            Message: '',
            formValid: false,
            pageTitle: 'Email Support',
            Reference: '',
            formErrors: {
                Subject: 'Subject is required',
                Message: 'Message is required',
                Email: ''
            },
            processCompleted: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleOnInputValidation = this.handleOnInputValidation.bind(this);
    }

    componentDidMount() {
    }

    currentPageTitle = () => {
        return this.state.pageTitle;
    }

    togglePleaseWait = status => {
        //console.log('togglePleaseWait.status: ', status);
        this.setState({
            loading: status
        });
    }


    handleInputChange(event) {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        if (value === undefined) {
            value = target.parentElement.value;
        }

        console.log("text changed" + target.name);

        const name = target.name;
        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        },
            () => {
                this.isValidInputField(name);
            }
        );
    }

    isValidInputField = fieldName => {
        let fieldValidationErrors = this.state.formErrors;
        let message = "";
        switch (fieldName.toLowerCase()) {
            case 'subject':
                fieldValidationErrors.Subject = validator.isEmpty(this.state.Subject) ? 'Subject is required.' : '';
                message = fieldValidationErrors.Subject;
                break;

            case 'message':
                fieldValidationErrors.Message = validator.isEmpty(this.state.Message) ? 'Message is required.' : '';
                message = fieldValidationErrors.Subject;
                break;

            case 'email':
                fieldValidationErrors.Email = validator.isEmpty(this.state.Message) ? 'Email is required.' : '';
                message = fieldValidationErrors.Email;
                break;

            default:
                break;
        }

        console.log("handleOnInputValidation : " + message);

        this.setState({
            formErrors: fieldValidationErrors,
            formValid: fieldValidationErrors.Message.length === 0 && fieldValidationErrors.Subject.length === 0 ? true : false
        }, this.validateForm);
        return message;
    }

    handleOnInputValidation = event => {
        let fieldValidationErrors = this.state.formErrors;
        let message = "";
        switch (event.target.name.toLowerCase()) {
            case 'subject':
                fieldValidationErrors.Subject = validator.isEmpty(event.target.value) ? 'Subject is required.' : '';
                message = fieldValidationErrors.Subject;
                break;

            case 'message':
                fieldValidationErrors.Message = validator.isEmpty(event.target.value) ? 'Message is required.' : '';
                message = fieldValidationErrors.Message;
                break;

            case 'email':
                fieldValidationErrors.Email = validator.isEmpty(event.target.value) ? 'Email is required.' : '';
                message = fieldValidationErrors.Email;
                break;

            default:
                break;
        }

        console.log("handleOnInputValidation : " + message + " " + event.target.name);

        if (message !== "") {
            this.setState({
                formErrors: fieldValidationErrors
            }, this.validateForm);
            //set custom validation error
            event.target.setCustomValidity(message);
        } else {
            //clear custom validation errors
            event.target.setCustomValidity("");
        }
    }


    formSubmit = e => {
        e.preventDefault();

        this.togglePleaseWait(true);

        let EmailBody = this.state.Message.split('\n');
        var MessageString = '';

        if (this.state.Reference.length !== 0) {
            MessageString = 'Installation Reference: ' + this.state.Reference + '<br/>';
        }

        MessageString = MessageString + '<p>Message received from - ' + this.state.Email.toString() + '</p>';

        for (let i = 0; i < EmailBody.length; i++) {
            MessageString = MessageString + '<p>' + EmailBody[i] + '</p>';
        }

        console.log(MessageString);
        console.log(EmailBody.toString());

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/EmailService', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.EmailCustomerSupport",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    EmailAsHTML: true,
                    EmailBody: MessageString,
                    //EmailSender: this.state.Email,
                    EmailSubject: this.state.Subject
                }),
                validate: false
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                if (data.returncode === 1) {
                    // if successful then reload with the id
                    //this.props.history.push(operativesEditUrl + JSON.parse(data.message).PersonId);
                    this.setState({
                        isNewOperative: false,
                        loading: false,
                        processCompleted: true,
                        notificationMessage: [data.message],
                        notificationPostfix: data.returncode === 1 ? 'success show' : 'danger show'
                    });
                    return;
                } else {
                    //if new operative redirect to list page
                    this.setState({
                        isNewOperative: false,
                        loading: false,
                        notificationMessage: [data.message],
                        notificationPostfix: data.returncode === 1 ? 'success show' : 'danger show'
                    });
                }
            });
    }

    render() {

        ReactGA.event({
            category: 'Contact Tech Support',
            action: 'Contact Tech Support View'
        });


        return (
            <form className='demoForm' onSubmit={this.formSubmit.bind(this)}>

                <div className='form-group-container mr-auto ml-auto'>
                    <div className='form-group-title'>{this.currentPageTitle()}</div>
                    <div className="row">
                        <div className="col">Please use this form to contact Fensa technical support</div>
                    </div>
                    <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                    <PleaseWait IsLoading={this.state.loading} />
                    <div className="row">
                        <div className="col-xs-12 col-md-4">
                            <ColumnAndLabel
                                ErrorMessage={this.state.formErrors.Email}
                                IconLabel="pencil"
                                Id="Email"
                                InputType="email"
                                IsRequired={true}
                                SizeCss="md"
                                HintLabel=""
                                TextLabel="Email From: "
                                Validate={true}
                                Value={this.state.Email}
                                HandleInputChange={this.handleInputChange}
                                HandleOnInput={this.handleOnInputValidation}
                            />
                        </div>
                        <div className="col-xs-12 col-md-8" />
                        <div className="col-xs-12 col-md-10">
                            <ColumnAndLabel
                                ErrorMessage='Please enter a subject'
                                IconLabel=""
                                Id="Subject"
                                InputType="text"
                                IsRequired={false}
                                SizeCss="md"
                                HintLabel=""
                                TextLabel="Subject"
                                Validate={false}
                                Value={this.state.Subject}
                                HandleInputChange={this.handleInputChange}
                                HandleOnInput={this.handleOnInputValidation}
                            />
                        </div>
                        <div className="col-xs-12 col-md-2" />
                        <div className="col-xs-12 col-md-10" >
                            <ColumnAndLabel
                                ErrorMessage='Please enter a Installation Reference if applicable'
                                IconLabel=""
                                Id="Reference"
                                InputType="text"
                                IsRequired={false}
                                SizeCss="md"
                                HintLabel=""
                                TextLabel="Installation Reference"
                                Validate={false}
                                Value={this.state.Reference}
                                HandleInputChange={this.handleInputChange}
                                HandleOnInput={this.handleOnInputValidation}
                            />
                        </div>
                        <div className="col-xs-12 col-md-10 col-lg-10">
                            <TextField
                                name="Message"
                                id="Message"
                                label="Message"
                                rows="10"
                                multiline
                                value={this.state.Message}
                                onChange={this.handleInputChange}
                                onInput={this.handleOnInputValidation}
                                className=""
                                margin="normal"
                                variant="filled"
                                fullWidth
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col text-right'>
                            <PleaseWait IsLoading={this.state.loading} />
                            <MDBBtn
                                className={!this.state.processCompleted ? "pull-right mdb-btn-resize" : "hide"}
                                color="success"
                                type="submit"
                                size="sm"
                                disabled={!this.state.formValid}
                            >
                                <MDBIcon icon="save" className="mr-2" />Submit</MDBBtn>
                            <Link className={this.state.processCompleted ? 'btn btn-sm btn-danger pull-right mr-2 mdb-btn-resize' : 'hide'} to={inspectionsHomeUrl}>
                                <i className='fa fa-arrow-left pr-2' />Back to Assessments</Link>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}