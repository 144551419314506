import React, { Component, Fragment } from 'react';
import { CardBodyOnly } from './CardBodyOnly';

export class AddressCardBody extends Component {
    state = {
        ...this.props
    };

    componentWillUpdate = (prevProps, prevState) => {
        if (prevProps.Address1 !== prevState.Address1) {
            this.setState({
                Address1: prevProps.Address1,
                Address2: prevProps.Address2,
                Address3: prevProps.Address3,
                CityTown: prevProps.CityTown,
                County: prevProps.County,
                Postcode: prevProps.Postcode
            });
        }
    }

    render() {
        return (
            <Fragment>
                <CardBodyOnly>
                    <Fragment>
                        {
                            this.props.children ?
                                this.props.children :
                                (
                                    <div className='pb-2'>
                                        <label>{this.state.AddressLabel}</label>
                                    </div>
                                )
                        }
                    </Fragment>
                    <div className='pr-4'>{this.state.Address1}</div>
                    <div className='pr-4'>{this.state.Address2}</div>
                    <div className='pr-4'>{this.state.Address3}</div>
                    <div className='pr-4'>{this.state.CityTown}</div>
                    <div className='pr-4'>{this.state.County}</div>
                    <div className='pr-4'>{this.state.Postcode}</div>
                </CardBodyOnly>
            </Fragment>
        );
    }
}