import React, { Component } from 'react';
import Popup from './Popup';
import PopupEscrow from './PopupEscrow';
import { Link } from 'react-router-dom';
import { ButtonToolbar, ButtonGroup, Button, Image} from 'react-bootstrap';
import { Tooltip } from '@material-ui/core';
import * as moment from 'moment';



export class HomeItem extends Component {
    displayName = HomeItem.name
    constructor(props) {
        super(props);
        this.state = {
            show:false
        }
    }
    toggleState(event) 
    {
        
            this.setState(
                {
                show:!this.state.show
            })             
        
    }


    render() {

        const { MenuId, Title, GlyphIcon, Description, Route, ExternalLink, IsImage, ToolTip,Visible,ParentId,BenefitsVideoLink} = this.props.item;
       
        let routepath = Route;       
     
        {            
            routepath = routepath.replace('{memberref}',sessionStorage.getItem('DefaultCompanyFensaNumber'));
            routepath = routepath.replace('{id}',sessionStorage.getItem('CompanyId'));

            // console.log(sessionStorage.getItem('DefaultCompanyFensaNumber'))
            // console.log(sessionStorage.getItem('CompanyId'))
            // console.log(sessionStorage.getItem('UserId'))

            //console.log('pid' + ParentId);
            
            
        }
        
        return (
            <div>
                <div className={ ExternalLink === true ? 'col-xs-12 col-md-4 col-lg-3 Centre MenuFixedHeight' : 'hide'} >
                    <div className={Visible?'FixedHeight Centre Benefits':'hide'}>
                        {(ParentId === 8 && <a onClick={() => this.toggleState()}>

                            <span className={(ToolTip == null || ToolTip == "") ? 'hide' : ''}>
                                {
                                    // <Tooltip title={ToolTip} placement="bottom"  >
                                    <img src={GlyphIcon} alt='License' />
                                    // {/* </Tooltip> */}
                                }
                            </span>
                            <span className={(ToolTip == null || ToolTip == "") ? '' : 'hide'}>
                                <img src={GlyphIcon} alt='License' />
                            </span>

                        </a>
                            || <a target="_blank" href={routepath}>


                                <span className={(ToolTip == null || ToolTip == "") ? 'hide' : ''}>
                                    {
                                        // <Tooltip title={ToolTip} placement="bottom"  >
                                        <img src={GlyphIcon} alt='License' />
                                        // {/* </Tooltip> */}
                                    }
                                </span>
                                <span className={(ToolTip == null || ToolTip == "") ? '' : 'hide'}>
                                    <img src={GlyphIcon} alt='License' />
                                </span>

                            </a>)

                        }
                        <br></br>
                        <br></br>
                        <div style={{fontWeight:'bold'}}>{Title}</div>
                    </div>
                    
                </div>
                
                <Link to={routepath}>
                     <div className={ ExternalLink === true ? 'hide' : 'col-xs-12 col-md-4 col-lg-3 Centre MenuFixedHeight'} key={MenuId}  >
                       <div className='FixedHeight Centre'>
                            {IsImage === true ? <div className='LogoCircle'> <img src={GlyphIcon} alt='License' /></div>
                                : <i className={GlyphIcon} />}
                            <div className="h6 h6-responsive pt-5">{Title}</div>jeg
                        </div> 
                    </div> 
                </Link>
              
                { ParentId===8 && Title != 'Fensa Secure Payments' && this.state.show && <Popup title={ToolTip} path={routepath} BenefitsVideoLink={BenefitsVideoLink}/>}

                { ParentId===8 && Title === 'Fensa Secure Payments' && this.state.show && <PopupEscrow title={ToolTip} path={routepath} BenefitsVideoLink={BenefitsVideoLink}/>}


            </div>
            

        );
    }
}
