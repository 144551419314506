import React, { Component, Fragment } from 'react';
import AuthHelper from './AuthHelper';
import { PleaseWait } from './PleaseWait';

export class ReactTableFilterToggler extends Component {
    displayName = ReactTableFilterToggler.name;

    state = {
        ...this.props,
        parentName: this.props.ParentDisplayName,
        toggleFilterText: this.props.UseFiltering ? 'On' : 'Off',
        useFiltering: this.props.UseFiltering,
        loading: this.props.loading
    };

    componentWillUpdate = (prevProps, prevState) => {
        if (prevProps.UseFiltering !== prevState.useFiltering) {
            this.setState({
                toggleFilterText: prevProps.UseFiltering ? 'On' : 'Off',
                useFiltering: prevProps.UseFiltering,
                loading: prevProps.loading
            });
        }
        //console.log(this.displayName + '.componentWillUpdate[prevProps]', prevProps);
        //console.log(this.displayName + '.componentWillUpdate[prevState]', prevState);
        //console.log(this.displayName + '.componentWillUpdate[state]', this.state);
    }

    handleToggleFiltering = () => {
        var toggle = !this.state.useFiltering;
        var toggleLabel = toggle ? 'On' : 'Off';
        this.setState({
            useFiltering: toggle,
            toggleFilterText: toggleLabel
        });
        new AuthHelper().CacheTableFiltering(this.state.parentName, toggleLabel);
        //console.log(this.displayName + '.handleToggleFiltering: ', toggle, toggleLabel, this.state);
        this.state.handleToggleFilter(toggle);
    }

    render() {
        return (
            <Fragment>
                {/*<div className="text-right"><PleaseWait IsLoading={this.props.loading} /></div>*/}
                <div className='py-3'>
                    Filter bar:
                    <strong>
                        <span title='Click to toggle filtering on/off' className='text-link' onClick={() => this.handleToggleFiltering()}>
                            {this.state.toggleFilterText}
                        </span>
                    </strong>
                </div>
            </Fragment>
        );
    }
}