import React, { Component } from 'react';
import SubMenuVideo  from './SubMenuVideos';
import './Popup.css';
export default class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Video:false,
      ShowPopup:true,
      ShowButton: this.props.BenefitsVideoLink
        }
    }
    ShowVideo(event){this.setState({
      
      Video:!this.state.Video,
      ShowPopup:false,
      ShowButton:false
     
        })}
        CloseVideo(event) {this.setState({
          ShowPopup:false

      })}
  
  render() {
    const {title,path,BenefitsVideoLink}=this.props
    //console.log('video',this.state.ShowButton)
    return (
      <>
      {this.state.Video && <SubMenuVideo BenefitsVideoLink={BenefitsVideoLink}/>}
      { 
        this.state.ShowPopup && <div className='popup' onClick={()=>this.CloseVideo()}>            
          <div className='popup_inner' >
          <a class="close">
          <i class="fa fa-window-close" aria-hidden="true"></i>
          </a> 
          <br></br>          
           <p>{title} </p>     
           <br></br>         
          {this.state.ShowButton!='NA' && <button class="btn circle" onClick={()=>this.ShowVideo()}>
          Video</button>}
          {this.state.ShowButton!='NA' && <button class="btn circle action_btn"  onClick={()=> window.open(path, "_blank")}>
          Website</button>}
          {this.state.ShowButton=='NA' && <button class="btnweb circle"  onClick={()=> window.open(path, "_blank")}>
          Website</button>}

          </div> 
          
        </div>
     }
      </>
    );
  }
}