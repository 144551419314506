import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

export class CardFooterButtonsIcons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    componentWillUpdate = (prevProps, prevState) => {
        if (prevProps.Id !== prevState.Id) {
            this.setState({
                ...prevProps
            });
        }
    }

    render() {
        return (
            <Fragment>
                {
                    this.state.CancelUrl && this.state.ShowCancelButton ?
                        (
                            <span className='pr-2'>
                                <Link className={this.state.CancelCss + ' border-0'} to={this.state.CancelUrl} title='Cancel'>
                                    <i className="fa fa-ban fa-2x" />
                                </Link>
                            </span>
                        ) : null
                }
                {
                    this.state.ViewUrl && this.state.ShowViewButton ?
                        (
                            <span className='pr-2'>
                                <Link className={this.state.ViewCss + ' border-0 btn-normal-size'} to={this.state.ViewUrl} title='View'>
                                    <i className={!this.state.ViewCss ? "fas fa-list text-dark" : "fas fa-list"} />
                                </Link>
                            </span>
                        ) : null
                }
                {
                    this.state.EditUrl && this.state.ShowEditButton ?
                        (
                            <span className='pr-2'>
                                <Link className={this.state.EditCss + ' border-0 btn-normal-size'} to={this.state.EditUrl} title='Edit'>
                                    <i className={!this.state.EditCss ? "far fa-edit text-primary" : "far fa-edit"} />
                                </Link>
                            </span>
                        ) : null
                }
                {
                    this.state.DeleteUrl && this.state.ShowDeleteButton ?
                        (
                            <span className='pr-2'>
                                <Link className={this.state.DeleteCss + ' border-0 btn-normal-size'} to={this.state.DeleteUrl} title='Delete'>
                                    <i className={!this.state.DeleteCss ? "fa fa-trash text-danger" : "fa fa-trash"} />
                                </Link>
                            </span>
                        ) : null
                }
            </Fragment>
        );
    }
}