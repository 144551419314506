import React, { Component } from 'react';
import { MenuItems } from '../SharedComponents/MenuItems';

import ReactGA from 'react-ga';


export class AddedBenefits extends Component {
    render() {
        ReactGA.event({
            category: 'Added Benefits',
            action: 'View Benefits'
        });
        return (
            <MenuItems ParentName="Fensa Benefits" />
        );
    }
}
