import React, { Component } from 'react';
import ReactGA from 'react-ga';
import uuid from "uuid";
import Moment from 'moment';
import '../Fensa.css';



export class LatestNews extends Component {
    //constructor(props) {
    //    super(props);
    //}

    //componentDidMount() {
    //}

    render() {

        ReactGA.event({
            category: 'Latest News',
            action: 'Latest News View'
        });

        return (
            <div className="container">
                <div className="row">
                    <div className="col">Coming soon..</div>
                </div>
            </div>
        );
    }
}

