import React, { Component } from "react";


import AuthHelper from './AuthHelper';
import { DefaultCompanyList } from './DefaultCompanyList';
import { MDBBtn, MDBIcon, MDBCard, MDBCardBody } from "mdbreact";

export class SelectDefaultCompany extends Component {
    displayName = SelectDefaultCompany.name;

    constructor(props) {
        super(props);
        const auth = new AuthHelper();
        this.state = {
            ...props,
            loading: true,
            notificationMessage: auth.SignInNotificationMessages ? auth.SignInNotificationMessages : [],
            notificationPostfix: 'info hide'
        };
    }

    componentDidMount = () => {
    }

    handleLogoutClick = () => {
        var auth = new AuthHelper();
        auth.LogOutAndReload();
    }


    handleDefaultCompanyCallback = (company) => {
        //console.log(this.displayName + '.handleDefaultCompanyCallback', company);
        this.props.handleAuthenticated();
    }

    render() {
        return (
            <MDBCard>
                <MDBCardBody>
                    <p className="h4 text-center py-4">Select Company</p>
                    <DefaultCompanyList
                        handleDefaultCompany={this.handleDefaultCompanyCallback}
                    />
                    <div className="text-right py-3">
                        <MDBBtn className="mdb-btn-resize"
                            color="danger"
                            type="button"
                            size="sm"
                            onClick={this.handleLogoutClick}>
                            <MDBIcon icon="sign-out" className="pr-2" />Cancel
                        </MDBBtn>
                    </div>
                </MDBCardBody>
            </MDBCard>
        );
    }
}
