import React, { Component } from 'react';
import uuid from "uuid";

import TextField from '@material-ui/core/TextField';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel'

const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class OpeningHours extends Component {

    displayName = OpeningHours.name;

    constructor(props) {
        super(props);

        this.state = {
            //...props,
            DayId: false,
            Opentime: '07:30',
            Closetime: '17:30'

        };

        this.handleInputChange = this.handleInputChange.bind(this);

    }

   

    handleInputChange(event) {

        const target = event.target;

        var value = target.type === 'checkbox' ? target.checked : target.value;

        if (value === undefined) {
            value = target.parentElement.value;
        }

        const name = target.name;

        //console.log("Opening Hour - On Change name", name);
        //console.log("On Change value", value);
        //console.log("On Change value", target);
        //console.log("On Change Parent value", target.parentElement);
        //console.log("On Change value", this.props.SelectedDays);

        let SelectDay = this.props.SelectedDays.find(x => parseInt(x.DayOfWeek) === parseInt(this.props.DayId));

        //console.log("On Change value", SelectDay);

        switch (name) {
            
            case 'DayId':
                if (value === true) {
                    
                    this.props.setOpeningHours(this.props.DayId, false, SelectDay === undefined ? '' : SelectDay.OpenTime,
                        SelectDay === undefined ? '' : SelectDay.CloseTime);
                }
                else {
                    this.props.setOpeningHours(this.props.DayId, true, SelectDay === undefined ? '' : SelectDay.OpenTime,
                        SelectDay === undefined ? '' : SelectDay.CloseTime);
                }
                
                break;

            case 'OpenTime':
                this.props.setOpeningHours(this.props.DayId, false, value, SelectDay.CloseTime);
                break;

            case 'CloseTime':
                this.props.setOpeningHours(this.props.DayId, false, SelectDay.OpenTime, value);
                break;

        }

    }


    render() {

        let SelectDay = this.props.SelectedDays.find(x => parseInt(x.DayOfWeek) === parseInt(this.props.DayId));

        return (
            <div className="row">
                <div className='col-xs-6 col-md-6'>
                <FormControlLabel control={<Checkbox />}

                    checked={SelectDay === undefined ? false :
                                SelectDay.NotOpen ? false : true}
                    name='DayId'
                    onChange={this.handleInputChange}
                    value={this.props.DayId}

                    label={this.props.DayName} />
                </div>

                <div className='col-xs-3 col-md-3' id={this.props.DayId}>
                <TextField

                    class={SelectDay === undefined ? 'hide' :
                            SelectDay.NotOpen ? 'hide' : 'show'}
                    
                    id={this.props.DayId}
                    label="Open"
                    type="time"
                    name='OpenTime'
                    value={SelectDay === undefined ? '' : SelectDay.OpenTime}
                    onChange={this.handleInputChange}                   
                    InputLabelProps={{
                        shrink: true
                    }}
                    inputProps={{
                        step: 300 // 5 min
                    }}
                        />
                    </div>
                        <div className='col-xs-3 col-md-3'>
                            
                <TextField
                    class={SelectDay === undefined ? 'hide' :
                            SelectDay.NotOpen ? 'hide' : 'show'}
                    id={this.props.DayId}
                    label="Closed"
                    type="time"
                    name='CloseTime'
                    value={SelectDay === undefined ? '' : SelectDay.CloseTime }
                    onChange={this.handleInputChange}                    
                    InputLabelProps={{
                        shrink: true
                    }}
                    inputProps={{
                        step: 300 // 5 min
                    }}
                />
                        </div>
            </div>
        );
    }
}
