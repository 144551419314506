import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { ColumnAndLabelRadioOptions } from './../SharedComponents/ColumnAndLabelRadioOptions';
import { ConfirmActionModal } from '../SharedComponents/ConfirmActionModal';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { PleaseWait } from './../SharedComponents/PleaseWait';
import { ReadonlyItem } from './../SharedComponents/ReadonlyItem';

import {
    MDBBtn,
    MDBIcon
} from "mdbreact";


const contactHomeUrl = '/Contacts/Home';

export class ContactView extends Component {

    displayName = ContactView.name

    constructor(props) {
        super(props);

        const auth = new AuthHelper();
        const deleting = this.getViewingPref(props);
        this.state = {
            CanEdit: auth.CanEditData,
            CompanyId: auth.CompanyId,
            ContactEmail: '',
            ContactId: props.match.params.id,
            ContactType: 0,
            ContactTypeList: [],
            ContactTypeName: '',
            FaxNumber: '',
            FirstName: '',
            HomeBranchId: 0,
            HomeBranchName: '',
            IsFensaAdmin: auth.IsFensaAdmin,
            LastName: '',
            MainContact: false,
            MiddleName: '',
            MobilePhone: '',
            PrimaryContact: false,
            Telephone: '',
            Title: '',
            UserStatus: 0,
            deleteActionMessage: '',
            isDeletePage: deleting,
            loading: true,
            modal: false,
            notificationPostfix: 'info hide',
            notificationMessage: [],
            pageTitle: deleting ? 'Delete Contact' : 'View Contact Details'
        };
        //console.log('ContactView.ctor', this.state);
        console.log(this.displayName, this.props);
    }

    componentDidMount() {
        this.fetchData();
    }

    getViewingPref = data => {
        const pathName = data.match.path;
        return (pathName !== undefined && pathName.toString().toLowerCase().indexOf('delete') > 0);
    }

    isDeletePage = () => {
        return this.state.isDeletePage;
    }

    currentPageTitle = () => {
        return this.state.pageTitle;
    }

    toggleModal = toggle => {
        this.setState({
            modal: toggle
        });
    }

    togglePleaseWait = status => {
        this.setState({
            loading: status
        });
    }

    submitForm = e => {
        e.preventDefault();

        if (!window.confirm('You are about to delete this item - ' + this.state.HomeBranchName + '. \nClick Ok to continue otherwise cancel to abort.')) {
            return false;
        }

        // Show please wait
        this.togglePleaseWait(true);

        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.DeleteContact",
            origin: "Installer Portal",
            operation: "DELETE",
            datavalues: JSON.stringify({
                ContactId: this.state.ContactId,
                CompanyId: this.state.CompanyId
            })
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                if (data.returncode === 1) {
                    // if successful then reload contact type list
                    this.props.history.push(contactHomeUrl);
                    return;
                }
                //tell the developer that delete failed
                //console.log(data.message);
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - Failed to complete delete']
                });
            })
            .catch(data => {
                console.log(data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - Failed to complete delete']
                });
            });
    }

    fetchData = () => {

        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetContactItem",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                ContactId: this.state.ContactId,
                CompanyId: this.state.CompanyId
            })
        });

        var fetchContact = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        });

        Promise.all([fetchContact])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                const data = JSON.parse(JSON.parse(responses[0]).message)[0];
                //console.log('fetchData', data);
                this.setState({
                    ContactEmail: data.Email,
                    ContactTypeId: data.ContactTypeId,
                    ContactTypeName: data.ContactTypeName,
                    FaxNumber: data.FaxNumber,
                    FirstName: data.FirstName,
                    HomeBranchId: data.BranchId,
                    HomeBranchName: data.BranchName,
                    LastName: data.LastName,
                    MainContact: data.MainContact,
                    MainContactText: data.MainContact === 1 ? 'Yes' : 'No',
                    MiddleName: '',
                    MobilePhone: data.MobilePhone,
                    PrimaryContact: data.PrimaryContact,
                    PrimaryContactText: data.PrimaryContact ? 'Yes' : 'No',
                    RoleId: data.RoleId,
                    RoleName: data.RoleName,
                    Telephone: data.Telephone,
                    Title: data.Title,
                    UserStatus: !data.UserStatus ? 1 : 0,
                    UserStatusText: !data.UserStatus ? 'Yes' : 'No',
                    deleteActionMessage: "You are about to delete " + data.Name + ". Click Ok to continue otherwise cancel to abort.",
                    loading: false
                });
            })
            .catch(data => {
                console.log('fetchData.error', data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['No Data']
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'Contact View',
            action: 'View Contact'
        });

        return (
            <div>

                <form className='demoForm' onSubmit={this.submitForm.bind(this)}>
                    <div className='form-group-container mr-auto ml-auto'>
                        <div className='form-group-title'>{this.currentPageTitle()}</div>
                        <div className='form-group'>
                            <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                            <div className='row pb-3'>
                                <div className='col-xs-12 col-md-4'>
                                    <ReadonlyItem textLabel="First Name">{this.state.FirstName}</ReadonlyItem>
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ReadonlyItem textLabel="Last Name">{this.state.LastName}</ReadonlyItem>
                                </div>
                            
                                <div className='col-xs-12 col-md-4'>
                                    <ReadonlyItem textLabel="Contact Email">{this.state.ContactEmail}</ReadonlyItem>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12 col-md-4'>
                                    <ReadonlyItem textLabel="Telephone">{this.state.Telephone}</ReadonlyItem>
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ReadonlyItem textLabel="Mobile Phone">{this.state.MobilePhone}</ReadonlyItem>
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ReadonlyItem textLabel="Fax Number">{this.state.FaxNumber}</ReadonlyItem>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12 col-md-4'>
                                    <ReadonlyItem textLabel="Contact Type">{this.state.ContactTypeName}</ReadonlyItem>
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ReadonlyItem textLabel="Role">{this.state.RoleName}</ReadonlyItem>
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ReadonlyItem textLabel="Located at Branch">{this.state.HomeBranchName}</ReadonlyItem>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12 col-md-4 py-3 text-muted'>
                                    <ColumnAndLabelRadioOptions
                                        id="PrimaryContact"
                                        name="PrimaryContact"
                                        textLabel="Show details on FENSA: "
                                        optionNo={'No'}
                                        optionYes={'Yes'}
                                        value={this.state.PrimaryContact}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="col-xs-12 col-md-4 py-3">
                                    <ColumnAndLabelRadioOptions
                                        id="UserStatus"
                                        name="UserStatus"
                                        textLabel="Is Active: "
                                        optionNo={'No'}
                                        optionYes={'Yes'}
                                        value={this.state.UserStatus}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='row pb-2'>
                                <div className='col'>
                                    <PleaseWait IsLoading={this.state.loading} />
                                    {
                                        this.isDeletePage() ?
                                            (
                                                <MDBBtn
                                                    className="pull-right mdb-btn-resize"
                                                    color="danger"
                                                    type="button"
                                                    size="sm"
                                                    onClick={this.toggleModal}
                                                >
                                                    <MDBIcon icon="trash" className="mr-2" />Delete
                                                </MDBBtn>
                                            ) : null
                                    }
                                    <Link className={this.state.CanEdit ? 'btn btn-sm btn-primary pull-right mdb-btn-resize' : 'hide'} to={'/Contact/Update/' + this.state.ContactId}>
                                        <i className="fa fa-edit pr-2" />Edit
                                    </Link>
                                    <Link className='btn btn-sm btn-dark pull-right mr-2 mdb-btn-resize' to={contactHomeUrl}>
                                        <i className='fa fa-arrow-left pr-2' />
                                        {this.isDeletePage() ? 'Cancel' : 'Back to List'}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ConfirmActionModal
                        ActionName="Delete"
                        ActionMessage={this.state.deleteActionMessage}
                        handleToggleAction={this.toggleModal}
                        handleModalAction={this.submitForm}
                        modalOpen={this.state.modal}
                    />
                </form>
            </div>
        );
    }
}
