import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import classNames from "classnames";
import PropTypes from "prop-types";

import "./Fensa.css";
import "./Site.css";

import AuthHelper from "./SharedComponents/AuthHelper";
import NavMenu from "./NavMenu/NavMenu";
import Actions from "./SharedComponents/Actions";
import User from "./SharedComponents/User";
import { Welcome } from "./SharedComponents/Welcome";
//import { User } from "./SharedComponents/User";
//import DebugInfo from './DebugInfo';

import CookieConsent from "react-cookie-consent";

import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Tooltip from "@material-ui/core/Tooltip";
import Tabs from "@material-ui/core/Tabs";


const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: "flex"
        // backgroundColor: "#6A214F"
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: "#6A214F"
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        // padding: "0 8px",
        // backgroundColor: "#6A214F",
        ...theme.mixins.toolbar
    },
    toolbarIconImg: {
        maxWidth: 85,
        marginRight: 24
    },
    sidebar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: "#696768"
    },
    sidebarIcon: {
        display: "flex",
        //alignItems: "center",
        justifyContent: "flex-end",
        backgroundColor: "#696768",
        padding: "10px 8px",
        // backgroundColor: "#6A214F",
        ...theme.mixins.toolbar
    },
    sidebarImg: {
        //maxWidth: 85,
        maxHeight: 80,
        paddingTop: 2,
        paddingRight: 8
        // marginRight: 24
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
        // backgroundColor: "#6A214F"
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36
    },
    menuButtonHidden: {
        display: "none"
    },
    title: {
        flexGrow: 1
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        backgroundColor: "#696768"
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing.unit * 8
        }
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: "100vh",
        overflow: "auto"
    },
    chartContainer: {
        marginLeft: -22
    },
    tableContainer: {
        height: 320
    },
    h5: {
        marginBottom: theme.spacing.unit * 2
    }
});

export class Layout extends Component {
    displayName = Layout.name;

    constructor(props) {
        super(props);
        const auth = new AuthHelper();
        this.state = {
            Authenticated: auth.IsAuthenticated,
            CanShowPortal: auth.CanShowPortal,
            CompanyName: auth.CompanyName,
            //CrumbMap: auth.CrumbMap,
            InConfirmAccountMode: auth.InConfirmAccountMode,
            InRegisterMode: auth.InRegisterMode,
            InSelectCompanyMode: auth.InSelectCompanyMode,
            InForgottenPwdMode: auth.InForgottenPwdMode,
            UserDisplayName: auth.UserDisplayName,
            UserEmail: auth.UserEmail,
            WithCompanyAccess: auth.AuthoriseAccountUser,
            open: true,
            //classes: this.props
            ...this.props
        };
        this.handleAuthenticated = this.handleAuthenticated.bind(this);
        //console.log(this.displayName + '.ctor', this.state);
        //console.log(this.displayName + '.ctor', this.props);

    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    handleAuthenticated(authenticated, CompanyName, UserEmail) {
        this.setState({
            Authenticated: authenticated,
            CompanyName: CompanyName,
            UserEmail: UserEmail
        });
    }

    handleAuthenticatedCallback = () => {
        // cause new authHelper to refresh login
        const auth = new AuthHelper();
        //console.log("Layout.handleAuthenticatedCallback: ", auth);
        this.setState({
            Authenticated: auth.IsAuthenticated,
            CanShowPortal: auth.CanShowPortal,
            CompanyName: auth.CompanyName,
            InConfirmAccountMode: auth.InConfirmAccountMode,
            InRegisterMode: auth.InRegisterMode,
            InForgottenPwdMode: auth.InForgottenPwdMode,
            InSelectCompanyMode: auth.InSelectCompanyMode,
            UserDisplayName: auth.UserDisplayName,
            UserEmail: auth.UserEmail
        });
    };

    handleStartConfirmAccountModeCallback = () => {
        const auth = new AuthHelper();
        //console.log(this.displayName + '.handleStartConfirmAccountModeCallback', auth);
        this.setState({
            Authenticated: auth.IsAuthenticated,
            CanShowPortal: auth.CanShowPortal,
            CompanyName: auth.CompanyName,
            InConfirmAccountMode: auth.InConfirmAccountMode,
            InRegisterMode: auth.InRegisterMode,
            InForgottenPwdMode: auth.InForgottenPwdMode,
            InSelectCompanyMode: auth.InSelectCompanyMode,
            UserEmail: auth.UserEmail
        });
        //console.log(this.displayName + '.handleStartConfirmAccountModeCallback', this.props);
    }

    handleStartRegisterModeCallback = () => {
        const auth = new AuthHelper();
        //console.log(this.displayName + '.handleStartRegisterModeCallback', auth);
        this.setState({
            Authenticated: auth.IsAuthenticated,
            CanShowPortal: auth.CanShowPortal,
            CompanyName: auth.CompanyName,
            InConfirmAccountMode: auth.InConfirmAccountMode,
            InRegisterMode: auth.InRegisterMode,
            InForgottenPwdMode: auth.InForgottenPwdMode,
            InSelectCompanyMode: auth.InSelectCompanyMode,
            UserEmail: auth.UserEmail
        });
        //console.log(this.displayName + '.handleStartRegisterModeCallback', this.props);
    }

    handleStartForgottenPwdModeCallback = () => {
        const auth = new AuthHelper();
        //console.log(this.displayName + '.handleStartRegisterModeCallback', auth);
        this.setState({
            Authenticated: auth.IsAuthenticated,
            CanShowPortal: auth.CanShowPortal,
            CompanyName: auth.CompanyName,
            InConfirmAccountMode: auth.InConfirmAccountMode,
            InRegisterMode: auth.InRegisterMode,
            InForgottenPwdMode: auth.InForgottenPwdMode,
            InSelectCompanyMode: auth.InSelectCompanyMode,
            UserEmail: auth.UserEmail
        });
        //console.log(this.displayName + '.handleStartRegisterModeCallback', this.props);
    }

    handleAbortClickCallback = () => {
        const auth = new AuthHelper();
        auth.LogOut();
        //console.log(this.displayName + '.handleAbortClickCallback', auth);
        this.setState({
            Authenticated: auth.IsAuthenticated,
            CanShowPortal: auth.CanShowPortal,
            CompanyName: auth.CompanyName,
            InConfirmAccountMode: auth.InConfirmAccountMode,
            InRegisterMode: auth.InRegisterMode,
            InForgottenPwdMode: auth.InForgottenPwdMode,
            InSelectCompanyMode: auth.InSelectCompanyMode,
            UserEmail: auth.UserEmail
        });
        //console.log(this.displayName + '.handleAbortClickCallback', this.props);
    }

    render() {
        const classes = this.state.classes;
        //const { classes } = this.props;
        //console.log(this.displayName + '.render', classes);
        return (
            <Fragment>
                {
                    this.state.Authenticated && this.state.CanShowPortal ?
                        (
                            <Fragment>
                                <div className={classes.root}>
                                    <CssBaseline />
                                    <AppBar
                                        position="absolute"
                                        className={classNames(classes.appBar, this.state.open && classes.appBarShift)}>
                                        <Toolbar
                                            disableGutters={!this.state.open}
                                            className={classes.toolbar}>
                                            <IconButton
                                                color="inherit"
                                                aria-label="Open drawer"
                                                onClick={this.handleDrawerOpen}
                                                className={classNames(classes.menuButton, this.state.open && classes.menuButtonHidden)}>
                                                <ChevronRightIcon />
                                            </IconButton>
                                            <Typography
                                                component="h1"
                                                variant="h6"
                                                color="inherit"
                                                noWrap
                                                className={classNames(classes.title)}>
                                                <Link to={"/"}>
                                                    <img
                                                        alt={"FENSA"}
                                                        src={require("./images/fensa_logo.PNG")}
                                                        className={classNames(classes.sidebarImg, this.state.open && classes.menuButtonHidden)} />
                                                </Link>
                                                <span className={!this.state.open ? "pl-3" : ""}>{this.state.CompanyName}</span>
                                            </Typography>
                                            {/* Add knowledge hub button */}
                                            <Tooltip title="FENSA Knowledge Hub" placement="bottom-end">
                                                <Link className='hide' to={"//www.fensaknowledgehub.co.uk"} target={"_blank"}>
                                                    <img
                                                        src={require("./images/fkh-logo.png")}
                                                        alt={"FENSA Knowledge Hub"}
                                                        className={classNames(classes.toolbarIconImg, "d-none d-md-block")} />
                                                </Link>
                                            </Tooltip>
                                            {/* Render installer actions and avartar icons here */}
                                            <Actions />
                                            <User
                                                Authenticated={this.state.Authenticated}
                                                CompanyName={this.state.CompanyName}
                                                UserEmail={this.state.UserEmail}
                                                handleAuthenticated={this.handleAuthenticated}
                                            />
                                        </Toolbar>
                                    </AppBar>
                                    {/* DRAWER AREA */}
                                    <Drawer
                                        variant="permanent"
                                        classes={{ paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose) }}
                                        open={this.state.open}>
                                        <div className={classes.sidebarIcon}>
                                            <Link to={"/"}>
                                                <img alt={"FENSA Sandbox"} src={require("./images/fensa_logo.PNG")} className={classNames(classes.sidebarImg)} />
                                            </Link>
                                            <IconButton onClick={this.handleDrawerClose}>
                                                <ChevronLeftIcon className={"text-light"} />
                                            </IconButton>
                                        </div>
                                        {/* NAV MENU AREA */}
                                        <NavMenu />
                                    </Drawer>
                                    <main className={classes.content}>
                                        <div className={classes.appBarSpacer} />
                                        <MDBContainer className='fluid-container'>
                                            {this.props.children}
                                        </MDBContainer>
                                    </main>
                                </div>
                            </Fragment>
                        ) : (
                            <MDBContainer>
                                <MDBRow className="sign-in-container">
                                    <MDBCol md="2" />
                                    <MDBCol md="8">
                                        <Welcome
                                            handleAuthenticated={this.handleAuthenticatedCallback}
                                            handleConfirmAccountMode={this.handleStartConfirmAccountModeCallback}
                                            handleStartRegisterMode={this.handleStartRegisterModeCallback}
                                            handleStartForgottenPwdMode={this.handleStartForgottenPwdModeCallback}
                                            handleAbortClick={this.handleAbortClickCallback}
                                        />
                                    </MDBCol>
                                    <MDBCol md="2" />
                                </MDBRow>
                            </MDBContainer>
                        )
                }
                <CookieConsent
                    location="bottom"
                    buttonText="I Understand"
                    cookieName="myAwesomeCookieName2"
                    style={{ background: "#69214f" }}
                    buttonStyle={{ background: "#21bcee", color: "#fff", fontSize: "13px" }}
                    expires={150}
                >
                    This website uses cookies to enhance the user experience.{" "}
                    <span style={{ fontSize: "10px" }}>
                        <a href="https://www.fensa.org.uk/cookies" target="_blank" style={{ color: "#fff" }}>Read More</a>
                    </span>
                </CookieConsent>
            </Fragment>
            
        );
    }
}

Layout.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Layout);