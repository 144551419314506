import React from 'react';

export const ButtonsJss = {
    ButtonStyles:
    {
        texticon: 'TextAndIcon',
        text: 'TextOnly',
        icon: 'IconOnly'
    },
    DefaultDeleteCss: 'btn btn-danger btn-sm',
    DefaultEditCss: 'btn btn-primary btn-sm',
    DefaultViewCss: 'btn btn-secondary btn-sm',
    DefaultDeleteTextCss: 'text-danger',
    DefaultEditTextCss: 'text-primary',
    DefaultViewTextCss: 'text-secondary'
};