import React, { Component } from 'react';
import SubMenuVideo  from './SubMenuVideos';
import './Popup.css';
export default class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Video:false,
      ShowPopup:true,
      ShowButton: false
        }
    }
    ShowVideo(event){this.setState({
      
      Video:!this.state.Video,
      ShowPopup:false,
      ShowButton:false
     
        })}
        CloseVideo(event) {this.setState({
          ShowPopup:false

      })}
  
  render() {
    const {title,path,BenefitsVideoLink}=this.props
    //console.log('video',this.state.ShowButton)
    return (
      <>
     
      { 
        this.state.ShowPopup && <div className='popup' onClick={()=>this.CloseVideo()}>            
          <div className='popup_inner' >
          <a class="close">
          <i class="fa fa-window-close" aria-hidden="true"></i>
          </a> 
          <br></br>          
           <p>{title} </p>     
           <br></br>         
         
         

         <button className="btn circle action_btn ml-2"  onClick={()=> window.open(path, "_blank")}> Registration</button>
         <button className="btnweb circle ml-50"  onClick={()=> window.open(path.replace('register','escrowrequest'), "_blank")}> <span className='text-nowrap' >Request An Escrow</span></button>
         
         
         
          
           
              
          
          
          
          </div> 
          
        </div>
     }
      </>
    );
  }
}