import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator';
import uuid from "uuid";
import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { ColumnAndLabel } from '../SharedComponents/ColumnAndLabel';
import { ColumnAndLabelRadioOptions } from './../SharedComponents/ColumnAndLabelRadioOptions';
import { ColumnAndLabelSelect } from './../SharedComponents/ColumnAndLabelSelect';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { PleaseWait } from './../SharedComponents/PleaseWait';
import { ReadonlyItem } from './../SharedComponents/ReadonlyItem';

import {
    MDBBtn,
    MDBIcon
} from "mdbreact";

const userHomeUrl = "/UserManagement/Users";
const userViewUrl = "/UserManagement/User/View/";
const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;
export class UserEdit extends Component {
    displayName = "UserEdit";

    constructor(props) {
        super(props);

        const auth = new AuthHelper();
        const newUser = props.match.params.id === '-1' || props.match.params.id === undefined;
        this.state = {
            Activated: 0,
            CanEdit: auth.CanEditData,
            CompanyId: auth.CompanyId,
            CompanyReference: auth.CompanyReference,
            CompanyUserStatus: newUser ? 1 : 0,
            ContactType: 0,
            ContactTypeList: [],
            CreatedBy: auth.Username,
            CreatedById: auth.UserId,
            Deleted: false,
            DuplicateUserName: false,
            ExistingAccount: true,
            FaxNumber: '',
            FirstName: '',
            GlobalUserStatus: newUser ? 1 : 0,
            IsFensaAdmin: auth.IsFensaAdmin,
            JobTypeId: -1,
            LastName: '',
            MainAuthUser: false,
            MiddleName: '',
            MobilePhone: '',
            ResetPwd: newUser ?true:false,
            RoleId: 0,
            RoleList: [],
            Telephone: '',
            Title: '',
            UserEmail: '',
            UserId: props.match.params.id,
            UserName: '',

            existingUser: false,
            formErrors: {
                FirstName: newUser ? "First name is required" : "",
                JobTypeId: newUser ? "Job title selection is required." : "",
                LastName: newUser ? "Last name is required" : "",
                RoleId: newUser ? "Role selection is required." : "",
                Telephone: newUser ? "Telephone number is required." : "",
                UserEmail: newUser ? "Email address is required" : "",
                UserName: newUser ? "Username is required" : ""
            },
            formValid: true,
            isNewUser: newUser,
            loading: !newUser,
            loadingRoles: true,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 0,
            pageTitle: newUser ? 'Create User' : 'Edit User',
            processingSubmit: false,
            registrationComplete: false,
            //if not creating a new user, then the username is already known
            validatedUsername: newUser ? false : true
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        console.log(this.state);
    }

    componentDidMount() {
        if (this.state.CanEdit) {
            this.fetchData();
        } else if (!this.state.isNewUser) {
            //redirect to readonly view
            this.props.history.push(userViewUrl + this.state.UserId);
        } else {
            this.props.history.push(userHomeUrl);
        }
    }

    currentPageTitle = () => {
        return this.state.pageTitle;
    }

    togglePleaseWait = status => {
        //console.log('togglePleaseWait.status: ', status);
        this.setState({
            loading: status
        });
    }

    handleDuplicateUserNameCallback = cbValue => {
        let fieldValidationErrors = this.state.formErrors;
        fieldValidationErrors.UserEmail = cbValue ? '' : 'Email address is required';
        var userName = this.state.UserName;
        this.setState({
            DuplicateUserName: cbValue ? 1 : 0,
            UserEmail: cbValue ? userName : '',
            formErrors: fieldValidationErrors
        });
    }

    handleCompanyUserStatusCallback = cbValue => {
        this.setState({
            CompanyUserStatus: cbValue ? true : false
        });
    }

    handleGlobalUserStatusCallback = cbValue => {
        this.setState({
            GlobalUserStatus: cbValue ? true : false
        });
    }

    handleValidateUsernameCallback = e => {

        e.preventDefault();

        // Show please wait
        this.togglePleaseWait(true);

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/VerifyUsername', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.VerifyUsername",
                origin: "Installer Portal",
                operation: "INSERT",
                validate: false,
                datavalues: JSON.stringify({
                    UserName: this.state.UserName
                })
            })
        })
            .then(response => response.json())
            .then(data => {
                console.log(this.displayName + '.handleValidateUsernameCallback', data);
                console.log(this.displayName + '.handleValidateUsernameCallback[data.returncode]', data.returncode);
                if (data.returncode === 1) {
                    var msg = JSON.parse(data.message)[0];
                    console.log(this.displayName + '.handleValidateUsernameCallback[msg]', msg);
                    let fieldValidationErrors = this.state.formErrors;
                    fieldValidationErrors.FirstName = "";
                    fieldValidationErrors.UserName = "";
                    fieldValidationErrors.LastName = "";
                    this.setState({
                        FirstName: msg.FirstName,
                        LastName: msg.LastName,
                        existingUser: true,
                        formErrors: fieldValidationErrors,
                        loading: false,
                        notificationPostfix: 'success hide mt-3',
                        notificationMessage: [],
                        validatedUsername: true
                    });
                } else {
                    this.setState({
                        existingUser: false,
                        loading: false,
                        notificationPostfix: 'danger hide mt-3',
                        notificationMessage: [],
                        validatedUsername: true
                    });
                }
            })
            .catch(idUser => {
                if (debugOn) {
                    console.log(this.displayName + '.submitForm[catch->createId]', idUser);
                }
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show mt-3',
                    notificationMessage: ['Bad Request - Failed to complete request or service is unavailable.', idUser.message],
                    validatedUsername: false
                });
            });
    }

    handleMainAuthUserCallback = cbValue => {
        this.setState({
            MainAuthUser: cbValue ? true : false
        });
        //console.log(this.displayName + '.handleMainContactCallback: ', cbValue, this.state);
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;

        if (value === undefined) {
            value = target.parentElement.value;
        }
        const name = target.name;
        //this state is only relevant locally - so no need for put it in redux.
        this.setState(
            {
                [name]: value
            },
            () => { this.validateField(name, value); }
        );
        //console.log(this.displayName + '.handleInputChange', name, value);
    }

    handleOnInputValidation = event => {

        let fieldValidationErrors = this.state.formErrors;
        let message = "";
        switch (event.target.name.toLowerCase()) {
            case 'firstname':
                fieldValidationErrors.FirstName = validator.isEmpty(event.target.value) ? 'First name is required.' : '';
                if (fieldValidationErrors.FirstName === "") {
                    fieldValidationErrors.FirstName = validator.isLength(event.target.value, { min: 2 }) ? '' : 'First name must be more than 1 character.';
                }
                message = fieldValidationErrors.FirstName;
                break;

            case 'lastname':
                fieldValidationErrors.LastName = validator.isEmpty(event.target.value) ? 'Last name is required.' : '';
                if (fieldValidationErrors.LastName === "") {
                    fieldValidationErrors.LastName = validator.isLength(event.target.value, { min: 2 }) ? '' : 'Last name must be more than 1 character.';
                }
                message = fieldValidationErrors.LastName;
                break;

            case 'telephone':
                fieldValidationErrors.Telephone = validator.isEmpty(event.target.value) ? 'Telephone is required.' : '';
                if (fieldValidationErrors.Telephone === "") {
                    fieldValidationErrors.Telephone = validator.isNumeric(event.target.value) ? '' : 'Telephone can only contain numbers.';
                }
                message = fieldValidationErrors.Telephone;
                break;

            case 'useremail':
                fieldValidationErrors.UserEmail = validator.isEmpty(event.target.value) ? 'Email address is Required.' : '';
                if (fieldValidationErrors.UserEmail !== "") {
                    fieldValidationErrors.UserEmail = validator.isEmail(event.target.value) ? '' : 'Email address is not in a valid format.';
                }
                message = fieldValidationErrors.UserEmail;
                break;

            case 'username':
                fieldValidationErrors.UserName = validator.isEmpty(event.target.value) ? 'Username is Required.' : '';
                if (fieldValidationErrors.UserName === "") {
                    fieldValidationErrors.UserName = validator.isEmail(event.target.value) ? '' : 'Username is not in a valid format.';
                }
                message = fieldValidationErrors.UserName;
                break;

            default:
                break;
        }

        if (message !== "") {
            this.setState({
                formErrors: fieldValidationErrors
            }, this.validateForm);
            //set custom validation error
            event.target.setCustomValidity(message);
        } else {
            //clear custom validation errors
            event.target.setCustomValidity("");
        }
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        switch (fieldName.toLowerCase()) {
            case 'username':
                fieldValidationErrors.UserName = validator.isEmpty(this.state.UserName) ? 'Username is required.' : '';
                fieldValidationErrors.UserName = !validator.isEmail(this.state.UserName) ? 'Invalid email address detected.' : '';
                break;
            case 'firstname':
                fieldValidationErrors.FirstName = validator.isEmpty(this.state.FirstName) ? 'First name is required.' : '';
                fieldValidationErrors.FirstName = validator.isLength(this.state.FirstName, { min: 2 }) ? '' : 'First name must be more than 1 character.';
                break;
            case 'lastname':
                fieldValidationErrors.LastName = validator.isEmpty(this.state.LastName) ? 'Last name is required.' : '';
                fieldValidationErrors.LastName = validator.isLength(this.state.LastName, { min: 2 }) ? '' : 'Last name must be more than 1 character.';
                break;
            case 'emailaddress':
                fieldValidationErrors.EmailAddress = validator.isEmpty(this.state.EmailAddress) ? 'Email address is required.' : '';
                fieldValidationErrors.EmailAddress = !validator.isEmail(this.state.EmailAddress) ? 'Invalid email address.' : '';
                break;

            case 'telephone':
                fieldValidationErrors.Telephone = validator.isEmpty(this.state.Telephone) ? 'Telephone number is required.' : '';
                fieldValidationErrors.Telephone = validator.isLength(this.state.Telephone, { min: 10 }) ? '' : 'Telephone number must be more than 10 characters.';
                break;

            case 'jobtypeid':
                fieldValidationErrors.JobTypeId = validator.isNumeric(this.state.JobTypeId) && parseInt(this.state.JobTypeId, 10) > 0 ? '' : 'Job title selection is required.';
                break;

            case 'roleid':
                fieldValidationErrors.RoleId = validator.isNumeric(this.state.RoleId) && parseInt(this.state.RoleId, 10) > 0 ? '' : 'Role selection is required.';
                break;

            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid: this.state.formErrors
                && this.state.formErrors.UserName.length === 0
                && this.state.formErrors.FirstName.length === 0
                && this.state.formErrors.LastName.length === 0
                && this.state.formErrors.UserEmail.length === 0
                && this.state.formErrors.Telephone.length === 0
                && this.state.formErrors.JobTypeId.length === 0
                && this.state.formErrors.RoleId.length === 0
        });
        return this.state.formValid;
    }

    submitForm = e => {
        e.preventDefault();
        if (this.validateForm()) {

            this.togglePleaseWait(true);

            var datavalues;
            if (this.state.isNewUser) {
                datavalues = JSON.stringify({
                    Activated: 1,
                    CompanyId: this.state.CompanyId,
                    CompanyUserEmail: this.state.UserEmail,
                    CompanyUserStatus: true,
                    CreatedBy: this.state.CreatedBy,
                    CreatedById: this.state.CreatedById,
                    Deleted: this.state.Deleted,
                    Email: this.state.UserEmail,
                    ExistingAccount: false,
                    FensaNumber: this.state.CompanyReference,
                    FirstName: this.state.FirstName,
                    GlobalUserStatus: true,
                    JobTypeId: this.state.JobTypeId,
                    LastName: this.state.LastName,
                    MainAuthUser: this.state.MainAuthUser,
                    Mobile: this.state.Mobile,
                    ResetPwd: true,
                    RoleId: this.state.RoleId,
                    Telephone: this.state.Telephone,
                    UserEmail: this.state.UserEmail,
                    UserId: 0,
                    UserName: this.state.UserName
                });
            }
            else {
                datavalues = JSON.stringify({
                    Activated: this.state.Activated,
                    CompanyId: this.state.CompanyId,
                    CompanyUserEmail: this.state.UserEmail,
                    CompanyUserStatus: this.state.CompanyUserStatus,
                    ContactType: this.state.ContactType,
                    CreatedBy: this.state.CreatedBy,
                    CreatedById: this.state.CreatedById,
                    Deleted: this.state.Deleted,
                    Email: this.state.UserEmail,
                    ExistingAccount: this.state.ExistingAccount,
                    FensaNumber: this.state.CompanyReference,
                    FirstName: this.state.FirstName,
                    GlobalUserStatus: this.state.GlobalUserStatus,
                    JobTypeId: this.state.JobTypeId,
                    LastName: this.state.LastName,
                    MainAuthUser: this.state.MainAuthUser,
                    Mobile: this.state.Mobile,
                    ResetPwd: this.state.ResetPwd,
                    RoleId: this.state.RoleId,
                    Telephone: this.state.Telephone,
                    UserEmail: this.state.UserEmail,
                    UserId: this.state.UserId,
                    UserName: this.state.UserName
                });
            }
            //console.log(this.displayName + '.Submitt[datavalues]', datavalues);
            fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/UpdateUser', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    seqno: uuid.v4(),
                    datatype: "BIT.Fensa.InstallerPortal.CreateUser",
                    origin: "Installer Portal",
                    operation: "INSERT",
                    datavalues: datavalues
                })
            })
                .then(response => response.json())
                //.then(data => JSON.parse(data))
                .then(data => {
                    console.log(this.displayName + '.fetchData[data]', data);
                    if (data.returncode === 1) {
                        this.setState({
                            Result: data.message,
                            loading: false,
                            notificationPostfix: 'success show',
                            notificationMessage: [data.message]
                        });
                        this.props.history.push(userHomeUrl);
                    } else {
                        this.setState({
                            Result: data.message,
                            loading: false,
                            notificationPostfix: 'danger show',
                            notificationMessage: [data.message]
                        });
                    }
                })
                .catch(data => {
                    console.log('ERR: submit_callback', data);
                    this.setState({
                        loading: false,
                        notificationPostfix: 'danger show',
                        notificationMessage: debugOn ? [data.message] : ['Bad Request - No data or service is unavailable']
                    });
                });
        }
    }

    fetchRoles = () => {

        //Roles
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.Roles",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyId
                })
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                //if (debugOn) {
                //    console.log(this.displayName + '.fetchRoles[responses]', responses);
                //}
                if (data.returncode === 1) {
                    const roleData = JSON.parse(data.ReturnData.Payload);
                    //if (debugOn) {
                    console.log(this.displayName + '.fetchRoles[data]', data);
                    //console.log(this.displayName + '.json.Payload', JSON.parse(data.ReturnData.Payload));
                    //console.log(this.displayName + '.roleData', roleData);
                    //}
                    if (roleData !== null) {
                        this.setState({
                            RoleList: roleData,
                            loadingRoles: false
                        });
                    } else {
                        this.setState({
                            RoleList: [],
                            loadingRoles: false
                        });
                    }
                } else {
                    this.setState({
                        RoleList: [],
                        loadingRoles: false
                    });
                }
            })
            .catch(data => {
                if (debugOn) {
                    console.log(this.displayName + '.fetchRoles.[role].error', data);
                }
                this.setState({
                    RoleList: [],
                    loadingRoles: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['No Data']
                });
            });
    }

    fetchContactTypes = () => {

        //ContactType (Job Titles)
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.ContactType",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyId
                })
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                //if (debugOn) {
                //    console.log(this.displayName + '.fetchContactTypes[responses]', responses);
                //}
                if (data.returncode === 1) {
                    const contactTypeData = JSON.parse(data.ReturnData.Payload);
                    //if (debugOn) {
                    console.log(this.displayName + '.fetchContactTypes[data]', data);
                    //console.log(this.displayName + '.json.Payload', JSON.parse(data.ReturnData.Payload));
                    //console.log(this.displayName + '.contactTypeData', contactTypeData);
                    //}
                    if (contactTypeData !== null) {
                        this.setState({
                            ContactTypeList: contactTypeData,
                            loadingContactTypes: false
                        });
                    } else {
                        this.setState({
                            ContactTypeList: [],
                            loadingContactTypes: false
                        });
                    }
                } else {
                    this.setState({
                        ContactTypeList: [],
                        loadingContactTypes: false
                    });
                }
            })
            .catch(data => {
                if (debugOn) {
                    console.log(this.displayName + '.fetchContactTypes.[contactType].error', data);
                }
                this.setState({
                    //loading: false,
                    ContactTypeList: [],
                    loadingContactTypes: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - No Content Types']
                });
            });
    }

    fetchContactTypesAndRoles = () => {
        this.fetchRoles();
        this.fetchContactTypes();
    }

    fetchData = () => {

        this.fetchContactTypesAndRoles();

        if (this.state.isNewUser) {
            return;
        }

        this.togglePleaseWait(true);

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.InstallerGetInstallerCompanyUser",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    UserId: this.state.UserId,
                    CompanyId: this.state.CompanyId
                })
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(responseData => {
                console.log(this.displayName + '.fetchData[responses]', responseData);
                const data = JSON.parse(responseData.ReturnData.Payload)[0];
                console.log(this.displayName + '.fetchData[data]', data);
                if (responseData.returncode === 1) {
                    this.setState({
                        Activated: data.Activated,
                        CompanyUserEmail: data.CompanyUserEmail,
                        CompanyUserStatus: data.CompanyUserStatus,
                        Deleted: data.Deleted,
                        ExistingAccount: data.ExistingAccount,
                        FirstName: data.FirstName,
                        GlobalUserStatus: data.GlobalUserStatus,
                        JobTypeId: data.JobTypeId,
                        LastName: data.LastName,
                        MainAuthUser: data.MainAuthUser === 0 || !data.MainAuthUser ? false : true,
                        Mobile: data.MobilePhone,
                        ResetPwd: data.ResetPwd,
                        RoleId: data.RoleId,
                        Telephone: data.Telephone,
                        UserEmail: data.CompanyUserEmail,
                        UserName: data.UserName,
                        VerificationCode: data.VerificationCode,

                        deleteActionMessage: "You are about to delete " + data.Name + ". Click Ok to continue otherwise cancel to abort.",
                        loading: false,
                        notificationPostfix: 'info hide',
                        notificationMessage: [''],
                        processingSubmit: false
                    });
                } else {
                    this.setState({
                        loading: false,
                        notificationPostfix: 'danger show',
                        notificationMessage: [data.message],
                        processingSubmit: false
                    });
                }
            })
            .catch(data => {
                if (debugOn) {
                    console.log(this.displayName + '.fetchData[err]', data);
                }
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: debugOn ? [data.message] : ['Bad Request - No data or service is unavailable'],
                    processingSubmit: false
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'Edit User',
            action: 'Edit User View'
        });

        return (
            <div>
                <form className='demoForm was-validated' onSubmit={this.submitForm.bind(this)} noValidate>
                    <div className='form-group-container'>
                        <div className='h3 h3-responsive form-group-title'>{this.currentPageTitle()}</div>
                        <PleaseWait IsLoading={this.state.loading} />
                        {!this.state.validatedUsername ?
                            (
                                <Fragment>
                                    <div className='form-group'>
                                        <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                                        <div className="grey-text pt-5">
                                            <h5>Validate username</h5>
                                            <div className='row'>
                                                <div className='col-xs-12 col-md-6'>
                                                    <ColumnAndLabel
                                                        ErrorMessage={this.state.formErrors.UserName}
                                                        IconLabel="user"
                                                        Id="UserName"
                                                        InputType="text"
                                                        IsRequired={true}
                                                        SizeCss="md"
                                                        HintLabel="name@domain.com"
                                                        TextLabel="Username"
                                                        Validate={true}
                                                        Value={this.state.UserName}
                                                        HandleInputChange={this.handleInputChange}
                                                        HandleOnInput={this.handleOnInputValidation}
                                                    />
                                                </div>
                                                <div className='col-xs-6 col-md-1'>
                                                    <MDBBtn
                                                        className="pull-right mdb-btn-resize"
                                                        color="primary"
                                                        type="submit"
                                                        size="sm"
                                                        floating
                                                        onClick={this.handleValidateUsernameCallback}
                                                        disabled={this.state.formErrors && this.state.formErrors.UserName.length !== 0}
                                                    >
                                                        <MDBIcon icon="check" className="mr-2" /></MDBBtn>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <div className='form-group'>
                                        <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                                        <div className='row'>
                                            <div className='col-xs-12 col-md-6'>
                                                <ReadonlyItem textLabel="Username">{this.state.UserName}</ReadonlyItem>
                                                {
                                                    this.state.isNewUser ? (
                                                        <ColumnAndLabelRadioOptions
                                                            id="DuplicateUserName"
                                                            name="DuplicateUserName"
                                                            textLabel="Copy to Email"
                                                            optionNo={'No'}
                                                            optionYes={'Yes'}
                                                            value={this.state.DuplicateUserName}
                                                            handleChange={this.handleDuplicateUserNameCallback} />
                                                    ) : ('')
                                                }
                                            </div>
                                            <div className={this.state.ResetPwd ? 'col-xs-12 col-md-6 pt-4 text-danger py-3' : 'hide'}>
                                                <ColumnAndLabelRadioOptions
                                                    id="ResetPwd"
                                                    name="ResetPwd"
                                                    textLabel="Account Locked: "
                                                    optionNo={'No'}
                                                    optionYes={'User must reset password'}
                                                    value={this.state.ResetPwd}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-xs-12 col-md-6'>
                                                {
                                                    !this.state.existingUser ?
                                                        (
                                                            <ColumnAndLabel
                                                                ErrorMessage={this.state.formErrors.FirstName}
                                                                IconLabel=""
                                                                Id="FirstName"
                                                                InputType="text"
                                                                IsRequired={true}
                                                                SizeCss="sm"
                                                                HintLabel=""
                                                                TextLabel="First Name"
                                                                Validate={true}
                                                                Value={this.state.FirstName}
                                                                HandleInputChange={this.handleInputChange}
                                                                HandleOnInput={this.handleOnInputValidation}
                                                            />
                                                        ) :
                                                        (
                                                            <ReadonlyItem textLabel="First Name">{this.state.FirstName}</ReadonlyItem>
                                                        )
                                                }
                                            </div>
                                            <div className='col-xs-12 col-md-6'>
                                                {
                                                    !this.state.existingUser ?
                                                        (
                                                            <ColumnAndLabel
                                                                ErrorMessage={this.state.formErrors.LastName}
                                                                IconLabel=""
                                                                Id="LastName"
                                                                InputType="text"
                                                                IsRequired={true}
                                                                SizeCss="sm"
                                                                HintLabel=""
                                                                TextLabel="Last Name"
                                                                Validate={true}
                                                                Value={this.state.LastName}
                                                                HandleInputChange={this.handleInputChange}
                                                                HandleOnInput={this.handleOnInputValidation}
                                                            />
                                                        ) :
                                                        (
                                                            <ReadonlyItem textLabel="Last Name">{this.state.LastName}</ReadonlyItem>
                                                        )
                                                }
                                            </div>
                                            <div className='col-xs-12 col-md-4'>
                                                <ColumnAndLabel
                                                    ErrorMessage={this.state.formErrors.UserEmail}
                                                    IconLabel=""
                                                    Id="UserEmail"
                                                    InputType="text"
                                                    IsRequired={true}
                                                    SizeCss="sm"
                                                    HintLabel=""
                                                    TextLabel="Email"
                                                    Validate={true}
                                                    Value={this.state.UserEmail}
                                                    HandleInputChange={this.handleInputChange}
                                                    HandleOnInput={this.handleOnInputValidation}
                                                />
                                            </div>
                                            <div className='col-xs-12 col-md-4'>
                                                <ColumnAndLabel
                                                    ErrorMessage={this.state.formErrors.Telephone}
                                                    IconLabel=""
                                                    Id="Telephone"
                                                    InputType="text"
                                                    IsRequired={true}
                                                    SizeCss="sm"
                                                    HintLabel=""
                                                    TextLabel="Telephone"
                                                    Validate={true}
                                                    Value={this.state.Telephone}
                                                    HandleInputChange={this.handleInputChange}
                                                    HandleOnInput={this.handleOnInputValidation}
                                                />
                                            </div>
                                            <div className='col-xs-12 col-md-4'>
                                                <ColumnAndLabel
                                                    ErrorMessage=""
                                                    IconLabel=""
                                                    Id="Mobile"
                                                    InputType="text"
                                                    IsRequired={false}
                                                    SizeCss="sm"
                                                    HintLabel=""
                                                    TextLabel="Mobile Phone"
                                                    Validate={true}
                                                    Value={this.state.Mobile}
                                                    HandleInputChange={this.handleInputChange}
                                                //HandleOnInput={this.handleOnInputValidation}
                                                />
                                            </div>
                                            <div className='col-xs-12 col-md-6'>
                                                <ColumnAndLabelSelect
                                                    id="JobTypeId"
                                                    name="JobTypeId"
                                                    textLabel="Job Title"
                                                    value={this.state.JobTypeId}
                                                    handleChange={this.handleInputChange}
                                                    optionIdField="ID"
                                                    optionTextField="Name"
                                                    optionsPayload={this.state.JobTypeId}
                                                >
                                                    {
                                                        this.state.ContactTypeList.map(contactType =>
                                                            <option value={contactType.ID} key={contactType.ID}>{contactType.Name}</option>)
                                                    }
                                                </ColumnAndLabelSelect>
                                            </div>
                                            <div className='col-xs-12 col-md-6'>
                                                <ColumnAndLabelSelect
                                                    id="RoleId"
                                                    name="RoleId"
                                                    textLabel="Role"
                                                    value={this.state.RoleId}
                                                    handleChange={this.handleInputChange}
                                                    optionIdField="Id"
                                                    optionTextField="Name"
                                                    optionsPayload={this.state.RoleList}
                                                    prompt="Select Role"
                                                >
                                                    <option value={0}>Select Role</option>
                                                    {
                                                        this.state.RoleList.map(role =>
                                                            <option value={role.Id} key={role.Id}>{role.Name}</option>)
                                                    }
                                                </ColumnAndLabelSelect>
                                            </div>
                                            <div className={this.state.IsFensaAdmin ? 'col-xs-12 col-md-12 pt-4' : 'hide'}>
                                                <ColumnAndLabelRadioOptions
                                                    id="MainAuthUser"
                                                    name="MainAuthUser"
                                                    textLabel="Main Authorised User"
                                                    optionNo={'No'}
                                                    optionYes={'Yes'}
                                                    value={this.state.MainAuthUser}
                                                    handleChange={this.handleMainAuthUserCallback} />
                                            </div>
                                            <div className='col-xs-12 col-md-6 py-3'>
                                                <ColumnAndLabelRadioOptions
                                                    id="CompanyUserStatus"
                                                    name="CompanyUserStatus"
                                                    textLabel="Status"
                                                    optionNo={'Deactivated'}
                                                    optionYes={'Active'}
                                                    value={this.state.CompanyUserStatus}
                                                    handleChange={this.handleCompanyUserStatusCallback}
                                                />
                                            </div>
                                            <div className={this.state.IsFensaAdmin ? 'col-xs-12 col-md-6 py-3' : 'hide'}>
                                                <ColumnAndLabelRadioOptions
                                                    id="GlobalUserStatus"
                                                    name="GlobalUserStatus"
                                                    textLabel="Global Status"
                                                    optionNo={'Deactivated'}
                                                    optionYes={'Active'}
                                                    value={this.state.GlobalUserStatus}
                                                    handleChange={this.handleGlobalUserStatusCallback} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row pb-2'>
                                        <div className='col text-right'>
                                            <PleaseWait IsLoading={this.state.loading} />
                                            <MDBBtn
                                                className="pull-right mdb-btn-resize"
                                                color="success"
                                                type="submit"
                                                size="sm"
                                                disabled={!this.state.formValid || this.state.processingSubmit}
                                                onClick={this.toggleModal}
                                            >
                                                <MDBIcon icon="save" className="mr-2" />Submit</MDBBtn>
                                            <Link className='btn btn-sm btn-danger pull-right mr-2 mdb-btn-resize' to={userHomeUrl}>
                                                <i className='fa fa-arrow-left pr-2' />Cancel</Link>
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        }
                    </div>
                </form>
            </div>
        );
    }
}

/// Handle data retrieval and handle ensuing errors if data is not found
UserEdit.prototype.extractData = response => {
    try {
        return JSON.parse(response).returncode === 1 ? JSON.parse(JSON.parse(response).message) : null;
    } catch (e) {
        console.log(e);
        return null;
    }
};
