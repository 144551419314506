export const AuthResponseType = {
    CredentialViolation:  '0',
    AccountDeleted: '1',
    AccountDisabled: '2',
    DefaultUserCompany: '3',
    InvalidCompanyData: '4',
    MissingAccount: '5',
    MissingCompanyData: '6',
    MissingRole: '7',
    MissingPermission: '8',
    PendingPasswordReset: '9',
    PendingVerification: '10',
    SignInSuccess: '11'
};
