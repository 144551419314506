import { combineReducers } from "redux";

import applicationerror from './applicationErrors';
import branch from "./branch";
import InstallerWork from './installerWork';
import applicationinformation from './applicationInformation';

export default combineReducers({
  //navigation: navigation,
    applicationerror: applicationerror,
    applicationinformation: applicationinformation,
    branch: branch,
    InstallerWork: InstallerWork
});
