import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

import uuid from "uuid";

import './NavMenu.css';

import { PleaseWait } from '../SharedComponents/PleaseWait';

import MenuItem from "@material-ui/core/MenuItem";
import MenuList from '@material-ui/core/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


const menuStyles = theme => ({
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& $primary, & $icon': {
                color: theme.palette.common.white
            }
        }
        //width: 300
        //maxWidth: 25,
    },
    primary: {
        color: theme.palette.common.white
    },
    active: {
        color: theme.palette.common.orange
    },
    icon: {
        color: theme.palette.common.white
    }
});

class NavMenu extends Component {
    displayName = NavMenu.name
   
    constructor(props) {
        super(props);       
        this.state = {           
            ...props,
            collapseId: '',
            displayContent: (<PleaseWait IsLoading={true} />),
            isOpen: false,
            loading: true,
            payload: [],
            CompanyId:sessionStorage.getItem('CompanyId')
            
        };
    }
        
    componentDidMount = () => {
        this.fetchData();
    }

    renderNoDataView = () => {       
        const classes = this.state.classes;
return (

    <MenuList>
                <MenuItem key={0} className={classes.menuItem}>
                    <ListItemIcon className={classes.icon}>
                        <i
                            className={"fa fa-ban"}
                            title={"Service unavailable"}
                        />                        
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.primary }} inset primary={"Service unavailable"} />
                </MenuItem>
                </MenuList>
           
        );
    }

    toggleCollapse = collapseId => {
        this.setState(prevState => ({
            collapseId: (prevState.collapseId !== collapseId ? collapseId : '')
        }));
        //console.log(this.displayName + '.toggleCollapse', this.state);       
    }

    renderItems = payload => {
       //console.log(this.displayName + '.renderItems', payload);            
        const classes = this.state.classes;
        return (
            <MenuList>
                {
                    payload.map(data =>
                        (
                            <MenuItem key={data.MenuId} className={classes.menuItem}>
                                <Link to={data.Route} className={"text-light"}>
                                    <ListItemIcon className={classes.icon}>
                                        <i
                                            className={classNames(data.GlyphIcon, "menu-item-icon")}
                                            title={"Click here for " + data.Title}
                                        />                                        
                                        <ListItemText classes={{ primary: classes.primary }} inset primary={data.Title} />
                                    </ListItemIcon>
                       {data.IfNotifyable === true ?<span className={classNames("fas fa-bell","")}style={{color:'red'}}></span>:
                                    <span className=''></span>}
                                </Link>
                            </MenuItem>
                        )
                    )
                }
            </MenuList>
        );
    }

    fetchData = () => {
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    seqno: uuid.v4(),
                    datatype: "BIT.Fensa.InstallerPortal.GetMenu",
                    origin: "Installer Portal",
                    operation: "Search",
                    datavalues: JSON.stringify({
                        ParentId: 0,
                        CompanyId:this.state.CompanyId
                    }),
                    validate: false
                })
            })
            .then(response => response.json())
            .then(data => {
                //console.log(this.displayName + '.fetchData[data]', data);
                const menuData = JSON.parse(data);
                //console.log(this.displayName + '.fetchData[data.ReturnData]', menuData);
                if (menuData.returncode === 0) {
                    this.setState({
                        loading: false,
                        payload: menuData,
                        displayContent: this.renderNoDataView()
                    });
                } else {
                    const menuPayload = JSON.parse(menuData.ReturnData.Payload);
                    this.setState({
                        loading: false,
                        payload: menuPayload,
                        displayContent: menuPayload && menuPayload.length > 0 ?
                            this.renderItems(menuPayload) :
                            this.renderNoDataView()
                    });
                }
            })
            .catch(data => {
                //console.log(this.displayName + '.fetchData[err]', data);
                const errData = JSON.parse(JSON.parse(data).ReturnData.Errors);
                //console.log(this.displayName + '.fetchData[errData]', errData);
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: errData
                });
            });
    }

    render() {        
        return (
            <Fragment>
                {this.state.displayContent}
            </Fragment>
        );
    }
}


NavMenu.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(menuStyles)(NavMenu);