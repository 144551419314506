import { GET_MATERIAL_TYPE } from "../actions/installerWork";
import { GET_SERVICE_TYPE } from "../actions/installerWork";
import { GET_PROPERTY_TYPE } from "../actions/installerWork";
import { GET_RADIUS_TYPE } from "../actions/installerWork";
import { GET_MINIMUMBUDGET_TYPE } from "../actions/installerWork";

import {
    INSERT_INSTALLER_WORK_DATA,
    GET_INSTALLER_WORK_DATA,
    GET_INSTALLER_WORK_OPENING_HOURS,
    GET_INSTALLER_WORK_MATERIALS,
    GET_INSTALLER_WORK_SERVICE,
    GET_INSTALLER_WORK_PROPERTY_TYPE,

    SET_INSTALLER_WORK_DATA,
    SET_INSTALLER_WORK_OPENING_HOURS,
    SET_INSTALLER_WORK_MATERIALS,
    SET_INSTALLER_WORK_SERVICE,
    SET_INSTALLER_WORK_PROPERTY_TYPE

} from "../actions/installerWork";


export default function (state = {}, action) {

    switch (action.type) {

        case GET_MATERIAL_TYPE:
            return Object.assign({}, state, {
                MaterialType: action.List
            });

        case GET_SERVICE_TYPE:
            return Object.assign({}, state, {
                ServiceType: action.List
            });

        case GET_PROPERTY_TYPE:
            return Object.assign({}, state, {
                PropertyType: action.List
            });

        case GET_RADIUS_TYPE:
            return Object.assign({}, state, {
                RadiusType: action.List
            });

        case GET_MINIMUMBUDGET_TYPE:
            return Object.assign({}, state, {
                MinimumBudgetType: action.List
            });

        case INSERT_INSTALLER_WORK_DATA:
            return Object.assign({}, state, {
                Result: action.List
            });

        case GET_INSTALLER_WORK_DATA:
            return Object.assign({}, state, {
                InstallerWorkData: action.InstallerWorkData
            });

        case GET_INSTALLER_WORK_OPENING_HOURS:
            return Object.assign({}, state, {
                InstallerOpeningHours: action.InstallerOpeningHours
            });

        case GET_INSTALLER_WORK_MATERIALS:
            return Object.assign({}, state, {
                InstallerWorkMaterials: action.InstallerWorkMaterials
            });

        case GET_INSTALLER_WORK_SERVICE:
            return Object.assign({}, state, {
                InstallerWorkServices: action.InstallerWorkServices
            });

        case GET_INSTALLER_WORK_PROPERTY_TYPE:
            return Object.assign({}, state, {
                InstallerWorkPropertyTypes: action.InstallerWorkPropertyTypes
            });

        case SET_INSTALLER_WORK_MATERIALS:

            
            let InstallerWorkMaterials = state.InstallerWorkMaterials.slice(0);

            let SelectedMaterial = InstallerWorkMaterials.find(x => x.MaterialId === parseInt(action.FilterName));

            

            if (SelectedMaterial === undefined) {
                InstallerWorkMaterials.push(
                    {
                        MaterialId: parseInt(action.FilterName),
                        Deleted: action.Value === true ? false : true
                    });
            }
            else {
                if (action.Value === true) {
                    InstallerWorkMaterials.find(x => x.MaterialId === parseInt(action.FilterName)).Deleted = false;
                }
                else {
                    InstallerWorkMaterials.find(x => x.MaterialId === parseInt(action.FilterName)).Deleted = true;
                }
                
            }

            console.log("setting Material", action, SelectedMaterial, InstallerWorkMaterials);

            return Object.assign({}, state, { InstallerWorkMaterials: InstallerWorkMaterials });
            //return InstallerWorkMaterials;

        case SET_INSTALLER_WORK_SERVICE:

            let InstallerWorkServices = state.InstallerWorkServices.slice(0);

            let SelectedServices = InstallerWorkServices.find(x => x.ServiceId === parseInt(action.FilterName));

            if (SelectedServices === undefined) {
                InstallerWorkServices.push(
                    {
                        ServiceId: parseInt(action.FilterName),
                        Deleted: action.Value === true ? false : true
                    });
            }
            else {
                if (action.Value === true) {
                    InstallerWorkServices.find(x => x.ServiceId === parseInt(action.FilterName)).Deleted = false;
                }
                else {
                    InstallerWorkServices.find(x => x.ServiceId === parseInt(action.FilterName)).Deleted = true;
                }

            }

            return Object.assign({}, state, { InstallerWorkServices: InstallerWorkServices });

        case SET_INSTALLER_WORK_PROPERTY_TYPE:

            let InstallerWorkPropertyTypes = state.InstallerWorkPropertyTypes.slice(0);

            let SelectedPropetyType = InstallerWorkPropertyTypes.find(x => x.PropertyTypeId === parseInt(action.FilterName));

            if (SelectedPropetyType === undefined) {
                InstallerWorkPropertyTypes.push(
                    {
                        PropertyTypeId: parseInt(action.FilterName),
                        Deleted: action.Value === true ? false : true
                    });
            }
            else {
                if (action.Value === true) {
                    InstallerWorkPropertyTypes.find(x => x.PropertyTypeId === parseInt(action.FilterName)).Deleted = false;
                }
                else {
                    InstallerWorkPropertyTypes.find(x => x.PropertyTypeId === parseInt(action.FilterName)).Deleted = true;
                }

            }

            return Object.assign({}, state, { InstallerWorkPropertyTypes: InstallerWorkPropertyTypes });

        case SET_INSTALLER_WORK_OPENING_HOURS:

            let InstallerOpeningHours = state.InstallerOpeningHours.slice(0);

            let SelectedOpeningHours = InstallerOpeningHours.find(x => x.DayOfWeek === parseInt(action.DayId));

            if (SelectedOpeningHours === undefined) {
                InstallerOpeningHours.push(
                    {
                        DayOfWeek: parseInt(action.DayId),
                        NotOpen: action.Value === true ? true : false,
                        OpenTime: '07:30',
                        CloseTime: '17:00'
                    });
            }
            else {

                InstallerOpeningHours.find(x => x.DayOfWeek === parseInt(action.DayId)).OpenTime = action.OpenTime;
                InstallerOpeningHours.find(x => x.DayOfWeek === parseInt(action.DayId)).CloseTime = action.CloseTime;
                InstallerOpeningHours.find(x => x.DayOfWeek === parseInt(action.DayId)).NotOpen = action.NotOpen;

            }

            return Object.assign({}, state, { InstallerOpeningHours: InstallerOpeningHours });

        case SET_INSTALLER_WORK_DATA:

            let InstallerWorkData = state.InstallerWorkData;

            if (InstallerWorkData === null) {
                InstallerWorkData = 
                    {
                        CompanyId: action.CompanyId,                   
                        Description : action.Description,
                        IsManufacturer : action.IsManufacturer,
                        LocalAuthorities : action.LocalAuthorities,
                        OfferFinance : action.OfferFinance,
                        HasShowroom : action.HasShowroom,
                        ContactEmail : action.ContactEmail,
                        AdditionalEmail : action.AdditionalContactEmail,
                        RadiusId : action.RadiusId,
                        MinimumBudgetId : action.MinimumBudgetId,
                        CallOut24Hours : action.CallOut24Hours     
                    };
            }
            else {
                InstallerWorkData.CompanyId = action.CompanyId;
                InstallerWorkData.Description = action.Description;
                InstallerWorkData.IsManufacturer = action.IsManufacturer;
                InstallerWorkData.LocalAuthorities = action.LocalAuthorities;
                InstallerWorkData.OfferFinance = action.OfferFinance;
                InstallerWorkData.HasShowroom = action.HasShowroom;
                InstallerWorkData.ContactEmail = action.ContactEmail;
                InstallerWorkData.AdditionalEmail = action.AdditionalContactEmail;
                InstallerWorkData.RadiusId = action.RadiusId;
                InstallerWorkData.MinimumBudgetId = action.MinimumBudgetId;
                InstallerWorkData.CallOut24Hours = action.CallOut24Hours;          
            }
            

            return Object.assign({}, state, { InstallerWorkData: InstallerWorkData });
            //return state;


        default:
            return state;
    }
}

