import React, { Component } from 'react';
//import { Col, Grid, Row } from 'react-bootstrap';
import { HomeMenuItems } from './HomeMenu/HomeMenuItems';

export class Home extends Component {
  displayName = Home.name

  render() {
      return (
          <HomeMenuItems />
    );
  }
}
