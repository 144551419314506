import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { MenuItems } from '../SharedComponents/MenuItems';

import ReactGA from 'react-ga';


export class Finance extends Component {
    displayName = Finance.name

    render() {

        ReactGA.event({
            category: 'Finance',
            action: 'View Finance'
        });


        return (
            /*<FinanceMenuItems />*/
            <MenuItems ParentName="Finance" />
        );
    }
}
