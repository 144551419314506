import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator';
import moment from 'moment';
import uuid from "uuid";

import ReactGA from 'react-ga';


import AuthHelper from '../SharedComponents/AuthHelper';
import { AddressLookup } from '../SharedComponents/AddressLookup';
import { CardBodyOnly } from './../SharedComponents/CardBodyOnly';
import { ColumnAndLabel } from '../SharedComponents/ColumnAndLabel';
import { ColumnAndLabelRadioOptions } from './../SharedComponents/ColumnAndLabelRadioOptions';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { PleaseWait } from './../SharedComponents/PleaseWait';

import {
    MDBBtn,
    MDBIcon
} from "mdbreact";


const branchHomeUrl = '/Branch/Home';
const branchViewUrl = '/Branch/View/';
const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class BranchEdit extends Component {

    displayName = BranchEdit.name

    constructor(props) {
        super(props);

        const auth = new AuthHelper();
        //console.log(auth);

        this.state = {
            AddressId: -1,
            Address1: '',
            Address2: '',
            Address3: '',
            BranchEmail: '',
            BranchId: props.match.params.id,
            BranchName: '',
            BranchUrl: '',
            CanEdit: auth.CanEditData,
            CityTown: '',
            CompanyId: auth.CompanyId,
            ContactName: '',
            County: '',
            MainBranch: 0,
            ShowOnWebsite: 1,
            Postcode: '',
            SearchPostcode: '',
            TelephoneNumber: '',
            UserId: auth.UserId,
            formValid: false,
            formErrors: {
                BranchName: (props.match.params.id === '-1' ? 'Branch name is Required' : ''),
                ContactName: (props.match.params.id === '-1' ? 'Contact name is Required' : ''),
                SearchPostcode: (props.match.params.id === '-1' ? 'Postcode is Required' : ''),
                TelephoneNumber: ''
            },
            hideAddress: true,
            isNewBranch: (props.match.params.id === '-1' ? true : false),
            loading: true,
            performMainBranchReset: false,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            pageTitle: props.match.params.id === '-1' ? 'Create Branch' : 'Edit Branch',
            processingSubmit: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAddressSelected = this.handleAddressSelected.bind(this);
        this.validateForm = this.validateForm.bind(this);

        console.log(this.displayName, this.props);
    }

    componentDidMount() {
        if (this.state.CanEdit) {
            this.fetchData();
        } else if (!this.state.isNewBranch) {
            //redirect to readonly view
            this.props.history.push(branchViewUrl + this.state.BranchId);
        } else {
            this.props.history.push(branchHomeUrl);
        }
    }

    currentPageTitle = () => {
        return this.state.pageTitle;
    }

    disableButtons = () => {
        //disable control buttons
        this.setState({
            processingSubmit: true
        });
    }

    getDataValues = () => {
        return !this.state.isNewBranch ?
            JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.UpdateBranch",
                origin: "Installer Portal",
                operation: "INSERT",
                datavalues: JSON.stringify({
                    __table__: "InstallerCompanyBranch",
                    ICB_Id: this.state.BranchId,
                    ICB_CompanyId: this.state.CompanyId,
                    ICB_Name: this.state.BranchName,
                    ICB_ContactName: this.state.ContactName,
                    ICB_Telephone: this.state.TelephoneNumber,
                    ICB_UpdatedBy: this.state.UserId,
                    ICB_MainBranch: this.state.MainBranch,
                    ICB_ShowOnWebsite: this.state.ShowOnWebsite,
                    ICB_BranchUrl: this.state.BranchUrl,
                    ICB_BranchEmail: this.state.BranchEmail,
                    ICB_UpdateDate: moment(),
                    Address_ICB_AddressId:
                    {
                        __table__: "Address",
                        A_Id: this.state.AddressId,
                        A_AddressLine1: this.state.Address1,
                        A_AddressLine2: this.state.Address2,
                        A_AddressLine3: this.state.Address3,
                        A_CityTown: this.state.CityTown,
                        A_County: this.state.County,
                        A_Postcode: this.state.Postcode,
                        A_UpdatedBy: this.state.UserId,
                        A_UpdateDate: moment(),
                        A_Deleted: 0
                    }
                }),
                validate: false
            }) :
            JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.InsertBranch",
                origin: "Installer Portal",
                operation: "INSERT",
                datavalues: JSON.stringify({
                    __table__: "InstallerCompanyBranch",
                    ICB_CompanyId: this.state.CompanyId,
                    ICB_Deleted: 0,
                    ICB_Name: this.state.BranchName,
                    ICB_ContactName: this.state.ContactName,
                    ICB_Telephone: this.state.TelephoneNumber,
                    ICB_Reference: 0, // MAY CAUSE UNINTENDED SIDE EFFECTS BECAUSE IT IS CALCULATED FROM MAX OF ICB_REFERENCE + 1
                    ICB_MainBranch: this.state.MainBranch,
                    ICB_ShowOnWebsite: this.state.ShowOnWebsite,
                    ICB_BranchUrl: this.state.BranchUrl,
                    ICB_BranchEmail: this.state.BranchEmail,
                    ICB_CreatedBy: this.state.UserId,
                    ICB_CreateDate: moment(),
                    Address_ICB_AddressId:
                    {
                        __table__: "Address",
                        A_AddressLine1: this.state.Address1,
                        A_AddressLine2: this.state.Address2,
                        A_AddressLine3: this.state.Address3,
                        A_CityTown: this.state.CityTown,
                        A_County: this.state.County,
                        A_Postcode: this.state.Postcode,
                        A_CreatedBy: this.state.UserId,
                        A_CreateDate: moment(),
                        A_Deleted: 0
                    }
                })
            });
    }

    handleAddressNotFound = msg => {
        //show the message if not found
        //console.log(msg);
        this.setState({
            loading: false,
            notificationPostfix: 'danger show',
            notificationMessage: [msg]
        });
    }

    handleAddressSelected = address => {
        let fieldErrors = this.state.formErrors;
        fieldErrors.SearchPostcode = address.Postcode ? '' : 'Postcode is Required.';
        this.setState({
            Address1: address.Address1,
            Address2: address.Address2,
            Address3: address.Address3,
            CityTown: address.CityTown,
            County: address.County,
            Postcode: address.Postcode,

            formErrors: fieldErrors,
            hideAddress: false,
            notificationPostfix: 'info hide',
            notificationMessage: []
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;

        if (value === undefined) {
            value = target.parentElement.value;
        }

        const name = target.name;

        //this state is only relevant locally - so no need for put it in redux.
        this.setState(
            {
                [name]: value
            },
            () => { this.validateField(name, value); }
        );
        //if (debugOn) {
        //    console.log('state', this.state);
        //}
    }

    handleOnInputValidation = event => {

        let fieldValidationErrors = this.state.formErrors;
        let message = "";
        switch (event.target.name.toLowerCase()) {
            case 'branchname':
                fieldValidationErrors.BranchName = validator.isEmpty(event.target.value) ? 'Branch name is required.' : '';
                if (fieldValidationErrors.BranchName === "") {
                    fieldValidationErrors.BranchName = validator.isLength(event.target.value, { min: 5 }) ? '' : 'Branch name must be more than 4 characters.';
                }
                message = fieldValidationErrors.BranchName;
                break;

            case 'contactname':
                fieldValidationErrors.ContactName = validator.isEmpty(event.target.value) ? 'Contact name is required.' : '';
                message = fieldValidationErrors.ContactName;
                break;

            case 'searchpostcode':
                fieldValidationErrors.SearchPostcode = validator.isEmpty(event.target.value) ? 'Postcode is Required.' : '';
                message = fieldValidationErrors.SearchPostcode;
                break;

            //case 'contactemail':
            //    fieldValidationErrors.ContactEmail = validator.isEmpty(event.target.value) ? 'Email is Required.' : '';
            //    if (fieldValidationErrors.ContactEmail === "") {
            //        fieldValidationErrors.ContactEmail = validator.isEmail(event.target.value) ? '' : 'Email is not in a valid format.';
            //    }
            //    message = fieldValidationErrors.ContactEmail;
            //    break;

            default:
                break;
        }

        if (message !== "") {
            this.setState({
                formErrors: fieldValidationErrors
            }, this.validateForm);
            //set custom validation error
            event.target.setCustomValidity(message);
        } else {
            //clear custom validation errors
            event.target.setCustomValidity("");
        }
    }

    handlePostcodeChange = event => {
        const target = event.target;
        var value = target.value;

        if (value === undefined) {
            value = target.parentElement.value;
        }

        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            //Postcode: value,
            SearchPostcode: value
        });
        //if (debugOn) {
        //    console.log('state', this.state);
        //}
    }

    handleLookupClick = status => {
        //if (debugOn) {
        //    console.log(this.displayName + '.handleLookupClick[start]', status);
        //}
        this.togglePleaseWait(status);
        //clear errors
    }

    handleMainBranchCallback = cbValue => {
        this.setState({
            MainBranch: cbValue ? 1 : 0,
            performMainBranchReset: cbValue ? true : false
        });
        //if (debugOn) {
        //    console.log('handleMainBranchCallback', cbValue, this.state);
        //}
    }

    handleShowOnWebsiteChange = cbValue => {
        this.setState({
            ShowOnWebsite: cbValue ? 1 : 0
          
        });
        //if (debugOn) {
        //    console.log('state', this.state);
        //}
    }

    togglePleaseWait = status => {
        this.setState({
            loading: status
        });
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        switch (fieldName) {
            case 'BranchName':
                fieldValidationErrors.BranchName = validator.isEmpty(this.state.BranchName) ? 'Branch Name is required.' : '';
                fieldValidationErrors.BranchName = validator.isLength(this.state.BranchName, { min: 5 }) ? '' : 'Branch must be more than 5 characters.';
                break;

            case 'ContactName':
                fieldValidationErrors.ContactName = validator.isEmpty(this.state.ContactName) ? 'Contact Name is required.' : '';
                break;
            case 'SearchPostcode':
                fieldValidationErrors.SearchPostcode = validator.isEmpty(this.state.SearchPostcode) ? 'Postcode is required.' : '';
                break;

            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid: this.state.formErrors.BranchName.length === 0
                && this.state.formErrors.ContactName.length === 0
                && this.state.formErrors.SearchPostcode.length === 0
        });
        return this.state.formValid;
    }

    submitForm(e) {
        e.preventDefault();

        //force errors to show if any
        //e.target.className += " was-validated";

        if (this.validateForm()) {
            // Show please wait
            this.togglePleaseWait(true);


            var postData = this.getDataValues();
            console.log(this.displayName + '.submitForm[postData]', postData);
            let enginePath = 'BITEngine/InsertObject';
            fetch(process.env.REACT_APP_FENSA_WEB_API + enginePath, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: postData
            })
                .then(response => response.json())
                .then(data => JSON.parse(data))
                .then(data => {
                    console.log('Edit branch', data);
                    let newBranchId = 0;
                    //if new branch then get the id of new branch in message
                    if (this.state.isNewBranch) {
                        newBranchId = JSON.parse(data.ReturnData.Payload).Id;
                        console.log('Setting new branch id', newBranchId, JSON.parse(data.message));
                    }
                    this.setState({
                        loading: false,
                        notificationPostfix: 'success show',
                        //notificationMessage: data.ReturnData.Errors
                        notificationMessage: ['Successfully completed update']
                    });
                    // perform main branch reset
                    if (this.state.performMainBranchReset) {
                        //if (debugOn) {
                        //    console.log('Performing main branch reset on all save current', this.state);
                        //}
                        // if new branch use the newly created branch id retrieved from previous step
                        var resetValues = JSON.stringify({
                            Id: !this.state.isNewBranch ? this.state.BranchId : newBranchId,
                            CompanyId: this.state.CompanyId
                        });

                        var resetData = JSON.stringify({
                            seqno: uuid.v4(),
                            datatype: "BIT.Fensa.InstallerPortal.InstallerCompanyBranchUpdateMainBranch",
                            origin: "Installer Portal",
                            operation: "Search",
                            datavalues: resetValues,
                            validate: false
                        });

                        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/InsertJsonObject', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: resetData
                        })
                            .then(response => response.json())
                            .then(data => JSON.parse(data))
                            .then(data => {
                                //console.log(this.displayName + '.submitData[resetMainBranch]', data);
                                // tell user it was completed successfully
                                if (data.returncode === 1) {
                                    // if successful then reload branch list
                                    this.props.history.push(branchHomeUrl);
                                    return;
                                }
                                //tell the developer that update failed
                                //console.log(data.message);
                                this.setState({
                                    loading: false,
                                    notificationPostfix: 'danger show',
                                    notificationMessage: ['Bad Request']
                                });
                            })
                            .catch(data => {
                                if (debugOn) {
                                    console.log(this.displayName + '.submitForm[catch->create]', data);
                                }
                                this.setState({
                                    loading: false,
                                    notificationPostfix: 'danger show',
                                    notificationMessage: ['Bad Request - Failed to branch creation']
                                });
                            });
                    } else {
                        // if successful then reload branch list
                        this.setState({
                            loading: false,
                            notificationPostfix: 'success show',
                            notificationMessage: ['Successfully updated branch']
                        });
                        this.props.history.push(branchHomeUrl);
                    }
                })
                .catch(data => {
                    if (debugOn) {
                        console.log(this.displayName + '.submitForm[catch->update]', data);
                    }
                    this.setState({
                        loading: false,
                        notificationPostfix: 'danger show mt-3',
                        notificationMessage: ['Bad Request - Failed to complete update']
                    });
                });
        }
    }

    fetchData = () => {

        //dont bother if new branch
        if (this.state.isNewBranch) {
            this.togglePleaseWait(false);
            return;
        }

        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetCompanyBranchItem",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                BranchId: this.state.BranchId
            })
        });

        var fetchBranchItem = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        });

        Promise.all([fetchBranchItem])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                //if (debugOn) {
                //    console.log(this.displayName + '.fetchData[responses]: ', responses);
                //}
                const data = JSON.parse(JSON.parse(responses).ReturnData.Payload)[0];
                //if (debugOn) {
                //    console.log(this.displayName + '.fetchData[data]: ', data);
                //}
                this.setState({
                    AddressId: data.AddressId,
                    Address1: data.Address1,
                    Address2: data.Address2,
                    Address3: data.Address3,
                    BranchName: data.Name,
                    BranchEmail: data.BranchEmail,
                    BranchUrl: data.BranchUrl,
                    CityTown: data.CityTown,
                    ContactName: data.ContactName,
                    County: data.County,
                    MainBranch: data.MainBranch,
                    ShowOnWebsite: data.ShowOnWebsite,
                    Postcode: data.PostCode,
                    SearchPostcode: data.PostCode,
                    TelephoneNumber: data.Telephone,
                    hideAddress: false,
                    loading: false
                });
                //if (debugOn) {
                //    console.log(this.displayName + '.fetchData[state]: ', this.state);
                //}
            })
            .catch(data => {
                if (debugOn) {
                    console.log(data);
                }
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - No Data or service not available']
                });
            });
        this.validateForm();
    }

    render() {

        ReactGA.event({
            category: 'Branch Edit',
            action: 'Edit Branch'
        });

        return (
            <div>
                <form className='demoForm was-validated' onSubmit={this.submitForm.bind(this)}>
                    <div className='form-group-container mr-auto ml-auto'>
                        <div className='form-group-title'>{this.currentPageTitle()}</div>
                        <div className='form-group'>
                            <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                            <div className='row'>
                                <div className='col-xs-12 col-md-4'>
                                    <ColumnAndLabel
                                        ErrorMessage={this.state.formErrors.BranchName}
                                        IconLabel="code-branch"
                                        Id="BranchName"
                                        InputType="text"
                                        IsRequired={true}
                                        SizeCss="md"
                                        HintLabel=""
                                        TextLabel="Branch Name"
                                        Validate={true}
                                        Value={this.state.BranchName}
                                        HandleInputChange={this.handleInputChange}
                                        HandleOnInput={this.handleOnInputValidation}
                                    />
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ColumnAndLabel
                                        ErrorMessage={this.state.formErrors.ContactName}
                                        IconLabel="user"
                                        Id="ContactName"
                                        InputType="text"
                                        IsRequired={true}
                                        SizeCss="md"
                                        HintLabel=""
                                        TextLabel="Contact Name"
                                        Validate={true}
                                        Value={this.state.ContactName}
                                        HandleInputChange={this.handleInputChange}
                                        HandleOnInput={this.handleOnInputValidation}
                                    />
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ColumnAndLabel
                                        ErrorMessage=""
                                        IconLabel="phone"
                                        Id="TelephoneNumber"
                                        InputType="text"
                                        IsRequired={false}
                                        SizeCss="md"
                                        HintLabel=""
                                        TextLabel="Telephone Number"
                                        Validate={true}
                                        Value={this.state.TelephoneNumber}
                                        HandleInputChange={this.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12 col-md-4'>
                                    <ColumnAndLabel
                                        ErrorMessage=""
                                        IconLabel="envelope"
                                        Id="BranchEmail"
                                        InputType="text"
                                        IsRequired={false}
                                        SizeCss="md"
                                        HintLabel=""
                                        TextLabel="Email"
                                        Validate={true}
                                        Value={this.state.BranchEmail}
                                        HandleInputChange={this.handleInputChange}
                                    />
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ColumnAndLabel
                                        ErrorMessage=""
                                        IconLabel="globe"
                                        Id="BranchUrl"
                                        InputType="text"
                                        IsRequired={false}
                                        SizeCss="md"
                                        HintLabel=""
                                        TextLabel="Website"
                                        Validate={true}
                                        Value={this.state.BranchUrl}
                                        HandleInputChange={this.handleInputChange}
                                    />
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <AddressLookup
                                        ErrorMessage={this.state.formErrors.SearchPostcode}
                                        HandleAddressSelected={this.handleAddressSelected}
                                        IsRequired={true}
                                        Postcode={this.state.SearchPostcode}
                                        PostcodeId="Postcode"
                                        PostcodeText="Post code"
                                        Validate={true}
                                        handleAddressNotFound={this.handleAddressNotFound}
                                        handleChange={this.handlePostcodeChange}
                                        handleLookupClick={this.handleLookupClick}
                                        loading={this.state.loading}
                                    />
                                </div>
                            </div>
                            <div className='row pb-1' hidden={this.state.hideAddress}>
                                <div className='col-12 text-muted'>
                                    <CardBodyOnly>
                                        <div className='pb-2'><label>Address</label></div>
                                        <div className='pr-4'>{this.state.Address1}</div>
                                        <div className='pr-4'>{this.state.Address2}</div>
                                        <div className='pr-4'>{this.state.Address3}</div>
                                        <div className='pr-4'>{this.state.CityTown}</div>
                                        <div className='pr-4'>{this.state.County}</div>
                                        <div className='pr-4'>{this.state.Postcode}</div>
                                    </CardBodyOnly>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12 col-md-4'>
                                    <ColumnAndLabelRadioOptions
                                        id="MainBranch"
                                        name="MainBranch"
                                        textLabel="Main Branch: "
                                        optionNo={'No'}
                                        optionYes={'Yes'}
                                        value={this.state.MainBranch}
                                        handleChange={this.handleMainBranchCallback}
                                    />
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ColumnAndLabelRadioOptions
                                        id="ShowOnWebsite"
                                        name="ShowOnWebsite"
                                        textLabel="Show on Website: "
                                        optionNo={'No'}
                                        optionYes={'Yes'}
                                        value={this.state.ShowOnWebsite}
                                        handleChange={this.handleShowOnWebsiteChange}
                                    />
                                </div>
                            </div>
                            <div className='row pb-1'>
                                <PleaseWait IsLoading={this.state.loading} />
                            </div>
                            <div className='row pb-2'>
                                <div className='col text-right'>
                                    <MDBBtn
                                        className="pull-right mdb-btn-resize"
                                        color="success"
                                        type="submit"
                                        size="sm"
                                        disabled={!this.state.formValid || this.state.processingSubmit}
                                        onClick={this.toggleModal}
                                    >
                                        <MDBIcon icon="save" className="mr-2" />Submit
                                    </MDBBtn>
                                    <Link className='btn btn-sm btn-danger pull-right mr-2 mdb-btn-resize' to={branchHomeUrl}>
                                        <i className='fa fa-arrow-left pr-2' />Cancel
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div >
        );
    }
}
