import React, { Component } from 'react';

const defaultView = 'address-card';
export class ViewToggler extends Component {
    displayName = ViewToggler.name;

    state = {
        ...this.props,
        viewName: this.props.viewName,
        cardCss: this.props.viewName === defaultView ?
            'fa fa-address-card fa-2x text-link text-info selected-toggle' :
            'fa fa-address-card fa-2x text-link',
        listCss: this.props.viewName === defaultView ?
            'fa fa-list fa-2x text-link' :
            'fa fa-list fa-2x text-link text-info selected-toggle'
    };

    /// Change the card-view/list css class accordingly
    handleSetCss = vwName => {
        const isOn = vwName === defaultView;
        //console.log('ViewToggler.handleSetCss', isOn, vwName);
        this.setState({
            cardCss: isOn ? 'fa fa-address-card fa-2x text-link text-info selected-toggle' : 'fa fa-address-card fa-2x text-link',
            listCss: isOn ? 'fa fa-list fa-2x text-link' : 'fa fa-list fa-2x text-link text-info selected-toggle'
        });
    }

    toggleViewChange = vwName => {
        //console.log('ViewToggler.toggleViewChange', vwName);
        this.handleSetCss(vwName);
        // render card or list?
        (vwName === defaultView) ?
            this.state.renderCard(vwName) :
            this.state.renderList(vwName);
    }

    render() {
        return (
            <div className='form-group text-muted p-3 border border-0 ml-2 d-sm-inline-flex'>
                <i className={this.state.cardCss} onClick={() => this.toggleViewChange('address-card')} title='Switch to Card View' />
                <span className='pt-2 d-block d-sm-none'></span>
                <span className='pr-2 d-none d-sm-block'></span>
                <i className={this.state.listCss} onClick={() => this.toggleViewChange('list')} title='Switch to List View' />
            </div>
        );
    }
}