import React, { Component, Fragment } from 'react';
import Moment from 'react-moment';
import ReactTable from "react-table";
import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
//import { ColumnAndLable } from '../SharedComponents/ColumnAndLable';
import { CardBodyOnly } from './../SharedComponents/CardBodyOnly';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import { ReactTableFilterToggler } from './../SharedComponents/ReactTableFilterToggler';
import { ReadonlyItem } from './../SharedComponents/ReadonlyItem';
import moment from 'moment';
import { StatementSearch } from "./StatementSearch";
import { CardFooterButtonsIcons } from '../SharedComponents/CardFooterButtonsIcons';

import Badge from "@material-ui/core/Badge";


export class Statement extends Component {
    displayName = "Statement";

    constructor(props) {
        super(props);
        const auth = new AuthHelper(this.displayName);
        this.state = {
            CompanyId: auth.CompanyId,
            InstallerStatement: [],
            InstallerStatementSummary: [],
            displayContent: (<PleaseWait IsLoading={true} />),
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 10,
            totals: '',
            totalsLabel: ' item',
            useFiltering: (auth.TableFilter && auth.TableFilter === 'On' ? true : false),
            StatementFrom: moment().add(-3, 'M'),
            StatementTo: moment(),
            ViewInvoices: 1,
            ViewPayments: 1,
            ViewAdjustments: 1,
            ShowViewButton: true,

            ExportData: null
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleStatementFromChange = this.handleStatementFromChange.bind(this);
        this.handleStatementToChange = this.handleStatementToChange.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.DataExport = this.DataExport.bind(this);
        

    }

    componentDidMount() {
        this.fetchData();
    }

    filterCaseInsensitive = ({ id, value }, row) => {
        if (id === 'Credit' || id === 'Debit' || id === 'Payment') {
            //console.log(id, value, row[id], row);
            return row[id] ? row[id].toString().includes(value.toString()) : false;
        } else {
            return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : false;
        }
    }

    handleToggleFilter = toggle => {
        this.setState({
            useFiltering: toggle
        });
        this.fetchData();
    }

    handleSearch(event) {

        this.fetchData();

        event.preventDefault();
    }

    handleClearSearch(event) {
        console.log(this.displayName + '.handleClearSearch', event);
        this.setState({
            ViewAdjustments: true,
            ViewPayments: true,
            ViewInvoices: true,
            StatementFrom: moment().add(-3, 'M'),
            StatementTo: moment()
        }, () => {
            this.fetchData();
        });
    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        });
    }

    handleStatementFromChange(value) {


        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            StatementFrom: value
        });

        this.fetchData();
    }

    handleStatementToChange(value) {

        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            StatementTo: value
        });

        this.fetchData();
    }

    handleViewInvoiceStatusCallback = cbValue => {

        this.setState({
            ViewInvoices: cbValue ? true : false
        });
        //console.log(this.displayName + '.handleUserStatusCallback: ', cbValue, this.state);
    }

    handleViewPaymentsStatusCallback = cbValue => {

        this.setState({
            ViewPayments: cbValue ? true : false
        });
    }

    handleViewAdjustmentsStatusCallback = cbValue => {

        this.setState({
            ViewAdjustments: cbValue ? true : false
        });
    }

    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    renderTable = (payload, summary) => {
        return (           
            <Fragment>            
                <div className='form-group'>
                    <p>This screen shows you your recent statements</p>
                    <CardBodyOnly>
                        <h3>Summary</h3>
                        <div className='row'>
                            <div className='col-md-3'>
                                <ReadonlyItem textLabel="Total YTD invoiced">

                                    {new Intl.NumberFormat('en-GB', {
                                        style: 'currency',
                                        currency: 'GBP',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }).format(summary[0].YTD_PaymentTotal)}
                                </ReadonlyItem>

                            </div>
                            <div className='col-md-3'>
                                <ReadonlyItem textLabel="Total YTD Payments">
                                    {new Intl.NumberFormat('en-GB', {
                                        style: 'currency',
                                        currency: 'GBP',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }).format(summary[0].YTD_DebitTotal)}

                                </ReadonlyItem>
                            </div>
                            <div className='col-md-3'>
                                <ReadonlyItem textLabel="Total YTD Adjustments">
                                    {new Intl.NumberFormat('en-GB', {
                                        style: 'currency',
                                        currency: 'GBP',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }).format(summary[0].YTD_CreditTotal)}

                                </ReadonlyItem>
                            </div>
                            <div className='col-md-3'>
                                <ReadonlyItem textLabel="Account Balance">
                                    {new Intl.NumberFormat('en-GB', {
                                        style: 'currency',
                                        currency: 'GBP',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }).format(summary[0].AccountBalance)}
                                </ReadonlyItem>
                            </div>
                        </div>
                    </CardBodyOnly>
                    <hr />
                    <CardBodyOnly>
                        <StatementSearch
                            CompletedBefore={this.state.StatementFrom}
                            CompletedAfter={this.state.StatementTo}
                            handleSearch={this.handleSearch}
                            handleViewInvoiceChange={this.handleViewInvoiceStatusCallback}
                            handleViewPaymentChange={this.handleViewPaymentsStatusCallback}
                            handleViewAdjustmentChange={this.handleViewAdjustmentsStatusCallback}
                            focused={false}
                            focusedOne={false}
                            handleCompletedBeforeChange={this.handleStatementFromChange}
                            handleCompletedAfterChange={this.handleStatementToChange}
                            loading={this.state.loading}
                            ViewInvoices={this.state.ViewInvoices}
                            ViewPayments={this.state.ViewPayments}
                            ViewAdjustments={this.state.ViewAdjustments}
                            handleClear={this.handleClearSearch}
                            DataProps={this.state}
                        />
                    </CardBodyOnly>

                    <div className='py-5'>
                        <div>
                            <ReactTableFilterToggler
                                ParentDisplayName={this.displayName}
                                UseFiltering={this.state.useFiltering}
                                handleToggleFilter={this.handleToggleFilter}
                            />
                            <small className='text-muted'>When filtering by date field use year-month-day format <code>e.g.1900-01-01</code></small>                           
                            <ReactTable data={payload}
                                columns=
                                {
                                    [
                                        {
                                            Header: 'Id', // Custom header components!
                                            accessor: 'Id',
                                            show: false
                                        },
                                        {
                                            Header: 'Reference',
                                            accessor: 'Reference',
                                            className: 'p-3',
                                            width: 120
                                        },
                                        {
                                            Header: 'Date', // Custom header components!
                                            accessor: 'Date',
                                            className: 'p-3',
                                            Cell: row => {
                                                return (
                                                    <Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.Date} />
                                                );
                                            }
                                        },
                                        {
                                            Header: 'Description',
                                            accessor: 'Description',
                                            className: 'p-3'
                                        },
                                        {
                                            Header: 'Invoiced',
                                            accessor: 'Debit',
                                            className: 'p-3',
                                            Cell: row => {
                                                return (                                                    
                                                        new Intl.NumberFormat('en-GB', {
                                                            style: 'currency',
                                                            currency: 'GBP',
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                    }).format(row.original.Debit)                                                    
                                                );
                                            }


                                        },
                                        {
                                            Header: 'Payment',
                                            accessor: 'Payment',
                                            className: 'p-3',
                                            Cell: row => {
                                                return (
                                                    new Intl.NumberFormat('en-GB', {
                                                        style: 'currency',
                                                        currency: 'GBP',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(row.original.Payment)
                                                );
                                            }
                                        },
                                        {
                                            Header: 'Credit Notes',
                                            accessor: 'Credit',
                                            className: 'p-3',
                                            Cell: row => {
                                                return (
                                                    new Intl.NumberFormat('en-GB', {
                                                        style: 'currency',
                                                        currency: 'GBP',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(row.original.Credit)
                                                );
                                            }

                                        },

                                        {
                                            Header: '',
                                            accessor: 'Edit',
                                            filterable: false,
                                            sortable: false,
                                            Cell: row => {
                                                return (                                           
                                                    <Fragment>
                                                        <div className='text-center py-1 pr-5'>
                                                            <CardFooterButtonsIcons
                                                                Id={row.original.Id}
                                                                //DeleteCss={ButtonsJss.DefaultDeleteCss}
                                                                //EditCss={ButtonsJss.DefaultEditCss}
                                                                //ViewCss={ButtonsJss.DefaultViewCss}
                                                                //DeleteUrl={'/Installation/Delete/' + row.original.Id}
                                                                //EditUrl={'/Installations/Edit/' + row.original.Id}
                                                                ViewUrl={row.original.TransactionType === 1 || row.original.Description=="Credit Note" ? '/Finance/StatementView/' + row.original.Id : ''}
                                                                ShowViewButton={row.original.TransactionType === 1 || row.original.Description=="Credit Note" ? true : false } 
                                                                    // use role priviledge to set visibility
                                                            //ShowEditButton={this.state.ShowEditButton} // use role priviledge to set visibility
                                                            //ShowDeleteButton={this.state.ShowDeleteButton}// use role priviledge to set visibility
                                                            />
                                                        </div>
                                                    </Fragment>
                                                );
                                            }
                                        }
                                    ]
                                }

                                className="table-bordered table-striped table-light table-hover table-shadowed"
                                defaultPageSize={this.state.pageSize}
                                expanded={this.state.expanded}
                                filterable={this.state.useFiltering}
                                minRows={0}
                                nextText='>'
                                previousText='<'
                                defaultFilterMethod={this.filterCaseInsensitive}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }


    fetchData = () => {

        var datavalues = JSON.stringify({
            CompanyId: this.state.CompanyId,
            StatementFrom: this.state.StatementFrom,
            StatementTo: this.state.StatementTo,
            ViewInvoices: this.state.ViewInvoices,
            ViewPayments: this.state.ViewPayments,
            ViewAdjustments: this.state.ViewAdjustments
        });

        var DataStatement = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerStatement_views",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: datavalues
        });


        var FetchStatement = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: DataStatement
        });

        var DataSummary = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerStatementSummary_views",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: datavalues
        });


        var fetchSummary = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: DataSummary
        });


        var DataExportStatement = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerStatement_views_csv",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: datavalues
        });


        var FetchExportData = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/Export', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: DataExportStatement
        });

        ///////////////////////////

        Promise.all([FetchStatement, fetchSummary, FetchExportData])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))


            .then(responses => {
                //this.setState({
                //    InstallerStatement: JSON.parse(JSON.parse(responses[0]).message),
                //    InstallerStatementSummary: JSON.parse(JSON.parse(responses[1]).message),
                //    loading: false
                //});
                const data = JSON.parse(responses[0]);
                const summary = JSON.parse(responses[1]);

                const DataExport = responses[2];           

                const installerData = data.ReturnData.Payload !== null ? JSON.parse(data.ReturnData.Payload) : [];
                const installerSummary = JSON.parse(summary.ReturnData.Payload);

                this.setState({
                    //InstallerStatement: JSON.parse(JSON.parse(responses[0]).message),
                    //InstallerStatementSummary: JSON.parse(JSON.parse(responses[1]).message),
                    InstallerStatement: installerData,
                    InstallerStatementSummary: installerSummary,
                    loading: false,
                    displayContent: data.returncode === 0 ?
                        this.renderNoDataView() :
                        this.renderTable(installerData, installerSummary),
                    notificationPostfix: data.returncode === 0 ? 'info show mt-3' : 'info hide',
                    notificationMessage: data.returncode === 0 ? ['No Data'] : [],
                    totals: installerData.length,
                    ExportData: DataExport !== null ? DataExport.fileContents : '',
                    ExportContentType: DataExport !== null ? DataExport.contentType : ''


                });
            })
            .catch(data => {
                console.log(data);
                this.setState({
                    InstallerStatement: [],
                    InstallerStatementSummary: [],
                    displayContent: '',
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    totals: 0,
                    totalsLabel: ' item'
                });
            });
    }

    DataExport = () => {

        var datavalues = JSON.stringify({
            CompanyId: this.state.CompanyId,
            StatementFrom: this.state.StatementFrom,
            StatementTo: this.state.StatementTo,
            ViewInvoices: this.state.ViewInvoices,
            ViewPayments: this.state.ViewPayments,
            ViewAdjustments: this.state.ViewAdjustments
        });

        var DataExportStatement = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerStatement_views",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: datavalues
        });

        var FetchExportData = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/Export', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: DataExportStatement
        });
        ///////////////////////////

        Promise.all([FetchExportData])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            //.then(responses => {
            //    this.setState({
            //        loading:false
            //    });
            //})

            .then(responses => {
                //this.setState({
                //    InstallerStatement: JSON.parse(JSON.parse(responses[0]).message),
                //    InstallerStatementSummary: JSON.parse(JSON.parse(responses[1]).message),
                //    loading: false
                //});
                const data = responses[0];

                console.log(data.fileContents);
                console.log(data.contentType);

                this.setState({
                    ExportData: data.fileContents,
                    ExportContentType: data.contentType
                });                
            })
            .catch(data => {
                console.log(data);
                this.setState({
                    InstallerStatement: [],
                    InstallerStatementSummary: [],
                    displayContent: '',
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    totals: 0,
                    totalsLabel: ' item'
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'Statement',
            action: 'Statement List'
        });


        return (
            <div className='fluid-container'>
                <div className='row'>
                    <div className='col-xs-12 col-sm-6 col-md-6'>
                        <h2>
                            <Badge badgeContent={this.state.totals} color="primary">Statement</Badge>
                        </h2>
                    </div>
                </div>

                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                {this.state.displayContent}

                <a className='btn btn-primary btn-sm pull-right' href={`data:text/csv;base64,${this.state.ExportData}`} download={'export.csv'}><i className="fa fa-times-circle pr-2" />Export</a>

            </div>
        );
    }
}
