
import React, { Component, Fragment } from 'react';
import uuid from "uuid";

import AuthHelper from '../SharedComponents/AuthHelper';
import { CardBodyOnly } from '../SharedComponents/CardBodyOnly';
import { NotificationPanel } from '../SharedComponents/NotificationPanel';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import ReactTable from "react-table";
import Moment from 'react-moment';
import { CardFooterButtonsIcons } from '../SharedComponents/CardFooterButtonsIcons';

const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class InvoiceViewInstallations extends Component {

    displayName = InvoiceViewInstallations.name;

    constructor(props) {
        super(props);

        const auth = new AuthHelper();

        this.state = {
            //...props,
            CompanyId: auth.CompanyId,
            InvoiceId: this.props.InvoiceId,
            Installations: [],
            UsePaging: true,
            loading: true,
            pageSize: 10,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            ShowViewButton: true
        };
    }

    componentWillMount() {
        this.fetchData();
    }

    renderTable = (Installations) => {
        return (
            <Fragment>
                {
                    Installations ?
                        (
                            <ReactTable
                                data={Installations}
                                columns=
                                {
                                    [
                                        {
                                            key: 'Id',
                                            Header: 'Id',
                                            accessor: 'Id',
                                            show: false
                                        },
                                        {
                                            accessor: 'Reference',
                                            Header: () => <span>Installation Reference</span>,
                                            Expander: ({ isExpanded }) => (
                                                <span>
                                                    {
                                                        isExpanded ?
                                                            (<span>&#x2299;</span>) :
                                                            (<span>&#x2295;</span>)
                                                    }
                                                </span>
                                            ),
                                            style: {
                                                padding: "15",
                                                textAlign: "center",
                                                userSelect: "none"
                                            }
                                        },
                                        {
                                            Header: 'Installer Reference',
                                            accessor: 'InstallerReference'
                                        },
                                        {
                                            Header: 'Installation Completion Date', // Custom header components!
                                            accessor: 'WorkCompletionDate',
                                            Cell: row => {
                                                return (
                                                    <Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.WorkCompletionDate} />
                                                );
                                            }
                                        },
                                        {
                                            Header: 'Submission Date', // Custom header components!
                                            accessor: 'SubmissionDate',
                                            Cell: row => {
                                                return (
                                                    <Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.SubmissionDate} />
                                                );
                                            }
                                        },
                                        {
                                            Header: 'Address',
                                            accessor: 'addressLine1,addressLine2,addressLine3',
                                            width: 200,
                                            Cell: row => {
                                                return (
                                                    <Fragment>
                                                        <span>{row.original.AddressLine1}</span>
                                                    </Fragment>
                                                );
                                            }
                                        },
                                        {
                                            Header: 'Postcode',
                                            accessor: 'Postcode',
                                            width: 120,
                                            Cell: row => {
                                                return (
                                                    <Fragment>
                                                        <span>{row.original.Postcode}</span>
                                                    </Fragment>
                                                );
                                            }
                                        },
                                        {
                                            Header: 'Valid', // Custom header components!
                                            accessor: 'isValid',
                                            filterable: false,
                                            Cell: row => {
                                                return (
                                                    <Fragment>
                                                        {
                                                            row.original.isValid === false ?
                                                                (<span>No<br /></span>) :
                                                                (<span>Yes<br /></span>)
                                                        }
                                                    </Fragment>
                                                );
                                            }
                                        },
                                        {
                                            Header: 'Date Sent to LA', // Custom header components!
                                            accessor: 'SendToLa',
                                            filterable: false,
                                            Cell: row => {
                                                return (
                                                    <Fragment>
                                                        {
                                                            row.original.SendToLa === 0 ?
                                                                (<span>No<br /></span>) :
                                                                (<span>Yes (<Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.SendToLaDate} />)<br /></span>)
                                                        }
                                                    </Fragment>
                                                );
                                            }
                                        },
                                        {
                                            Header: 'Date Certificate sent', // Custom header components!
                                            accessor: 'CertificateDispatchDate',
                                            filterable: false,
                                            Cell: row => {
                                                return (
                                                    <Fragment>
                                                        {
                                                            row.original.CertificateDispatchDate === undefined ?
                                                                (<span>No<br /></span>) :
                                                                (<span>Yes (<Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.CertificateDispatchDate} />)<br /></span>)
                                                        }
                                                    </Fragment>
                                                );
                                            }
                                        },
                                        {
                                            Header: '',
                                            accessor: 'Edit',
                                            filterable: false,
                                            sortable: false,
                                            Cell: row => {
                                                return (
                                                    <Fragment>
                                                        <div className='text-center py-1 pr-5'>
                                                            <CardFooterButtonsIcons
                                                                Id={row.original.Id}
                                                                //DeleteCss={ButtonsJss.DefaultDeleteCss}
                                                                //EditCss={ButtonsJss.DefaultEditCss}
                                                                //ViewCss={ButtonsJss.DefaultViewCss}
                                                                //DeleteUrl={'/Installation/Delete/' + row.original.Id}
                                                                ShowViewButton={this.state.ShowViewButton} // use role priviledge to set visibility
                                                                ViewUrl={'/Installations/View/' + row.original.Id}
                                                            />
                                                        </div>
                                                    </Fragment>
                                                );
                                            }
                                        }
                                    ]
                                }
                                SubComponent={(row) =>
                                    (
                                        <Fragment>
                                            <CardBodyOnly>
                                                <div style={{ paddingTop: '10px' }}>
                                                    <i className="fa fa-home fa-1x pr-4" />
                                                    {row.original.AddressLine1}
                                                </div>
                                                <div className='pl-5'>{row.original.AddressLine2}</div>
                                                <div className='pl-5'>{row.original.AddressLine3}</div>
                                                <div className='pl-5'>{row.original.CityTown}</div>
                                                <div className='pl-5'>{row.original.County}</div>
                                                <div className='pl-5'>{row.original.InstallPostcode}</div>
                                                <div className='pl-5'>{row.original.postcode}</div>
                                            </CardBodyOnly>
                                        </Fragment>
                                    )}
                                className="table-bordered table-striped table-hover table-shadowed"
                                defaultPageSize={this.state.pageSize}
                                expanded={this.state.expanded}
                                filterable={this.state.useFiltering}
                                minRows={0}
                                nextText='>'
                                previousText='<'

                            />
                        ) : this.renderNoDataView()
        }
            </Fragment>
        );
    }

    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    fetchData = () => {

        var MemberDeclarationData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetInstallerInvoiceInstallations",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                CompanyId: this.state.CompanyId,
                InvoiceId: this.state.InvoiceId
            })
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: MemberDeclarationData
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                const installationData = JSON.parse(data.ReturnData.Payload);

                this.setState({
                    Installations: installationData,
                    loading: false
                });
            })
            .catch(data => {
                if (debugOn) {
                    console.log(data);
                }
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - No Data or service not available']
                });
            });
    };

    render() {
        let contents = this.state.loading
            ? <PleaseWait IsLoading={true} />
            : this.renderTable(this.state.Installations);

        return (
            <div className='fluid-container'>
                <div>
                    <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                    {contents}
                </div>
            </div>
        );
    }
}
