import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator';
import { ColumnAndLabel } from '../SharedComponents/ColumnAndLabel';
import moment from 'moment';
import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { ColumnAndLabelRadioOptions } from './../SharedComponents/ColumnAndLabelRadioOptions';
import { ColumnAndLabelSelect } from './../SharedComponents/ColumnAndLabelSelect';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { PleaseWait } from './../SharedComponents/PleaseWait';

import {
    MDBBtn,
    MDBIcon
} from "mdbreact";

const contactHomeUrl = '/Contacts/Home';
const contactViewUrl = '/Contact/View/';
const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class ContactEdit extends Component {

    displayName = ContactEdit.name

    constructor(props) {
        super(props);

        const auth = new AuthHelper();
        //console.log(this.displayName + '.ctor: ' + auth);
        const newContact = props.match.params.id === '-1';
        //console.log('newContact ', newContact);
        this.state = {
            BranchList: [],
            CanEdit: auth.CanEditData,
            CompanyId: auth.CompanyId,
            CompanyContactId: 0,
            ContactEmail: '',
            ContactId: props.match.params.id,
            ContactTypeId: 0,
            ContactTypeList: [],
            FaxNumber: '',
            FirstName: '',
            HomeBranchId: 0,
            IsFensaAdmin: auth.IsFensaAdmin,
            LastName: '',
            MainContact: 0,
            MiddleName: '',
            MobilePhone: '',
            NewReferenceId: '',
            PrimaryContact: false,
            Reference: 0,
            RoleId: 0,
            RoleList: [],
            Telephone: '',
            Title: '',
            UserId: auth.UserId,
            UserStatus: newContact ? 1 : 0,
            formValid: false,
            formErrors: {
                FirstName: newContact ? "Firstname is required." : "",
                LastName: newContact ? "Lastname is required." : "",
                ContactEmail: newContact ? "Email is required." : ""
            },
            isNewContact: newContact ? true : false,
            loading: true,
            loadingContactTypes: true,
            loadingRoles: true,
            loadingBranches: true,
            performPrimaryContactReset: false,
            notificationPostfix: 'info hide',
            notificationMessage: [],
            pageTitle: newContact ? 'Create Contact' : 'Edit Contact',
            processingSubmit: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        console.log(this.displayName, this.props);
    }

    componentDidMount() {
        if (this.state.CanEdit) {
            this.fetchData();
        } else if (!this.state.isNewContact) {
            //redirect to readonly view
            this.props.history.push(contactViewUrl + this.state.ContactId);
        } else {
            this.props.history.push(contactHomeUrl);
        }
    }

    currentPageTitle = () => {
        return this.state.pageTitle;
    }

    togglePleaseWait = status => {
        //console.log('togglePleaseWait.status: ', status);
        this.setState({
            loading: status
        });
    }

    getDataValues = () => {
        return !this.state.isNewContact ?
            JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.UpdateContact",
                origin: "Installer Portal",
                operation: "INSERT",
                datavalues: JSON.stringify({
                    __table__: "Contact",
                    C_Id: this.state.ContactId,
                    C_Name: this.state.FirstName + ' ' + this.state.LastName,
                    C_FirstName: this.state.FirstName,
                    C_MiddleNames: '',
                    C_LastName: this.state.LastName,
                    C_Title: this.state.Title,
                    C_Email: this.state.ContactEmail,
                    C_MobilePhone: this.state.MobilePhone,
                    C_FaxNumber: this.state.FaxNumber,
                    C_Telephone: this.state.Telephone,
                    C_UpdatedBy: this.state.UserId,
                    C_UpdateDate: moment(),
                    C_Deleted: this.state.UserStatus ? 0 : 1,
                    C_ContactTypeId: this.state.ContactTypeId,
                    C_Reference: this.state.Reference,
                    CompanyContact_CC_CompanyContactId:
                    {
                        __table__: "CompanyContact",
                        CC_CompanyContactId: this.state.CompanyContactId,
                        CC_CompanyId: this.state.CompanyId,
                        CC_ContactId: this.state.ContactId,
                        CC_Primary: this.state.PrimaryContact,
                        CC_ContactTypeId: this.state.ContactTypeId,
                        CC_UpdatedBy: this.state.UserId,
                        CC_UpdateDate: moment(),
                        CC_Deleted: this.state.UserStatus ? 0 : 1,
                        CC_MainContact: this.state.MainContact,
                        CC_BranchId: this.state.HomeBranchId,
                        CC_RoleId: this.state.RoleId
                    }
                }),
                validate: false
            }) :
            JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.InsertContact",
                origin: "Installer Portal",
                operation: "INSERT",
                datavalues: JSON.stringify({
                    __table__: "CompanyContact",
                    //CC_CompanyContactId: 0, // not required as will be supplied by sqlachemy engine
                    CC_CompanyId: this.state.CompanyId,
                    //CC_ContactId: this.state.ContactId, // not required as will be matched by sqlachemy engine
                    CC_Primary: this.state.PrimaryContact,
                    CC_ContactTypeId: this.state.ContactTypeId,
                    CC_CreatedBy: this.state.UserId,
                    CC_CreateDate: moment(),
                    CC_Deleted: this.state.UserStatus ? 0 : 1,
                    CC_MainContact: this.state.MainContact,
                    CC_BranchId: this.state.HomeBranchId,
                    CC_RoleId: this.state.RoleId,
                    Contact_CC_ContactId:
                    {
                        __table__: "Contact",
                        //C_Id: 0, // not required as will be matched by sqlachemy engine
                        C_Name: this.state.FirstName + ' ' + this.state.LastName,
                        C_FirstName: this.state.FirstName,
                        C_MiddleNames: '',
                        C_LastName: this.state.LastName,
                        C_Title: this.state.Title,
                        C_Email: this.state.ContactEmail,
                        C_MobilePhone: this.state.MobilePhone,
                        C_FaxNumber: this.state.FaxNumber,
                        C_Telephone: this.state.Telephone,
                        C_CreatedBy: this.state.UserId,
                        C_CreateDate: moment(),
                        C_Deleted: this.state.UserStatus ? 0 : 1,
                        C_ContactTypeId: this.state.ContactTypeId,
                        C_Reference: 0
                    }
                })
            });
    }

    handleShownOnFensaCallback = cbValue => {
        this.setState({
            PrimaryContact: cbValue ? 1 : 0,
            performPrimaryContactReset: cbValue ? true : false
        });
        //console.log(this.displayName + '.handleShownOnFensaCallback: ', cbValue, this.state);
    }

    handleUserStatusCallback = cbValue => {
        this.setState({
            UserStatus: cbValue ? 1 : 0
        });
        //console.log(this.displayName + '.handleUserStatusCallback: ', cbValue, this.state);
    }

    handleInputChange = (event) => {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;

        if (value === undefined) {
            value = target.parentElement.value;
        }
        const name = target.name;
        //this state is only relevant locally - so no need for put it in redux.        
        this.setState({
            [name]: value
        },
            () => { this.validateField(name, value); }
        );
        //console.log(this.displayName + '.handleInputChange: ', name, value, this.state);
    }

    handleOnInputValidation = event => {

        let fieldValidationErrors = this.state.formErrors;
        let message = "";
        switch (event.target.name.toLowerCase()) {
            case 'firstname':
                fieldValidationErrors.FirstName = validator.isEmpty(event.target.value) ? 'Firstname is required.' : '';
                if (fieldValidationErrors.FirstName === "") {
                    fieldValidationErrors.FirstName = validator.isLength(event.target.value, { min: 2 }) ? '' : 'Firstname must be more than 1 character.';
                }
                message = fieldValidationErrors.FirstName;
                break;

            case 'lastname':
                fieldValidationErrors.LastName = validator.isEmpty(event.target.value) ? 'Lastname is required.' : '';
                if (fieldValidationErrors.LastName === "") {
                    fieldValidationErrors.LastName = validator.isLength(event.target.value, { min: 2 }) ? '' : 'Lastname must be more than 1 character.';
                }
                message = fieldValidationErrors.LastName;
                break;

            case 'contactemail':
                fieldValidationErrors.ContactEmail = validator.isEmpty(event.target.value) ? 'Email is Required.' : '';
                if (fieldValidationErrors.ContactEmail === "") {
                    fieldValidationErrors.ContactEmail = validator.isEmail(event.target.value) ? '' : 'Email is not in a valid format.';
                }
                message = fieldValidationErrors.ContactEmail;
                break;

            default:
                break;
        }

        if (message !== "") {
            this.setState({
                formErrors: fieldValidationErrors
            }, this.validateForm);
            //set custom validation error
            event.target.setCustomValidity(message);
        } else {
            //clear custom validation errors
            event.target.setCustomValidity("");
        }
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        switch (fieldName) {
            case 'FirstName':
                fieldValidationErrors.FirstName = validator.isEmpty(this.state.FirstName) ? 'Firstname is required.' : '';
                fieldValidationErrors.FirstName = validator.isLength(this.state.FirstName, { min: 2 }) ? '' : 'Firstname must be more than 1 character.';
                break;

            case 'LastName':
                fieldValidationErrors.LastName = validator.isEmpty(this.state.LastName) ? 'Lastname is required.' : '';
                fieldValidationErrors.LastName = validator.isLength(this.state.LastName, { min: 2 }) ? '' : 'Lastname must be more than 1 character.';
                break;

            case 'ContactEmail':
                fieldValidationErrors.ContactEmail = validator.isEmpty(this.state.ContactEmail) ? 'Email is Required' : '';
                fieldValidationErrors.ContactEmail = validator.isEmail(this.state.ContactEmail) ? '' : 'Email is not in a valid format';
                break;

            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm = () => {
        this.setState({
            formValid: this.state.formErrors.LastName.length === 0
                && this.state.formErrors.ContactEmail.length === 0
        });
        return this.state.formValid;
    }

    submitForm = e => {

        e.preventDefault();

        this.togglePleaseWait(true);

        var postData = this.getDataValues();

        //if (debugOn) {
        //    console.log(this.displayName + '.submitForm[postData]', postData);
        //}

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/InsertObject', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                //if (debugOn) {
                //    console.log(this.displayName + '.submitForm[data]', data);
                //    console.log(this.displayName + '.submitForm[data.message]', JSON.parse(data.message));
                //}
                const msg = JSON.parse(data.message);
                if (msg.Error) {
                    //console.log(this.displayName + '.submitForm[data.message.Error]', msg.Error);
                    this.setState({
                        loading: false,
                        notificationPostfix: 'danger show',
                        notificationMessage: ['Bad Request - Failed to complete update']
                    });
                    return;
                }

                let newContactId = 0;
                //if new contact then get the id of new contact in message
                if (this.state.isNewContact) {
                    newContactId = JSON.parse(data.ReturnData.Payload).Id;
                    //if (debugOn) {
                    //    console.log(this.displayName + '.submitForm - Setting new contact id', newContactId, JSON.parse(data.message));
                    //}
                }
                //this.setState({
                //    //loading: false,
                //    notificationPostfix: 'success show',
                //    //notificationMessage: data.ReturnData.Payload.Errors,
                //    notificationMessage: ['Successfully completed update']
                //});

                // perform main contact reset
                //if (this.state.isNewContact) {
                if (this.state.performPrimaryContactReset) {
                    //console.log(this.displayName + '.submitForm[performPrimaryContactReset]', this.state);
                    // if new contact use the newly created contact id retrieved from previous step
                    var resetValues = JSON.stringify({
                        ContactId: !this.state.isNewContact ? this.state.ContactId : newContactId,
                        CompanyId: this.state.CompanyId
                    });
                    //console.log(this.displayName + '.submitForm.resetValues', resetValues);
                    var resetData = JSON.stringify({
                        seqno: uuid.v4(),
                        datatype: "BIT.Fensa.InstallerPortal.InstallerCompanyContactUpdateMainContact",
                        origin: "Installer Portal",
                        operation: "Search",
                        datavalues: resetValues,
                        validate: false
                    });

                    fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: resetData
                    })
                        .then(response => response.json())
                        .then(data => JSON.parse(data))
                        .then(data => {
                            //console.log(this.displayName + '.submitForm[resetDataResult] ', data);
                            // tell user it was completed successfully
                            if (data.returncode === 1) {
                                // if successful then reload contact list
                                this.props.history.push(contactHomeUrl);
                                return;
                            }
                            //tell the developer that update failed
                            if (debugOn) {
                                console.log(this.displayName + '.submitForm[resetDataResult.Error] ', data.message);
                            }
                            this.setState({
                                loading: false,
                                notificationPostfix: 'danger show',
                                notificationMessage: ['Bad Request']
                            });
                        })
                        .catch(data => {
                            if (debugOn) {
                                console.log(this.displayName + '.submitForm[catch]', data);
                            }
                            this.setState({
                                loading: false,
                                notificationPostfix: 'danger show',
                                notificationMessage: ['Failed to complete']
                            });
                        });

                } else {
                    // if successful then reload contact list
                    this.setState({
                        notificationPostfix: 'success show',
                        notificationMessage: ['Successfully updated contact']
                    });
                    this.props.history.push(contactHomeUrl);
                }
            })
            .catch(data => {
                if (debugOn) {
                    console.log('ERR: submit_callback', data);
                }
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: debugOn ? [data.message] : ['Bad Request - No data or service is unavailable']
                });
            });
    }

    fetchBranches = () => {

        //ContactBranch
        var fetchBranches = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.InstallerBranches",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyId
                })
            })
        });

        Promise.all([fetchBranches])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                //if (debugOn) {
                //    console.log(this.displayName + '.fetchBranches[responses]', responses);
                //}
                var retData = JSON.parse(responses);
                if (retData !== null && retData.returncode === 1) {
                    //const branchData = this.extractData(responses[0]);
                    //if (debugOn) {
                    //    console.log(this.displayName + '.fetchBranches.[branches]', branchData);
                    //}
                    const branchData = JSON.parse(retData.ReturnData.Payload);
                    if (branchData !== null) {
                        this.setState({
                            //loading: false,
                            loadingBranches: false,
                            BranchList: branchData
                        });
                    } else {
                        this.setState({
                            //loading: false,
                            loadingBranches: false,
                            BranchList: []
                        });
                    }
                } else {
                    this.setState({
                        //loading: false,
                        loadingBranches: false,
                        BranchList: []
                    });
                }
            })
            .catch(data => {
                if (debugOn) {
                    console.log(this.displayName + '.fetchBranches.[branches].error', data);
                }
                this.setState({
                    //loading: false,
                    loadingBranches: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['No Data']
                });
            });
    }

    fetchContactTypes = () => {

        //ContactType
        var fetchContactTypes = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.ContactType",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyId
                })
            })
        });

        Promise.all([fetchContactTypes])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                //if (debugOn) {
                //    console.log(this.displayName + '.fetchContactTypes[responses]', responses);
                //}
                const contactTypeData = this.extractData(responses[0]);
                //if (debugOn) {
                //    console.log(this.displayName + '.fetchContactTypes.[contactType]', contactTypeData);
                //}
                this.setState({
                    //loading: false,
                    loadingContactTypes: false,
                    ContactTypeList: contactTypeData
                });
            })
            .catch(data => {
                if (debugOn) {
                    console.log(this.displayName + '.fetchContactTypes.[contactType].error', data);
                }
                this.setState({
                    //loading: false,
                    loadingContactTypes: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - No Content Types']
                });
            });
    }

    fetchRoles = () => {

        //ContactRoles
        var fetchRoles = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.Roles",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyId
                })
            })
        });

        Promise.all([fetchRoles])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                //if (debugOn) {
                //    console.log(this.displayName + '.fetchRoles[responses]', responses);
                //}
                let roleData = this.extractData(responses[0]);
                if (roleData === null) {
                    roleData = [];
                }
                //if (debugOn) {
                //    console.log(this.displayName + '.fetchRoles.[roles]', roleData);
                //}
                this.setState({
                    //loading: false,
                    loadingRoles: false,
                    RoleList: roleData
                });
            })
            .catch(data => {
                if (debugOn) {
                    console.log(this.displayName + '.fetchRoles.[role].error', data);
                }
                this.setState({
                    //loading: false,
                    loadingRoles: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['No Data']
                });
            });
    }

    fetchContactTypesRolesAndBranches = () => {
        this.fetchContactTypes();
        this.fetchRoles();
        this.fetchBranches();
    }

    fetchData = () => {

        //get contact types and branches
        this.fetchContactTypesRolesAndBranches();

        if (this.state.isNewContact) {
            // atomic data has finished loading...
            if (this.state.loadingBranches &&
                this.state.loadingContactTypes &&
                this.state.loadingRoles) {
                this.togglePleaseWait(false);
            }
            return;
        }

        var contactParams = JSON.stringify({
            ContactId: this.state.ContactId,
            CompanyId: this.state.CompanyId
        });

        //ContactItem
        var contactItem = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetContactItem",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: contactParams
        });

        var fetchContactItem = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: contactItem
        });

        Promise.all([fetchContactItem])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                let contactData = this.extractData(responses[0]);
                if (contactData) {
                    contactData = contactData[0];
                }
                //if (debugOn) {
                //    console.log(this.displayName + '.fetchData[contactData]', contactData);
                //}
                this.setState({
                    CompanyContactId: contactData.CompanyContactId,
                    ContactEmail: contactData.Email,
                    ContactTypeId: contactData.ContactTypeId,
                    ContactTypeName: contactData.ContactTypeName,
                    FaxNumber: contactData.FaxNumber,
                    FirstName: contactData.FirstName,
                    HomeBranchId: contactData.BranchId,
                    HomeBranchName: contactData.BranchName,
                    LastName: contactData.LastName,
                    MainContact: contactData.MainContact,
                    MiddleName: '',
                    MobilePhone: contactData.MobilePhone,
                    PrimaryContact: contactData.PrimaryContact,
                    Reference: contactData.Reference,
                    RoleId: contactData.RoleId,
                    RoleName: contactData.RoleName,
                    Telephone: contactData.Telephone,
                    Title: contactData.Title,
                    UserStatus: !contactData.UserStatus ? 1 : 0,
                    loading: false
                });
                //if (debugOn) {
                //    console.log(this.displayName + '.fetchData[contactData.state]', this.state);
                //}
            })
            .catch(data => {
                if (debugOn) {
                    console.log(this.displayName + '.fetchData[contactData.error]', data);
                }
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request']
                });
            });
        this.validateForm();
    }

    render() {

        ReactGA.event({
            category: 'Contact Edit',
            action: 'Edit Contact'
        });


        return (
            <div>
                <form className='demoForm was-validated' onSubmit={this.submitForm.bind(this)} noValidate>
                    <div className='form-group-container'>
                        <div className='form-group-title'>{this.currentPageTitle()}</div>
                        <div className='form-group'>
                            <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                            <div className='row'>
                                <div className='col-xs-12 col-md-4'>
                                    <ColumnAndLabel
                                        ErrorMessage={this.state.formErrors.FirstName}
                                        IconLabel=""
                                        Id="FirstName"
                                        InputType="text"
                                        IsRequired={true}
                                        SizeCss="sm"
                                        HintLabel=""
                                        TextLabel="First Name"
                                        Validate={true}
                                        Value={this.state.FirstName}
                                        HandleInputChange={this.handleInputChange}
                                        HandleOnInput={this.handleOnInputValidation}
                                    />
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ColumnAndLabel
                                        ErrorMessage={this.state.formErrors.LastName}
                                        IconLabel=""
                                        Id="LastName"
                                        InputType="text"
                                        IsRequired={true}
                                        SizeCss="sm"
                                        HintLabel=""
                                        TextLabel="Last Name"
                                        Validate={true}
                                        Value={this.state.LastName}
                                        HandleInputChange={this.handleInputChange}
                                        HandleOnInput={this.handleOnInputValidation}
                                    />
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ColumnAndLabel
                                        ErrorMessage={this.state.formErrors.ContactEmail}
                                        IconLabel=""
                                        Id="ContactEmail"
                                        InputType="email"
                                        IsRequired={true}
                                        SizeCss="sm"
                                        HintLabel=""
                                        TextLabel="Email"
                                        Validate={true}
                                        Value={this.state.ContactEmail}
                                        HandleInputChange={this.handleInputChange}
                                        HandleOnInput={this.handleOnInputValidation}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12 col-md-4'>
                                    <ColumnAndLabel
                                        ErrorMessage=""
                                        IconLabel=""
                                        Id="Telephone"
                                        InputType="text"
                                        IsRequired={false}
                                        SizeCss="sm"
                                        HintLabel=""
                                        TextLabel="Telephone"
                                        Validate={true}
                                        Value={this.state.Telephone}
                                        HandleInputChange={this.handleInputChange}
                                    />
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ColumnAndLabel
                                        ErrorMessage=""
                                        IconLabel=""
                                        Id="MobilePhone"
                                        InputType="text"
                                        IsRequired={false}
                                        SizeCss="sm"
                                        HintLabel=""
                                        TextLabel="Mobile Phone"
                                        Validate={true}
                                        Value={this.state.MobilePhone}
                                        HandleInputChange={this.handleInputChange}
                                    />
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ColumnAndLabel
                                        ErrorMessage=""
                                        IconLabel=""
                                        Id="FaxNumber"
                                        InputType="text"
                                        IsRequired={false}
                                        SizeCss="sm"
                                        HintLabel=""
                                        TextLabel="Fax Number"
                                        Validate={true}
                                        Value={this.state.FaxNumber}
                                        HandleInputChange={this.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className='row pb-3'>
                                <div className='col-xs-12 col-md-6'>
                                    <ColumnAndLabelSelect
                                        id="ContactTypeId"
                                        name="ContactTypeId"
                                        textLabel="Job Title"
                                        value={this.state.ContactTypeId}
                                        handleChange={this.handleInputChange}
                                        optionIdField="ID"
                                        optionTextField="Name"
                                        optionsPayload={this.state.ContactTypeList}
                                    >
                                        {
                                            this.state.ContactTypeList.map(contactType =>
                                                <option value={contactType.ID} key={contactType.ID}>{contactType.Name}</option>)
                                        }
                                    </ColumnAndLabelSelect>
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                    <ColumnAndLabelSelect
                                        id="RoleId"
                                        name="RoleId"
                                        textLabel="Role"
                                        value={this.state.RoleId}
                                        handleChange={this.handleInputChange}
                                        optionIdField="Id"
                                        optionTextField="Name"
                                        optionsPayload={this.state.RoleList}
                                        prompt="Select Role"
                                    >
                                        <option value={0}>Select Role</option>
                                        {
                                            this.state.RoleList.map(role =>
                                                <option value={role.Id} key={role.Id}>{role.Name}</option>)
                                        }
                                    </ColumnAndLabelSelect>
                                </div>
                            </div>
                            <div className='row pb-3'>
                                <div className='col-xs-12 col-md-6'>
                                    <ColumnAndLabelSelect
                                        id="HomeBranchId"
                                        name="HomeBranchId"
                                        textLabel="Located at Branch"
                                        value={this.state.HomeBranchId}
                                        handleChange={this.handleInputChange}
                                        optionIdField="Id"
                                        optionTextField="Name"
                                        optionsPayload={this.state.BranchList}
                                        prompt="Select Branch"
                                    >
                                        <option value={0}>Select Branch</option>
                                        {
                                            this.state.BranchList.map(branch =>
                                                <option value={branch.Id} key={branch.Id}>{branch.Name}</option>)
                                        }
                                    </ColumnAndLabelSelect>
                                </div>
                                
                            </div>
                            <div className='row py-3'>
                                <div className='col-xs-12 col-md-6 py-3'>
                                    <ColumnAndLabelRadioOptions
                                        id="PrimaryContact"
                                        name="PrimaryContact"
                                        textLabel="Show Details on FENSA Website"
                                        optionNo={'No'}
                                        optionYes={'Yes'}
                                        value={this.state.PrimaryContact}
                                        handleChange={this.handleUserStatusCallback} />
                                </div>
                                <div className={!this.state.isNewContact ? 'col-xs-12 col-md-4 py-3' : 'col-xs-12 col-md-4 py-3 hide'}>
                                    <ColumnAndLabelRadioOptions
                                        id="UserStatus"
                                        name="UserStatus"
                                        textLabel="Is Active"
                                        optionNo={'No'}
                                        optionYes={'Yes'}
                                        value={this.state.UserStatus}
                                        handleChange={this.handleUserStatusCallback} />
                                </div>
                            </div>
                            <div className='row pb-2'>
                                <div className='col text-right'>
                                    <PleaseWait IsLoading={this.state.loading} />
                                    <MDBBtn
                                        className="pull-right mdb-btn-resize"
                                        color="success"
                                        type="submit"
                                        size="sm"
                                        disabled={!this.state.formValid || this.state.processingSubmit}
                                        onClick={this.toggleModal}
                                    >
                                        <MDBIcon icon="save" className="mr-2" />Submit
                                    </MDBBtn>
                                    <Link className='btn btn-sm btn-danger pull-right mr-2 mdb-btn-resize' to={contactHomeUrl}>
                                        <i className='fa fa-arrow-left pr-2' />Cancel
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

/// Handle data retrieval and handle ensuing errors if data is not found
ContactEdit.prototype.extractData = response => {
    try {
        return JSON.parse(response).returncode === 1 ? JSON.parse(JSON.parse(response).message) : null;
    } catch (e) {
        console.log(e);
        return null;
    }
};