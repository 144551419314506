import React, { Component } from 'react';
import { MDBSwitch } from "mdbreact";

export class ColumnAndLabelRadioOptions extends Component {
    displayName = ColumnAndLabelRadioOptions.name

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            switchOn: props.value === 1
        };
        //console.log(this.displayName + '.ctor: ', this.state);
    }

    /// handle state updates from the parent
    componentWillReceiveProps = nextProps => {
        //console.log('ColumnAndLabelRadioOptions.componentWillReceiveProps', nextProps);
        // if the value supplied is different to the initial value then change
        if (nextProps.value !== this.state.value) {
            this.setState({
                switchOn: nextProps.value
            });
        }
    }

    handleSwitchChange = event => {
        //console.log(this.displayName + '.handleSwitchChange', event);
        const target = event.target;
        const isOn = target.checked;
        this.setState({ switchOn: isOn });
        this.state.handleChange(isOn);
        //console.log(this.displayName + '.handleSwitchChange', this.state);
    }

    render() {
        return (
            <div className={this.state.visibleCss}>
                <label>{this.state.textLabel}</label>
                <div className="btn-group btn-group-toggle pl-3" data-toggle="buttons">
                    <MDBSwitch
                        checked={this.state.switchOn}
                        color="primary"
                        onChange={this.handleSwitchChange}
                        disabled={this.state.readOnly}
                        labelLeft=""
                        labelRight=""
                    />
                </div>
                {this.state.switchOn ? this.props.optionYes : this.props.optionNo}
            </div>
        );
    }
}