import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import AuthHelper from "./AuthHelper";
import { AvatarJss } from './AvatarJss';

//import "./Login.css";

import AccountAvatarIcon from "@material-ui/icons/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from '@material-ui/core/Typography';

class User extends Component {
    displayName = User.name;

    constructor(props) {
        super(props);
        const auth = new AuthHelper();
        //console.log(this.displayName + '.ctor', auth);
        this.state = {
            Email: auth.Email,
            UserDisplayName: auth.UserDisplayName,
            UserName: auth.Username,
            ...this.props
        };
    }

    handleLogout = () => {
        new AuthHelper().LogOut();
        this.props.handleAuthenticated(false);
    }

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const open = Boolean(this.state.anchorEl);
        return (
            <Fragment>
                {
                    this.props.Authenticated ?
                        (
                            <Fragment>
                                <Typography variant="h6" className={classNames(this.state.classes.title)}>{this.state.UserDisplayName}</Typography>
                                <Tooltip title={this.state.UserDisplayName} placement="bottom-end">
                                    <IconButton
                                        aria-owns={open ? "menu-appbar" : undefined}
                                        aria-haspopup="true"
                                        onClick={this.handleMenu}
                                        color="inherit"
                                    >
                                        <AccountAvatarIcon className={classNames(this.state.classes.lgAvatar)} />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={this.state.anchorEl}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right"
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right"
                                    }}
                                    open={open}
                                    onClose={this.handleClose}
                                >
                                    <MenuItem onClick={this.handleClose}>
                                        <Link to="/MyDetails" className={"nav-link"}>My Profile</Link>
                                    </MenuItem>
                                    <MenuItem onClick={this.handleClose}>
                                        <Link to="/UserManagement/ConfigureDashboard" className={"nav-link"}>Change Company</Link>
                                    </MenuItem>
                                    <MenuItem onClick={this.handleClose}>
                                        <Link to="/UserManagement/ChangePassword" className={"nav-link"}>Change Password</Link>
                                    </MenuItem>
                                    <MenuItem onClick={this.handleLogout}>
                                        <Link to="/" className={"nav-link"}>Log Out</Link>
                                    </MenuItem>
                                </Menu>
                            </Fragment>
                        ) : null
                }
            </Fragment>
        );
    }
}

User.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(AvatarJss)(User);