
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { AddressCardBody } from './../SharedComponents/AddressCardBody';
import { AddressLookup } from '../SharedComponents/AddressLookup';
import { ColumnAndLabel } from '../SharedComponents/ColumnAndLabel';
import { ColumnAndLabelRadioOptions } from './../SharedComponents/ColumnAndLabelRadioOptions';
import { ColumnAndLabelSelect } from './../SharedComponents/ColumnAndLabelSelect';
import { DocumentView } from '../SharedComponents/DocumentView';
import { FileUpload } from '../SharedComponents/FileUpload';
import { ImageView } from '../SharedComponents/ImageView';
import { NotificationPanel } from '../SharedComponents/NotificationPanel';
import { NotificationToast } from '../SharedComponents/NotificationToast';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import { ReadonlyItem } from './../SharedComponents/ReadonlyItem';

import { MDBBtn, MDBIcon } from "mdbreact";

const operativesHomeUrl = "/Operatives/OurOperatives";
const operativesEditUrl = "/Operatives/OurOperatives/Update/";
const operativesViewUrl = "/Operatives/OurOperatives/View/";
export class OperativeEdit extends Component {
    displayName = "OperativeEdit";

    constructor(props) {
        super(props);
        const auth = new AuthHelper();
        const newOperative = props.match.params.id === '-1';
        this.state = {
            Address1: '',
            Address2: '',
            Address3: '',
            AddressId: '',
            CanEdit: auth.CanEditData,
            CityTown: '',
            CompanyId: auth.CompanyId,
            CompanyReference: auth.CompanyReference,
            Competency: '',
            CompetencyType: [
                {
                    ID: -1,
                    Name: 'Select Option'
                },
                {
                    ID: 0,
                    Name: 'Competency'
                },
                {
                    ID: 1,
                    Name: 'Qualification'
                }
            ],
            County: '',
            DateOfBirth: '',
            DocumentDescription: '',
            Email: '',
            FileProcessing: '',
            FirstName: '',
            LastName: '',
            MtcPreRegistrationDetailId: '',
            NationalInsuranceNo: '',
            OperativeDocuments: [],
            OperativeEmployerType: [],
            OperativeEmployerTypeId: '',
            OperativeEmployerTypeValue: '',
            OperativeId: props.match.params.id,
            OperativePhoto: '',
            OperativePhotos: [],
            OperativeStatusType: [],
            OperativeType: [],
            OperativeTypeValue: '',
            OperativeVerified: true,
            PersonId: props.match.params.id,
            Postcode: '',
            SearchPostcode: '',
            ShowCompetancy: false,
            ShowQualification: false,
            Status: '',
            Telephone: '',
            Title: '',
            UploadErrors: [],
            UploadFile: '',
            UserId: auth.UserId,
            UserName: auth.Username,
            UserStatus: newOperative ? 1 : 0,
            VerifiedDate: '',

            formValid: true,
            isNewOperative: props.match.params.id === '-1' ? true : false,
            loading: true,
            loadingDocs: true,
            loadingPhotos: true,
            notificationMessage: [],
            notificationPostfix: '',
            pageTitle: newOperative ? 'Create Operative' : 'Edit Operative',
            toastMessage: []
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        //this.onClick = this.onClick.bind(this);
        console.log(this.displayName + '.ctor', this.state);
    }

    componentDidMount() {
        if (this.state.CanEdit) {
            this.fetchData();
        } else if (!this.state.isNewOperative) {
            //redirect to readonly view
            this.props.history.push(operativesViewUrl + this.state.OperativeId);
        } else {
            this.props.history.push(operativesHomeUrl);
        }
    }

    currentPageTitle = () => {
        return this.state.pageTitle;
    }

    handleAddressNotFound = msg => {
        //show the message if not found
        console.log(msg);
        this.setState({
            loading: false,
            notificationPostfix: 'danger show',
            notificationMessage: [msg]
        });
    }

    handleAddressSelected = address => {
        this.setState({
            Address1: address.Address1,
            Address2: address.Address2,
            Address3: address.Address3,
            CityTown: address.CityTown,
            County: address.County,
            Postcode: address.Postcode,
            hideAddress: false,
            notificationPostfix: 'info hide',
            notificationMessage: []
        });
    }

    handlePostcodeChange = event => {
        const target = event.target;
        var value = target.value;

        if (value === undefined) {
            value = target.parentElement.value;
        }

        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            //Postcode: value,
            SearchPostcode: value
        });
    }

    handleLookupClick = status => {
        //if (debugOn) {
        //    console.log(this.displayName + '.handleLookupClick[start]', status);
        //}
        this.togglePleaseWait(status);
        //clear errors
    }

    togglePleaseWait = status => {
        this.setState({
            loading: status
        });
    }

    updateNotificationToastCallback = (msg, status) => {
        this.setState({
            notificationMessage: [msg],
            notificationPostfix: status ? 'success show' : 'warning hide',
            toastMessage: msg
        });
    }

    formSubmit = e => {
        e.preventDefault();
        this.togglePleaseWait(true);

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.HelixAdmin.UpdateInstaller_MTC_OperativeItem",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    Address1: this.state.Address1,
                    Address2: this.state.Address2,
                    Address3: this.state.Address3,
                    AddressId: this.state.AddressId,
                    CityTown: this.state.CityTown,
                    CompanyId: this.state.CompanyId,
                    Competency: this.state.Competency,
                    County: this.state.County,
                    DateOfBirth: this.state.DateOfBirth,
                    Email: this.state.Email,
                    FirstName: this.state.FirstName,
                    LastName: this.state.LastName,
                    MemberNo: this.state.CompanyReference,
                    MtcPreRegistrationDetailId: this.state.MtcPreRegistrationDetailId,
                    NationalInsuranceNo: this.state.NationalInsuranceNo,
                    OperativeEmployerTypeId: this.state.OperativeEmployerTypeId,
                    OperativeEmployerTypeValue: this.state.OperativeEmployerTypeValue,
                    OperativeTypeValue: this.state.OperativeTypeValue,
                    PersonId: this.state.PersonId,
                    Postcode: this.state.Postcode,
                    Status: this.state.Status,
                    Telephone: this.state.Telephone,
                    Title: this.state.Title,
                }),
                validate: false
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                console.log(this.displayName + '.formSubmit', data);
                console.log(this.displayName + '.formSubmit', data.message);
                console.log(this.displayName + '.formSubmit[message]', JSON.parse(data.message));
                if (data.returncode === 1 && this.state.isNewOperative) {
                    // if successful then reload with the id
                    //this.props.history.push(operativesEditUrl + JSON.parse(data.message).PersonId);
                    new AuthHelper().ReloadUrl(operativesEditUrl + JSON.parse(data.message).PersonId);
                    return;
                } else if(data.returncode === 1) {
                    //if new operative redirect to list page
                    var msg = [JSON.parse(data.message).message];
                    if (!msg) {
                        msg = 'Please refresh this screen';
                    }
                    this.setState({
                        OperativeId: JSON.parse(data.message).PersonId,
                        isNewOperative: false,
                        loading: false,
                        notificationMessage: msg,
                        notificationPostfix: data.returncode === 1 ? 'success show' : 'danger show'
                    });

                    this.fetchData();
                }
                else {
                    //if new operative redirect to list page
                    this.setState({
                        //OperativeId: JSON.parse(data.message).PersonId,
                        isNewOperative: false,
                        loading: false,
                        notificationMessage: [JSON.parse(data.message).message],
                        notificationPostfix: 'danger show'
                    });

                    this.fetchData();
                }
            });
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        if (value === undefined) {
            value = target.parentElement.value;
        }
        const name = target.name;
        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        });
        switch (name) {
            case "Competency":
                if (value === '0') {
                    this.setState({
                        ShowCompetancy: true,
                        ShowQualification: false
                    });
                }
                else if (value === "1") {
                    this.setState({
                        ShowCompetancy: false,
                        ShowQualification: true
                    });
                }
                else {
                    this.setState({
                        ShowCompetancy: false,
                        ShowQualification: false
                    });
                }
                break;
        }
    }

    fetchPhotos = () => {
        // Now fetch any photos / documents associated with the operative
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.HelixAdmin.GetInstaller_MTC_Person_Photo",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyReference: this.state.CompanyReference,
                    OperativeId: this.state.OperativeId,
                    MtcPreRegistrationDetailId: this.state.MtcPreRegistrationDetailId
                }),
                validate: false
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                this.setState({
                    OperativePhotos: data.ReturnData.Payload === null ? [] : JSON.parse(data.ReturnData.Payload),
                    loadingPhotos: false
                });
            });
    }

    fetchDocuments = () => {
        // Now fetch any photos / documents associated with the operative
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.HelixAdmin.GetInstaller_MTC_Person_Document",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyReference,
                    OperativeId: this.state.OperativeId,
                    MtcPreRegistrationDetailId: this.state.MtcPreRegistrationDetailId
                }),
                validate: false
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                this.setState({
                    OperativeDocuments: data.ReturnData.Payload === null ? [] : JSON.parse(data.ReturnData.Payload),
                    loadingDocs: false
                });
            });
    }

    fetchData = () => {
        var datavalues = JSON.stringify({
            CompanyId: this.state.CompanyReference,
            OperativeId: this.state.OperativeId
        });

        var fetchOperativeItem = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.HelixAdmin.GetInstaller_MTC_OperativeItem",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: datavalues
            })
        });

        var fetchOperativeType = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.HelixAdmin.GetInstaller_MTC_Type",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: datavalues
            })
        });

        var fetchOperativeEmployerType = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.InstallerOperativeEmployerType",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: datavalues
            })
        });

        var fetchOperativeStatusType = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.HelixAdmin.GetInstaller_MTC_RegistrationStatus",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: datavalues
            })
        });

        var fetchCompetencyType = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.HelixAdmin.GetInstaller_MTC_Route",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: datavalues
            })
        });

        Promise.all([fetchOperativeType, fetchOperativeEmployerType, fetchOperativeStatusType, fetchOperativeItem, fetchCompetencyType])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                this.setState({
                    Address1: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].AddressBuildingOrNumber,
                    Address2: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].AddressLine1,
                    Address3: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].AddressLine2,
                    AddressId: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].AddressId,
                    CityTown: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].City,
                    Competency: this.state.isNewOperative ? '1' : JSON.parse(JSON.parse(responses[3]).message)[0].RouteID,
                    CompetencyType: JSON.parse(JSON.parse(responses[4]).message),
                    County: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].CountyTown,
                    DateOfBirth: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].DateOfBirth,
                    Email: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].EmailAddress,
                    FirstName: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].FirstName,
                    LastName: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].LastName,
                    MtcPreRegistrationDetailId: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].MtcPreRegistrationDetailId,
                    NationalInsuranceNo: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].NationalInsuranceNo,
                    OperativeEmployerType: JSON.parse(JSON.parse(responses[1]).message),
                    OperativeEmployerTypeId: this.state.isNewOperative ? '1' : JSON.parse(JSON.parse(responses[3]).message)[0].OperativeEmployerTypeId,
                    OperativeStatusType: JSON.parse(JSON.parse(responses[2]).message),
                    OperativeType: JSON.parse(JSON.parse(responses[0]).message),
                    OperativeTypeValue: this.state.isNewOperative ? '1' : JSON.parse(JSON.parse(responses[3]).message)[0].CardTypeID,
                    OperativeVerified: this.state.isNewOperative ? '' : false, //JSON.parse(JSON.parse(responses[3]).message)[0].VerifiedByFensa,
                    PersonId: this.state.isNewOperative ? '-1' : JSON.parse(JSON.parse(responses[3]).message)[0].PersonId,
                    Postcode: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].Postcode,
                    SearchPostcode: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].Postcode,
                    Status: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].RegistrationStatusId,
                    Telephone: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].PhoneNumber,
                    Title: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].Salutation,
                    VerifiedDate: this.state.isNewOperative ? '' : JSON.parse(JSON.parse(responses[3]).message)[0].VerifiedDate,
                    loading: false
                });
                // Now fetch any photos / documents associated with the operative
                this.fetchPhotos();
                this.fetchDocuments();
            });
    }

    render() {

        ReactGA.event({
            category: 'Operative Edit',
            action: 'Operative Edit View'
        });

        return (
            <div>
                <div>
                    <form className='demoForm' onSubmit={this.formSubmit.bind(this)}>
                        <div className='form-group-container mr-auto ml-auto'>
                            <div className='form-group-title'>{this.currentPageTitle()}</div>
                            <div className='row py-3'>
                                <div className='col text-right'>                                   
                                    <Link className='btn btn-sm btn-dark pull-right mr-2 mdb-btn-resize' to={operativesHomeUrl}>
                                        <i className='fa fa-arrow-left pr-2' />Back to Operatives</Link>
                                </div>
                            </div>
                            <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                            <PleaseWait IsLoading={this.state.loading} />
                            
                             <div className='row'>
                                 <div className='col-xs-12 col-md-2'>
                                    <ReadonlyItem textLabel="Person Id">{this.state.PersonId === '-1' ? '' : this.state.PersonId}</ReadonlyItem>
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ColumnAndLabel
                                        ErrorMessage=""
                                        IconLabel=""
                                        Id="NationalInsuranceNo"
                                        InputType="text"
                                        IsRequired={false}
                                        SizeCss="md"
                                        HintLabel=""
                                        TextLabel="National Insurance Number"
                                        Validate={true}
                                        Value={this.state.NationalInsuranceNo}
                                        HandleInputChange={this.handleInputChange}
                                        max="9"
                                    />
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                    <ColumnAndLabel
                                        IconLabel="calendar"
                                        Id="DateOfBirth"
                                        InputType="date"
                                        IsRequired={true}
                                        SizeCss="md"
                                        HintLabel=""
                                        TextLabel="Date Of Birth"
                                        Validate={true}
                                        Value={moment(this.state.DateOfBirth).format('YYYY-MM-DD')}
                                        HandleInputChange={this.handleInputChange}
                                    />
                                </div>
                            
                           
                                <div className='col-xs-12 col-md-6'> 
                                    <ColumnAndLabel
                                        ErrorMessage=""
                                        IconLabel=""
                                        Id="FirstName"
                                        InputType="text"
                                        IsRequired={false}
                                        SizeCss="md"
                                        HintLabel=""
                                        TextLabel="First Name"
                                        Validate={true}
                                        Value={this.state.FirstName}
                                        HandleInputChange={this.handleInputChange}
                                    />
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                    <ColumnAndLabel
                                        ErrorMessage=""
                                        IconLabel=""
                                        Id="LastName"
                                        InputType="text"
                                        IsRequired={false}
                                        SizeCss="md"
                                        HintLabel=""
                                        TextLabel="Last Name"
                                        Validate={true}
                                        Value={this.state.LastName}
                                        HandleInputChange={this.handleInputChange}
                                    />
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                    <ColumnAndLabel
                                        ErrorMessage=""
                                        IconLabel=""
                                        Id="Email"
                                        InputType="text"
                                        IsRequired={false}
                                        SizeCss="md"
                                        HintLabel=""
                                        TextLabel="Email"
                                        Validate={true}
                                        Value={this.state.Email}
                                        HandleInputChange={this.handleInputChange}
                                    />
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                    <ColumnAndLabel
                                        ErrorMessage=""
                                        IconLabel=""
                                        Id="Telephone"
                                        InputType="text"
                                        IsRequired={false}
                                        SizeCss="md"
                                        HintLabel=""
                                        TextLabel="Telephone"
                                        Validate={true}
                                        Value={this.state.Telephone}
                                        HandleInputChange={this.handleInputChange}
                                    />
                                </div>
                                <div className='col-xs-12 col-md-6 pt-3'>
                                    <ColumnAndLabelSelect
                                        id="OperativeTypeValue"
                                        name="OperativeTypeValue"
                                        textLabel="Occupation Type"
                                        value={this.state.OperativeTypeValue}
                                        handleChange={this.handleInputChange}
                                        optionIdField="Id"
                                        optionTextField="Name"
                                        optionsPayload={this.state.OperativeTypeValue}
                                        prompt="Select Occupation Type"
                                    >
                                        <option value={0}>Select Occupation Type</option>
                                        {
                                            this.state.OperativeType.map(ContactType =>
                                                <option value={ContactType.ID} key={ContactType.ID}>{ContactType.TypeDescription}</option>)
                                        }
                                    </ColumnAndLabelSelect>
                                </div>
                                <div className='col-xs-12 col-md-6 pt-3'>
                                    <ColumnAndLabelSelect
                                        id="OperativeEmployerTypeId"
                                        name="OperativeEmployerTypeId"
                                        textLabel="Operative Employer Type"
                                        value={this.state.OperativeEmployerTypeId}
                                        handleChange={this.handleInputChange}
                                        optionIdField="Id"
                                        optionTextField="Name"
                                        optionsPayload={this.state.OperativeEmployerTypeValue}
                                        prompt="Select Operative Employer Type"
                                    >
                                        <option value={0}>Select Operative Employer Type</option>
                                        {
                                            this.state.OperativeEmployerType.map(x =>
                                                <option value={x.ID} key={x.ID}>{x.Name}</option>)
                                        }
                                    </ColumnAndLabelSelect>
                                </div>
                                <div className='col-xs-12 col-md-6 pt-4'>
                                    <ColumnAndLabelSelect
                                        id="Status"
                                        name="Status"
                                        textLabel="Status"
                                        value={this.state.Status}
                                        handleChange={this.handleInputChange}
                                        optionIdField="Id"
                                        optionTextField="Name"
                                        optionsPayload={this.state.Status}
                                        prompt="Select Status"
                                    >
                                        <option value={0}>Select Status</option>
                                        {
                                            this.state.OperativeStatusType.map(x =>
                                                <option value={x.ID} key={x.ID}>{x.StatusName}</option>)
                                        }
                                    </ColumnAndLabelSelect>
                                </div>
                                <div className='col-xs-12 col-md-6 pt-4'>
                                    <ColumnAndLabelSelect
                                        id="Competency"
                                        name="Competency"
                                        textLabel="Competency / Qualification"
                                        value={this.state.Competency}
                                        handleChange={this.handleInputChange}
                                        optionIdField="Id"
                                        optionTextField="Name"
                                        optionsPayload={this.state.Competency}
                                        prompt="Select Competency / Qualification"
                                    >
                                        <option value={0}>Select Competency / Qualification</option>
                                        {
                                            this.state.CompetencyType.map(x =>
                                                <option value={x.ID} key={x.ID}>{x.MtcRouteDescription}</option>)
                                        }
                                    </ColumnAndLabelSelect>
                                </div>                            
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <AddressLookup
                                        PostcodeId="Postcode"
                                        PostcodeText="Post code"
                                        Postcode={this.state.SearchPostcode}
                                        handleChange={this.handlePostcodeChange}
                                        handleLookupClick={this.handleLookupClick}
                                        handleAddressNotFound={this.handleAddressNotFound}
                                        HandleAddressSelected={this.handleAddressSelected}
                                        loading={this.state.loading}
                                    />
                                </div>
                            </div>
                            <div className='row pb-1' hidden={this.state.hideAddress}>
                                <div className='col-12 text-muted'>
                                    <AddressCardBody
                                        AddressLabel={"Address"}
                                        Address1={this.state.Address1}
                                        Address2={this.state.Address2}
                                        Address3={this.state.Address3}
                                        CityTown={this.state.CityTown}
                                        County={this.state.County}
                                        Postcode={this.state.Postcode}
                                    />
                                </div>
                            </div>
                            <div className='row py-3'>
                                <div className='col-xs-12 col-md-2' />
                                <div className='col-xs-12 col-md-4 py-4'>
                                    <ColumnAndLabelRadioOptions
                                        id="OperativeVerified"
                                        name="OperativeVerified"
                                        textLabel="Verified By Fensa"
                                        optionNo={'No'}
                                        optionYes={'Yes'}
                                        value={this.state.OperativeVerified}
                                        handleChange={this.handleInputChange}
                                        readOnly="true" />
                                </div>
                                <div className='col-xs-12 col-md-4 py-0'>
                                    <ReadonlyItem textLabel="Verified Date">{!this.state.VerifiedDate ? 'Pending' : moment(this.state.VerifiedDate).format('DD MMM YYYY')}</ReadonlyItem>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 pt-3'>
                                    <PleaseWait IsLoading={this.state.loadingPhotos} />
                                    <FileUpload Title="Photos"
                                        Buttontext="Upload Photo"
                                        DataType="BIT.Fensa.InstallerPortal.MongoDB.Photo"
                                        HandleOnUpload=""
                                        IncludeDescription={false}
                                        InformationText="Passport style colour photo of your head and shoulders only. This photo will be used on your FENSA Skills card."
                                        MtcPreRegistrationDetailId={this.state.MtcPreRegistrationDetailId}
                                        MultipleMode={true}
                                        UpdateNotificationMessage={this.updateNotificationToastCallback}
                                        UploadButtonText="Browse"
                                        UploadFieldText="Choose photo"
                                        UploadName="uploadPhotos"
                                    />
                                    {
                                        this.state.OperativePhotos.map((UploadResult) =>
                                            (
                                                <ImageView Identifier={UploadResult.ContentIdentifier}
                                                    id={UploadResult.MTC_Person_Photo_Id}
                                                    FileName={UploadResult.FileName}
                                                    OperativeId={this.state.OperativeId}
                                                    ContentType={UploadResult.ContentType}
                                                />
                                            )
                                        )
                                    }
                                </div>
                                <div className='col-12 pt-3'>
                                    <PleaseWait IsLoading={this.state.loadingDocs} />
                                    <FileUpload Title="Evidence"
                                        Buttontext="Upload Evidence"
                                        DataType="BIT.Fensa.InstallerPortal.MongoDB.Evidence"
                                        HandleOnUpload=""
                                        IncludeDescription={true}
                                        InformationText="Evidence should be provided"
                                        MtcPreRegistrationDetailId={this.state.MtcPreRegistrationDetailId}
                                        MultipleMode={true}
                                        UpdateNotificationMessage={this.updateNotificationToastCallback}
                                        UploadButtonText="Browse"
                                        UploadFieldText="Choose document"
                                        UploadName="uploadDocs"
                                    />
                                    {
                                        this.state.OperativeDocuments.map((UploadResult) =>
                                            (
                                                <DocumentView
                                                    Identifier={UploadResult.ContentIdentifier}
                                                    id={UploadResult.MTC_Person_Document_Id}
                                                    FileName={UploadResult.FileName}
                                                    OperativeId={this.state.OperativeId}
                                                    DocumentDescription={UploadResult.ContentDescription}
                                                    ContentType={UploadResult.ContentType}
                                                />
                                            )
                                        )
                                    }
                                </div>
                            </div>
                            <div className='row py-3'>
                                <div className='col text-right'>
                                    <MDBBtn
                                        className="pull-right mdb-btn-resize"
                                        color="success"
                                        type="submit"
                                        size="sm"
                                        disabled={!this.state.formValid}
                                        visible={this.state.CanEdit}
                                    >
                                        <MDBIcon icon="save" className="mr-2" />Submit
                                    </MDBBtn>
                                    <Link className='btn btn-sm btn-dark pull-right mr-2 mdb-btn-resize' to={operativesHomeUrl}>
                                        <i className='fa fa-arrow-left pr-2' />Back to Operatives</Link>
                                </div>
                            </div>
                            <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                            {/*<NotificationToast ToastTimeout={3000} NotificationTitle="Operative edit" NotificationMessage={this.state.toastMessage} />*/}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
