import React, { Component } from 'react';

export class InspectionsActionsRequired extends Component {
    displayName = InspectionsActionsRequired.name;

    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">Assessments actions required section is under construction</div>
                </div>
            </div>
        );
    }
}