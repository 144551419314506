import React, { Component } from 'react';

import { CardBodyOnly } from '../SharedComponents/CardBodyOnly';

import { MDBInput, MDBRow, MDBCol } from 'mdbreact';

import { MDBBtn, MDBIcon } from "mdbreact";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { OpeningHours } from './OpeningHours';

import { ColumnAndLabel } from '../SharedComponents/ColumnAndLabel';
import { ReduxNotificationPanel } from '../SharedComponents/ReduxNotificationPanel';
import { NotificationPanel } from '../SharedComponents/NotificationPanel';


export default class InformationView extends Component {
    displayName = InformationView.name

    constructor(props) {
        super(props);

        this.state = {
            ...props
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {

        event.preventDefault();

        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;

        if (value === undefined) {
            value = target.parentElement.value;
        }
        const name = target.name;

        //console.log("On Change name", name);
        //console.log("On Change value", value);
        //console.log("On Change target value", target.value);
        //console.log("On Change value", this.props.SelectedMaterial);

        switch (name) {
            case 'Material':
                this.props.setWorkMaterials(target.value, value);

                break;

            case 'Service':
                this.props.setWorkServices(target.value, value);
                
                break;

            case 'Property':
                this.props.setPropertyType(target.value, value);
                               
                break;

            case 'Radius':

                this.props.setWorkData(this.props.CompanyId,
                    this.props.CompanyDescription,
                    this.props.IsManufacturer,
                    this.props.LocalAuthorities,                    
                    this.props.OfferFinance,
                    this.props.HasShowroom,
                    this.props.ContactEmail,
                    this.props.AdditionalContactEmail,
                    parseInt(target.value),
                    this.props.SelectedMinimumHours,
                    this.props.CallOut24Hours
                );                


                break;

            case 'MinimumHours':
                this.props.setWorkData(this.props.CompanyId,
                    this.props.CompanyDescription,
                    this.props.IsManufacturer,
                    this.props.LocalAuthorities,
                    this.props.OfferFinance,
                    this.props.HasShowroom,                    
                    this.props.ContactEmail,
                    this.props.AdditionalContactEmail,
                    this.props.SelectedWorkRadius,
                    parseInt(target.value),                    
                    this.props.CallOut24Hours
                );             

                break;


            case 'IsManufacturer':
                this.props.setWorkData(this.props.CompanyId,
                    this.props.CompanyDescription,
                    value,
                    this.props.LocalAuthorities,
                    this.props.OfferFinance,
                    this.props.HasShowroom,                    
                    this.props.ContactEmail,
                    this.props.AdditionalContactEmail,
                    this.props.SelectedWorkRadius,
                    this.props.SelectedMinimumHours,
                    this.props.CallOut24Hours
                );

                break;

            case 'LocalAuthorities':
                this.props.setWorkData(this.props.CompanyId,
                    this.props.CompanyDescription,
                    this.props.IsManufacturer,
                    value,
                    this.props.OfferFinance,
                    this.props.HasShowroom,                    
                    this.props.ContactEmail,
                    this.props.AdditionalContactEmail,
                    this.props.SelectedWorkRadius,
                    this.props.SelectedMinimumHours,
                    this.props.CallOut24Hours
                );

                break;

            case 'HasShowroom':
                this.props.setWorkData(this.props.CompanyId,
                    this.props.CompanyDescription,
                    this.props.IsManufacturer,
                    this.props.LocalAuthorities,
                    this.props.OfferFinance,
                    value,                    
                    this.props.ContactEmail,
                    this.props.AdditionalContactEmail,
                    this.props.SelectedWorkRadius,
                    this.props.SelectedMinimumHours,
                    this.props.CallOut24Hours
                );

                break;


            case 'OfferFinance':
                this.props.setWorkData(this.props.CompanyId,
                    this.props.CompanyDescription,
                    this.props.IsManufacturer,
                    this.props.LocalAuthorities,
                    value,
                    this.props.HasShowroom,                    
                    this.props.ContactEmail,
                    this.props.AdditionalContactEmail,
                    this.props.SelectedWorkRadius,
                    this.props.SelectedMinimumHours,
                    this.props.CallOut24Hours
                );

                break;

            case 'CallOut24Hours':

                this.props.setWorkData(this.props.CompanyId,
                    this.props.CompanyDescription,
                    this.props.IsManufacturer,
                    this.props.LocalAuthorities,
                    this.props.OfferFinance,
                    this.props.HasShowroom,                    
                    this.props.ContactEmail,
                    this.props.AdditionalContactEmail,
                    this.props.SelectedWorkRadius,
                    this.props.SelectedMinimumHours,
                    value
                );

                break;

            case 'ContactEmail':

                this.props.setWorkData(this.props.CompanyId,
                    this.props.CompanyDescription,
                    this.props.IsManufacturer,
                    this.props.LocalAuthorities,
                    this.props.OfferFinance,
                    this.props.HasShowroom,                    
                    value,
                    this.props.AdditionalContactEmail,
                    this.props.SelectedWorkRadius,
                    this.props.SelectedMinimumHours,
                    this.props.CallOut24Hours
                );

                break;

            case 'AdditionalContactEmail':

                this.props.setWorkData(this.props.CompanyId,
                    this.props.CompanyDescription,
                    this.props.IsManufacturer,
                    this.props.LocalAuthorities,
                    this.props.OfferFinance,
                    this.props.HasShowroom,                    
                    this.props.ContactEmail,
                    value,
                    this.props.SelectedWorkRadius,
                    this.props.SelectedMinimumHours,
                    this.props.CallOut24Hours
                );

                break;

            case 'CompanyDescription':             

                this.props.setWorkData(this.props.CompanyId,
                    value,
                    this.props.IsManufacturer,
                    this.props.LocalAuthorities,
                    this.props.OfferFinance,
                    this.props.HasShowroom,                    
                    this.props.ContactEmail,
                    this.props.AdditionalContactEmail,
                    this.props.SelectedWorkRadius,
                    this.props.SelectedMinimumHours,
                    this.props.CallOut24Hours
                );          

                break;

            default:
                break;
        }
    }

    submitForm = (e) => {
        e.preventDefault();

        let SelectedDays = this.props.SelectedDays;
        let OpeningHours = [];

        for (let i = 0; i < this.props.DaysOfWeek.length; i++) {

            let CurrentDay = SelectedDays.find(x => x.DayOfWeek === this.props.DaysOfWeek[i].DayId);

            if (CurrentDay === undefined) {
                OpeningHours.push({
                    __table__: "OpeningHours",
                    OT_CompanyId: this.props.CompanyId,
                    OT_DayOfWeek: this.props.DaysOfWeek[i].DayId,
                    OT_OpenTime: null,
                    OT_CloseTime: null,
                    OT_NotOpen: true
                });

            }
            else {

                OpeningHours.push({
                    __table__: "OpeningHours",
                    OT_Id: CurrentDay.Id,
                    OT_CompanyId: this.props.CompanyId,
                    OT_DayOfWeek: CurrentDay.DayOfWeek,
                    OT_OpenTime: CurrentDay.OpenTime,
                    OT_CloseTime: CurrentDay.CloseTime,
                    OT_NotOpen: CurrentDay.NotOpen
                });

            }
        }

        let SelectedMaterial = this.props.SelectedMaterial;
        let Material = [];

        for (let i = 0; i < this.props.MaterialType.length; i++) {

            let MaterialExists = SelectedMaterial.find(x => x.MaterialId === this.props.MaterialType[i].ID);

            if (MaterialExists === undefined) {

                Material.push({
                    __table__: "WorkMaterial",
                    WM_CompanyId: this.props.CompanyId,
                    WM_MaterialId: this.props.MaterialType[i].ID,
                    WM_Deleted: true
                });
            }
            else {
                Material.push({
                    __table__: "WorkMaterial",
                    WM_Id: MaterialExists.Id,
                    WM_CompanyId: this.props.CompanyId,
                    WM_MaterialId: MaterialExists.MaterialId,
                    WM_Deleted: MaterialExists.Deleted
                });
            }
        }       

        let SelectedService = this.props.SelectedService;
        let Service = [];

        for (let i = 0; i < this.props.ServiceType.length; i++) {

            let ServiceExists = SelectedService.find(x => x.ServiceId === this.props.ServiceType[i].ID);

            if (ServiceExists === undefined) {

                Service.push({
                    __table__: "WorkService",
                    WS_CompanyId: this.props.CompanyId,
                    WS_ServiceId: this.props.ServiceType[i].ID,
                    WS_Deleted: true
                });
            }
            else {
                Service.push({
                    __table__: "WorkService",
                    WS_Id: ServiceExists.Id,
                    WS_CompanyId: this.props.CompanyId,
                    WS_ServiceId: ServiceExists.ServiceId,
                    WS_Deleted: ServiceExists.Deleted
                });
            }
        }




        let SelectedPropertyType = this.props.SelectedPropertyType;
        let PropertyType = [];

        for (let i = 0; i < this.props.PropertyType.length; i++) {

            let PropertyTypeExists = SelectedPropertyType.find(x => x.PropertyTypeId === this.props.PropertyType[i].ID);

            if (PropertyTypeExists === undefined) {

                PropertyType.push({
                    __table__: "WorkPropertyType",
                    WPT_CompanyId: this.props.CompanyId,
                    WPT_PropertyTypeId: this.props.PropertyType[i].ID,
                    WPT_Deleted: true
                });
            }
            else {
                PropertyType.push({
                    __table__: "WorkPropertyType",
                    WPT_Id: PropertyTypeExists.Id,
                    WPT_CompanyId: this.props.CompanyId,
                    WPT_PropertyTypeId: PropertyTypeExists.PropertyTypeId,
                    WPT_Deleted: PropertyTypeExists.Deleted
                });
            }
        }

        let datavalues;

        if (this.props.InstallerWorkDataId === -1) {
            datavalues =
            {
                __table__: "Work",
                W_CompanyId: this.props.CompanyId,

                W_Description: this.props.CompanyDescription,
                W_IsManufacturer: this.props.IsManufacturer,
                W_LocalAuthorities: this.props.LocalAuthorities,
                W_HasShowroom: this.props.HasShowroom,
                W_OfferFinance: this.props.OfferFinance,
                W_CallOut24Hours: this.props.CallOut24Hours,
                W_ContactEmail: this.props.ContactEmail,
                W_AdditionalEmail: this.props.AdditionalContactEmail,
                W_RadiusId: this.props.SelectedWorkRadius,
                W_MinimumBudgetId: this.props.SelectedMinimumHours,


                OpeningHours_OT_Wid_collection: OpeningHours,
                WorkMaterial_WM_Wid_collection: Material,
                WorkService_WS_Wid_collection: Service,
                WorkPropertyType_WPT_Wid_collection: PropertyType

            };
        }
        else {
            datavalues =
            {
                __table__: "Work",
                W_Id: this.props.InstallerWorkDataId,
                W_CompanyId: this.props.CompanyId,

                W_Description: this.props.CompanyDescription,
                W_IsManufacturer: this.props.IsManufacturer,
                W_LocalAuthorities: this.props.LocalAuthorities,
                W_HasShowroom: this.props.HasShowroom,
                W_OfferFinance: this.props.OfferFinance,
                W_CallOut24Hours: this.props.CallOut24Hours,
                W_ContactEmail: this.props.ContactEmail,
                W_AdditionalEmail: this.props.AdditionalContactEmail,
                W_RadiusId: this.props.SelectedWorkRadius,
                W_MinimumBudgetId: this.props.SelectedMinimumHours,


                OpeningHours_OT_Wid_collection: OpeningHours,
                WorkMaterial_WM_Wid_collection: Material,
                WorkService_WS_Wid_collection: Service,
                WorkPropertyType_WPT_Wid_collection: PropertyType

            };
        }


        console.log('Opening Times', OpeningHours);
        console.log('Json Times', datavalues);

        this.props.InsertInstallerWorkData(datavalues);

        console.log("submitForm - ", this.props.Result);
    }
    render() {

        const CompanyDesc = this.props.CompanyDescription;

        return (
            
            <div className='container'>

                <div className='col-md-12 pb-2'>

                    {this.props.Errors.length === 0 ?
                        this.props.Information.length === 0 ? '' :
                            <NotificationPanel CssClassPostFix='info show mt-3' NotificationMessage={this.props.Information[0].Message} />
                        :
                        <ReduxNotificationPanel CssClassPostFix='warning show mt-3' NotificationMessage={this.props.Errors} />
                    }

                </div>

                <MDBRow>
                    <div className="col-12">
                        <MDBInput
                            //error={this.state.ErrorMessage}
                            //group={this.state.GroupItem}
                            icon="pencil"
                            id="CompanyDescription"
                            hint="Company Description"
                            label="Company Description to show in search results (max 250 chracters)"
                            name="CompanyDescription"
                            onChange={this.state.HandleInputChange}
                            //onBlur={this.state.HandleOnBlur}
                            //ref={this.state.UglyHack}
                            //required={this.state.IsRequired}
                            size="md"
                            //success={this.state.SuccessMessage}
                            type="textarea"
                            value={this.props.CompanyDescription}
                            //outline={false}
                            validate={false}
                            onInput={this.handleInputChange}
                            maxLength={this.props.max === undefined ? 255 : this.props.max}
                            rows="10"
                        />
                            
                    </div>
                </MDBRow>
                <MDBRow>
                    <div className="col-12">
                        <ColumnAndLabel
                            IconLabel="pencil"
                            Id="ContactEmail"
                            name="ContactEmail"
                            InputType="text"
                            SizeCss="md"
                            HintLabel=""
                            TextLabel="Email you would like leads to be sent to"
                            Value={this.props.ContactEmail}
                            HandleInputChange={this.handleInputChange}
                            //HandleOnInput={this.handleInputChange}
                            Validate={false}
                        />
                    </div>
                </MDBRow>
                <MDBRow>
                    <div className="col-12">
                        <ColumnAndLabel
                            IconLabel="pencil"
                            Id="AdditionalContactEmail"
                            name="AdditionalContactEmail"
                            InputType="text"
                            SizeCss="md"
                            HintLabel=""
                            TextLabel="Additional Email you would like leads to be sent to"
                            Value={this.props.AdditionalContactEmail}
                            HandleInputChange={this.handleInputChange}
                            //HandleOnInput={this.handleInputChange}
                            Validate={false}
                        />
                    </div>
                </MDBRow>

                <MDBRow>
                    <MDBCol className="col-md-12">

                        <CardBodyOnly>
                            Material Used                                                          
                                <div className="col-md-12">
                                    {this.props.MaterialType.map((Material) => (

                                            <FormControlLabel control={<Checkbox />}
                                                checked={this.props.SelectedMaterial.length === 0 ? false :
                                                    this.props.SelectedMaterial.find(x => x.MaterialId === Material.ID) === undefined ? false :
                                                        this.props.SelectedMaterial.find(x => x.MaterialId === Material.ID).Deleted === false ? true : false}
                                                name='Material'
                                                label={Material.Name}
                                                onChange={this.handleInputChange}
                                                value={Material.ID}                                               
                                            />
                                     
                                    ))}                        
                                </div>
                        </CardBodyOnly>


                        <CardBodyOnly>
                        Products Installed
                            <div className="col-md-12">

                                {this.props.ServiceType.map((Service) =>(

                                        <FormControlLabel control={<Checkbox />}
                                            checked={this.props.SelectedService.length === 0 ? false :
                                                this.props.SelectedService.find(x => x.ServiceId === Service.ID) === undefined ? false :
                                                    this.props.SelectedService.find(x => x.ServiceId === Service.ID).Deleted === false ? true : false}
                                            name='Service'
                                            label={Service.Name}
                                            onChange={this.handleInputChange}
                                            value={Service.ID}                                           
                                        />

                                    ))}
                            </div>
                        </CardBodyOnly>

                        <CardBodyOnly>
                            Property Types
                            <div className="col-md-12">

                                {this.props.PropertyType.map((Property) => (
                                    <div className="row">
                                        <FormControlLabel control={<Checkbox />}
                                            checked={this.props.SelectedPropertyType.length === 0 ? false :
                                                this.props.SelectedPropertyType.find(x => x.PropertyTypeId === Property.ID) === undefined ? false :
                                                    this.props.SelectedPropertyType.find(x => x.PropertyTypeId === Property.ID).Deleted === false ? true : false}
                                            name='Property'
                                            onChange={this.handleInputChange}
                                            value={Property.ID}                                            
                                            label={Property.Name + ' - ' + Property.Description} />
                                    </div>
                                ))}
                            </div>
                        </CardBodyOnly>

                        <CardBodyOnly>
                            Work Radius from my postcode
                            <div className="col-md-12">
                                {this.props.RadiusType.map((Radius) => (

                                        <FormControlLabel control={<Checkbox />}
                                            checked={this.props.SelectedWorkRadius === Radius.ID ? true : false}
                                            name='Radius'
                                            onChange={this.handleInputChange}
                                            value={Radius.ID}                                            
                                            label={Radius.Name} />

                                ))}
                            </div>
                        </CardBodyOnly>

                        <CardBodyOnly>
                            Minimum Budget catered for
                            <div className="col-md-12">
                                {this.props.MinimumBudgetType.map((MinimumBudget) => (

                                        <FormControlLabel control={<Checkbox />}

                                            checked={this.props.SelectedMinimumHours === MinimumBudget.ID ? true : false}
                                            name='MinimumHours'
                                            onChange={this.handleInputChange}
                                            value={MinimumBudget.ID}
                                            label={MinimumBudget.Name} />

                                ))}
                            </div>
                        </CardBodyOnly>  

                        <CardBodyOnly>
                            Opening Hours
                            <div className="col-md-12">

                                {this.props.DaysOfWeek.map((DaysOfWeek) => (
                                    
                                    <div className="row">

                                        <OpeningHours
                                            SelectedDays={this.props.SelectedDays}
                                            HandleItemChecked={this.props.HandleItemChecked}
                                            DayId={DaysOfWeek.DayId}
                                            DayName={DaysOfWeek.Name}
                                            setOpeningHours={this.props.setOpeningHours}
                                        />                                     

                                    </div>
                                ))}

                            </div>

                           
                        </CardBodyOnly>  

                        <CardBodyOnly>
                            Select other Information that applies to you (Please select those that apply).
                            <div className="col-md-12">
                                
                                    <div className="row">
                                        <FormControlLabel control={<Checkbox />}
                                            checked={this.props.IsManufacturer === true ? true : false}
                                            name='IsManufacturer'
                                        onChange={this.handleInputChange}
                                            value='IsManufacturer'
                                            label='Do you manufacture Windows and/or Doors.' />
                                    </div>

                                <div className="row">
                                    <FormControlLabel control={<Checkbox />}
                                        checked={this.props.LocalAuthorities === true ? true : false}
                                        name='LocalAuthorities'
                                        onChange={this.handleInputChange}
                                        value='LocalAuthorities'
                                        label='Do you install replacement windows and/or doors for local authority or housing associations.' />
                                </div>

                                <div className="row">
                                    <FormControlLabel control={<Checkbox />}
                                        checked={this.props.HasShowroom === true ? true : false}
                                        name='HasShowroom'
                                        onChange={this.handleInputChange}
                                        value='HasShowroom'
                                        label='Do you have a showroom consumers can visit.' />
                                </div>

                                <div className="row">
                                    <FormControlLabel control={<Checkbox />}
                                        checked={this.props.OfferFinance === true ? true : false}
                                        name='OfferFinance'
                                        onChange={this.handleInputChange}
                                        value='OfferFinance'
                                        label='Do you offer finance to your customers.' />
                                </div>

                                <div className="row">
                                    <FormControlLabel control={<Checkbox />}
                                        checked={this.props.CallOut24Hours === true ? true : false}
                                        name='CallOut24Hours'
                                        onChange={this.handleInputChange}
                                        value='CallOut24Hours'
                                        label='Do you offer 24 hour Call out.' />
                                </div>

                            </div>
                        </CardBodyOnly>  

                    </MDBCol>
                    

                </MDBRow>

                <div className='col-md-12 pb-2'>

                    {this.props.Errors.length === 0 ?
                        this.props.Information.length === 0 ? '' :
                            <NotificationPanel CssClassPostFix='info show mt-3' NotificationMessage={this.props.Information[0].Message} />
                        :
                        <ReduxNotificationPanel CssClassPostFix='warning show mt-3' NotificationMessage={this.props.Errors} />
                    }

                </div>

                <div className='col-md-12 pb-2'>
                    <div className='text-left'>  
                          <MDBBtn
                            color="success"
                            icon="save"
                            type="submit"
                            size="sm"
                            className="mdb-btn-resize pull-right show"
                            onClick={this.submitForm}
                        >
                           <MDBIcon icon="save" className="pr-2" />Submit
                        </MDBBtn>
                            
                    </div>
                </div>

                
                </div>


        );
    }
}

