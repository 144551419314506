import React, { Component, Fragment } from 'react';
import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { CardBodyOnly } from '../SharedComponents/CardBodyOnly';
import { NotificationPanel } from '../SharedComponents/NotificationPanel';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import { MDBInput } from 'mdbreact';

const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class InstallationDeclaration extends Component {
    displayName = InstallationDeclaration.name;

    constructor(props) {
        super(props);

        const auth = new AuthHelper();
        this.state = {
            //...props,
            CompanyId: auth.CompanyId,
            MemberDeclarationId: -1,
            MemberDeclarationText: '',
            UsePaging: true,
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide'
        };
    }

    componentWillMount() {
        this.fetchData();
    }

    renderTable = (payload, declarationAgreed, handleInputChange, Error, MemberDeclarationId) => {
        return (
            <Fragment>
                {
                    payload ?
                        (
                            <div className="py-3">
                                <CardBodyOnly>
                                    <h2 className="h2-responsive">Member Declaration</h2>
                                    <div dangerouslySetInnerHTML={{ __html: payload }} />
                                    <div className="text-center pt-3">
                                        <MDBInput label="I have read and understood to continue" type="checkbox" id="DeclarationAgreed" name="DeclarationAgreed" checked={declarationAgreed} onChange={handleInputChange} />
                                        <input name="MemberDeclarationId" className="hide" type="text" value={MemberDeclarationId} onChange={handleInputChange} />
                                        <div className="text-danger pt-2"><strong>{Error}</strong></div>
                                    </div>
                                </CardBodyOnly>
                            </div>
                        ) : null
                }
            </Fragment>
        );
    }

    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    fetchData = () => {

        var MemberDeclarationData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerMemberDeclaration",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                CompanyId: this.state.CompanyId
            })
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: MemberDeclarationData
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                const declarationData = JSON.parse(data.ReturnData.Payload)[0];
                //console.log(this.displayName + 'fetchData[data]', data);
                //console.log(this.displayName + 'fetchData[declarationData]', declarationData);
                this.setState({
                    MemberDeclarationText: declarationData.DeclarationText,
                    MemberDeclarationId: declarationData.ID,
                    loading: false
                });
                this.props.handleMemberDeclarationId(this.state.MemberDeclarationId);
            })
            .catch(data => {
                if (debugOn) {
                    console.log(data);
                }
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - No Data or service not available']
                });
            });
    };

    render() {
        let contents = this.state.loading
            ? <PleaseWait IsLoading={true} />
            : this.renderTable(this.state.MemberDeclarationText,
                this.props.DeclarationAgreed,
                this.props.handleInputChange,
                this.props.Error,
                this.state.MemberDeclarationId);

        ReactGA.event({
            category: 'Installation Declaration',
            action: 'Installation Declaration View'
        });


        return (
            <div className='container'>
                <div>
                    <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                    {contents}
                </div>
            </div>
        );
    }
}
