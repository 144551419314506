import React, { Component } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';

import { PleaseWait } from '../SharedComponents/PleaseWait';

export class InstallationSearch extends Component {
    displayName = InstallationSearch.name

    constructor(props) {
        super(props);

        this.state = {
            ...props
        };
    }

    render() {
        return (
            <div className='form-group'>
                <div className='row'>
                    <div className='col-xs-6 col-md-3'>
                        <input name='Reference' className='form-control' type='text' placeholder='Reference' value={this.props.DataProps.Reference} onChange={this.props.handleChange} />
                    </div>
                    <div className='col-xs-6 col-md-3'>
                        <input name='InstallPostcode' className='form-control' type='text' placeholder='Install Postcode' value={this.props.DataProps.InstallPostcode} onChange={this.props.handleChange} />
                    </div>
                    <div className='col-xs-6 col-md-3'>
                        <input name='DispatchPostcode' className='form-control' type='text' placeholder='Dispatch Postcode' value={this.props.DataProps.DispatchPostcode} onChange={this.props.handleChange} />
                    </div>
                    <div className='col-xs-6 col-md-3'>
                        <input name='InstallerReference' className='form-control' type='text' placeholder='Installer Reference' value={this.props.DataProps.InstallerReference} onChange={this.props.handleChange} />
                    </div>
                </div>

                <div className='row date'>

                    <div className='col-md-3' >
                        <SingleDatePicker id='CompletedAfter' inputIconPosition="after" block={false} date={this.props.CompletedAfter} placeholder="Completed After"
                            isOutsideRange={() => false} onDateChange={(event) => this.props.handleCompletedAfterChange(event, this.props.CompletedAfter)}
                            focused={this.state.focusedOne} onFocusChange={({ focused }) => this.setState({ "focusedOne": focused })} openDirection="down" hideKeyboardShortcutsPanel displayFormat="DD MMM YYYY" showClearDate />
                    </div>

                    <div className='col-md-3' >
                        <SingleDatePicker id='CompletedBefore' inputIconPosition="after" block={false} date={this.props.CompletedBefore} placeholder="Completed Before"
                            isOutsideRange={() => false} onDateChange={(event) => this.props.handleCompletedBeforeChange(event, this.props.CompletedBefore)}
                            focused={this.state.focused} onFocusChange={({ focused }) => this.setState({ "focused": focused })}
                            openDirection="down"
                            hideKeyboardShortcutsPanel displayFormat="DD MMM YYYY" showClearDate />
                    </div>                                       
                </div>

                <div className='row'>

                    <div className='col-md-12'>

                        <a className='btn btn-success btn-sm pull-right' onClick={this.props.handleSearch}><i className="fa fa-search pr-2" />Search</a>
                        <a className='btn btn-primary btn-sm pull-right' onClick={this.props.handleClear}><i className="fa fa-times-circle pr-2" />Clear</a>
                        <PleaseWait IsLoading={this.props.loading} />
                    </div>
                </div>
            </div >
        );
    }
}