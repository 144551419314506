import React, { Component } from 'react';
import ReactGA from 'react-ga';

import { MenuItems } from '../SharedComponents/MenuItems';

export class UserManagement extends Component {
    render() {

        ReactGA.event({
            category: 'User Management',
            action: 'User Management Home'
        });

        return (
            <MenuItems ParentName="User Management" />
        );
    }
}
