import React, { Component, Fragment } from 'react';
import uuid from "uuid";

import { HomeItem } from './HomeItem';
import { PleaseWait } from './PleaseWait';

import { MDBJumbotron, MDBContainer, MDBRow, MDBCol } from 'mdbreact';

import ModalVideo from 'react-modal-video';
import "react-modal-video/scss/modal-video.scss";
import { Button } from 'react-bootstrap/lib/InputGroup';

import AuthHelper from './AuthHelper';

export class MenuItems extends Component {
    displayName = MenuItems.name;

    constructor(props) {
        super(props);
        this.state = {
            CrumbMap: [],
            Description: '',
            MenuId: 0,
            Title: '',
            description: '',
            displayContent: (<PleaseWait IsLoading={true} />),
            loading: true,
            parentName: this.props.ParentName,
            noDataPrompt: this.props.NoDataPrompt ? this.props.NoDataPrompt : 'No data to display',
            payload: [],
            isOpen: false,
            showButton: false,
            videoTitle: '',
            videoPath: '',
            parentId:0
        };
        this.displayName = this.props.ParentName;    
        this.parentId = this.props.ParentId !=null?this.props.ParentId:0;      
    }

    componentDidMount = () => {
        this.fetchData();        
    }
    
    renderNoDataView = () => {
        return (
            <p>
                <em>{this.state.noDataPrompt}</em>
            </p>
        );
    }

    handleVideoDisplay = (menuId) => {
        
        if (menuId === 2) {
            this.setState({
                showButton: true,
                videoTitle: 'How To Register An Installation',
                videoPath: './Register a single installation.mp4'
            });
        }
        if (menuId === 4) {
            this.setState({
                showButton: true,
                videoTitle: 'How to find and download an invoice',
                videoPath: './How to find and download an invoice.mp4'
            });
        }

        if (menuId === 60 || menuId === 65) {
            this.setState({
                showButton: true,
                videoTitle: 'How FENSA lead generation work',
                videoPath: './FENSA-Lead-Generation.mp4'
            });
        }
       
    }


    renderItems = payload => {
        //console.log('payload', payload);
        return (
            <Fragment>
                <div className='row fluid'>
                    {
                        payload.map(item => (<HomeItem item={item} key={item.MenuId} />))
                    }
                </div>
            </Fragment>
        );
    }

    fetchData = () => {
        //load parent data and on success load children
       
      
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    seqno: uuid.v4(),
                    datatype: "BIT.Fensa.InstallerPortal.GetMenuByRef",
                    origin: "Installer Portal",
                    operation: "Search",
                    datavalues: JSON.stringify({
                        Name: this.state.parentName,
                        MenuId: 0,
                        ParentId: this.parentId
                    })
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log(this.displayName + '.fetchData[data]', data);
                const parentData = JSON.parse(data);
                //console.log(parentData);
                //console.log(this.displayName + '.fetchData[parentData]', parentData);
                if (parentData.returncode === 0) {
                    this.setState({
                        loading: false,
                        payload: [],
                        displayContent: this.renderNoDataView()
                    });
                } else {
                    const parentMenu = JSON.parse(parentData.ReturnData.Payload)[0];
                    //set the parent data before loading children
                    this.setState({
                        Description: parentMenu.Description,
                        MenuId: parentMenu.MenuId,
                        Title: parentMenu.Title
                    });
                    
                    this.handleVideoDisplay(parentMenu.MenuId);

                    
                   
                    //now load all children
                    fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine',
                        {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                seqno: uuid.v4(),
                                datatype: "BIT.Fensa.InstallerPortal.GetMenu",
                                origin: "Installer Portal",
                                operation: "Search",
                                datavalues: JSON.stringify({
                                    ParentId: parentMenu.MenuId
                                })
                            })
                        })
                        .then(childrenResponse => childrenResponse.json())
                        .then(children => {
                            //found any child?
                            //console.log(this.displayName + '.fetchData[children]', children);
                            const childrenData = JSON.parse(children);
                            if (childrenData.returncode === 0) {
                                this.setState({
                                    loading: false,
                                    payload: [],
                                    displayContent: this.renderNoDataView()
                                });
                            } else {
                                //console.log(this.displayName + '.fetchData[childrenData]', childrenData);
                                const childrenMenu = JSON.parse(childrenData.ReturnData.Payload);
                                //console.log(this.displayName + '.fetchData[childrenMenu]', childrenMenu);
                                this.setState({
                                    loading: false,
                                    payload: childrenMenu,
                                    displayContent: childrenMenu && childrenMenu.length > 0 ?
                                        this.renderItems(childrenMenu) :
                                        this.renderNoDataView()
                                });
                            }
                        })
                        .catch(data => {
                            console.log(this.displayName + '.fetchData.children[err]', data);
                            const errData = JSON.parse(JSON.parse(data).ReturnData.Errors);
                            console.log(this.displayName + '.fetchData.children[errData]', errData);
                            this.setState({
                                loading: false,
                                notificationPostfix: 'danger show',
                                notificationMessage: errData
                            });
                        });
                }
            })
            .catch(data => {
               // console.log(this.displayName + '.fetchData.parent[err]', data);
               // const errData = JSON.parse(JSON.parse(data).ReturnData.Errors);
               // console.log(this.displayName + '.fetchData.parent[errData]', errData);
               const errData = 'test';
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: errData
                });
            });
    }

    render() {
       
        return ( 
            <div>
              
                <MDBContainer>
                    <MDBRow>
                        <MDBCol>
                            <MDBJumbotron fluid>
                                <div className='px-5'>
                                    <h2 className='h2 h2-responsive'>{this.state.Title}</h2>
                                    <div className='lead text-left' dangerouslySetInnerHTML={{ __html: this.state.Description }} />
                                </div>
                            </MDBJumbotron>
                            {this.state.showButton ?
                                <Button type="button" className='btn orange pull-right' onClick={() => this.setState({ isOpen: true })}>
                                    <i className="fa fa-play pr-2" />{this.state.videoTitle}</Button>
                                : ''}
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <div className='row'>
                            <div className='col-xs-12 pl-5'>
                                {this.state.displayContent}                               
                            </div>
                        </div>
                    </MDBRow>
                </MDBContainer>
                <ModalVideo
                    isOpen={this.state.isOpen}
                    onClose={() => this.setState({ isOpen: false })}
                    channel='custom'
                    url={this.state.videoPath}
                />
            </div>
        );
    }
}
