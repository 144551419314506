import React, { Component } from 'react';
import logo from './logo.svg';
import './PleaseWait.css';

export class PleaseWait extends Component {
    displayName = PleaseWait.name;

    state = {
        showLoading: this.props.IsLoading,
        float: this.props.float
    };

    componentWillUpdate = (prevProps, prevState) => {
        if (prevProps.IsLoading !== prevState.showLoading) {
            this.setState({
                showLoading: prevProps.IsLoading
            });
        }
        //console.log(this.displayName + '.componentWillUpdate[prevProps]', prevProps);
        //console.log(this.displayName + '.componentWillUpdate[prevState]', prevState);
        //console.log(this.displayName + '.componentWillUpdate[state]', this.state);
    }

    render() {
        return (
            <div className={this.state.showLoading === true ? 'show' : 'hide'}>
                <div className='please-wait'>
                    <img src={logo} alt='Please wait...' className='please-wait-logo' />
                    { this.props.PleaseWaitText }
                </div>
            </div>
        );
    }
}
