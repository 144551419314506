import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import uuid from "uuid";
import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { ColumnAndLabelRadioOptions } from './../SharedComponents/ColumnAndLabelRadioOptions';
import { ConfirmActionModal } from '../SharedComponents/ConfirmActionModal';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { PleaseWait } from './../SharedComponents/PleaseWait';
import { ReadonlyItem } from './../SharedComponents/ReadonlyItem';

import {
    MDBBtn,
    MDBIcon
} from "mdbreact";


const usersHomeUrl = '/UserManagement/Users';

export class UserView extends Component {
    displayName = 'UserView';

    constructor(props) {
        super(props);

        const auth = new AuthHelper();
        const deleting = this.getViewingPref(props);
        this.state = {
            CanEdit: auth.CanEditData,
            CompanyId: auth.CompanyId,
            CompanyUserStatus: 1,
            ContactEmail: '',
            ContactName: '',
            ContactType: 0,
            CreatedBy: auth.Username,
            FaxNumber: '',
            FirstName: '',
            GlobalUserStatus: 0,
            IsFensaAdmin: auth.IsFensaAdmin,
            LastName: '',
            MainAuthUser: false,
            MobilePhone: '',
            ResetPwd: false,
            Telephone: '',
            UserId: props.match.params.id,

            deleteActionMessage: '',
            isDeletePage: deleting,
            loading: true,
            modal: false,
            notificationPostfix: 'info hide',
            notificationMessage: [],
            pageTitle: deleting ? 'Delete User' : 'View User Details'
        };
        console.log(this.displayName + '.ctor', this.state);
    }

    componentDidMount() {
        this.fetchData();
    }

    getViewingPref = data => {
        const pathName = data.match.path;
        return (pathName !== undefined && pathName.toString().toLowerCase().indexOf('delete') > 0);
    }

    isDeletePage = () => {
        return this.state.isDeletePage;
    }

    currentPageTitle = () => {
        return this.state.pageTitle;
    }

    toggleModal = toggle => {
        this.setState({
            modal: toggle
        });
    }

    togglePleaseWait = status => {
        this.setState({
            loading: status
        });
    }

    submitForm = e => {
        e.preventDefault();

        if (!window.confirm('You are about to delete this item - ' + this.state.ContactName + '. \nClick Ok to continue otherwise cancel to abort.')) {
            return false;
        }

        // Show please wait
        this.togglePleaseWait(true);

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.DeleteUser",
                origin: "Installer Portal",
                operation: "DELETE",
                datavalues: JSON.stringify({
                    UserId: this.state.UserId,
                    CompanyId: this.state.CompanyId,
                })
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                if (data.returncode === 1) {
                    // if successful then reload contact type list
                    this.props.history.push(usersHomeUrl);
                    return;
                }
                //tell the developer that delete failed
                console.log(data.message);
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - Failed to complete delete']
                });
            })
            .catch(data => {
                console.log(data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - Failed to complete delete']
                });
            });
    }

    fetchData = () => {
        var fetchContact = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.InstallerGetInstallerCompanyUser",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    UserId: this.state.UserId,
                    CompanyId: this.state.CompanyId
                })
            })
        });

        Promise.all([fetchContact])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                const data = JSON.parse(JSON.parse(responses[0]).message)[0];
                //console.log('fetchData', data);
                this.setState({
                    CompanyUserStatus: data.CompanyUserStatus ? 1 : 0,
                    ContactEmail: data.Email,
                    ContactName: data.Name,
                    FaxNumber: data.FaxNumber,
                    FirstName: data.FirstName,
                    GlobalUserStatus: data.GlobalUserStatus ? 1 : 0,
                    JobTypeId: data.JobTypeId,
                    JobTypeName: data.JobTypeName,
                    LastName: data.LastName,
                    MainAuthUser: data.MainAuthUser === 0 || !data.MainAuthUser ? false : true,
                    MobilePhone: data.MobilePhone,
                    ResetPwd: data.ResetPwd ? 1 : 0,
                    RoleId: data.RoleId,
                    RoleName: data.RoleName,
                    Telephone: data.Telephone,
                    deleteActionMessage: "You are about to delete " + data.Name + ". Click Ok to continue otherwise cancel to abort.",
                    loading: false
                });
                //console.log(this.displayName + '.fetchData[state]', this.state);
            })
            .catch(data => {
                console.log('fetchData.error', data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['No Data']
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'User View Details',
            action: 'User Details Views'
        });

        return (
            <div>

                <form className='demoForm' onSubmit={this.submitForm.bind(this)}>
                    <div className='form-group-container mr-auto ml-auto'>
                        <div className='form-group-title'>{this.currentPageTitle()}</div>
                        <div className='form-group'>
                            <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                            <div className='row'>
                                <div className='col-sm-12 col-md-4'>
                                    <ReadonlyItem textLabel="First Name">{this.state.FirstName}</ReadonlyItem>
                                </div>
                                <div className='col-sm-12 col-md-4'>
                                    <ReadonlyItem textLabel="Last Name">{this.state.LastName}</ReadonlyItem>
                                </div>
                                <div className='col-sm-12 col-md-4'>
                                    <ReadonlyItem textLabel="Email">{this.state.ContactEmail}</ReadonlyItem>
                                </div>
                                <div className='col-sm-12 col-md-4'>
                                    <ReadonlyItem textLabel="Telephone">{this.state.Telephone}</ReadonlyItem>
                                </div>
                                <div className='col-sm-12 col-md-4'>
                                    <ReadonlyItem textLabel="Mobile Phone">{this.state.MobilePhone}</ReadonlyItem>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12 col-md-4'>
                                    <ReadonlyItem textLabel="Job Title">{this.state.JobTypeName}</ReadonlyItem>
                                </div>
                                <div className='col-sm-12 col-md-4'>
                                    <ReadonlyItem textLabel="Role">{this.state.RoleName}</ReadonlyItem>
                                </div>
                            </div>
                            <div className='row'>
                                <div className={this.state.MainAuthUser ? 'col-xs-12 col-md-12 pt-3 text-success py-3' : 'hide'}>
                                    <ColumnAndLabelRadioOptions
                                        id="MainAuthUser"
                                        name="MainAuthUser"
                                        textLabel="Main Authorised User: "
                                        optionNo={'No'}
                                        optionYes={'Yes'}
                                        value={this.state.MainAuthUser}
                                        readOnly={true}
                                    />
                                </div>
                                <div className={this.state.CompanyUserStatus === 1 ? 'col-xs-12 col-md-4 pt-3 text-success py-3' : 'col-xs-12 col-md-4 pt-3 text-danger py-3'}>
                                    <ColumnAndLabelRadioOptions
                                        id="CompanyUserStatus"
                                        name="CompanyUserStatus"
                                        textLabel="Status: "
                                        optionNo={'Deactivated'}
                                        optionYes={'Active'}
                                        value={this.state.CompanyUserStatus}
                                        readOnly={true}
                                    />
                                </div>
                                <div className={this.state.IsFensaAdmin ? this.state.GlobalUserStatus === 1 ? 'col-xs-12 col-md-8 pt-3 text-success py-3' : 'col-xs-12 col-md-8 pt-3 text-danger py-3' : 'hide'}>
                                    <ColumnAndLabelRadioOptions
                                        id="GlobalUserStatus"
                                        name="GlobalUserStatus"
                                        textLabel="Global Status: "
                                        optionNo={'Deactivated'}
                                        optionYes={'Active'}
                                        value={this.state.GlobalUserStatus}
                                        readOnly={true}
                                    />
                                </div>
                                <div className={this.state.ResetPwd ? 'col-xs-12 col-md-12 pt-3 text-danger py-3' : 'hide'}>
                                    <ColumnAndLabelRadioOptions
                                        id="ResetPwd"
                                        name="ResetPwd"
                                        textLabel="Account Locked: "
                                        optionNo={'No'}
                                        optionYes={'User must reset password'}
                                        value={this.state.ResetPwd}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <PleaseWait IsLoading={this.state.loading} />
                                    {
                                        this.isDeletePage() ?
                                            (
                                                <MDBBtn
                                                    className="pull-right mdb-btn-resize"
                                                    color="danger"
                                                    type="button"
                                                    size="sm"
                                                    onClick={this.toggleModal}
                                                >
                                                    <MDBIcon icon="trash" className="mr-2" />Delete
                                                </MDBBtn>
                                            ) : null
                                    }
                                    <Link className={this.state.CanEdit ? 'btn btn-sm btn-primary pull-right mdb-btn-resize' : 'hide'} to={'/UserManagement/User/Update/' + this.state.UserId}>
                                        <i className="fa fa-edit pr-2" />Edit</Link>
                                    <Link className='btn btn-sm btn-dark pull-right mr-2 mdb-btn-resize' to={usersHomeUrl}>
                                        <i className='fa fa-arrow-left pr-2' />
                                        {this.isDeletePage() ? 'Cancel' : 'Back to List'}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ConfirmActionModal
                        ActionName="Delete"
                        ActionMessage={this.state.deleteActionMessage}
                        handleToggleAction={this.toggleModal}
                        handleModalAction={this.submitForm}
                        modalOpen={this.state.modal}
                    />
                </form>
            </div>
        );
    }
}
