import React, { Component } from "react";
import validator from 'validator';

import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdbreact";

import { ColumnAndLabel } from './ColumnAndLabel';
import { NotificationPanel } from './NotificationPanel';
import { PleaseWait } from '../SharedComponents/PleaseWait';

export class DefaultCompanySearch extends Component {
    displayName = "DefaultCompanySearch";

    constructor(props) {
        super(props);
        this.state = {
            CompanyName: '',
            FensaNumber: '',
            loading: false,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            formErrors: {
                FensaNumber: 'FENSA number is required'
            },
            payload: []
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        var value = target.value;
        if (value === undefined) {
            value = target.parentElement.value;
        }
        const name = target.name;
        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        });
    }

    togglePleaseWait = status => {
        this.setState({
            loading: status
        });
    }

    handleOnInputValidation = event => {

        let fieldValidationErrors = this.state.formErrors;
        let message = "";
        switch (event.target.name.toLowerCase()) {
            case 'fensanumber':
                fieldValidationErrors.FensaNumber = validator.isEmpty(event.target.value) ? 'FENSA number is required.' : '';
                message = fieldValidationErrors.FensaNumber;
                break;
            default:
                break;
        }

        if (message !== "") {
            this.setState({
                formErrors: fieldValidationErrors
            });
            //set custom validation error
            event.target.setCustomValidity(message);
        } else {
            //clear custom validation errors
            event.target.setCustomValidity("");
        }
    }

    submitForm = (e) => {
        e.preventDefault();
        // Show please wait
        this.togglePleaseWait(true);

        var postData = JSON.stringify({
            seqno: "1234",
            datatype: "BIT.Fensa.InstallerPortal.InstallerCompanySearch",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                FensaNumber: this.state.FensaNumber,
                CompanyName: this.state.CompanyName
            }),
            validate: false
        });
        //console.log(this.displayName + '.submitForm[postData]', postData);
        //    let enginePath = 'BITEngine/InsertObject';
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                console.log(this.displayName + '.searchData', data);
                //console.log(this.displayName + '.searchData.payload', JSON.parse(data.ReturnData.Payload));
                const hasData = data.returncode === 1 && data.ReturnData.Payload !== null;
                const companyData = hasData ?
                    JSON.parse(data.ReturnData.Payload).sort(function (a, b) {
                        if (a.CompanyName < b.CompanyName) { return 1; } //desc
                        if (a.CompanyName > b.CompanyName) { return -1; }
                        return 0;
                    }) : [];
                console.log(this.displayName + '.companyData', hasData, companyData);
                this.setState({
                    loading: false,
                    notificationPostfix: !hasData ? 'warning show mt-3' : 'info hide',
                    notificationMessage: !hasData ? ['No Data'] : [],
                    payload: companyData
                });
                this.props.handleCompanySearch(companyData);               
                console.log(window.location);
                if (companyData[0].FensaNumber !== null && companyData[0].FensaNumber === 8000 && window.location.pathname === '/') {
                    window.location.reload();
                }
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData[catch]', data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    displayContent: ''
                });
            });
    }

    render() {
        return (
            <MDBContainer>
                <MDBRow>
                    <MDBCol>
                        <form className='demoForm was-validated' onSubmit={this.submitForm.bind(this)}>
                            <p className="h6 text-left py-2">Find Company</p>
                            <div className="grey-text">
                                <div className='row'>
                                    <div className='col-md-5'>
                                        <ColumnAndLabel
                                            ErrorMessage={this.state.formErrors.FensaNumber}
                                            IconLabel=""
                                            Id="FensaNumber"
                                            InputType="text"
                                            IsRequired={true}
                                            SizeCss="md"
                                            HintLabel=""
                                            TextLabel="FENSA Number"
                                            Validate={true}
                                            Value={this.state.FensaNumber}
                                            HandleInputChange={this.handleInputChange}
                                            HandleOnInput={this.handleOnInputValidation}
                                        />
                                    </div>
                                    <div className='col-md-5 hide'>
                                        <ColumnAndLabel
                                            ErrorMessage=""
                                            IconLabel=""
                                            Id="CompanyName"
                                            InputType="text"
                                            IsRequired={false}
                                            SizeCss="md"
                                            HintLabel=""
                                            TextLabel="Company Name"
                                            Validate={false}
                                            Value={this.state.CompanyName}
                                            HandleInputChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div className='col-md-1 pt-3'>
                                        <MDBBtn
                                            className="pull-right mdb-btn-resize"
                                            color="primary"
                                            type="submit"
                                            floating
                                            size="sm">
                                            <MDBIcon icon="search" className="mr-2" />
                                        </MDBBtn>
                                    </div>
                                    <PleaseWait IsLoading={this.state.loading} />
                                </div>
                                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                            </div>
                        </form>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}
