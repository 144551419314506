import React, { Component } from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBIcon,
    MDBContainer,
    MDBModalBody
} from "mdbreact";

export class ConfirmActionModal extends Component {
    displayName = ConfirmActionModal.name;

    state = {
        ...this.props,
        actionMessage:this.props.ActionMessage,
        modalIsOpen: false
    };


    componentDidMount = () => {
        console.log();
    }

    componentWillUpdate = (prevProps, prevState) => {
        if (prevProps.modalOpen !== prevState.modalIsOpen) {
            this.setState({
                modalIsOpen: prevProps.modalOpen,
                actionMessage: prevProps.ActionMessage
            });
        }
    }

    toggleModal = () => {
        const popUp = !this.state.modalIsOpen;
        this.setState({
            modalIsOpen: popUp
        });
        this.props.handleToggleAction(popUp);
    }

    render() {
        return (
            <MDBContainer className="fluid-container mt-5 text-center">
                <MDBModal isOpen={this.state.modalIsOpen} toggle={this.toggleModal} frame position="bottom">
                    <MDBModalBody>
                        <p className="lead">{this.state.actionMessage}<br /></p>
                        <MDBBtn color="dark" size="sm" onClick={this.toggleModal}><MDBIcon icon="arrow-left" className="mr-2" />Cancel</MDBBtn>
                        <MDBBtn color="danger" size="sm" type="button" onClick={this.state.handleModalAction}><MDBIcon icon="trash" className="mr-2" />{this.state.ActionName}</MDBBtn>
                    </MDBModalBody>
                </MDBModal>
            </MDBContainer>
        );
    }
}