import React, { Component } from 'react';
import { PopularDownloads } from './PopularDownloads';
import { ApprovedDownloads } from './ApprovedDownloads';

export class Downloads extends Component {
    displayName = Downloads.name

    render() {
        return (
            <div>
                <h1>Useful Downloads</h1>

                <p>This is a simple example of a React component.</p>

                <PopularDownloads />

                <ApprovedDownloads />

            </div>            
        );
    }
}
