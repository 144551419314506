import React, { Component } from 'react';

export class AmendInstallation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">Amend installation section is under contruction</div>
                </div>
            </div>
        );
    }
}