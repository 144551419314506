import { DataFetch } from "js-helpers";
import { SET_APPLICATION_ERROR } from "../actions/applicationErrors";

export const GET_BRANCHES = "GET_BRANCHES";


export const GetBranches = (CompanyId, Url) => dispatch => {

    let DatatypeRequested = 'BIT.Fensa.InstallerPortal.InstallerBranches';
    let DatatypeURL = process.env.REACT_APP_FENSA_WEB_API + "BITEngine";

    new DataFetch()
        .PerformEngineFetch(
            JSON.stringify(
                {
                    CompanyId: CompanyId
                }),
            DatatypeRequested,
            DatatypeURL
        )
        .then(response => response.json())
        .then(data => {
           
            const Result = JSON.parse(data);

            let branches = Result.returncode === 1 ? JSON.parse(Result.message) : null;

            if (branches !== null) {

                branches = branches.sort(function (a, b) {
                // make main branch first item in the list
                if (a.MainBranch) { return -1; }
                if (b.MainBranch > a.MainBranch) { return 1; }
                if (a.Name < b.Name) { return -1; }
                if (a.Name > b.Name) { return 1; }
                return 0;
            });
            }
            
            if (Result.returncode === 0) {

                dispatch({
                    type: SET_APPLICATION_ERROR,
                    Item: {
                        Route: Url,
                        Function: 'GetBranches',
                        DatatypeRequested: DatatypeRequested,
                        DatatypeURL: DatatypeURL,
                        Message: [Result.message],
                        Trace: Result.ReturnData.DeclaringTypes
                    }
                });

            }


            dispatch({
                type: GET_BRANCHES,
                List: branches === null ? null : branches,
                Total: branches === null ? 0 :branches.length
            });
        });
};
