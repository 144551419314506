import React, { Component, Fragment } from 'react';
import ReactTable from "react-table";
import Moment from 'react-moment';
import moment from 'moment';
import uuid from "uuid";

import ReactGA from 'react-ga';


//import DatePicker from 'react-datepicker';

import AuthHelper from '../SharedComponents/AuthHelper';
import { PleaseWait } from './../SharedComponents/PleaseWait';
import { ReactTableFilterToggler } from './../SharedComponents/ReactTableFilterToggler';
import { CardFooterButtonsIcons } from '../SharedComponents/CardFooterButtonsIcons';

import Badge from "@material-ui/core/Badge";

export class Notices extends Component {
    displayName = "Notices";

    constructor(props) {
        super(props);
        const auth = new AuthHelper();
        this.state = {
            ...props,
            CompanyId: auth.CompanyId,
            ShowEditButton: false,
            ShowDeleteButton: false,
            ShowViewButton: true,
            UsePaging: true,
            displayContent: null,
            installation: [],
            loading: false,
            page: 0,
            pageSize: 10,
            resized: [],
            sorted: [],
            totals: '',
            totalsLabel: ' item',
            useFiltering: (auth.TableFilter && auth.TableFilter === 'On' ? true : false)
        };
    }

    componentDidMount = () => {
        this.fetchData();
    }

    handleToggleFilter = toggle => {
        this.setState({
            useFiltering: toggle
        });
        this.fetchData();
    }

    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    renderTable = installation => {
        return (
            <div className='pb-5'>
                <ReactTableFilterToggler
                    ParentDisplayName={this.displayName}
                    UseFiltering={this.state.useFiltering}
                    handleToggleFilter={this.handleToggleFilter}
                    loading={this.state.loading}
                />
                <ReactTable data={installation}
                    columns={[
                        {
                            Header: 'Subject',
                            accessor: 'Subject'
                        },
                        {
                            Header: 'PublishDate', // Custom header components!
                            accessor: 'PublishDate',
                            Cell: row => {
                                return (
                                    <Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.PublishDate}></Moment>
                                )
                            }
                        },
                        {
                            Header: '',
                            accessor: 'Edit',
                            filterable: false,
                            sortable: false,
                            Cell: row => {
                                return (
                                    <Fragment>
                                        <div className='text-center py-1 pr-5'>
                                            <CardFooterButtonsIcons
                                                Id={row.original.Id}
                                                //DeleteCss={ButtonsJss.DefaultDeleteCss}
                                                //EditCss={ButtonsJss.DefaultEditCss}
                                                //ViewCss={ButtonsJss.DefaultViewCss}
                                                //DeleteUrl={'/Installation/Delete/' + row.original.Id}
                                                //EditUrl={'/Installations/Edit/' + row.original.Id}
                                                ViewUrl={'/Notices/View/' + row.original.Id}
                                                ShowViewButton={this.state.ShowViewButton} // use role priviledge to set visibility
                                                ShowEditButton={this.state.ShowEditButton} // use role priviledge to set visibility
                                                ShowDeleteButton={this.state.ShowDeleteButton}// use role priviledge to set visibility
                                            />
                                        </div>
                                    </Fragment>
                                );
                            }
                        }
                    ]}
                    className="table-bordered table-striped table-hover table-shadowed"
                    defaultPageSize={this.state.pageSize}
                    expanded={this.state.expanded}
                    filterable={this.state.useFiltering}
                    minRows={0}
                    nextText='>'
                    previousText='<'

                //onPageChange={(page) => this.setPageId(page)}
                //page={this.state.page}
                //page={this.state.page}
                //pageSize={this.state.pageSize}
                //resized={this.state.resized}
                //filtered={this.state.filtered}
                // Callbacks
                //onSortedChange={sorted => this.setState({ sorted })}
                //onPageChange={page => this.setState({ page })}
                //onPageSizeChange={(pageSize, page) => this.setState({ page, pageSize })}
                //onExpandedChange={expanded => this.setState({ expanded })}
                //onResizedChange={resized => this.setState({ resized })}
                //onFilteredChange={filtered => this.setState({ filtered })}
                />
            </div>
        );
    }

    fetchData = () => {

        this.setState({
            loading: true
        });

        var Data = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerNotices",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                CompanyId: this.state.CompanyId
            })
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: Data
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                //console.log(this.displayName + '.fetchData[data]', data);
                if (data.returncode === 0) {
                    this.setState({
                        installation: [],
                        loading: false,
                        totals: 0,
                        displayContent: this.renderNoDataView
                    });
                } else {
                    const installationData = JSON.parse(data.ReturnData.Payload).sort(function (a, b) {
                        // make main branch first item in the list
                        if (moment(a.PublishDate) < moment(b.PublishDate)) { return 1; }
                        if (moment(a.PublishDate) > moment(b.PublishDate)) { return -1; }
                        return 0;
                    });
                    //console.log(this.displayName + '.fetchData[installationData]', installationData);
                    this.setState({
                        installation: installationData,
                        loading: false,
                        totals: installationData.length,
                        displayContent: (this.state.installationData === null) ?
                            this.renderNoDataView :
                            this.renderTable(installationData)
                    });
                }
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData[catch]', data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    displayContent: ''
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'Installer Notices',
            action: 'Installer Notices View'
        });

        return (
            <div>
                <div className="h2 h2-responsive">
                    <Badge badgeContent={this.state.totals} color="primary">Installer Notices</Badge>
                    <PleaseWait IsLoading={this.state.loading} float={'right'} />
                </div>
                {this.state.displayContent}
            </div>
        );
    }
}
