import React, { Component } from 'react';
import uuid from "uuid";
import ReactGA from 'react-ga';
import AuthHelper from '../SharedComponents/AuthHelper';
import { ColumnAndLabel } from '../SharedComponents/ColumnAndLabel';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { ReadonlyItem } from '../SharedComponents/ReadonlyItem';

import {
    MDBBtn,
    MDBIcon
} from "mdbreact";


export class ChangePassword extends Component {

    displayName = ChangePassword.name

    constructor(props) {
        super(props);
        const auth = new AuthHelper();
        //console.log(this.displayName + '.ctor', auth);
        this.state = {
            ConfirmPassword: '',
            CurrentPassword: '',
            Email: auth.UserEmail,
            NewPassword: '',
            UsePaging: true,
            UserDisplayName: auth.UserDisplayName,
            Username: auth.Username,
            formValid: false,
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 0
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    componentDidMount() {

    }

    submitForm = (e) => {
        e.preventDefault();

        this.setState({
            Processing: true,
            open: true
        });

        if (this.validateForm()) {

            var postData = JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.InsertUser",
                origin: "Installer Portal",
                operation: "createuser",
                datavalues: JSON.stringify({
                    ConfirmPassword: this.state.ConfirmPassword,
                    Email: this.state.Email,
                    NewPassword: this.state.NewPassword,
                    Password: this.state.CurrentPassword,
                    UserName: this.state.Username
                })
            });

            fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/ChangePassword', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: postData
            })
                .then(response => response.json())
                //.then(data => { console.log("Server Data: " + JSON.stringify(data)); return JSON.parse(data); })
                .then(data => {
                    this.setState({
                        LoginVisible: false,
                        loading: false,
                        Processing: false,
                        IdentityDataVisible: data.returncode !== 1,
                        notificationPostfix: (data.returncode === 1) ? 'success show' : 'danger show',
                        notificationMessage: (data.returncode === 1) ? ['Successfully complete password change'] : [JSON.parse(data.message)]
                    });
                })
                .catch(data => {
                    console.log(this.displayName + 'fetchData[err]', data);
                    this.setState({
                        loading: false,
                        ErrorMessage: 'No Data',
                        notificationPostfix: 'danger show',
                        notificationMessage: ['Bad Request - No data or service not available']
                    });
                });
        } else {
            this.setState({
                loading: false,
                ErrorMessage: 'No Data',
                notificationPostfix: 'danger show',
                notificationMessage: ['Bad Request - Form has errors']
            });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;

        if (value === undefined) {
            value = target.parentElement.value;
        }

        const name = target.name;

        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid: this.state.CurrentPassword.length !== 0
                && this.state.NewPassword.length !== 0
                && this.state.ConfirmPassword.length !== 0
                && this.state.ConfirmPassword === this.state.NewPassword
        });
        return this.state.formValid;
    }

    render() {

        ReactGA.event({
            category: 'Change Password',
            action: 'Change Password View'
        });


        return (
            <div className='form-group-container mr-auto ml-auto paper'>
                <div className='form-group-title'>Change My Password</div>
                <div>
                    <form className='demoForm' onSubmit={this.submitForm.bind(this)}>
                        <div className='form-group'>
                            <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                            <div className='form-group'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <ReadonlyItem textLabel="Account">{this.state.UserDisplayName}</ReadonlyItem>
                                    </div>
                                    <div className='col-md-8'>
                                        <ReadonlyItem textLabel="Email">{this.state.Email}</ReadonlyItem>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <ColumnAndLabel
                                    ErrorMessage=""
                                    IconLabel="lock"
                                    Id="CurrentPassword"
                                    InputType="password"
                                    IsRequired={false}
                                    SizeCss="md"
                                    HintLabel=""
                                    TextLabel="Current Password"
                                    Validate={true}
                                    Value={this.state.CurrentPassword}
                                    HandleInputChange={this.handleInputChange}
                                />
                            </div>
                            <div className='form-group'>
                                <ColumnAndLabel
                                    ErrorMessage=""
                                    IconLabel="lock"
                                    Id="NewPassword"
                                    InputType="password"
                                    IsRequired={false}
                                    SizeCss="md"
                                    HintLabel=""
                                    TextLabel="New Password"
                                    Validate={true}
                                    Value={this.state.NewPassword}
                                    HandleInputChange={this.handleInputChange}
                                />
                            </div>
                            <div className='form-group'>
                                <ColumnAndLabel
                                    ErrorMessage=""
                                    IconLabel="lock"
                                    Id="ConfirmPassword"
                                    InputType="password"
                                    IsRequired={false}
                                    SizeCss="md"
                                    HintLabel=""
                                    TextLabel="Confirm Password"
                                    Validate={true}
                                    Value={this.state.ConfirmPassword}
                                    HandleInputChange={this.handleInputChange}
                                />
                            </div>
                            <div className='text-right py-3'>
                                <MDBBtn
                                    className="pull-right mdb-btn-resize"
                                    color="primary"
                                    type="submit"
                                    size="sm"
                                    disabled={!this.state.formValid}
                                >
                                    <MDBIcon icon="save" className="mr-2" />Reset Password
                                </MDBBtn>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
