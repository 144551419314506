import React, { Component, Fragment } from "react";
import validator from 'validator';
import uuid from "uuid";

import AuthHelper from './AuthHelper';
import { ColumnAndLabel } from './ColumnAndLabel';
import { NotificationPanel } from './NotificationPanel';
import { ReadonlyItem } from './ReadonlyItem';

import { MDBBtn, MDBIcon, MDBCard, MDBCardBody } from "mdbreact";

const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;
export class AccountReset extends Component {
    displayName = AccountReset.name;
    displayNameStr = 'AccountReset';

    constructor(props) {
        super(props);
        const auth = new AuthHelper();
        this.state = {
            ...props,
            EmailAddress: '',
            Password: '',
            PasswordConfirm: '',
            UserId: auth.ResetPasswordUser,
            UserDisplayName: '',
            Username: '',
            expiredToken: false,
            formValid: false,
            formErrors: {
                Password: 'Password Is Required',
                PasswordConfirm: 'Password Must Match'
            },
            loading: false,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            resetComplete: false
        };

    }

    componentDidMount = () => {
        this.fetchData();
    }

    togglePleaseWait = status => {
        this.setState({
            loading: status
        });
    }


    handleInputChange = (event) => {

        const target = event.target;
        var value = target.value;
        if (value === undefined) {
            value = target.parentElement.value;
        }
        const name = target.name;
        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        },
            () => { this.validateField(name, value); }
        );
        if (debugOn) {
            console.log(this.displayNameStr + '.state', this.state);
        }
    }

    handleOnInputValidation = event => {

        let fieldValidationErrors = this.state.formErrors;
        let message = "";
        switch (event.target.name.toLowerCase()) {
            case 'password':
                fieldValidationErrors.Password = validator.isEmpty(event.target.value) ? 'Password is required.' : '';
                if (fieldValidationErrors.Password === "") {
                    fieldValidationErrors.Password = validator.isLength(this.state.Password, { min: 8 }) ? '' : 'Password must be more than 7 characters.';
                }
                message = fieldValidationErrors.Password;
                break;

            case 'passwordconfirm':
                fieldValidationErrors.PasswordConfirm = validator.isEmpty(event.target.value) ? 'Password is required.' : '';
                if (fieldValidationErrors.PasswordConfirm === "") {
                    fieldValidationErrors.PasswordConfirm = !validator.equals(event.target.value, this.state.Password) ? 'Password do not match.' : '';
                }
                message = fieldValidationErrors.PasswordConfirm;
                break;

            default:
                break;
        }

        if (message !== "") {
            this.setState({
                formErrors: fieldValidationErrors
            });
            //set custom validation error
            event.target.setCustomValidity(message);
        } else {
            //clear custom validation errors
            event.target.setCustomValidity("");
        }
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        switch (fieldName) {
            case 'Password':
                fieldValidationErrors.Password = validator.isEmpty(this.state.Password) ? 'Password is required.' : '';
                fieldValidationErrors.Password = validator.isLength(this.state.Password, { min: 8 }) ? '' : 'Password must be more than 7 characters.';
                break;

            case 'PasswordConfirm':
                fieldValidationErrors.PasswordConfirm = validator.isEmpty(this.state.PasswordConfirm) ? 'Password confirmation is required.' : '';
                if (fieldValidationErrors.PasswordConfirm === "") {
                    fieldValidationErrors.PasswordConfirm = !validator.equals(this.state.PasswordConfirm, this.state.Password) ? 'Password do not match.' : '';
                }
                break;

            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid: this.state.formErrors
                && this.state.formErrors.Password.length === 0
                && this.state.formErrors.PasswordConfirm.length === 0
        });
        return this.state.formValid;
    }

    handleAbortCallback = () => {
        const auth = new AuthHelper();
        auth.LogOut();
        var url = window.location.origin;
        console.log(this.displayName + '.handleAbortCallback', url, window.location);
        if (url) {
            //replace the querystring and go to home
            console.log(this.displayName + '.url', url, window.location.pathname);
            window.location.href = url;
        }
    }

    submitForm = (e) => {
        e.preventDefault();

        if (this.validateForm()) {
            var postData = JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.InsertUser",
                origin: "Installer Portal",
                operation: "changeuserpassword",
                datavalues: JSON.stringify({
                    ConfirmPassword: this.state.PasswordConfirm,
                    Email: this.state.EmailAddress,
                    Password: this.state.Password,
                    UserName: this.state.Username
                })
            });
            console.log(this.displayName + '.submitForm[postData]', postData);
            fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/ChangePassword', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: postData
            })
                .then(response => response.json())
                //.then(data => JSON.parse(data))
                .then(data => {
                    console.log(this.displayName + '.submitForm(data)', data);
                    if (data.returncode === 1) {
                        const resetData = JSON.parse(data.returnData.payload);
                        console.log(this.displayName + '.submitForm(resetData)', resetData);
                        //if (data.returncode == 1) {
                        //} //Password01!
                        this.setState({
                            loading: false,
                            notificationPostfix: 'success show',
                            notificationMessage: ['Successfully completed password reset. Please login'],
                            resetComplete: true
                        });
                    } else {
                        //if (data.returncode == 1) {
                        //} //Password01!
                        this.setState({
                            loading: false,
                            notificationPostfix: 'danger show',
                            notificationMessage: [data.message],
                            resetComplete: false
                        });
                    }

                })
                .catch(data => {
                    console.log(this.displayName + 'fetchData[err]', data);
                    this.setState({
                        loading: false,
                        ErrorMessage: 'No Data',
                        notificationPostfix: 'danger show',
                        notificationMessage: ['Bad Request - No data or service not available'],
                        resetComplete: false
                    });
                });
        } else {
            this.setState({
                loading: false,
                ErrorMessage: 'No Data',
                notificationPostfix: 'danger show',
                notificationMessage: ['Bad Request - Form has errors']
            });
        }
    }

    fetchData = () => {
        //perform account lookup from userId
        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetUserAccount",
            origin: "Installer Portal",
            operation: "getunexpiredtoken",
            datavalues: JSON.stringify({
                UserId: this.state.UserId
            })
        });
        //console.log(this.displayName + '.fetchData[postData]', postData);
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/FindUser', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        })
            .then(response => response.json())
            //.then(data => JSON.parse(data))
            .then(data => {
                console.log(this.displayName + '.fetchData[data]', data);
                if (data.returncode === 1) {
                    var user = JSON.parse(data.returnData.payload);
                    this.setState({
                        EmailAddress: user.Email,
                        UserDisplayName: user.DisplayName,
                        Username: user.UserName,
                        loading: false,
                        notificationPostfix: 'success hide',
                        notificationMessage: ['Successfully loaded user account']
                    });
                } else {
                    this.setState({
                        EmailAddress: '',
                        UserDisplayName: '',
                        Username: '',
                        loading: false,
                        notificationPostfix: 'danger show',
                        notificationMessage: [data.message],
                        expiredToken: true
                    });
                }
            })
            .catch(data => {
                console.log(this.displayName + 'fetchData[err]', data);
                this.setState({
                    EmailAddress: '',
                    UserDisplayName: '',
                    Username: '',
                    loading: false,
                    ErrorMessage: 'No Data',
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - No data or service not available']
                });
            });
    }

    render() {
        return (
            <MDBCard>
                <MDBCardBody>
                    {!this.state.resetComplete && !this.state.expiredToken ?
                        (
                            <Fragment>
                                <div className='form-group-title'>Reset My Password</div>
                                <div>
                                    <form className='demoForm was-validated' onSubmit={this.submitForm.bind(this)} noValidate>
                                        <div className='form-group'>
                                            <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                                            <div className='form-group'>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <ReadonlyItem textLabel="Username">{this.state.Username}</ReadonlyItem>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <ReadonlyItem textLabel="Name">{this.state.UserDisplayName}</ReadonlyItem>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <ReadonlyItem textLabel="Email">{this.state.EmailAddress}</ReadonlyItem>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <ColumnAndLabel
                                                    ErrorMessage={this.state.formErrors.Password}
                                                    IconLabel="lock"
                                                    Id="Password"
                                                    InputType="password"
                                                    IsRequired={true}
                                                    SizeCss="md"
                                                    HintLabel=""
                                                    TextLabel="Password"
                                                    Validate={true}
                                                    Value={this.state.Password}
                                                    HandleInputChange={this.handleInputChange}
                                                    HandleOnInput={this.handleOnInputValidation}
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <ColumnAndLabel
                                                    ErrorMessage={this.state.formErrors.PasswordConfirm}
                                                    IconLabel="lock"
                                                    Id="PasswordConfirm"
                                                    InputType="password"
                                                    IsRequired={true}
                                                    SizeCss="md"
                                                    HintLabel=""
                                                    TextLabel="Confirm Password"
                                                    Validate={true}
                                                    Value={this.state.PasswordConfirm}
                                                    HandleInputChange={this.handleInputChange}
                                                    HandleOnInput={this.handleOnInputValidation}
                                                />
                                            </div>
                                            <div className='text-right py-3'>
                                                <MDBBtn
                                                    className="pull-right mdb-btn-resize"
                                                    color="primary"
                                                    type="submit"
                                                    size="sm"
                                                    disabled={!this.state.formValid}>
                                                    <MDBIcon icon="save" className="mr-2" />Reset Password</MDBBtn>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Fragment>
                        ) :
                        (
                            <Fragment>
                                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                                <div className="text-center py-4 mt-3">
                                    <MDBBtn
                                        className="mdb-btn-resize"
                                        color="info"
                                        type="button"
                                        size="sm"
                                        onClick={this.handleAbortCallback}>
                                        <MDBIcon icon="arrow-left" className="mr-2" />{this.state.expiredToken ? 'Restart' : 'Login'}</MDBBtn>
                                </div>
                            </Fragment>
                        )
                    }

                </MDBCardBody>
            </MDBCard>
        );
    }
}

