import React, { Component, Fragment } from 'react';

import AuthHelper from '../SharedComponents/AuthHelper';
import { DefaultCompanyList } from '../SharedComponents/DefaultCompanyList';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';


const dashboardUrl = '/';
export class ConfigureDashboard extends Component {
    displayName = "ConfigureDashboard";

    constructor(props) {
        super(props);
        //const auth = new AuthHelper();
        this.state = {
            ...props,
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide'
        };

        //console.log(this.displayName + '.ctor: auth > ', auth);
        //console.log(this.displayName + '.ctor: state > ', this.state);
    }

    handleDefaultCompanyCallback = (company) => {
        //console.log(this.displayName + '.handleDefaultCompanyCallback', company);
        //force home reload
        this.props.history.push(dashboardUrl);
        //force reload
        window.location.reload();
    }

    render() {
        return (
            <Fragment>
                <div className='form-group-fluid-container mr-auto ml-auto'>
                    <div className='form-group-title'>Change Company</div>
                    <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                    <DefaultCompanyList
                        handleDefaultCompany={this.handleDefaultCompanyCallback}
                    />
                </div>
            </Fragment>
        );
    }
}