import React, { Component } from 'react';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';
import { ColumnAndLabelRadioOptions } from './../SharedComponents/ColumnAndLabelRadioOptions';

export class StatementSearch extends Component {
    displayName = StatementSearch.name

    

    constructor(props) {
        super(props);

        this.state = {
            ...props
        };
    }

    render() {

        return (
            <div className='form-group'>
                <div className='row'>
                    <div className='col-xs-6 col-md-4'>

                        <ColumnAndLabelRadioOptions
                            id="ViewInvoices"
                            name="ViewInvoices"
                            textLabel="View Invoices"
                            optionNo={'No'}
                            optionYes={'Yes'}                            
                            value={this.props.ViewInvoices}
                            handleChange={this.props.handleViewInvoiceChange}
                            />

                    </div>
                    <div className='col-xs-6 col-md-4'>
                        <ColumnAndLabelRadioOptions
                            id="ViewPayments"
                            name="ViewPayments"
                            textLabel="View Payments"
                            optionNo={'No'}
                            optionYes={'Yes'}
                            value={this.props.ViewPayments}
                            handleChange={this.props.handleViewPaymentChange}
                        />
                        
                    </div>
                    <div className='col-xs-6 col-md-4'>
                        <ColumnAndLabelRadioOptions
                            id="ViewAdjustments"
                            name="ViewAdjustments"
                            textLabel="View Adjustments"
                            optionNo={'No'}
                            optionYes={'Yes'}
                            value={this.props.ViewAdjustments}
                            handleChange={this.props.handleViewAdjustmentChange}
                        />
                        
                    </div>
                </div>
                <div className='row date'>
                    <div className='col-md-3' >
                        <SingleDatePicker id='CompletedBefore' inputIconPosition="after" block={false} date={this.props.CompletedBefore} placeholder="Date From"
                            isOutsideRange={() => false} onDateChange={(event) => this.props.handleCompletedBeforeChange(event, this.props.CompletedBefore)}
                            focused={this.state.focused} onFocusChange={({ focused }) => this.setState({ "focused": focused })}
                            openDirection="down"                           
                            hideKeyboardShortcutsPanel displayFormat="DD MMM YYYY" showClearDate />
                    </div>
                    <div className='col-md-3' >
                        <SingleDatePicker id='CompletedAfter' inputIconPosition="after" block={false} date={this.props.CompletedAfter} placeholder="Date To"
                            isOutsideRange={() => false} onDateChange={(event) => this.props.handleCompletedAfterChange(event, this.props.CompletedAfter)}
                            focused={this.state.focusedOne} onFocusChange={({ focused }) => this.setState({ "focusedOne": focused })} openDirection="down" hideKeyboardShortcutsPanel displayFormat="DD MMM YYYY" showClearDate />
                    </div>                    
                </div>

                <div className='row'>

                    <div className='col-md-12'>

                        <a className='btn btn-success btn-sm pull-right' onClick={this.props.handleSearch}><i className="fa fa-search pr-2" />Search</a>
                        <a className='btn btn-primary btn-sm pull-right' onClick={this.props.handleClear}><i className="fa fa-times-circle pr-2" />Clear</a>

                    </div>
                </div>

            </div >
        );
    }
}