import React, { Component } from 'react';

import ReactGA from 'react-ga';

import '../Fensa.css';
import './UsefulResources.css';

import { PopularDownloads } from '../Downloads/PopularDownloads';
import { ApprovedDownloads } from '../Downloads/ApprovedDownloads';
//import { Table, ButtonToolbar, Button } from 'react-bootstrap';

//import AuthHelper from '../SharedComponents/AuthHelper';
import { CardBodyOnly } from './../SharedComponents/CardBodyOnly';
//import { PleaseWait } from '../SharedComponents/PleaseWait';

import { MDBJumbotron, MDBContainer, MDBRow, MDBCol } from 'mdbreact';

export class UsefulFinanceResources extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {

        ReactGA.event({
            category: 'Useful Finance Resources',
            action: 'Useful Finance Resources List'
        });


        return (
            <MDBContainer>
                <MDBRow>
                    <MDBCol>
                        <MDBJumbotron fluid>
                            <div className='px-5'>
                                <h1 className='h1 h1-responsive'>Useful Finance Resources</h1>
                                <p className='lead text-left'>Here you will be able to download or print off useful documents such as your company registration certificate, inspection regime, FENSA logo, order forms for free van and showroom stickers, technical guidance notes and other useful information.</p>
                            </div>
                        </MDBJumbotron>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="col-md-6">
                        <CardBodyOnly>
                            <h2 className='h2-responsive pb-3'>Popular Downloads</h2>
                            <PopularDownloads />
                        </CardBodyOnly>
                    </MDBCol>
                    <MDBCol className="col-md-6">
                        <CardBodyOnly>
                            <h2 className='h2-responsive pb-3'>Approved Downloads</h2>
                            <ApprovedDownloads />
                        </CardBodyOnly>
                    </MDBCol>
                </MDBRow >
            </MDBContainer>
        );
    }
}