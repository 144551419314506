import React, { Component, Fragment } from 'react';
import ReactTable from "react-table";
import { Link } from 'react-router-dom';
import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { CardBodyOnly } from './../SharedComponents/CardBodyOnly';
import { CardFooterButtonsIcons } from './../SharedComponents/CardFooterButtonsIcons';
import { EnergyRatingSearch } from './EnergyRatingSearch';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { PleaseWait } from './../SharedComponents/PleaseWait';
import { ReactTableFilterToggler } from './../SharedComponents/ReactTableFilterToggler';

import Badge from "@material-ui/core/Badge";

export class EnergyRatingList extends Component {
    displayName = EnergyRatingList.name;

    constructor(props) {
        super(props);
        const auth = new AuthHelper(this.displayName);

        //auth.CompanyReference

        this.state = {
            ...props,
            CompanyNumber: auth.CompanyReference,
            LicenceNumber: "",
            ShowEditButton: false,
            ShowDeleteButton: false,
            ShowViewButton: true,
            displayContent: (<PleaseWait IsLoading={true} />),
            payload: [],
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 10,
            resized: [],
            sorted: [],
            totals: '',
            useFiltering: (auth.TableFilter && auth.TableFilter === 'On' ? true : false)
        };
        this.handleSearch = this.handleSearch.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount = () => {
        this.fetchData();
    }

    handleSearch(event) {
        this.fetchData();
        event.preventDefault();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        });
    }

    handleToggleFilter = toggle => {
        this.setState({
            useFiltering: toggle
        });
        this.fetchData();
    }

    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    renderTable = labels => {
        return (
            <div className="ui-layout-content">
                <ReactTableFilterToggler
                    ParentDisplayName={this.displayName}
                    UseFiltering={this.state.useFiltering}
                    handleToggleFilter={this.handleToggleFilter}
                />
                <ReactTable data={labels}
                    columns={[
                        {
                            key: 'LicenceId',
                            Header: 'Id',
                            accessor: 'LicenceId',
                            show: false
                        },
                        {
                            Header: 'LicenceNumber:',
                            accessor: 'LicenceNumber',
                            className: 'p-3'
                        },
                        {
                            Header: 'Material', // Custom header components!
                            accessor: 'ProductMaterial',
                            className: 'p-3'
                        },
                        {
                            Header: 'Product', // Custom header components!
                            accessor: 'ProductName',
                            className: 'p-3'
                        },
                        {
                            Header: 'Company', // Custom header components!
                            accessor: 'CompanyName',
                            className: 'p-3'
                        },
                        {
                            Header: 'Type', // Custom header components!
                            accessor: 'ProductType',
                            className: 'p-3'
                        },
                        {
                            Header: 'Description', // Custom header components!
                            accessor: 'ProductDescription',
                            className: 'p-3'
                        },
                        {
                            Header: 'Rating', // Custom header components!
                            accessor: 'EnergyRating',
                            className: 'p-3'
                        },
                        {
                            Header: '',
                            accessor: '',
                            filterable: false,
                            sortable: false,
                            Cell: row => {
                                return (
                                    <Fragment>
                                        <div className='text-center py-1 pr-5'>
                                            <CardFooterButtonsIcons
                                                Id={row.original.Id}
                                                //DeleteCss={ButtonsJss.DefaultDeleteCss}
                                                //EditCss={ButtonsJss.DefaultEditCss}
                                                //ViewCss={ButtonsJss.DefaultViewCss}
                                                //DeleteUrl={'/EnergyRatings/Delete/' + row.original.Id}
                                                //EditUrl={'/EnergyRatings/Update/' + row.original.Id}
                                                ViewUrl={'/EnergyRatings/View/' + row.original.LicenceId}
                                                ShowViewButton={this.state.ShowViewButton} // use role priviledge to set visibility
                                                ShowEditButton={this.state.ShowEditButton} // use role priviledge to set visibility
                                                ShowDeleteButton={this.state.ShowDeleteButton}// use role priviledge to set visibility
                                            />
                                        </div>
                                    </Fragment>
                                );
                            }
                        }
                    ]}
                    className="table-bordered table-striped table-hover table-shadowed"
                    defaultPageSize={this.state.pageSize}
                    expanded={this.state.expanded}
                    filterable={this.state.useFiltering}
                    minRows={0}
                    nextText='>'
                    previousText='<'

                //onPageChange={(page) => this.setPageId(page)}
                //page={this.state.page}
                //page={this.state.page}
                //pageSize={this.state.pageSize}
                //resized={this.state.resized}
                //filtered={this.state.filtered}
                // Callbacks
                //onSortedChange={sorted => this.setState({ sorted })}
                //onPageChange={page => this.setState({ page })}
                //onPageSizeChange={(pageSize, page) => this.setState({ page, pageSize })}
                //onExpandedChange={expanded => this.setState({ expanded })}
                //onResizedChange={resized => this.setState({ resized })}
                //onFilteredChange={filtered => this.setState({ filtered })}
                />

            </div>
        );
    }

    fetchData = () => {

        this.setState({
            loading: true
        });

        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerContacts",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                LicenceNumber: this.state.LicenceNumber,
                CompanyNumber: this.state.CompanyNumber
            })
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BFRCWebAPI/GetCompanyLabelsInfo', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        })
            .then(response => response.json())
            .then(data => JSON.parse(data.result))
            .then(data => {
                //console.log(this.displayName + '.fetchData[raw]', data);
                //console.log(this.displayName + '.fetchData[raw.returncode]', data.returncode);
                const licenseData = JSON.parse(data.message);
                //console.log(this.displayName + '.fetchData[data]', licenseData);
                if (!licenseData || licenseData.length === 0) {
                    this.setState({
                        loading: false,
                        displayContent: this.renderNoDataView(),
                        notificationPostfix: 'info hide',
                        notificationMessage: ['No Data'],
                        payload: []
                    });
                } else {
                    //console.log(this.displayName + '.fetchData[licenseData]', licenseData);
                    this.setState({
                        displayContent: this.renderTable(licenseData),
                        loading: false,
                        notificationPostfix: 'info hide',
                        notificationMessage: [],
                        payload: licenseData,
                        totals: licenseData.length
                    });
                }
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData[catch]', data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    displayContent: ''
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'Energy Rating List',
            action: 'View Energy Ratings Lists'
        });


        return (
            <div>
                <div className="h2 h2-responsive">
                    <Badge badgeContent={this.state.totals} color="primary">Energy Rating License Numbers</Badge>
                    <PleaseWait IsLoading={this.state.loading} float={'right'} />
                </div>
                <CardBodyOnly>
                    <EnergyRatingSearch
                        handleSearch={this.handleSearch}
                        handleChange={this.handleInputChange}
                    />
                </CardBodyOnly>
                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                <p className="pt-3">Click on the Energy Ratings table below.</p>
                {this.state.displayContent}
                <Link className='btn btn-sm btn-dark pull-right mt-5 mdb-btn-resize' to='/EnergyRatings/Home'><i className="fa fa-arrow-left pr-3" />Back to Home</Link>
            </div>
        );
    }
}