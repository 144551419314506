import React, { Component } from 'react';
//import { Col, Grid, Row } from 'react-bootstrap';
//import { Link } from 'react-router-dom';
//import { Glyphicon, Nav, Navbar, NavItem } from 'react-bootstrap';

export class PopularItem extends Component {
    displayName = PopularItem.name

    render() {
//        console.log(this.props.Update)
        return (
            <div className='row'>
                <div className='col-md-12' >
                    <a href={this.props.url} target="_blank">{this.props.text}</a>{this.props.Update === 'true' ?<span style={{color:'red'}}>{'   (Updated!)'}</span>:' '}
                    <br />
                    <p>{this.props.description}</p>
                    <p>{this.props.baseurl}</p>
                </div>
            </div>
        );
    }
}