import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator';
import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { ColumnAndLabel } from '../SharedComponents/ColumnAndLabel';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { PleaseWait } from './../SharedComponents/PleaseWait';
import { ReadonlyItem } from './../SharedComponents/ReadonlyItem';

import {
    MDBBtn,
    MDBIcon
} from "mdbreact";

const userHomeUrl = "/";
const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;
export class MyDetails extends Component {
    displayName = "MyDetails";

    constructor(props) {
        super(props);

        const auth = new AuthHelper();
        const newUser = auth.UserId === '-1' || auth.UserId === undefined;
        this.state = {
            Activated: 0,
            CanEdit: auth.CanEditData,
            CompanyId: auth.CompanyId,
            CompanyReference: auth.CompanyReference,
            CompanyUserEmail: '',
            CompanyUserStatus: 1,
            ContactType: 0,
            ContactTypeList: [],
            CreatedBy: auth.Username,
            CreatedById: auth.UserId,
            Deleted: false,
            DuplicateUserName: false,
            ExistingAccount: true,
            FaxNumber: '',
            FirstName: '',
            GlobalUserStatus: 0,
            IsFensaAdmin: auth.IsFensaAdmin,
            JobTypeId: -1,
            LastName: '',
            MainAuthUser: false,
            MiddleName: '',
            MobilePhone: '',
            ResetPwd: false,
            RoleId: 0,
            RoleList: [],
            Telephone: '',
            Title: '',
            UsePaging: true,
            UserId: auth.UserId,
            UserName: '',

            existingUser: false,
            formErrors: {
                CompanyUserEmail: "Email address is required.",
                Telephone: "Telephone number is required."
            },
            formValid: true,
            isNewUser: newUser,
            loading: !newUser,
            loadingRoles: true,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 0,
            pageTitle: 'My Profile',
            processingSubmit: false,
            registrationComplete: false,
            //if not creating a new user, then the username is already known
            validatedUsername: newUser ? false : true
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        console.log(this.state);
    }


    componentDidMount() {
        this.fetchData();
    }

    currentPageTitle = () => {
        return this.state.pageTitle;
    }

    togglePleaseWait = status => {
        console.log('togglePleaseWait.status: ', status);
        this.setState({
            loading: status
        });
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;

        if (value === undefined) {
            value = target.parentElement.value;
        }

        const name = target.name;

        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        },
            () => { this.validateField(name, value); }
        );

        //console.log(this.displayName + '.handleInputChange', name, value);
    }

    handleOnInputValidation = event => {

        let fieldValidationErrors = this.state.formErrors;
        let message = "";
        switch (event.target.name.toLowerCase()) {
            case 'companyuseremail':
                fieldValidationErrors.CompanyUserEmail = validator.isEmpty(event.target.value) ? 'Email is Required.' : '';
                if (fieldValidationErrors.CompanyUserEmail !== "") {
                    fieldValidationErrors.CompanyUserEmail = validator.isEmail(event.target.value) ? '' : 'Email is not in a valid format.';
                }
                message = fieldValidationErrors.CompanyUserEmail;
                break;

            case 'telephone':
                fieldValidationErrors.Telephone = validator.isEmpty(event.target.value) ? 'Telephone is Required.' : '';
                message = fieldValidationErrors.Telephone;
                break;

            default:
                break;
        }

        if (message !== "") {
            this.setState({
                formErrors: fieldValidationErrors
            }, this.validateForm);
            //set custom validation error
            event.target.setCustomValidity(message);
        } else {
            //clear custom validation errors
            event.target.setCustomValidity("");
        }
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        switch (fieldName.toLowerCase()) {
            case 'companyuseremail':
                fieldValidationErrors.CompanyUserEmail = validator.isEmpty(this.state.CompanyUserEmail) ? 'Email address is required.' : '';
                fieldValidationErrors.CompanyUserEmail = !validator.isEmail(this.state.CompanyUserEmail) ? 'Email is not in a valid format.' : '';
                break;

            case 'telephone':
                fieldValidationErrors.Telephone = validator.isEmpty(this.state.Telephone) ? 'Telephone number is required.' : '';
                fieldValidationErrors.Telephone = validator.isLength(this.state.Telephone, { min: 10 }) ? '' : 'Telephone number must be more than 10 characters.';
                break;

            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid: this.state.formErrors
                && this.state.formErrors.CompanyUserEmail.length === 0
                && this.state.formErrors.Telephone.length === 0
        });
        return this.state.formValid;
    }

    submitForm = e => {
        e.preventDefault();
        if (this.validateForm()) {

            this.togglePleaseWait(true);

            var datavalues = JSON.stringify({
                Activated: this.state.Activated === 0 ? false : true,
                Email: this.state.CompanyUserEmail,
                CompanyId: this.state.CompanyId,
                CompanyUserEmail: this.state.CompanyUserEmail,
                CompanyUserStatus: this.state.CompanyUserStatus,
                ContactType: this.state.ContactType,
                CreatedBy: this.state.CreatedBy,
                CreatedById: this.state.CreatedById,
                Deleted: this.state.Deleted,
                ExistingAccount: this.state.ExistingAccount,
                FensaNumber: this.state.CompanyReference,
                FirstName: this.state.FirstName,
                GlobalUserStatus: this.state.GlobalUserStatus,
                JobTypeId: this.state.JobTypeId,
                LastName: this.state.LastName,
                MainAuthUser: this.state.MainAuthUser,
                Mobile: this.state.Mobile,
                ResetPwd: this.state.ResetPwd,
                RoleId: this.state.RoleId,
                Telephone: this.state.Telephone,
                UserId: this.state.UserId,
                UserName: this.state.UserName
            });

            console.log(this.displayName + '.submitForm[datavalues]', datavalues);

            fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/UpdateUser', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    seqno: uuid.v4(),
                    datatype: "BIT.Fensa.InstallerPortal.CreateUser",
                    origin: "Installer Portal",
                    operation: "INSERT",
                    datavalues: datavalues
                })
            })
                .then(response => response.json())
                //.then(data => JSON.parse(data))
                .then(data => {
                    console.log(this.displayName + '.fetchData[data]', data);
                    if (data.returncode === 1) {
                        this.setState({
                            Result: data.message,
                            loading: false,
                            notificationPostfix: 'success show',
                            notificationMessage: [data.message]
                        });
                        this.props.history.push(userHomeUrl);
                    } else {
                        this.setState({
                            Result: data.message,
                            loading: false,
                            notificationPostfix: 'danger show',
                            notificationMessage: [data.message]
                        });
                    }
                })
                .catch(data => {
                    if (debugOn) {
                        console.log('ERR: submit_callback', data);
                    }
                    this.setState({
                        loading: false,
                        notificationPostfix: 'danger show',
                        notificationMessage: debugOn ? [data.message] : ['Bad Request - No data or service is unavailable']
                    });
                });
        }
    }

    fetchData = () => {

        this.togglePleaseWait(true);

        var fetchRoles = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.Roles",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyId
                })
            })
        });

        var fetchJobTitle = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.ContactType",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyId
                })
            })
        });

        var fetchMyDetails = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.GetInstallerMyProfile",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    UserId: this.state.UserId,
                    CompanyId: this.state.CompanyId
                })
            })
        });

        Promise.all([fetchMyDetails, fetchJobTitle, fetchRoles])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {

                console.log(this.displayName + '.fetchData[data]', responses);
                let fieldValidationErrors = this.state.formErrors;

                let myDetailsData = JSON.parse(JSON.parse(responses[0]).ReturnData.Payload);
                if (myDetailsData && myDetailsData.length > 0) {
                    myDetailsData = myDetailsData[0];
                }
                //console.log(this.displayName + '.fetchData[myDetailsData]', myDetailsData);
                const jobTitleData = JSON.parse(JSON.parse(responses[1]).ReturnData.Payload);
                //console.log(this.displayName + '.fetchData[jobTitleData]', jobTitleData);
                const rolesData = JSON.parse(JSON.parse(responses[2]).ReturnData.Payload);
                //console.log(this.displayName + '.fetchData[rolesData]', rolesData);

                let jobTitleText = 'Not Specified';
                if (myDetailsData !== null && jobTitleData !== null) {
                    const filter = jobTitleData.find((jobType) => jobType.Id === myDetailsData.JobTypeId);
                    jobTitleText = filter ? filter.Name : 'Not Specified';
                }

                let roleText = 'Not Set';
                if (myDetailsData !== null && rolesData !== null) {
                    const filter = rolesData.find((role) => role.ID === myDetailsData.RoleId);
                    roleText = filter ? filter.Name : 'Not Set';
                }

                //clear errors
                fieldValidationErrors.CompanyUserEmail = myDetailsData.CompanyUserEmail ? validator.isEmail(myDetailsData.CompanyUserEmail)? '': 'Email is not in a valid format.' : 'Email address is Required';
                fieldValidationErrors.Telephone = myDetailsData.Telephone ? '' : 'Telephone number is Required';

                this.setState({
                    Activated: myDetailsData.Activated === 0 ? false : true,
                    CompanyUserEmail: myDetailsData.CompanyUserEmail,
                    CompanyUserStatus: myDetailsData.CompanyUserStatus,
                    Deleted: myDetailsData.Deleted,
                    ExistingAccount: myDetailsData.ExistingAccount,
                    FirstName: myDetailsData.FirstName,
                    GlobalUserStatus: myDetailsData.GlobalUserStatus,
                    JobTypeId: myDetailsData.JobTypeId,
                    JobTypeValueText: jobTitleText,
                    LastName: myDetailsData.LastName,
                    MainAuthUser: myDetailsData.MainAuthUser === 0 || !myDetailsData.MainAuthUser ? false : true,
                    Mobile: myDetailsData.MobilePhone,
                    ResetPwd: myDetailsData.ResetPwd,
                    RoleId: myDetailsData.RoleId,
                    RoleValueText: roleText,
                    Telephone: myDetailsData.Telephone,
                    UserEmail: myDetailsData.CompanyUserEmail,
                    UserName: myDetailsData.UserName,
                    VerificationCode: myDetailsData.VerificationCode,

                    formErrors: fieldValidationErrors,
                    loading: false,
                    notificationPostfix: 'info hide',
                    notificationMessage: [''],
                    processingSubmit: false
                });
            })
            .catch(data => {
                if (debugOn) {
                    console.log(this.displayName + '.fetchData[err]', data);
                }
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: debugOn ? [data.message] : ['Bad Request - No data or service is unavailable'],
                    processingSubmit: false
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'My Profile',
            action: 'My Profile View'
        });

        return (
            <div>
                <form className='demoForm was-validated' onSubmit={this.submitForm.bind(this)} noValidate>
                    <div className='form-group-container'>
                        <div className='h3 h3-responsive form-group-title'>{this.currentPageTitle()}</div>
                        <PleaseWait IsLoading={this.state.loading} />
                        <Fragment>
                            <div className='form-group'>
                                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                                <div className='row'>
                                    <div className='col-xs-12 col-md-6'>
                                        <ReadonlyItem textLabel="Username">{this.state.UserName}</ReadonlyItem>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-xs-12 col-md-6'>
                                        <ReadonlyItem textLabel="First Name">{this.state.FirstName}</ReadonlyItem>
                                    </div>
                                    <div className='col-xs-12 col-md-6'>
                                        <ReadonlyItem textLabel="Last Name">{this.state.LastName}</ReadonlyItem>
                                    </div>
                                    <div className='col-xs-12 col-md-4'>
                                        <ColumnAndLabel
                                            ErrorMessage={this.state.formErrors.CompanyUserEmail}
                                            IconLabel=""
                                            Id="CompanyUserEmail"
                                            InputType="text"
                                            IsRequired={true}
                                            SizeCss="sm"
                                            HintLabel=""
                                            TextLabel="Email"
                                            Validate={true}
                                            Value={this.state.CompanyUserEmail}
                                            HandleInputChange={this.handleInputChange}
                                            HandleOnInput={this.handleOnInputValidation}
                                        />
                                    </div>
                                    <div className='col-xs-12 col-md-4'>
                                        <ColumnAndLabel
                                            ErrorMessage={this.state.formErrors.Telephone}
                                            IconLabel=""
                                            Id="Telephone"
                                            InputType="text"
                                            IsRequired={true}
                                            SizeCss="sm"
                                            HintLabel=""
                                            TextLabel="Telephone"
                                            Validate={true}
                                            Value={this.state.Telephone}
                                            HandleInputChange={this.handleInputChange}
                                            HandleOnInput={this.handleOnInputValidation}
                                        />
                                    </div>
                                    <div className='col-xs-12 col-md-4'>
                                        <ColumnAndLabel
                                            ErrorMessage=""
                                            IconLabel=""
                                            Id="Mobile"
                                            InputType="text"
                                            IsRequired={false}
                                            SizeCss="sm"
                                            HintLabel=""
                                            TextLabel="Mobile Phone"
                                            Validate={true}
                                            Value={this.state.Mobile}
                                            HandleInputChange={this.handleInputChange}
                                        //HandleOnInput={this.handleOnInputValidation}
                                        />
                                    </div>
                                    <div className='col-xs-12 col-md-6'>
                                        <ReadonlyItem textLabel="Job Title">{this.state.JobTypeValueText}</ReadonlyItem>
                                    </div>
                                    <div className='col-xs-12 col-md-6'>
                                        <ReadonlyItem textLabel="Role">{this.state.RoleValueText}</ReadonlyItem>
                                    </div>
                                </div>
                            </div>
                            <div className='row pb-2'>
                                <div className='col text-right'>
                                    <PleaseWait IsLoading={this.state.loading} />
                                    <MDBBtn
                                        className="pull-right mdb-btn-resize"
                                        color="success"
                                        type="submit"
                                        size="sm"
                                        disabled={!this.state.formValid || this.state.processingSubmit}
                                        onClick={this.toggleModal}
                                    >
                                        <MDBIcon icon="save" className="mr-2" />Save</MDBBtn>
                                    <Link className='btn btn-sm btn-danger pull-right mr-2 mdb-btn-resize' to={userHomeUrl}>
                                        <i className='fa fa-arrow-left pr-2' />Cancel</Link>
                                </div>
                            </div>
                        </Fragment>
                    </div>
                </form>
            </div>
        );
    }
}
