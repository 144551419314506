import React, { Component } from 'react';

export class ColumnAndLabelSelect extends Component {
    displayName = ColumnAndLabelSelect.name

    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
        //console.log('ColumnAndLabelSelect.ctor', this.state);
    }

    componentWillReceiveProps = nextProps => {
        //console.log('ColumnAndLabelSelect.componentWillReceiveProps', nextProps);
        if (nextProps.value !== this.state.value) {
            this.setState({
                value: nextProps.value
            });
        }
    }

    render() {
        return (
            <div>
                <label htmlFor={this.state.id}>{this.state.textLabel}</label>
                <select id={this.state.id} name={this.state.name} className="browser-default custom-select" onChange={this.state.handleChange} value={this.state.value}>
                    {this.props.children}
                </select>
                <span className={this.state.validate && this.state.isRequired ? 'invalid-feedback' : ''}>{this.state.validate && this.state.isRequired ? this.state.errorMessage : null}</span>
            </div>
        );
    }
}