
import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { ReadonlyItem } from '../SharedComponents/ReadonlyItem';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import ReactTable from "react-table";
import { MDBBtn, MDBIcon } from "mdbreact";

import moment from 'moment';

import { InvoiceViewInstallations } from './InvoiceViewInstallations';
import { InvoiceViewInspections } from './InvoiceViewInspections';

import { DownloadPDF } from '../SharedComponents/DownloadPDF';

import '../Fensa.css';

// const detailsHomeUrl = '/Details';
const debugOn = process.env.DEBUG_MODE_ON;



export class StatementView extends Component {
    displayName = StatementView.name;

    constructor(props) {
        super(props);

        const auth = new AuthHelper();

        this.state = {
            CompanyId: auth.CompanyId,
            CompanyName: auth.CompanyName,
            InvoiceId: props.match.params.id,
            UsePaging: true,
            displayContent: (<PleaseWait IsLoading={true} />),
            InvoiceLines: '',
            loading: true,
            notificationMessage: '',
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 5,
            payload: [],
            IBGEdit: false,
            Tabvalue: 0,
            ShowInstallations: false,
            ShowInspections: false,
            InvoicePdf: '',
            EnableDownload: false,
            Transactiontype:''
        
        };

        this.HandleIBGClick = this.HandleIBGClick.bind(this);
        this.handleViewInstallations = this.handleViewInstallations.bind(this);
        this.handleViewInspections = this.handleViewInspections.bind(this);

        //this.handleTabChange = this.handleTabChange.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    HandleIBGClick(event) {

        event.preventDefault();

        this.setState({
            IBGEdit: true
        });
    }

    handleViewInstallations(event) {

        event.preventDefault();

        this.setState({
            ShowInstallations: true,
            ShowInspections:false
        });  

    }

    handleViewInspections(event) {

        event.preventDefault();

        this.setState({
            ShowInstallations: false,
            ShowInspections: true
        });  

    }

    fetchData = () => {

        var fetchInvoiceItem = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.GetInstallerInvoiceItem",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyId,
                    InvoiceId: this.state.InvoiceId
                })
            })
        });

        var fetchInvoiceLines = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.GetInstallerInvoiceLines",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyId,
                    InvoiceId: this.state.InvoiceId
                })
            })
        });

        Promise.all([fetchInvoiceItem, fetchInvoiceLines])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                console.log(this.displayName + '.fetchData[responses]', responses);

                var data = this.extractData(responses[0]);
                var Lines = this.extractData(responses[1]);

                console.log(this.displayName + '.fetchData[data]', data);
                console.log(this.displayName + '.fetchData[data]', Lines);

                this.setState({
                    payload: data,//JSON.parse(JSON.parse(responses[0]).message),
                    loading: false,
                    displayContent: !data ? this.renderNoDataView() : this.renderTable(data[0], Lines),
                    //InvoiceLines: !Lines ? this.renderNoDataView() : this.renderLines(Lines),
                    notificationPostfix: 'info hide',
                    notificationMessage: []
                });
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData[err]', data);
                this.setState({
                    displayContent: '',
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - No data or service unavailable'],
                    payload: []
                });
            });

        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.ReportGetCompanyInvoice",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                CompanyId: this.state.CompanyId,
                InvoiceId: this.state.InvoiceId
            })
        });

        
        console.log(process.env.REACT_APP_FENSA_WEB_API + 'FensaReporting/GetCompanyInvoice');

        //fetch('http://fensawebapi.uat.boroughit.com/api/FensaReporting/GetCompanyInvoice', {
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'FensaReporting/GetCompanyInvoice', {        
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    InvoicePdf: data.FileContents,
                    EnableDownload: true
                });
            });
    }


    renderTable = (companyDetailsData, Lines) => {
        return (
            companyDetailsData === null ?
                'Bad Request' : (
                    <div className='form-group-fluid-container mr-auto ml-auto'>

                            {(() => {
                           if(companyDetailsData.CreditDebitInd=='C')
                           {
                            this.state.Transactiontype='C';
                            return (
                             <div className='h3 h3-responsive'>Credit Note Details - {companyDetailsData.CompanyName}</div>
                            )
                           }
                           else
                           {
                            this.state.Transactiontype='D';
                            return (
                            <div className='h3 h3-responsive'>Invoice Details - {companyDetailsData.CompanyName}</div>
                            )
                           }
                            })()}
                                <div>
                                    <div className='form-group'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <ReadonlyItem textLabel="Reference">{companyDetailsData.Reference}</ReadonlyItem>
                                            </div>
                                            <div className='col-md-6'>
                                                <ReadonlyItem textLabel={companyDetailsData.CreditDebitInd=='C'?"Credit Note Date":"Invoice Date"}>{moment(companyDetailsData.InvoiceDate).format('DD MMM YYYY')}</ReadonlyItem>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <ReadonlyItem textLabel="Net Value">
                                                    {new Intl.NumberFormat('en-GB', {
                                                        style: 'currency',
                                                        currency: 'GBP',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(companyDetailsData.Net)}

                                                </ReadonlyItem>
                                            </div>
                                            <div className='col-md-6'>
                                                <ReadonlyItem textLabel="Tax">
                                                    {new Intl.NumberFormat('en-GB', {
                                                        style: 'currency',
                                                        currency: 'GBP',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(companyDetailsData.Tax)}
                                                </ReadonlyItem>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <ReadonlyItem textLabel="Total">
                                                
                                                    {new Intl.NumberFormat('en-GB', {
                                                        style: 'currency',
                                                        currency: 'GBP',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }).format(companyDetailsData.Total)}
                                              
                                            </ReadonlyItem>
                                        </div>
                                        <div className='col-md-6'>
                                            <ReadonlyItem textLabel="Credit / Debit">{companyDetailsData.CreditDebitInd}</ReadonlyItem>
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <ReadonlyItem textLabel="Invoice Type">{companyDetailsData.InvoiceTypeName}</ReadonlyItem>
                                        </div>
                                        <div className='col-md-6'>
                                            <ReadonlyItem textLabel="Payment Reference">{companyDetailsData.PaymentReference}</ReadonlyItem>
                                        </div>
                            </div>     

                            
                        </div>

                        

                                <h4>Invoice Lines</h4>

                                <ReactTable data={Lines}
                                    columns=
                                    {
                                        [
                                            {
                                                Header: 'Line', // Custom header components!
                                                accessor: 'LineNumber',
                                                show: true,
                                                width:50
                                            },
                                            {
                                                Header: 'Category', // Custom header components!
                                                accessor: 'InvoiceLineItemTypeName',
                                                show: true,
                                                width: 150
                                            },                                            
                                            {
                                                Header: 'Product', // Custom header components!
                                                accessor: 'ProductDescription',
                                                show: true
                                            },
                                            {
                                                Header: 'Unit Cost',
                                                accessor: 'UnitCost',
                                                className: 'p-3',
                                                width: 100
                                            },
                                            {
                                                Header: 'Quantity', // Custom header components!
                                                accessor: 'Quantity',
                                                className: 'p-3',
                                                width: 100
                                            },
                                            {
                                                Header: 'Tax Amount',
                                                accessor: 'TaxAmount',
                                                className: 'p-3',
                                                width: 100
                                            },
                                            {
                                                Header: 'Total',
                                                accessor: 'Total',
                                                className: 'p-3',
                                                width: 100
                                            }
                                        ]
                                    }

                                    className="table-bordered table-striped table-light table-hover table-shadowed"
                                    defaultPageSize={this.state.pageSize}
                                    expanded={this.state.expanded}
                            filterable={this.state.useFiltering}
                            nextText='>'
                            previousText='<'

                                />

                        <div className='row'>
                            <MDBBtn
                                color="info"
                                icon="save"
                                type="submit"
                                className={!this.state.showInstallationHistoryButton ? "mdb-btn-resize pull-right show" : "hide"}
                                onClick={this.handleViewInstallations}>
                                <MDBIcon
                                    icon="align-justify"
                                    className="pr-2"
                                />View Installations</MDBBtn>

                            <MDBBtn
                                color="info"
                                icon="save"
                                type="submit"
                                size="sm"
                                className={!this.state.showInstallationHistoryButton ? "mdb-btn-resize pull-right show" : "hide"}
                                onClick={this.handleViewInspections}
                            >
                                <MDBIcon
                                    icon="align-justify"
                                    className="pr-2"
                                />View Inspections</MDBBtn>
                        </div>
                    </div>
                )
        );
    }

    render() {

        ReactGA.event({
            category: 'Statement View',
            action: 'View Statements'
        });


        return (
            <div>
                <DownloadPDF EnableDownload={this.state.EnableDownload} DownloadLabel={this.state.InvoicePdf} FileName={this.state.Transactiontype=='C'?'Credit Note':'Invoice(Ref: ' + this.state.InvoiceId + ').pdf'} Text={this.state.Transactiontype=='C'?'Credit Note PDF':'Invoice PDF'} />

                {this.state.displayContent}

                <DownloadPDF EnableDownload={this.state.EnableDownload} DownloadLabel={this.state.InvoicePdf} FileName={this.state.Transactiontype=='C'?'Credit Note':'Invoice(Ref: ' + this.state.InvoiceId + ').pdf'} Text={this.state.Transactiontype=='C'?'Credit Note PDF':'Invoice PDF'} />

                {this.state.ShowInstallations === true ? <InvoiceViewInstallations InvoiceId={this.state.InvoiceId} /> : ''}
                {this.state.ShowInspections === true ? <InvoiceViewInspections InvoiceId={this.state.InvoiceId} /> : ''}

            </div>
        );
    }
}

/// Handle data retrieval and handle ensuing errors if data is not found
StatementView.prototype.extractData = response => {
    try {
        return JSON.parse(response).returncode !== 0 ? JSON.parse(JSON.parse(response).message) : [];
    } catch (e) {
        console.log(e);
        return [];
    }
};

