import React, { Component } from 'react';
import { CardBodyOnly } from './../SharedComponents/CardBodyOnly';
import { MDBJumbotron, MDBContainer, MDBRow, MDBCol } from 'mdbreact';

export class UsefulMarketingReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Fensaref: sessionStorage.getItem('CompanyFensaNumber'),
            Company: sessionStorage.getItem('CompanyName')
    
        };
    }
    static DrawItems(ReportData) {
        //console.log('ReportData',ReportData)
        return (
            
            <MDBRow>
                <MDBCol className="col-md-12">
                    <CardBodyOnly>
                        <h2 className='h2-responsive pb-3'>{"Industry Reports"}</h2>
                        
                       
                               {/* <a target='_blank' href={`data:application/pdf;base64,${ReportData}`} download={'Glazing Market Knowledge Report 2023'} ><i />{'Glazing Market Knowledge Report 2023'} </a> */}                   

                               {
                               (typeof ReportData !=='undefined')?
                               
                               Object.entries(ReportData).map(([key, value]) => (
                                <div className='row'>
                               <div className='col-md-12' >
                              <a target='_blank' href={`data:application/pdf;base64,${value}`} download={key} ><i />{key} </a> 
                              </div>
                        </div>
                              )) :''
                              }

                     
                         
                    </CardBodyOnly>
                </MDBCol>
            </MDBRow>
        );
    }
    componentDidMount() {


        this.fetchData();
    }

    fetchData = () => {
        var postData = JSON.stringify({
            datavalues: JSON.stringify({
                Company: this.state.Company,
                FensaRefNo: this.state.Fensaref

            })
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/GetMarketingReport', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: postData

        })
            .then(response => response.json())
            .then(data => {
               //  console.log('rept',data)
                this.setState({
                    MarketingData: data,

                });
            
            });
           
             
    }

    render() {


        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : UsefulMarketingReport.DrawItems(this.state.MarketingData);

        return (
            <div>
                {contents}
            </div>
        );
    }
}