import React, { Component } from 'react';

import ReactGA from 'react-ga';

import { PleaseWait } from '../SharedComponents/PleaseWait';


export class EnergyRatingSearch extends Component {
    displayName = EnergyRatingSearch.name

    render() {

        ReactGA.event({
            category: 'Energy Rating Search',
            action: 'Energy Rating Search View'
        });

        return (
            <div className='form-group'>
                <div className='row'>
                    <div className='col-md-3' >
                        <input id='LicenceNumber' name='LicenceNumber' className='form-control' type='text' placeholder='LicenceNumber' onChange={this.props.handleChange} />
                    </div>
                    <a className='btn btn-primary btn-sm pull-right' onClick={this.props.handleSearch} onChange={this.props.handleChange}><i className="fa fa-search pr-2" />Search</a>
                    <PleaseWait IsLoading={this.props.loading} />
                </div>
            </div>
        );
    }
}