
import React, { Component } from 'react';
import uuid from "uuid";

import AuthHelper from '../SharedComponents/AuthHelper';
import { PleaseWait } from '../SharedComponents/PleaseWait';


export class ImageView extends Component {
    displayName = "ImageView";

    constructor(props) {

        super(props);

        const auth = new AuthHelper();
        this.state = {
            CompanyId: auth.CompanyId,
            ContentType: this.props.ContentType,
            FileName: this.props.FileName,
            Identifier: this.props.Identifier,
            OperativePhoto: '',

            displayContent: (<PleaseWait IsLoading={true} PleaseWaitText="Processing" />),
            loading: true
        };
    }

    componentWillMount() {
        this.fetchData();
    }

    renderView(operativePhoto, fileName, contentType) {
        return (
            <div className='row py-3'>
                <div className='col-md-6'>
                    <img src={`data:${contentType};base64,${operativePhoto}`} className="img-thumbnail" alt='License' />
                    <div className='text-center'>{fileName}</div>
                </div>
            </div>
        );
    }

    fetchData = () => {
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/DownloadImage', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.MongoDB.Photo",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyReference,
                    OperativeId: this.state.OperativeId,
                    Identifier: this.state.Identifier
                })
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                const file = this.state.FileName;
                const contentType = this.state.ContentType;
                this.setState({
                    OperativePhoto: data.message,
                    displayContent: this.renderView(data.message, file, contentType),
                    loading: false
                });
            });
    };


    render() {
        return (
            <div>
                {this.state.displayContent}
            </div>
        );
    }
}
