import React, { Component } from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class DownloadPDF extends Component {

    displayName = DownloadPDF.name

    render() {

        return (
            <a
                className={this.props.EnableDownload === true ? 'btn orange pull-right btn-sm mdb-button-resize' : 'hide'}
                //className= 'btn orange pull-right btn-sm mdb-button-resize'
                target='_blank'
                href={`data:application/pdf;base64,${this.props.DownloadLabel}`}   
                download={this.props.FileName}
            >
                <i className="fas fa-download pr-2" />{this.props.Text}
            </a>
        );
    }
}
