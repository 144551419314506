import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import uuid from "uuid";

import './HomeMenuItems.css';
import { HomeItem } from '../SharedComponents/HomeItem';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import { LatestNotice } from '../SharedComponents/LatestNotice'

import { MDBJumbotron, MDBContainer, MDBRow, MDBCol } from 'mdbreact';

export class HomeMenuItems extends Component {
    displayName = HomeMenuItems.name;

    constructor(props) {
        super(props);
        this.state = {
            Description: '',
            MenuId: 0,
            Title: '',
            description: '',
            displayContent: (<PleaseWait IsLoading={true} />),
            loading: true,
            parentName: 'Home',
            payload: []
        };
        //console.log(this.displayName, this.props);
    }

    componentDidMount = () => {
        //this.fetchData();
    }

    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    renderItems = payload => {
        return (
            <Fragment>
                <div className='row fluid'>
                    {
                        payload.map(item =>
                        (
                            <HomeItem
                                id={item.MenuId}
                                title={item.Title}
                                glyph={item.GlyphIcon}
                                description={item.Description}
                                route={item.Route}
                                key={item.MenuId}
                            />
                        )
                        )
                    }
                </div>
            </Fragment>
        );
    }

    fetchData = () => {
        //load parent data and on success load children
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    seqno: uuid.v4(),
                    datatype: "BIT.Fensa.InstallerPortal.GetMenuByRef",
                    origin: "Installer Portal",
                    operation: "Search",
                    datavalues: JSON.stringify({
                        Name: this.state.parentName,
                        MenuId: 0,
                        ParentId: 0
                    })
                })
            })
            .then(response => response.json())
            .then(data => {
                //console.log(this.displayName + '.fetchData[data]', data);
                const parentData = JSON.parse(data);
                //console.log(this.displayName + '.fetchData[parentData]', parentData);
                if (parentData.returncode === 0) {
                    this.setState({
                        loading: false,
                        payload: [],
                        displayContent: this.renderNoDataView()
                    });
                } else {
                    const parentMenu = JSON.parse(parentData.ReturnData.Payload)[0];
                    //console.log(this.displayName + '.fetchData[parentMenu]', parentMenu);
                    //set the parent data before loading children
                    this.setState({
                        Description: parentMenu.Description,
                        MenuId: parentMenu.MenuId,
                        Title: parentMenu.Title
                    });

                    //now load all children
                    fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine',
                        {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                seqno: uuid.v4(),
                                datatype: "BIT.Fensa.InstallerPortal.GetMenu",
                                origin: "Installer Portal",
                                operation: "Search",
                                datavalues: JSON.stringify({
                                    ParentId: parentMenu.MenuId
                                })
                            })
                        })
                        .then(childrenResponse => childrenResponse.json())
                        .then(children => {
                            //found any child?
                            //console.log(this.displayName + '.fetchData[children]', children);
                            const childrenData = JSON.parse(children);
                            if (childrenData.returncode === 0) {
                                this.setState({
                                    loading: false,
                                    payload: [],
                                    displayContent: this.renderNoDataView()
                                });
                            } else {
                                //console.log(this.displayName + '.fetchData[childrenData]', childrenData);
                                const childrenMenu = JSON.parse(childrenData.ReturnData.Payload);
                                //console.log(this.displayName + '.fetchData[childrenMenu]', childrenMenu);
                                this.setState({
                                    loading: false,
                                    payload: childrenMenu,
                                    displayContent: childrenMenu && childrenMenu.length > 0 ?
                                        this.renderItems(childrenMenu) :
                                        this.renderNoDataView()
                                });
                            }
                        })
                        .catch(data => {
                            console.log(this.displayName + '.fetchData.children[err]', data);
                            const errData = JSON.parse(JSON.parse(data).ReturnData.Errors);
                            console.log(this.displayName + '.fetchData.children[errData]', errData);
                            this.setState({
                                loading: false,
                                notificationPostfix: 'danger show',
                                notificationMessage: errData
                            });
                        });

                }
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData.parent[err]', data);
                const errData = JSON.parse(JSON.parse(data).ReturnData.Errors);
                console.log(this.displayName + '.fetchData.parent[errData]', errData);
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: errData
                });
            });
    }

    render() {
        return (
            <MDBContainer>
                <MDBRow>
                    <MDBCol>
                        <MDBJumbotron fluid>
                            <div className='px-5'>
                                <h1 className='h1 h1-responsive text-center'>Welcome to FENSA Online</h1>
                                <p className='lead text-left'>{this.state.Description}</p>
                            </div>
                        </MDBJumbotron>
                        <Link className='btn orange pull-left' to='/MyLeads/Home'><i className="fa fa-arrow-right pr-2" />Manage My Leads</Link>
                        <Link className='btn orange pull-right' to='/Installations/Home'><i className="fa fa-arrow-right pr-2" />Register Installation</Link>
                    </MDBCol>
                </MDBRow>

                <LatestNotice />

                <MDBRow>
                    <div className='col-md-6'>
                        <h5>10 rules for professional FENSA Approved Installers</h5>
                        <p>Ten rules for professional window and door installers to follow to keep their customers happy and to promote positive word of mouth referrals.</p>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/0iynC13sW5w" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-6'>
                        <h5>How to show your professionalism in 6 easy steps</h5>
                        <p>Window and door installers are competing for your business every day. But you have a winning advantage - you're FENSA Approved.</p>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/J0mO1PL0_LA" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </MDBRow>



            </MDBContainer>
        );
    }
}
