import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { ReadonlyItem } from '../SharedComponents/ReadonlyItem';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import { ColumnAndLabel } from '../SharedComponents/ColumnAndLabel';

import { MDBBtn, MDBIcon } from "mdbreact";
import moment from 'moment';
import { NotificationPanel } from '../SharedComponents/NotificationPanel';

import '../Fensa.css';

// const detailsHomeUrl = '/Details';
const debugOn = process.env.DEBUG_MODE_ON;

export class InsuranceEdit extends Component {
    displayName = InsuranceEdit.name;

    constructor(props) {
        super(props);

        const auth = new AuthHelper();

        this.state = {
            CompanyId: auth.CompanyId,
            CompanyName: auth.CompanyName,
            UsePaging: true,
            displayContent: (<PleaseWait IsLoading={true} />),
            loading: true,
            notificationMessage: [''],
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 0,
            payload: [],
            IBGEdit: false,
            ShowEditButton: true,
            PublicLiabilityCompany: '',
            PublicLiabilityPolicyNumber: '',
            PublicLiabilityExpiryDate: '',
            EmployerLiabilityCompany: '',
            EmployerLiabilityPolicyNumber: '',
            EmployerLiabilityExpiryDate: ''
            
        };

        this.HandleIBGClick = this.HandleIBGClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    handleInputChange(event) {
        console.log("On Change");

        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        if (value === undefined) {
            value = target.parentElement.value;
        }
        const name = target.name;

        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        });
    }

    HandleIBGClick(event) {

        event.preventDefault();

        this.setState({
            IBGEdit: true
        });

        alert(this.state.IBGEdit);
    }

    fetchData = () => {

        var fetchInstallation = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.GetInstallerCompanyInsurance",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyId
                })
            })
        });

        Promise.all([fetchInstallation])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                console.log(this.displayName + '.fetchData[responses]', responses);
                var data = this.extractData(responses[0]);
                console.log(this.displayName + '.fetchData[data]', data);
                this.setState({
                    payload: data,//JSON.parse(JSON.parse(responses[0]).message),
                    loading: false,

                    // Save away the data that can be changed
                    PublicLiabilityCompany: data[0].PublicLiabilityCompany,
                    PublicLiabilityPolicyNumber: data[0].PublicLiabilityPolicyNumber,
                    PublicLiabilityExpiryDate: data[0].PublicLiabilityExpiryDate,
                    EmployerLiabilityCompany: data[0].EmployerLiabilityCompany,
                    EmployerLiabilityPolicyNumber: data[0].EmployerLiabilityPolicyNumber,
                    EmployerLiabilityExpiryDate: data[0].EmployerLiabilityExpiryDate,

                    notificationPostfix: 'info hide',
                    notificationMessage: []
                });
            })

            .catch(data => {
                console.log(this.displayName + '.fetchData[err]', data);
                this.setState({
                    displayContent: '',
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - No data or service unavailable'],
                    payload: []
                });
            });
    }

    submitForm = (e) => {
        e.preventDefault();



            // reset the New products list, otherwise it will just keep appanding to it.
            this.setState({
                loading: true,
                NewProducts: []
            });
         
            var datavalues = JSON.stringify(
                {
                    __table__: "InstallerCompany",
                    IC_CompanyId: this.state.CompanyId,
                    IC_PublicLiabilityCompany: this.state.PublicLiabilityCompany,
                    IC_PublicLiabilityPolicyNumber:this.state.PublicLiabilityPolicyNumber,
                    IC_PublicLiabilityExpiryDate: this.state.PublicLiabilityExpiryDate,
                    IC_EmployerLiabilityCompany:this.state.EmployerLiabilityCompany,
                    IC_EmployerLiabilityPolicyNumber: this.state.EmployerLiabilityPolicyNumber,
                    IC_EmployerLiabilityExpiryDate: this.state.EmployerLiabilityExpiryDate
		
                });

            var postData = JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.UpdateInstallerCompanyInsurance",
                origin: "Installer Portal",
                operation: "UPDATE",
                datavalues: datavalues
            });

            fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/InsertObject', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: postData
            })
                .then(response => response.json())
                .then(data => JSON.parse(data))
                .then(data => {
                    this.setState({
                        //ErrorMessage: 'No Data',
                        loading: false,
                        notificationPostfix: 'danger show',
                        notificationMessage: ['Your Insurance details have been updated'],
                        showInstallationEditCompleted: true
                    });
                })
                .catch(data => {
                    console.log(this.displayName + '.submitForm[err]', data);
                    var arrMsg = data.ReturnData.Errors;
                    console.log(this.displayName + '.submitForm[arrMsg]', arrMsg);
                    arrMsg.splice(0, 0, 'Bad Request - No Data or service not available');
                    this.setState({
                        //ErrorMessage: 'No Data',
                        loading: false,
                        notificationPostfix: 'danger show',
                        notificationMessage: arrMsg,
                        showInstallationEditCompleted: false
                    });
                });
      
    }


    renderTable = companyDetailsData => {
        return (
            <div className='form-group-container mr-auto ml-auto'>
                <div className='form-group'>

                    <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />

                    <div className='h3 h3-responsive'>Insurance Details - {this.state.CompanyName}</div>
                    <form className='demoForm was-validated' onSubmit={this.submitForm.bind(this)} noValidate>
                        
                    <div className='form-group'>                         
                        <div className='h4 h4-responsive'>Public Liability</div>
                        <div className='form-group'>
                            <div className='row'>
                                <div className='col-md-4'>
                                            <ColumnAndLabel
                                                //ErrorMessage={this.state.formErrors.ContactName}
                                                IconLabel="pencil"
                                                Id="PublicLiabilityCompany"
                                                InputType="text"
                                                IsRequired={true}
                                                SizeCss="md"
                                                HintLabel=""
                                                TextLabel="Public Liability Insurance Company"
                                                Validate={true}
                                                Value={this.state.PublicLiabilityCompany}
                                                HandleInputChange={this.handleInputChange}
                                                //HandleOnInput={this.handleOnInputValidation}
                                    />   
                                                                        
                                </div>

                                <div className='col-md-4'>


                                    <ColumnAndLabel
                                        //ErrorMessage={this.state.formErrors.ContactName}
                                        IconLabel="pencil"
                                        Id="PublicLiabilityPolicyNumber"
                                        InputType="text"
                                        IsRequired={true}
                                        SizeCss="md"
                                        HintLabel=""
                                        TextLabel="Public Liability Policy Number"
                                        Validate={true}
                                        Value={this.state.PublicLiabilityPolicyNumber}
                                        HandleInputChange={this.handleInputChange}
                                    //HandleOnInput={this.handleOnInputValidation}
                                    />      

                                </div>

                                <div className='col-md-4'>
                                    <ColumnAndLabel
                                        //ErrorMessage={this.state.formErrors.CompletionDate}
                                        IconLabel="calendar"
                                        Id="PublicLiabilityExpiryDate"
                                        InputType="date"
                                        IsRequired={true}
                                        SizeCss="md"
                                        HintLabel=""
                                        TextLabel="Public Liability Expiry Date"
                                        Validate={true}
                                        Value={moment(this.state.PublicLiabilityExpiryDate).format('YYYY-MM-DD')}
                                        HandleInputChange={this.handleInputChange}
                                    //HandleOnInput={this.handleOnInputValidation}
                                    />                                    
                                </div>
                            </div>
                                </div>


                        <div className='h4 h4-responsive'>Employer Liability</div>

                        <div className='form-group'>

                            <div className='row'>
                                <div className='col-md-4'>
                                        <ColumnAndLabel
                                            //ErrorMessage={this.state.formErrors.ContactName}
                                            IconLabel="pencil"
                                            Id="EmployerLiabilityCompany"
                                            InputType="text"
                                            IsRequired={true}
                                            SizeCss="md"
                                            HintLabel=""
                                            TextLabel="Employer Liability Insurance Company"
                                            Validate={true}
                                            Value={this.state.EmployerLiabilityCompany}
                                            HandleInputChange={this.handleInputChange}
                                        //HandleOnInput={this.handleOnInputValidation}
                                        />
                                    </div>

                                <div className='col-md-4'>
                                    <ColumnAndLabel
                                        //ErrorMessage={this.state.formErrors.ContactName}
                                        IconLabel="pencil"
                                        Id="EmployerLiabilityPolicyNumber"
                                        InputType="text"
                                        IsRequired={true}
                                        SizeCss="md"
                                        HintLabel=""
                                        TextLabel="Employer Liability Policy Number"
                                        Validate={true}
                                        Value={this.state.EmployerLiabilityPolicyNumber}
                                        HandleInputChange={this.handleInputChange}
                                    //HandleOnInput={this.handleOnInputValidation}
                                    />

                                        </div>

                                <div className='col-md-4'>
                                    <ColumnAndLabel
                                        //ErrorMessage={this.state.formErrors.CompletionDate}
                                        IconLabel="calendar"
                                        Id="EmployerLiabilityExpiryDate"
                                        InputType="date"
                                        IsRequired={true}
                                        SizeCss="md"
                                        HintLabel=""
                                        TextLabel="Employer Liability Expiry Date"
                                        Validate={true}
                                        Value={moment(this.state.EmployerLiabilityExpiryDate).format('YYYY-MM-DD')}
                                        HandleInputChange={this.handleInputChange}
                                        //HandleOnInput={this.handleOnInputValidation}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='row py-3'>
                        <div className='col'>
                            <MDBBtn
                                color="success"
                                icon="save"
                                type="submit"
                                size="sm"                                
                                className= "mdb-btn-resize pull-right show">
                                <MDBIcon icon="save" className="pr-2" />Submit</MDBBtn>
                            
                        </div>
                        </div>

                    </form>
                </div>
            </div>

        );
    }

    render() {

        ReactGA.event({
            category: 'Insurances',
            action: 'Edit Insurance'
        });


        return (
            <div>
                {this.renderTable()}
            </div>
        );
    }


}

/// Handle data retrieval and handle ensuing errors if data is not found
InsuranceEdit.prototype.extractData = response => {
    try {
        return JSON.parse(response).returncode !== 0 ? JSON.parse(JSON.parse(response).message) : [];
    } catch (e) {
        console.log(e);
        return [];
    }
};