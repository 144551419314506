import React, { Component, Fragment } from 'react';
import ReactTable from "react-table";
import { Link } from 'react-router-dom';
import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { CardBodyOnly } from '../SharedComponents/CardBodyOnly';
import { CardFooterButtonsIcons } from '../SharedComponents/CardFooterButtonsIcons';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import { ReactTableFilterToggler } from './../SharedComponents/ReactTableFilterToggler';
import Moment from 'react-moment';

import Badge from "@material-ui/core/Badge";


const operativeHomeUrl = "/Operatives/Home";
const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class OurOperatives extends Component {
    displayName = "OurOperatives";

    constructor(props) {
        super(props);
        var auth = new AuthHelper(this.displayName);
        this.state = {
            CompanyId: auth.CompanyId,
            CompanyReference: auth.CompanyReference,
            ShowEditButton: auth.CanEditData,
            ShowDeleteButton: auth.CanDoAdminTask,
            ShowViewButton: true,
            displayContent: (<PleaseWait IsLoading={true} />),
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 0,
            payload: [],
            resized: [],
            sorted: [],
            totals: '',
            totalsLabel: ' item',
            useFiltering: (auth.TableFilter && auth.TableFilter === 'On' ? true : false)
        };
    }

    componentDidMount = () => {
        this.fetchData();
    }

    filterCaseInsensitive = ({ id, value }, row) => {
        return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : false;
    }

    handleToggleFilter = toggle => {
        this.setState({
            useFiltering: toggle
        });
        this.fetchData();
    }

    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    renderTable = installation => {
        return (
            <div>
                <ReactTableFilterToggler
                    ParentDisplayName={this.displayName}
                    UseFiltering={this.state.useFiltering}
                    handleToggleFilter={this.handleToggleFilter}
                />
                <small className='text-muted'>When filtering by date field use year-month-day format <code>e.g.1900-01-01</code></small>
                <ReactTable data={installation}
                    columns={
                        [
                            {
                                accessor: 'PersonId',
                                className: 'p-3',
                                Header: () => <span>Reference</span>,
                                Expander: ({ isExpanded }) => (
                                    <span>
                                        {
                                            isExpanded ?
                                                (<span>&#x2299;</span>) :
                                                (<span>&#x2295;</span>)
                                        }
                                    </span>
                                ),
                                style: {
                                    padding: "15",
                                    textAlign: "left",
                                    userSelect: "none"
                                }
                            },
                            {
                                Header: 'Name',
                                accessor: 'Name',
                                className: 'p-3',
                                filterMethod: (filter, row) =>
                                    row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase())

                                //width: 200,
                                //Cell: row => {
                                //    return (
                                //        <div>
                                //            <span>{row.original.Name}</span><br />
                                //            <span>{row.original.Email}</span><br />
                                //            <span>{row.original.Telephone}</span><br />
                                //        </div>
                                //    )
                                //}
                            },
                            {
                                Header: 'Operative Type', // Custom header components!
                                accessor: 'TypeDescription',
                                className: 'p-3',
                                filterMethod: (filter, row) =>
                                    row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase())
                            },
                            {
                                Header: 'Qualification', // Custom header components!
                                accessor: 'MtcRouteDescription',
                                className: 'p-3',
                                filterMethod: (filter, row) =>
                                    row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase())
                            },
                            {
                                Header: 'Status',
                                accessor: 'StatusName',
                                className: 'p-3',
                                filterMethod: (filter, row) =>
                                    row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase())
                            },
                           /*  {
                                Header: 'Date Of Birth', // Custom header components!
                                accessor: 'DateOfBirth',
                                className: 'p-3',
                                show: false,
                                Cell: row => {
                                    return (
                                        <Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.DateOfBirth} />
                                    );
                                }
                            }, */
                            {
                                Header: '',
                                accessor: 'Id',
                                className: 'p-3',
                                filterable: false,
                                sortable: false,
                                //width: 65,
                                Cell: row => {
                                    return (
                                        <Fragment>                                         
                                            <div className='text-center py-1'>
                                                <CardFooterButtonsIcons
                                                    Id={row.original.Id}
                                                    //DeleteCss={ButtonsJss.DefaultDeleteCss}
                                                    //EditCss={ButtonsJss.DefaultEditCss}
                                                    //ViewCss={ButtonsJss.DefaultViewCss}
                                                    //DeleteUrl={'/Operatives/OurOperatives/Delete/' + row.original.PersonId}
                                                    EditUrl={'/Operatives/OurOperatives/Update/' + row.original.PersonId}
                                                    ViewUrl={'/Operatives/OurOperatives/View/' + row.original.PersonId}
                                                    ShowViewButton={this.state.ShowViewButton} // use role priviledge to set visibility
                                                    ShowEditButton={this.state.ShowEditButton} // use role priviledge to set visibility
                                                    ShowDeleteButton={this.state.ShowDeleteButton}// use role priviledge to set visibility
                                                />
                                            </div>
                                        </Fragment>
                                    );
                                }
                            }
                        ]
                    }
                    SubComponent={(row) =>
                        (
                            <Fragment>
                                <CardBodyOnly>
                                    <div style={{ paddingTop: '10px' }}>
                                        <i className="fas fa-address-book fa-1x pr-4" />
                                        {row.original.Name}
                                    </div>
                                    <div className=''><i className="fas fa-envelope fa-1x pr-4" />{row.original.EmailAddress}</div>
                                    <div className=''><i className="fas fa-phone fa-1x pr-4" />{row.original.PhoneNumber}</div>
                                </CardBodyOnly>
                            </Fragment>
                        )}
                    className="table-bordered table-striped table-light table-hover table-shadowed"
                    defaultPageSize={10}
                    expanded={this.state.expanded}
                    filterable={this.state.useFiltering}
                    minRows={0}
                    nextText='>'
                    previousText='<'
                    defaultFilterMethod={this.filterCaseInsensitive}

                //sorted={this.state.sorted}
                //onSortedChange={sorted => this.setState({ sorted })}
                //page={this.state.page}
                //pageSize={this.state.pageSize}
                //resized={this.state.resized}
                //filtered={this.state.filtered}
                // Callbacks
                //onPageChange={page => this.setState({ page })}
                //onPageSizeChange={(pageSize, page) => this.setState({ page, pageSize })}
                //onExpandedChange={expanded => this.setState({ expanded })}
                //onResizedChange={resized => this.setState({ resized })}
                //onFilteredChange={filtered => this.setState({ filtered })}
                />
            </div>
        );
    }

    fetchData() {

        var datavalues = JSON.stringify({
            CompanyId: this.state.CompanyReference
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.HelixAdmin.GetInstaller_MTC_Operatives",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: datavalues
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                if (debugOn) {
                    console.log(this.displayName + '.fetchData[data]: ', data);
                }
                if (data.returncode === 0) {
                    this.setState({
                        loading: false,
                        payload: [],
                        totals: 0,
                        totalsLabel: ' item',
                        notificationPostfix: 'warning show mt-3',
                        notificationMessage: ['No Data'],
                        displayContent: ''
                    });
                    return;
                }

                //this.setState({
                //    payload: JSON.parse(data.message),
                //    loading: false
                //});
                const operatives = data.ReturnData.Payload === null ? [] : JSON.parse(data.ReturnData.Payload);

                this.setState({
                    loading: false,
                    payload: operatives,
                    totals: operatives.length,
                    totalsLabel: operatives.length > 1 ? ' items' : ' item',
                    notificationPostfix: data.returncode === 0 ? 'info show mt-3' : 'info hide',
                    notificationMessage: data.returncode === 0 ? ['No Data'] : [],
                    displayContent: data.returncode === 0 ?
                        this.renderNoDataView() :
                        this.renderTable(operatives)
                });
            })
            .catch(data => {
                console.log(JSON.parse(data));
                this.setState({
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    displayContent: '',
                    payload: []
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'Our Operatives',
            action: 'Our Operatives List'
        });

        return (
            <div className='fluid-container'>
                <div className='row'>
                    <div className='col-xs-12 col-sm-6 col-md-8'>
                        <div className='h2-responsive'>
                            Our Operatives{/*<Badge badgeContent={this.state.totals} color="primary">Our Operatives</Badge>*/}
                        </div>
                    </div>
                    <div className={this.state.ShowEditButton ? 'pl-2 pt-2' : 'hide'}>
                        <Link className='btn btn-sm btn-primary mdb-btn-resize' to='/Operatives/OurOperatives/Update/-1'>
                            <i className="fa fa-plus pr-2" />Add Operative
                        </Link>
                    </div>
                </div>
                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                <div className='col-xs-12 pb-3'>
                    {this.state.displayContent}
                </div>
            </div>
        );
    }
}
