import React, { Component, Fragment } from 'react';
import ReactTable from "react-table";
import Moment from 'react-moment';
import moment from 'moment';
import uuid from "uuid";

import ReactGA from 'react-ga';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

import AuthHelper from '../SharedComponents/AuthHelper';

//import { ButtonsJss } from '../SharedComponents/ButtonsJss';
import { CardBodyOnly } from '../SharedComponents/CardBodyOnly';
import { CardFooterButtonsIcons } from '../SharedComponents/CardFooterButtonsIcons';
import { InstallationSearch } from "./InstallationSearch";
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import { ReactTableFilterToggler } from './../SharedComponents/ReactTableFilterToggler';


import Badge from "@material-ui/core/Badge";


export class InstallationHistory extends Component {

    displayName = "InstallationHistory";

    constructor(props) {
        super(props);

        const auth = new AuthHelper(this.displayName);
        this.state = {
            CompanyId: auth.CompanyId,
            CompanyName: '',
            // default this to the last 3 months
            // of data
            CompletedAfter: moment().add(-3, 'M'),
            CompletedBefore: moment(),
            CrumbMap: [{ Title: 'Home', LinkUrl: '/Installations/Home' }],
            DispatchPostcode: '',
            InstallerReference: '',
            InstallPostcode: '',
            Reference: '',
            //ShowEditButton: auth.CanEditData,
            ShowEditButton: false,
            ShowDeleteButton: auth.CanDoAdminTask,
            ShowViewButton: true,
            UsePaging: true,
            displayContent: (<PleaseWait IsLoading={true} />),
            filtered: [],
            installation: [],
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 10,
            resized: [],
            sorted: [],
            totals: '',
            totalsLabel: ' item',
            useFiltering: (auth.TableFilter && auth.TableFilter === 'On' ? true : false)
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCompletedBeforeChange = this.handleCompletedBeforeChange.bind(this);
        this.handleCompletedAfterChange = this.handleCompletedAfterChange.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);

    }

    componentDidMount() {
        this.fetchData();
    }

    filterCaseInsensitive = ({ id, value }, row) => {
        //console.log(this.displayName + '.filterCaseInsensitive[row]', id, value, row);
        //console.log(this.displayName + '.filterCaseInsensitive[row].id', row[id]);
        // filter these dates differently: WorkCompletionDate, SubmissionDate
        if (id === 'SubmissionDate' || id === 'SubmissionDate') {
            //console.log(this.displayName + '.filterCaseInsensitive[moment(row[id]).toLowerCase()]', moment(row[id]).toString(), value);
            //return row[id] ? moment(row[id]).toString().toLowerCase().includes(value.toLowerCase()) : true;
            return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : false;
        } else {
            return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : false;
        }
    }

    setCrumbs = (auth) => {
        //set crumb map
        var crumbs = [];
        crumbs.push({ Title: 'Home', LinkUrl: '/Installations/Home' });
        auth.SetCrumbMap(JSON.stringify(crumbs));
    }

    handleToggleFilter = toggle => {
        this.setState({
            useFiltering: toggle
        });
        this.fetchData();
    }

    handleSearch(event) {
        this.fetchData();
        event.preventDefault();
    }

    handleClearSearch(event) {
        console.log(this.displayName + '.handleClearSearch', event);
        this.setState({
            InstallerReference: '',
            InstallPostcode: '',
            DispatchPostcode: '',
            CompletedAfter: moment().add(-3, 'M'),
            CompletedBefore: moment(),
            Reference: ''
        }, () => { this.fetchData(); });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        console.log(this.displayName + '.handleInputChange', name, value);
        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        });
    }

    handleCompletedBeforeChange(value) {
        //const target = event.target;
        //const value = target.value;

        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            CompletedBefore: value
        });
    }

    handleCompletedAfterChange(value) {
        //const target = event.target;
        //const value = target.type === 'checkbox' ? target.checked : target.value;
        //const name = target.name;

        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            CompletedAfter: value
        });
    }

    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    renderTable = installation => {
        return (
            <div className='py-5'>
                <ReactTableFilterToggler
                    ParentDisplayName={this.displayName}
                    UseFiltering={this.state.useFiltering}
                    handleToggleFilter={this.handleToggleFilter}
                />
                <small className='text-muted'>When filtering by date fields use year-month-day format <code>e.g.1900-01-01</code></small>
                <ReactTable
                    data={installation}
                    columns=
                    {
                        [
                            {
                                key: 'Id',
                                Header: 'Id',
                                accessor: 'Id',
                                show: false
                            },
                            {
                                accessor: 'reference',
                                Header: () => <span>Installation Reference</span>,
                                Expander: ({ isExpanded }) => (
                                    <span>
                                        {
                                            isExpanded ?
                                                (<span>&#x2299;</span>) :
                                                (<span>&#x2295;</span>)
                                        }
                                    </span>
                                ),
                                style: {
                                    padding: "15",
                                    textAlign: "center",
                                    userSelect: "none"
                                }
                            },
                            {
                                Header: 'Installer Reference',
                                accessor: 'InstallerReference'
                            },
                            {
                                Header: 'Installation Completion Date', // Custom header components!
                                accessor: 'WorkCompletionDate',
                                Cell: row => {
                                    return (
                                        <Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.WorkCompletionDate} />
                                    );
                                }
                            },
                            {
                                Header: 'Submission Date', // Custom header components!
                                accessor: 'SubmissionDate',
                                Cell: row => {
                                    return (
                                        <Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.SubmissionDate} />
                                    );
                                }
                            },
                            {
                                Header: 'Address',
                                accessor: 'addressLine1,addressLine2,addressLine3',
                                width: 200,
                                Cell: row => {
                                    return (
                                        <Fragment>
                                            <span>{row.original.AddressLine1}</span>
                                        </Fragment>
                                    );
                                }
                            },
                            {
                                Header: 'Postcode',
                                accessor: 'InstallPostcode',
                                width: 80,
                                Cell: row => {
                                    return (
                                        <Fragment>
                                            <span>{row.original.InstallPostcode}</span>
                                        </Fragment>
                                    );
                                }
                            },
                            {
                                Header: 'Valid', // Custom header components!
                                accessor: 'isValid',
                                filterable: false,
                                Cell: row => {
                                    return (
                                        <Fragment>
                                            {
                                                row.original.Valid === false ?
                                                    (<span>No<br /></span>) :
                                                    (<span>Yes<br /></span>)
                                            }
                                        </Fragment>
                                    );
                                }
                            },
                            {
                                Header: 'Date Sent to LA', // Custom header components!
                                accessor: 'SendToLa',
                                filterable: false,
                                Cell: row => {
                                    return (
                                        <Fragment>
                                            {
                                                row.original.SendToLa === 0 ?
                                                    (<span>No<br /></span>) :
                                                    (<span>Yes (<Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.SendToLaDate} />)<br /></span>)
                                            }
                                        </Fragment>
                                    );
                                }
                            },
                            {
                                Header: 'Date Certificate sent', // Custom header components!
                                accessor: 'CertificateDispatchDate',
                                filterable: false,
                                Cell: row => {
                                    return (
                                        <Fragment>
                                            {
                                                row.original.CertificateDispatchDate === undefined ?
                                                    (<span>No<br /></span>) :
                                                    (<span>Yes (<Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.CertificateDispatchDate} />)<br /></span>)
                                            }
                                        </Fragment>
                                    );
                                }
                            },
                            {
                                Header: '',
                                accessor: 'Edit',
                                filterable: false,
                                sortable: false,
                                Cell: row => {
                                    return (
                                        <Fragment>
                                            <div className='text-center py-1 pr-5'>
                                                <CardFooterButtonsIcons
                                                    Id={row.original.Id}
                                                    //DeleteCss={ButtonsJss.DefaultDeleteCss}
                                                    //EditCss={ButtonsJss.DefaultEditCss}
                                                    //ViewCss={ButtonsJss.DefaultViewCss}
                                                    //DeleteUrl={'/Installation/Delete/' + row.original.Id}
                                                    EditUrl={'/Installations/Edit/' + row.original.Id}
                                                    ViewUrl={'/Installations/View/' + row.original.Id}
                                                    ShowViewButton={this.state.ShowViewButton} // use role priviledge to set visibility

                                                    // if the row is a conservatory, do not allow it to be edited
                                                    ShowEditButton={row.original.Conservatory === true ? false : this.state.ShowEditButton} // use role priviledge to set visibility
                                                    ShowDeleteButton={this.state.ShowDeleteButton}// use role priviledge to set visibility
                                                />
                                            </div>
                                        </Fragment>
                                    );
                                }
                            }
                        ]
                    }
                    SubComponent={(row) =>
                    (
                        <Fragment>
                            <CardBodyOnly>
                                <div style={{ paddingTop: '10px' }}>
                                    <i className="fa fa-home fa-1x pr-4" />
                                    {row.original.AddressLine1}
                                </div>
                                <div className='pl-5'>{row.original.AddressLine2}</div>
                                <div className='pl-5'>{row.original.AddressLine3}</div>
                                <div className='pl-5'>{row.original.CityTown}</div>
                                <div className='pl-5'>{row.original.County}</div>
                                <div className='pl-5'>{row.original.InstallPostcode}</div>
                                <div className='pl-5'>{row.original.postcode}</div>
                            </CardBodyOnly>
                        </Fragment>
                    )}
                    className="table-bordered table-striped table-hover table-shadowed"
                    defaultPageSize={this.state.pageSize}
                    expanded={this.state.expanded}
                    filterable={this.state.useFiltering}
                    minRows={0}
                    nextText='>'
                    previousText='<'
                    defaultFilterMethod={this.filterCaseInsensitive}

                //onPageChange={(page) => this.setPageId(page)}
                //page={this.state.page}
                //page={this.state.page}
                //pageSize={this.state.pageSize}
                //resized={this.state.resized}
                //filtered={this.state.filtered}
                // Callbacks
                //onSortedChange={sorted => this.setState({ sorted })}
                //onPageChange={page => this.setState({ page })}
                //onPageSizeChange={(pageSize, page) => this.setState({ page, pageSize })}
                //onExpandedChange={expanded => this.setState({ expanded })}
                //onResizedChange={resized => this.setState({ resized })}
                //onFilteredChange={filtered => this.setState({ filtered })}
                />
            </div>
        );
    }

    setPageId = pageId => {
        console.log(this.displayName + '.setPageId', pageId);
        this.setState({
            page: pageId
        });
    }

    fetchData = () => {

        //var crumbs = [];
        //crumbs.push({ Title: 'Home', LinkUrl: '/Installations/Home' });
        //new AuthHelper().SetCrumbMap(JSON.stringify(crumbs));

        this.setState({
            //displayContent: (<PleaseWait IsLoading={true} />),
            loading: true
        });

        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerInstallations",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                CompanyId: this.state.CompanyId,
                InstallerReference: this.state.InstallerReference,
                InstallPostcode: this.state.InstallPostcode,
                DispatchPostcode: this.state.DispatchPostcode,
                CompletedBefore: this.state.CompletedBefore,
                CompletedAfter: this.state.CompletedAfter,
                Reference: this.state.Reference
            })
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                const installationData = data.returncode === 1 ?
                    JSON.parse(data.ReturnData.Payload).sort(function (a, b) {
                        //console.log('a:', a);
                        //console.log('b:', b);
                        if (a.WorkCompletionDate < b.WorkCompletionDate) { return 1; } //desc
                        if (a.WorkCompletionDate > b.WorkCompletionDate) { return -1; }
                        return 0;
                    }) : [];
                this.setState({
                    installation: installationData,
                    loading: false,
                    notificationPostfix: data.returncode === 0 ? 'info show mt-3' : 'info hide',
                    notificationMessage: data.returncode === 0 ? ['No Data'] : [],
                    totals: installationData.length,
                    totalsLabel: installationData.length > 1 ? ' items' : ' item',
                    displayContent: (this.state.installationData === null) ?
                        this.renderNoDataView() :
                        this.renderTable(installationData)
                });
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData[catch]', data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    displayContent: ''
                });
            });
    }

    render() {


        ReactGA.event({
            category: 'Installation History',
            action: 'Installation History View'
        });

        return (
            <Fragment>
                <div className='fluid-container'>
                    <h2>
                        <Badge badgeContent={this.state.totals} color="primary">Installation History</Badge>
                    </h2>
                    <p>You will find all your past installations listed here. Click on each installation for detailed information.</p>
                    <p>You can also search for particular installations.</p>
                    <CardBodyOnly>
                        <InstallationSearch CompletedBefore={this.state.CompletedBefore}
                            CompletedAfter={this.state.CompletedAfter}
                            handleSearch={this.handleSearch}
                            handleChange={this.handleInputChange}
                            DataProps={this.state}
                            focused={false}
                            focusedOne={false}
                            handleCompletedBeforeChange={this.handleCompletedBeforeChange}
                            handleCompletedAfterChange={this.handleCompletedAfterChange}
                            handleClear={this.handleClearSearch}
                            loading={this.state.loading}
                        />
                    </CardBodyOnly>
                    <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                    {this.state.displayContent}
                </div>
            </Fragment>
        );
    }
}
