import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import AuthHelper from './AuthHelper';
import { AvatarJss } from './AvatarJss';

import NotificationIcon from "@material-ui/icons/NotificationImportant";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";
import AccountAvatarIcon from "@material-ui/icons/AccountCircle";
import { Button, Glyphicon } from 'react-bootstrap';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";


class Actions extends Component {
    displayName = Actions.name;

    constructor(props) {
        super(props);
        //const auth = new AuthHelper();
        this.state = {
            ...props,
            badgeCount: 0,
            anchorEl: null
        };
        // console.log(this.displaName + '.ctor', this.state);
    }

    handleMenuClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const open = Boolean(this.state.anchorEl);
        return (
            this.state.badgeCount === 0 ? ('') :
                (
                    <Fragment>
                        <Tooltip title={this.state.badgeCount + " Urgent Actions Required"} placement="bottom-end">
                            <IconButton
                                aria-owns={open ? "menu-appbar" : undefined}
                                aria-haspopup="true"
                                onClick={this.handleMenuClick}
                                color="inherit"
                            >
                                <Badge badgeContent={this.state.badgeCount} color="secondary">
                                    <NotificationIcon className={classNames(this.state.classes.mdAvatar)} />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="menu-appbar"
                            anchorEl={this.state.anchorEl}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            open={open}
                            onClose={this.handleClose}
                        >
                            <MenuItem onClick={this.handleClose}>
                                <Link to="/Actions/Home" className={"nav-link"}>Actions Required</Link>
                            </MenuItem>
                        </Menu>
                    </Fragment>
                )
        );
    }
}

Actions.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(AvatarJss)(Actions);