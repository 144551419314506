import React, { Component } from 'react';
//import { Col, Grid, Row } from 'react-bootstrap';
//import { Link } from 'react-router-dom';
//import { Glyphicon, Nav, Navbar, NavItem } from 'react-bootstrap';

export class ApprovedItem extends Component {
    displayName = ApprovedItem.name

    render() {
        return (
            <div className='row'>
                <div className='col-md-12' >
                    <a href={this.props.url} target="_blank">{this.props.text}</a><br />
                    <p>{this.props.description}</p>
                    <p>{this.props.baseurl}</p>
                </div>
            </div>
        );
    }
}