import { GET_BRANCHES } from "../actions/branch";


export default function (state = {}, action) {

    switch (action.type) {

        case GET_BRANCHES:
            return Object.assign({}, state, {
                List: action.List,
                Total: action.Total
            });

        default:
            return state;
    }
}

