import React, { Component } from 'react';
import { MenuItems } from '../SharedComponents/MenuItems';

import ReactGA from 'react-ga';


export class Details extends Component {
    displayName = Details.name

    render() {

        ReactGA.event({
            category: 'Details',
            action: 'View Details'
        });

        return (
            /*<DetailsMenuItems />*/
            <MenuItems ParentName="Company Details" />
        );
    }
}
