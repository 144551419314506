import React, { Component } from "react";
import "./Welcome.css";
import AuthHelper from "./AuthHelper";
import { AccountAuthorise } from "./AccountAuthorise";
import { AccountForgottenPwd } from "./AccountForgottenPwd";
import { AccountRegister } from "./AccountRegister";
import { AccountReset } from "./AccountReset";
import { AccountVerify } from "./AccountVerify";
import { SelectDefaultCompany } from "./SelectDefaultCompany";
import { SignIn } from "./SignIn";




export class Welcome extends Component {
    displayName = Welcome.name;

    constructor(props) {
        super(props);
        const auth = new AuthHelper();
        this.state = {
            ...props,
            authoriseMode: auth.InAuthoriseAccountMode,
            canShowPortal: auth.CanShowPortal,
            confirmAccountMode: auth.InConfirmAccountMode,
            forgottenPwdMode: auth.InForgottenPwdMode,
            isAuthenticated: auth.IsAuthenticated,
            registerMode: auth.InRegisterMode,
            resetPwdMode: auth.InResetPasswordMode,
            selectCompanyMode: auth.InSelectCompanyMode,
        };
        //console.log("Welcome.ctor: ", this.state);
        //console.log("Welcome.ctor: registerMode ", auth.InRegisterMode);
        //console.log("Welcome.ctor: forgottenPwdMode ", auth.InForgottenPwdMode);
        //console.log("Welcome.ctor: selectCompanyMode ", auth.InSelectCompanyMode);
        //console.log("Welcome.ctor: confirmAccountMode ", auth.InConfirmAccountMode);
    }

    handleAbortCallback = () => {
        this.props.handleAbortClick();
        const auth = new AuthHelper();
        this.setState({
            authoriseMode: auth.InAuthoriseAccountMode,
            canShowPortal: auth.CanShowPortal,
            confirmAccountMode: auth.InConfirmAccountMode,
            forgottenPwdMode: auth.InForgottenPwdMode,
            registerMode: auth.InRegisterMode,
            resetPwdMode: auth.InResetPasswordMode,
            selectCompanyMode: auth.InSelectCompanyMode
        });
    }

    handleLogoutCallback = () => {
        const auth = new AuthHelper();
        this.setState({
            authoriseMode: auth.InAuthoriseAccountMode,
            canShowPortal: auth.CanShowPortal,
            confirmAccountMode: auth.InConfirmAccountMode,
            forgottenPwdMode: auth.InForgottenPwdMode,
            registerMode: auth.InRegisterMode,
            resetPwdMode: auth.InResetPasswordMode,
            selectCompanyMode: auth.InSelectCompanyMode
        });
    }

    handleStartConfirmAccountModeCallback = () => {
        this.props.handleConfirmAccountMode();
        const auth = new AuthHelper();
        this.setState({
            authoriseMode: auth.InAuthoriseAccountMode,
            canShowPortal: auth.CanShowPortal,
            confirmAccountMode: auth.InConfirmAccountMode,
            forgottenPwdMode: auth.InForgottenPwdMode,
            registerMode: auth.InRegisterMode,
            resetPwdMode: auth.InResetPasswordMode,
            selectCompanyMode: auth.InSelectCompanyMode
        });
    }

    handleStartSelectCompanyModeCallback = () => {
        this.props.handleSelectCompanyMode();
        const auth = new AuthHelper();
        this.setState({
            authoriseMode: auth.InAuthoriseAccountMode,
            canShowPortal: auth.CanShowPortal,
            confirmAccountMode: auth.InConfirmAccountMode,
            forgottenPwdMode: auth.InForgottenPwdMode,
            registerMode: auth.InRegisterMode,
            resetPwdMode: auth.InResetPasswordMode,
            selectCompanyMode: auth.InSelectCompanyMode
        });
    }

    handleStartRegisterModeCallback = () => {
        this.props.handleStartRegisterMode();
        const auth = new AuthHelper();
        this.setState({
            authoriseMode: auth.InAuthoriseAccountMode,
            canShowPortal: auth.CanShowPortal,
            confirmAccountMode: auth.InConfirmAccountMode,
            forgottenPwdMode: auth.InForgottenPwdMode,
            registerMode: auth.InRegisterMode,
            resetPwdMode: auth.InResetPasswordMode,
            selectCompanyMode: auth.InSelectCompanyMode
        });
    }

    handleStartForgottenPwdModeCallback = () => {
        this.props.handleStartForgottenPwdMode();
        const auth = new AuthHelper();
        this.setState({
            authoriseMode: auth.InAuthoriseAccountMode,
            canShowPortal: auth.CanShowPortal,
            confirmAccountMode: auth.InConfirmAccountMode,
            forgottenPwdMode: auth.InForgottenPwdMode,
            registerMode: auth.InRegisterMode,
            resetPwdMode: auth.InResetPasswordMode,
            selectCompanyMode: auth.InSelectCompanyMode
        });
    }

    handleSignInCallback = () => {
        const auth = new AuthHelper();
        this.setState({
            authoriseMode: auth.InAuthoriseAccountMode,
            canShowPortal: auth.CanShowPortal,
            confirmAccountMode: auth.InConfirmAccountMode,
            forgottenPwdMode: auth.InForgottenPwdMode,
            registerMode: auth.InRegisterMode,
            resetPwdMode: auth.InResetPasswordMode,
            selectCompanyMode: auth.InSelectCompanyMode
        });
        if (!this.state.selectCompanyMode) {
            this.props.handleAuthenticated();
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12 my-5" key={this.props.id}>
                    <h2>Welcome to the FENSA Installer portal!</h2>
                   
                    
                    <div className="mr-auto ml-auto pt-2">
                        {
                            !this.state.authoriseMode &&
                                !this.state.isAuthenticated &&
                                !this.state.canShowPortal &&
                                !this.state.registerMode &&
                                !this.state.confirmAccountMode &&
                                !this.state.forgottenPwdMode &&
                                !this.state.resetPwdMode &&
                                !this.state.selectCompanyMode ?
                                (
                                    <SignIn
                                        handleAuthenticated={this.handleSignInCallback}
                                        handleStartConfirmAccountMode={this.handleStartConfirmAccountModeCallback}
                                        handleStartRegisterMode={this.handleStartRegisterModeCallback}
                                        handleStartForgottenPwdMode={this.handleStartForgottenPwdModeCallback}
                                    />
                                ) :
                                (
                                    this.state.selectCompanyMode ?
                                        (
                                            <SelectDefaultCompany
                                                handleLogoutClick={this.handleLogoutCallback}
                                                handleAuthenticated={this.props.handleAuthenticated}
                                            />
                                        )
                                        :
                                        this.state.registerMode ?
                                            (
                                                <AccountRegister
                                                    handleAbortClick={this.handleAbortCallback}
                                                    handleAuthenticated={this.props.handleAuthenticated}
                                                />
                                            ) :
                                            this.state.forgottenPwdMode ?
                                                (
                                                    <AccountForgottenPwd
                                                        handleAbortClick={this.handleAbortCallback}
                                                        handleAuthenticated={this.props.handleAuthenticated}
                                                    />
                                                ) :
                                                this.state.resetPwdMode ?
                                                    (
                                                        <AccountReset
                                                            handleAbortClick={this.handleAbortCallback}
                                                            handleAuthenticated={this.props.handleAuthenticated}
                                                        />
                                                    ) :
                                                    this.state.confirmAccountMode ?
                                                        (
                                                            <AccountVerify
                                                                handleAbortClick={this.handleAbortCallback}
                                                                handleAuthenticated={this.props.handleAuthenticated}
                                                            />
                                                        ) :
                                                        this.state.authoriseMode ?
                                                            (
                                                                <AccountAuthorise
                                                                    handleAbortClick={this.handleAbortCallback}
                                                                    handleAuthenticated={this.props.handleAuthenticated}
                                                                />
                                                            ) : null

                                )
                        }
                    </div>
                    <div className="row text-center py-3">
                        <div className="col-xs-12">
                            <h6>We recommend using the latest version of either <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a> or <a href="https://www.mozilla.org/en-GB/firefox/new/" target="_blank">Mozilla Firefox</a> browser for the best experience.</h6>
                            <h6>Experiencing problems? Call FENSA on <a href="tel:02076453700">0207 645 3700</a></h6>
                        </div>
                        <hr />
                        
                    </div>
                </div>
            </div>
        );
    }
}
