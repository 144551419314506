import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { AddressCardBody } from '../SharedComponents/AddressCardBody';
import { ColumnAndLabelRadioOptions } from './../SharedComponents/ColumnAndLabelRadioOptions';
import { ConfirmActionModal } from '../SharedComponents/ConfirmActionModal';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { PleaseWait } from './../SharedComponents/PleaseWait';
import { ReadonlyItem } from './../SharedComponents/ReadonlyItem';

import {
    MDBBtn,
    MDBIcon
} from "mdbreact";


const branchHomeUrl = '/Branch/Home';

export class BranchView extends Component {
    displayName = BranchView.name

    constructor(props) {
        super(props);
        const auth = new AuthHelper();
        const deleting = this.getViewingPref(props);
        this.state = {
            Address1: '',
            Address2: '',
            Address3: '',
            BranchEmail: '',
            BranchId: props.match.params.id,
            BranchName: '',
            BranchUrl: '',
            CanEdit: auth.CanEditData,
            CityTown: '',
            CompanyId: auth.CompanyId,
            ContactName: '',
            County: '',
            MainBranch: 0,
            //MainBranchCss: 'btn btn-outline-secondary active',
            MainBranchText: 'No',
            ShowOnWebsite: 0,
            ShowOnWebsiteText: 'No',
            Postcode: '',
            TelephoneNumber: '',
            UserId: auth.UserId,
            deleteActionMessage: '',
            hideAddress: true,
            isDeletePage: deleting,
            loading: true,
            modal: false,
            notificationPostfix: 'info hide',
            notificationMessage: [],
            pageTitle: deleting ? 'Delete Branch' : 'View Branch Details'
        };

        // console.log(this.state);
        console.log(this.displayName, this.props);
    }

    componentDidMount() {
        this.fetchData();
    }

    getViewingPref = data => {
        const pathName = data.match.path;
        return (pathName !== undefined && pathName.toString().toLowerCase().indexOf('delete') > 0);
    }

    isLoading = () => {
        return this.state.loading;
    }

    isDeletePage = () => {
        return this.state.isDeletePage;
    }

    currentPageTitle = () => {
        return this.state.pageTitle;
    }

    toggleModal = toggle => {
        this.setState({
            modal: toggle
        });
    }

    togglePleaseWait = status => {
        this.setState({
            loading: status
        });
    }

    submitForm = e => {
        e.preventDefault();

        if (!window.confirm('You are about to delete this item - ' + this.state.BranchName + '. \nClick Ok to continue otherwise cancel to abort.')) {
            return false;
        }

        // Show please wait
        this.togglePleaseWait(true);

        var datavalues = JSON.stringify({
            BranchId: this.state.BranchId,
            UpdatedById: this.state.UserId
        });

        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.DeleteBranch",
            origin: "Installer Portal",
            operation: "DELETE",
            datavalues: datavalues
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                if (data.returncode === 1) {
                    // if successful then reload branch list
                    this.props.history.push(branchHomeUrl);
                    return;
                }
                //tell the developer that delete failed
                //console.log(data.message);
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['No data']
                });
            })
            .catch(data => {
                console.log(data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Failed to complete delete']
                });
            });
    }

    fetchData = () => {

        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetCompanyBranchItem",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                BranchId: this.state.BranchId
            })
        });

        var fetchBranchItem = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        });

        Promise.all([fetchBranchItem])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                const data = JSON.parse(JSON.parse(responses).message)[0];
                // console.log('responses', data);
                this.setState({
                    AddressId: data.AddressId,
                    Address1: data.Address1,
                    Address2: data.Address2,
                    Address3: data.Address3,
                    BranchEmail: data.BranchEmail,
                    BranchName: data.Name,
                    BranchUrl: data.BranchUrl,
                    CityTown: data.CityTown,
                    ContactName: data.ContactName,
                    County: data.County,
                    MainBranch: data.MainBranch,
                    MainBranchText: data.MainBranch === 1 ? 'Yes' : 'No',
                    ShowOnWebsite: data.ShowOnWebsite,
                    ShowOnWebsiteText: data.ShowOnWebsite === 1 ? 'Yes' : 'No',
                    Postcode: data.PostCode,
                    TelephoneNumber: data.Telephone,
                    deleteActionMessage: "You are about to delete " + data.Name + ". Click Ok to continue otherwise cancel to abort.",
                    loading: false,
                    hideAddress: false
                });
                //console.log(this.displayName + '.fetchData', this.state);
            })
            .catch(data => {
                console.log(data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - No data or service is not available']
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'Branch View',
            action: 'View Branch'
        });

        return (
            <div>
                <form className='demoForm' onSubmit={this.submitForm.bind(this)}>
                    <div className='form-group-container mr-auto ml-auto'>
                        <div className='form-group-title'>{this.currentPageTitle()}</div>
                        <div className='form-group'>
                            <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                            <div className='row'>
                                <div className='col-xs-12 col-md-4'>
                                    <ReadonlyItem textLabel="Branch Name">{this.state.BranchName}</ReadonlyItem>
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ReadonlyItem textLabel="Contact Name">{this.state.ContactName}</ReadonlyItem>
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ReadonlyItem textLabel="Telephone Number">{this.state.TelephoneNumber}</ReadonlyItem>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12 col-md-4'>
                                    <ReadonlyItem textLabel="Email">{this.state.BranchEmail}</ReadonlyItem>
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ReadonlyItem textLabel="Website">{this.state.BranchUrl}</ReadonlyItem>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 text-muted'>
                                    <AddressCardBody
                                        AddressLabel={"Branch Office"}
                                        Address1={this.state.Address1}
                                        Address2={this.state.Address2}
                                        Address3={this.state.Address3}
                                        CityTown={this.state.CityTown}
                                        County={this.state.County}
                                        Postcode={this.state.Postcode}
                                    >
                                        <div className='pb-2'>
                                            {
                                                this.state.MainBranch ?
                                                    (
                                                        <label><i className='fa fa-code-branch pr-3 fa-icon-normal-size' />Main Office</label>
                                                    ) : (
                                                        <label>Branch Office</label>
                                                    )
                                            }
                                        </div>
                                    </AddressCardBody>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12 col-md-4'>
                                    <ColumnAndLabelRadioOptions
                                        id="MainBranch"
                                        name="MainBranch"
                                        textLabel="Main Branch: "
                                        optionNo={'No'}
                                        optionYes={'Yes'}
                                        value={this.state.MainBranch}
                                        readOnly={true}
                                    />
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ColumnAndLabelRadioOptions
                                        id="ShowOnWebsite"
                                        name="ShowOnWebsite"
                                        textLabel="Show on Website: "
                                        optionNo={'No'}
                                        optionYes={'Yes'}
                                        value={this.state.ShowOnWebsite}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='row py-3'>
                                <div className='col'>
                                    <PleaseWait IsLoading={this.state.loading} />
                                    {
                                        this.isDeletePage() ?
                                            (
                                                <MDBBtn
                                                    className="pull-right mdb-btn-resize"
                                                    color="danger"
                                                    type="button"
                                                    size="sm"
                                                    onClick={this.toggleModal}
                                                >
                                                    <MDBIcon icon="trash" className="mr-2" />Delete
                                                </MDBBtn>
                                            ) : null
                                    }
                                    <Link className={this.state.CanEdit ? 'btn btn-sm btn-primary pull-right mdb-btn-resize' : 'hide'} to={'/Branch/Update/' + this.state.BranchId}>
                                        <i className="fa fa-edit pr-2" />Edit</Link>
                                    <Link className='btn btn-sm btn-dark pull-right mr-2 mdb-btn-resize' to={branchHomeUrl}>
                                        <i className='fa fa-arrow-left pr-2' />
                                        {this.isDeletePage() ? 'Cancel' : 'Back to List'}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ConfirmActionModal
                        ActionName="Delete"
                        ActionMessage={this.state.deleteActionMessage}
                        handleToggleAction={this.toggleModal}
                        handleModalAction={this.submitForm}
                        modalOpen={this.state.modal}
                    />
                </form>
            </div>
        );
    }
}
