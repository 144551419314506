import React, { Component } from "react";
import ReactGA from 'react-ga';
import { AuthResponseType } from "./AuthResponseType";

import {
    MDBInput,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBIcon
} from "mdbreact";

import AuthHelper from "./AuthHelper";
import { NotificationPanel } from "./NotificationPanel";
import { PleaseWait } from "./PleaseWait";
import uuid from "uuid";

const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;
const registerUrl = "/SignUp";
export class SignIn extends Component {
    displayName = "SignIn";

    // get state from parent
    state = {
        ...this.props,
        Email: "",
        Password: "",
        formValid: false,
        loading: false,
        notificationMessage: [],
        notificationCss: "info hide",
        notificationVisible: false,
        showSelectDefaultCompany: false
    };

    handleInputChange = event => {
        const target = event.target;
        var value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        //this state is only relevant locally - so no need for put it in redux.
        this.setState(
            {
                [name]: value
            },
            this.validateForm
        );
    };

    validateForm = () => {
        this.setState({
            formValid:
                this.state.Email.length !== 0 && this.state.Password.length !== 0
        });
        return this.state.formValid;
    };

    handleRegisterClick = () => {
        var auth = new AuthHelper();
        auth.StartRegisterMode(true);
        this.props.handleStartRegisterMode();
    }

    handleForgottenClick = () => {
        var auth = new AuthHelper();
        auth.StartForgottenPwdMode(true);
        this.props.handleStartForgottenPwdMode();
    }

    submitForm = e => {
        e.preventDefault();

        this.setState({
            loading: true
        });

        //const uuidv4 = require('uuid/v4');
        if (this.validateForm()) {
            var postData = JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.Authenticate",
                origin: "Installer Portal",
                operation: "INSERT",
                datavalues: JSON.stringify({
                    UserName: this.state.Email,
                    Password: this.state.Password,
                    ShouldConsiderLockout: false // temporary set to false due to users reset password not working..
                })
            });

            const auth = new AuthHelper();
            auth
                .Login(postData)
                .then(data => {
                    //if (debugOn) {
                    console.log(this.displayName + ".submitForm[data]", data);
                    //console.log(this.displayName + ".submitForm[data.returnData.errors]", data.returnData.errors);
                    //}
                    if (data.returncode === 1) {
                        //var user = JSON.parse(data.message);
                        //var user = JSON.parse(data.returnData.payload);

                        //handle opid
                        switch (data.opid) {
                            //case AuthResponseType.CredentialViolation:
                            //    break;
                            //case AuthResponseType.AccountDeleted:
                            //    break;
                            //case AuthResponseType.AccountDisabled:
                            //    break;
                            //case AuthResponseType.MissingAccount:
                            //    //show info area or message
                            //    break;
                            //case AuthResponseType.MissingRole:
                            //    break;
                            //case AuthResponseType.MissingPermission:
                            //    break;
                            //case AuthResponseType.PendingPasswordReset:
                            //    break;
                            //case AuthResponseType.PendingVerification:
                            //    break;

                            case AuthResponseType.InvalidCompanyData:
                            case AuthResponseType.MissingCompanyData:
                                auth.ReloadOnly();
                                break;

                            default:
                                this.setState({
                                    loading: false,
                                    notificationCss: data.returnData.errors ? "warning" : "success",
                                    notificationMessage: data.returnData.errors ? data.returnData.errors : ["Successfully logged in"],
                                    notificationVisible: data.returnData.errors ? true : false
                                });
                                //Successfully completed login so hand control over to parent/router
                                if (data.returnData.errors === null || data.returnData.errors.length === 0) {
                                    this.props.handleAuthenticated();
                                }
                                break;
                        }
                    } else {
                        this.setState({
                            loading: false,
                            notificationCss: "danger",
                            notificationMessage: [data.message],
                            notificationVisible: true
                        });
                    }
                })
                .catch(data => {
                    if (debugOn) {
                        console.log(this.displayName + ".submitForm[catch]", data);
                    }
                    this.setState({
                        loading: false,
                        notificationCss: "danger",
                        notificationMessage: ["Bad Request - Failed to complete login or service unavailable"],
                        notificationVisible: true
                    });
                });
        } else {
            this.setState({
                loading: false,
                notificationMessage: ["Bad Request"],
                notificationCss: "danger pb-3",
                notificationVisible: true
            });
        }
    };

    render() {

        ReactGA.event({
            category: 'Sign In',
            action: 'Sign In View'
        });

        return (
            <MDBCard>
                <div className="text-center">
                    <img
                        src={require("../images/fensa_logo.PNG")}
                        className="signin-icon"
                        alt="FENSA"
                    />
                </div>
                <MDBCardBody>
                    <form onSubmit={this.submitForm.bind(this)}>
                        <p className="h4 text-center">Sign in</p>
                        <NotificationPanel
                            CssClassPostFix={this.state.notificationCss}
                            NotificationMessage={this.state.notificationMessage}
                        />
                        <div className="grey-text pt-2">
                            <MDBInput
                                error="Email address is required"
                                group
                                hint=""
                                icon="envelope"
                                id="Email"
                                label="Your Username"
                                name="Email"
                                onChange={this.handleInputChange}
                                success=""
                                size="md"
                                type="email"
                                validate
                                value={this.state.Email}
                            />
                            <MDBInput
                                error="Password is required"
                                group
                                hint=""
                                icon="lock"
                                id="Password"
                                label="Password"
                                name="Password"
                                onChange={this.handleInputChange}
                                size="md"
                                type="password"
                                validate
                                value={this.state.Password}
                            />
                            <div className="row text-center">
                                <div className="col-xs-6 pl-5 pr-5">
                                    <p>Need to reset your password?</p><a href="#!" className="card-link" onClick={this.handleForgottenClick}>Click here</a>
                                </div>
                                <div className="col-xs-6 pl-5">
                                    <p>First time User to this page?</p><a href="#!" className="card-link" onClick={this.handleRegisterClick}>Register here</a>
                                </div>
                            </div>
                        </div>
                        <div className="text-right py-3">
                            <MDBBtn className="mdb-btn-resize"
                                color="success"
                                type="submit"
                                size="sm"
                                disabled={!this.state.formValid}>
                                <MDBIcon icon="sign-in" className="pr-2" />Login
                            </MDBBtn>
                        </div>
                        <PleaseWait IsLoading={this.state.loading} />
                    </form>
                </MDBCardBody>
            </MDBCard>
        );
    }
}
