import "@babel/polyfill";

import "isomorphic-fetch";
import "es6-promise";

import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { ReactTable } from 'react-table';
import { ReactTableCSS } from 'react-table/react-table.css';

import ReactGA from 'react-ga';

import 'react-dates/initialize';
import 'moment/locale/en-gb';

// 3rd party components
import 'react-dates/lib/css/_datepicker.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faStroopwafel, faKey, faAnchor, faFileInvoice, faHistory, faClock, faSearch, faUsers, faEye, faMobileAlt, faCodeBranch, faExclamationTriangle, faHome, faWrench, faClipboardList, faChartLine, faInfoCircle, faFolderOpen, faNewspaper, faUser, faHandshake, faEdit, faPhone, faHeadset, faCreditCard, faCoins, faFileAlt, faList, faAddressBook, faShieldAlt, faSitemap, faLink, faKeyboard, faBusinessTime, faUserPlus, faUsersCog, faRedo, faLightbulb } from '@fortawesome/free-solid-svg-icons';

import { Provider } from 'react-redux';
import store from "./store";


// Local components
import Layout from './components/Layout';
import { Home } from './components/Home';
import { Downloads } from './components/Downloads/UsefulDownloads';
import { UsefulInformation } from './components/UsefulInformation/UsefulInformation';

import { BranchEdit } from './components/Branches/BranchEdit';
import { BranchView } from './components/Branches/BranchView';
import { Branches } from './components/Branches/BranchList';

import { ContactEdit } from './components/Contacts/ContactEdit';
import { ContactView } from './components/Contacts/ContactView';
import { ContactList } from './components/Contacts/ContactList';


import { Inspections } from "./components/Inspection/Inspections";
import { InspectionView } from './components/Inspection/InspectionView';
import { InspectionsHistory } from './components/Inspection/InspectionsHistory';
import { ContactTechSupport } from "./components/Inspection/ContactTechSupport";
import { InspectionsList } from './components/Inspection/InspectionsList';
import { InspectionsActionsRequired } from './components/Inspection/InspectionsActionsRequired';


import { Installation } from './components/Installation/Installation';
import { AmendInstallation } from './components/Installation/AmendInstallation';
import { InstallationHistory } from './components/Installation/InstallationHistory';
import { InstallationEdit } from './components/Installation/InstallationEdit';
import { InstallationView } from './components/Installation/InstallationView';
import { RegisterInstallation } from './components/Installation/RegisterInstallation';

import { Finance } from "./components/Finance/Finance";
import { FinanceServices } from "./components/Finance/FinanceServices";
import { DirectDebit } from "./components/Finance/DirectDebit";
import { Invoices } from "./components/Finance/Invoices";
import { StatementView } from "./components/Finance/StatementView";


import { Payments } from "./components/Finance/Payments";
import { Statement } from "./components/Finance/Statement";

import { Operatives } from "./components/Operatives/Operatives";
import { OurOperatives } from './components/Operatives/OurOperatives';
import { OperativeEdit } from './components/Operatives/OperativeEdit';
import { OperativeView } from './components/Operatives/OperativeView';
import { IndividualsCPD } from "./components/Operatives/IndividualsCPD";

import { CompanyDetails } from "./components/Details/CompanyDetails";
import { APIKey } from "./components/Details/APIKey";
import { Insurances } from "./components/Details/Insurances";
import { Schemes } from "./components/Details/Schemes";
import { Details } from "./components/Details/Details";
import { InsuranceEdit } from "./components/Details/InsuranceEdit";


import { UsefulFinanceResources } from "./components/UsefulResources/UsefulFinanceResources";
import { UsefulInstallationResources } from "./components/UsefulResources/UsefulInstallationResources";
import { UsefulMembershipResources } from "./components/UsefulResources/UsefulMembershipResources";
import { UsefulOperativeResources } from "./components/UsefulResources/UsefulOperativeResources";
import { UsefulResources } from "./components/UsefulResources/UsefulResources";

import { ActionsRequired } from "./components/ActionsRequired/ActionsRequired";

import { AddedBenefits } from "./components/AddedBenefits/AddedBenefits";

import { HistoricNews } from "./components/News/HistoricNews";
import { LatestNews } from "./components/News/LatestNews";
import { News } from "./components/News/News";
import { ReportSomeNews } from "./components/News/ReportSomeNews";

import { ChangePassword } from './components/UserManagement/ChangePassword';
import { ChangePermissions } from './components/UserManagement/ChangePermissions';
import { ConfigureDashboard } from './components/UserManagement/ConfigureDashboard';
import { ListUsers } from './components/UserManagement/ListUsers';
import { UserEdit } from './components/UserManagement/UserEdit';
import { UserManagement } from "./components/UserManagement/UserManagement";
import { UserView } from './components/UserManagement/UserView';


import { EnergyRatings } from './components/EnergyRatings/EnergyRatings';
import { EnergyRatingList } from './components/EnergyRatings/EnergyRatingList';
import { EnergyRatingView } from './components/EnergyRatings/EnergyRatingView';


import { Invoicing } from './components/Invoicing/Invoicing';

import { Notices } from './components/Notices/Notices';
import { NoticesView } from './components/Notices/NoticesView';

import { AccountRegister } from './components/SharedComponents/AccountRegister';
import { AccountVerify } from './components/SharedComponents/AccountVerify';
import { AccountForgottenPwd } from './components/SharedComponents/AccountForgottenPwd';

import { MyDetails } from './components/MyDetails/MyDetails';

import { FKH } from './components/FKH/FKH';

import { MyLeadsMenu } from './components/MyLeads/Home';
import MyLeadsInformation  from './components/MyLeads/Information';
import ComingSoon from './components/MyLeads/ComingSoon';

import DebugInfo from './components/DebugInfo';

import { Webinars } from "./components/Webinars/webinars";



library.add(faStroopwafel, faKey, faAnchor, faFileInvoice, faHistory, faClock, faSearch, faUsers, faEye, faMobileAlt, faCodeBranch, faExclamationTriangle, faHome, faWrench, faClipboardList, faChartLine, faInfoCircle, faFolderOpen, faNewspaper, faUser, faHandshake, faEdit, faPhone, faHeadset, faCreditCard, faCoins, faFileAlt, faList, faAddressBook, faShieldAlt, faSitemap, faLink, faKeyboard, faBusinessTime, faUserPlus, faUsersCog, faRedo, faLightbulb)

function initializeReactGA() {
    console.log("starting GA.");
    ReactGA.initialize('UA-20381064-5');
    ReactGA.pageview('/');
}

initializeReactGA();

export default class App extends Component {
    displayName = App.name
    render() {
        return (
            <Provider store={store}>

                <Layout>
                    <Route exact path='/' component={Home} />

                    <Route path='/Installations/Home' component={Installation} exact />
                    <Route path='/Installations/Amend' component={AmendInstallation} exact />
                    <Route path='/Installations/History' component={InstallationHistory} />
                    <Route path='/Installations/Register' component={RegisterInstallation} exact />
                    <Route path='/Installations/Edit/:id' component={InstallationEdit} exact />
                    <Route path='/Installations/Delete/:id' component={InstallationView} exact />
                    <Route path='/Installations/View/:id' component={InstallationView} exact />

                    <Route path='/Inspections/Home' component={Inspections} exact />
                    <Route path='/Inspections/List' component={InspectionsList} exact />
                    <Route path='/Inspections/View/:id' component={InspectionView} exact />
                    <Route path='/Inspections/History' component={InspectionsHistory} exact />
                    <Route path='/Inspections/Actions' component={InspectionsActionsRequired} exact />
                    <Route path='/Inspections/TechSupport' component={ContactTechSupport} />

                    <Route path='/Finance/Home' component={Finance} />
                    <Route path='/Finance/DirectDebit' component={DirectDebit} />
                    <Route path='/Finance/Invoicing' component={Invoices} exact />
                    <Route path='/Finance/Services' component={FinanceServices} />

                    <Route path='/Finance/StatementView/:id' component={StatementView} exact />

                    <Route path='/Finance/Payments' component={Payments} />
                    <Route path='/Finance/Statement' component={Statement} />

                    <Route path='/Operatives/Home' component={Operatives} exact />
                    <Route path='/Operatives/OurOperatives' component={OurOperatives} exact />
                    <Route path='/Operatives/CPD' component={IndividualsCPD} exact />
                    <Route path='/Operatives/OurOperatives/Delete/:id' component={OperativeView} exact />
                    <Route path='/Operatives/OurOperatives/View/:id' component={OperativeView} exact />
                    <Route path='/Operatives/OurOperatives/Update/:id' component={OperativeEdit} exact />

                    <Route path='/Details/Home' component={Details} />
                    <Route path='/Details/Insurance' component={Insurances} />
                    <Route path='/Details/Company' component={CompanyDetails} />
                    <Route path='/Details/APIKey' component={APIKey} />
                    <Route path='/Details/InsuranceEdit' component={InsuranceEdit} />

                    <Route path='/Schemes/Home' component={Schemes} />

                    <Route path='/Contacts/Home' component={ContactList} exact />
                    <Route path='/Contact/Update/:id' component={ContactEdit} />
                    <Route path='/Contact/Delete/:id' component={ContactView} />
                    <Route path='/Contact/View/:id' component={ContactView} />

                    <Route path='/Branch/Home' component={Branches} exact />
                    <Route path='/Branch/Update/:id' component={BranchEdit} />
                    <Route path='/Branch/Delete/:id' component={BranchView} />
                    <Route path='/Branch/View/:id' component={BranchView} />

                    <Route path='/UsefulResources/Home' component={UsefulInstallationResources} />
                    <Route path='/UsefulResources/Finance' component={UsefulFinanceResources} />
                    <Route path='/UsefulResources/Installation' component={UsefulInstallationResources} />
                    <Route path='/UsefulResources/Membership' component={UsefulMembershipResources} />
                    <Route path='/UsefulResources/Operatives' component={UsefulOperativeResources} />

                    <Route path='/FensaBenefits/Home' component={AddedBenefits} exact />

                    <Route path='/News/Home' component={News} />
                    <Route path='/News/Latest' component={LatestNews} />
                    <Route path='/News/Historic' component={HistoricNews} />
                    <Route path='/News/ReportNews' component={ReportSomeNews} />

                    <Route path='/Actions/Home' component={ActionsRequired} />

                    <Route path='/UserManagement/Home' component={UserManagement} exact />
                    <Route path='/UserManagement/Users' component={ListUsers} exact />
                    <Route path='/UserManagement/User/Update/:id' component={UserEdit} exact />
                    <Route path='/UserManagement/User/Delete/:id' component={UserView} exact />
                    <Route path='/UserManagement/User/View/:id' component={UserView} exact />
                    <Route path='/UserManagement/ChangePassword' component={ChangePassword} exact />
                    <Route path='/UserManagement/ConfigureDashboard' component={ConfigureDashboard} exact />
                    <Route path='/UserManagement/Permissions/Home' component={ChangePermissions} exact />

                    <Route path='/EnergyRatings/Home' component={EnergyRatingList} exact />
                    <Route path='/EnergyRatings/List' component={EnergyRatingList} exact />
                    <Route path='/EnergyRatings/View/:id' component={EnergyRatingView} exact />

                    <Route path='/MyDetails' component={MyDetails} />

                    <Route path='/Notices/Home' component={Notices} exact />
                    <Route path='/Notices/View/:id' component={NoticesView} exact />

                    <Route path='/Downloads' component={Downloads} />
                    <Route path='/UsefulInformation' component={UsefulInformation} />
                    <Route path='/Invoicing' component={Invoicing} />

                    <Route path='/FKH/Home' component={FKH} />

                    <Route path='/Register' component={AccountRegister} exact />
                    <Route path='/Verify' component={AccountVerify} exact />
                    <Route path='/ForgottenPwd' component={AccountForgottenPwd} exact />
                    <Route path='/Debug' component={DebugInfo} exact />

                    <Route path='/MyLeads/Home' component={MyLeadsMenu} exact />
                    <Route path='/MyLeads/Information' component={MyLeadsInformation} exact />
                    <Route path='/MyLeads/ComingSoon' component={ComingSoon} exact />

                    <Route path='/Webinars/Home' component={Webinars} />

                </Layout>
            </Provider>
        );
    }
}
