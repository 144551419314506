import React, { Component } from 'react';
import { MenuItems } from '../SharedComponents/MenuItems';

import ReactGA from 'react-ga';

export class Operatives extends Component {
    displayName = Operatives.name

    render() {

        ReactGA.event({
            category: 'Operatives',
            action: 'Operatives Home'
        });


        return (
            <MenuItems ParentName="Operatives" />
        );
    }
}
