import React, { Component } from 'react';

import { ButtonToolbar, ButtonGroup, Button, Image } from 'react-bootstrap';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

import { Product } from './Product';
import AuthHelper from '../SharedComponents/AuthHelper';
import { ColumnAndLabel } from '../SharedComponents/ColumnAndLabel';
import { MDBBtn } from "mdbreact";


const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class ProductAdd extends Component {

    displayName = Product.name;

    constructor(props) {
        super(props);

        const auth = new AuthHelper();

        this.state = {
            //...props,
            CompanyId: auth.CompanyId,
            loading: false,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            InstallTypes: [
                { InstallTypeId: 0, InstallType: 'WINDOW' },
                { InstallTypeId: 1, InstallType: 'DOORABOVE' },
                { InstallTypeId: 2, InstallType: 'DOORBELOW' }
            ],
            LicenceDetails: [],
            shouldHideBFRC: true,
            NumberOfWindows: '',
            RatingType: 'UValue',
            InstallType: 'WINDOW',
            Rating: '',
            Rated: false,
            LicenceNumber: '',
            HideRatingType: true,
            HideQuantity: true,
            showAddRatingButton: false,
            maxItemCount: 90                      
        };

        this.onRatingButtonClick = this.onRatingButtonClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleProductQuantityChange = this.handleProductQuantityChange.bind(this);
        this.onProductAddClick = this.onProductAddClick.bind(this);


    }

    componentWillMount() {
        this.fetchData();
    }



    onProductAddClick(event) {

        //console.log(this.props.Products);

        event.preventDefault();

        var RatingId;
        var ProductId;

        switch (this.state.RatingType) {
            case "BFRC":
            case "TRR":
            case "BSI":
            case "RATED":
                RatingId = 3;
                break;
            case "UValue":
                RatingId = 1;
                break;
            default:
                RatingId = 0;
                break;
        }

        
        switch (this.state.InstallType) {
            case "WINDOW":
                ProductId = 0;
                break;
            case "DOORABOVE":
                ProductId = 1;
                break;
            case "DOORBELOW":
                ProductId = 2;
                break;   
               
        
        }
   

        let proceed = true;
        if (this.state.NumberOfWindows > 0) {
            this.props.Products.push({
                __table__: "InstallationAppliance",
                Id: this.props.Products.length + 1,
                InstallationItemTypeId: ProductId,
                Count: this.state.NumberOfWindows,
                UValue: '',
                ERPRating: this.state.Rating,
                ComplienceType: RatingId,
                BFRCLicenceNumber: this.state.LicenceNumber,
                NewProduct: true,
                Deleted: false,
                RatingType: this.state.RatingType
            });

            this.setState({
                ProductType: "WINDOW",
                NumberOfWindows: 0,
                Rated: false,
                Rating: "",
                RatingType: "none",
                LicenceNumber: "",
                LicenceDetails: null,
                LicenceValid: false,
                shouldHideBFRC: true,
                HideQuantity: false
            });
        } else {
            this.props.HandleReturnMessage('danger show', 'You must select at least one item');
            this.setState({
                HideQuantity: this.state.RatingType === 'BFRC' ? false : true
            });
            proceed = false;
        }

        if (proceed) {
            this.setState({
                Products: this.props.Products,
                HideRatingType: true,
                HideQuantity: true
            });
            this.props.HandleProductAdd(this.props.Products);
           
        }
    }

    handleProductQuantityChange = event => {
        const target = event.target;
        let value = target.value;
        
        console.log('jjj : ' + value);

        if(value > this.state.maxItemCount)
        {
            value= this.state.NumberOfWindows;
        }
        
        this.setState({
                NumberOfWindows: value > 0 ? value : ''
            });
            this.props.HandleProductQuantityChange(value);
        
       
    }

    onRatingButtonClick(event) {

        event.preventDefault();

        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;

        if (value === undefined) {
            value = target.parentElement.value;
        }

        const name = target.name;


        switch (name) {
            case "NoWindows":
                this.setState({ "NumberOfWindows": value });
                break;

            case "RatingType":

                this.setState({ "RatingType": value });

                if (value === 'BFRC' || value === 'TRR' || value === 'BSI' || value === "RATED") {
                    this.setState({
                        shouldHideBFRC: false,
                        Rated: true,
                        RatingType: value,
                        Rating: '',
                        LicenceNumber: '',
                        LicenceDetails: [],
                        HideQuantity: false
                    });
                }
                else {
                    this.setState({
                        shouldHideBFRC: true,
                        ShowInvalidMessage: false,
                        Rated: false,
                        RatingType: value,
                        Rating: '',
                        LicenceNumber: '',
                        LicenceDetails: [],
                        HideQuantity: false
                    });
                }
                break;

            case "InstallType":
                
                let totalDoorsAbove = 0;
                let totalDoorsBelow = 0;
                let totalWindows = 0;
                let _maxDoorsPerItem = 20;
                let _maxWindows = 90;

                this.props.Products.map((itm, idx) =>
                    {                         
                        if( value == 'DOORABOVE' && itm.InstallationItemTypeId==1 && itm.Deleted == false )
                        {
                            totalDoorsAbove += parseInt(itm.Count) ;
                            _maxDoorsPerItem = _maxDoorsPerItem - totalDoorsAbove;
                        }
                        
                        if(value == 'DOORBELOW' && itm.InstallationItemTypeId==2 && itm.Deleted == false )
                        {
                            totalDoorsBelow += parseInt(itm.Count) ;
                            _maxDoorsPerItem = _maxDoorsPerItem - totalDoorsBelow;
                        }

                        if(itm.InstallationItemTypeId==0 && itm.Deleted == false )
                        {
                            totalWindows += parseInt(itm.Count) ;
                            _maxWindows = _maxWindows - totalWindows;
                        }
                    }
                );



                this.setState({
                    InstallType: value,
                    HideRatingType: false,
                    //maxItemCount: value === 'WINDOW'?90:40 - totalDoors
                    maxItemCount: value === 'WINDOW'?_maxWindows:_maxDoorsPerItem
                });
                break;

            default:
                break;
        }

        
        console.log(this.state.InstallType + ' : ' + this.state.maxItemCount);
        console.log(this.props.Products)
    }

    handleInputChange(event) {

        const target = event.target;

        var value = target.type === 'checkbox' ? target.checked : target.value;

        if (value === undefined) {
            value = target.parentElement.value;
        }

        const name = target.name;
        if (name === 'NumberOfWindows') {

            let value = target.value;
            let limit = this.state.maxItemCount;
    
            if(value > limit)
            {
                value= this.state.NumberOfWindows;
            }
            value = value > 0 ? value : 0
        }
        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        });
        if (name === 'NumberOfWindows') {
            this.props.HandleProductQuantityChange(value);
        }
    }

    fetchData = () => {
    };

    render() {
        return (
            <div className="pb-3">
                <hr />
                <h3>Add FENSA Product</h3>
                <div className='small-text text-muted pb-2'><strong>Step 1 of 3</strong>: Select product type.</div>
                <div className="col-xs-3" key={this.props.id}>
                    <ButtonToolbar onClick={this.onRatingButtonClick}>
                        <ButtonGroup>
                            <div className="col-xs-4 product-type">
                                <button className={this.state.InstallType === 'WINDOW' ? ' active' : ''} name="InstallType" value="WINDOW">
                                    <Image src={require('./window.png')} alt="my image" name="InstallType" />
                                </button>
                                <p>Window</p>
                            </div>
                            <div className="col-xs-4 product-type">
                                <button className={this.state.InstallType === 'DOORABOVE' ? ' active' : ''} name="InstallType" value="DOORABOVE">
                                    <Image src={require('./DoorMore50.png')} alt="my image" name="InstallType" />
                                </button>
                                <p>Door &#8593; 50% Glass</p>
                            </div>
                            <div className="col-xs-4 product-type">
                                <button className={this.state.InstallType === 'DOORBELOW' ? ' active' : ''} name="InstallType" value="DOORBELOW">
                                    <Image src={require('./DoorLess50.png')} alt="my image" name="InstallType" />
                                </button>
                                <p>Door &#8595; 50% Glass</p>
                            </div>
                        </ButtonGroup>
                    </ButtonToolbar>
                    <div className={this.state.HideRatingType === true ? 'hide' : 'row py-4'}>
                        <div className="col">
                            <div className='small-text text-muted pb-2'><strong>Step 2 of 3</strong>: Select rating type.</div>
                            <ButtonToolbar onClick={this.onRatingButtonClick}>
                                <ButtonGroup className='mx-auto'>
                                    <Button className={this.state.RatingType === 'UValue' ? ' active' : ''} name="RatingType" value="UValue">UValue</Button>
                                    <Button className={this.state.RatingType === 'BFRC' ? ' active' : ''} name="RatingType" value="BFRC">Energy Rated BFRC</Button>
                                    <Button className={this.state.RatingType === 'TRR' ? ' active' : ''} name="RatingType" value="TRR">Energy Rated TRR</Button>
                                    <Button className={this.state.RatingType === 'BSI' ? ' active' : ''} name="RatingType" value="BSI">Energy Rated BSI</Button>
                                    <Button className={this.state.RatingType === 'RATED' ? ' active' : ''} name="RatingType" value="RATED">Energy Rated</Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </div>
                    </div>
                    <div className={this.state.shouldHideBFRC ? 'hide' : 'show row pl-5 pt-5'}>
                        <div className="row">
                            <div className='d-flex'>
                                <div className='d-flex flex-column'>
                                    <ColumnAndLabel
                                        Id="LicenceNumber"
                                        InputType="text"
                                        IsRequired={false}
                                        SizeCss="sm"
                                        HintLabel=""
                                        TextLabel="Licence No"
                                        Validate={true}
                                        Value={this.state.LicenceNumber}
                                        HandleInputChange={this.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <ColumnAndLabel
                                    ErrorMessage={"Rating is required"}
                                    Id="Rating"
                                    InputType="text"
                                    IsRequired={false}
                                    SizeCss="sm"
                                    HintLabel=""
                                    TextLabel="Rating"
                                    Validate={true}
                                    Value={this.state.Rating}
                                    HandleInputChange={this.handleInputChange}
                                />
                            </div>

                        </div>
                    </div>
                    <div className={this.state.HideQuantity === true ? 'hide' : 'row py-4'}>
                        <div className='small-text text-muted pb-2'><strong>Step 3 of 3</strong>: Number of Products (Total  max doors per type 20 & 90 windows per installation).</div>
                    </div>

                    <div className={this.state.HideQuantity === true ? 'hide' : 'row pl-5'}>

                        <div className="col">
                            <div className='d-flex'>
                                <div className='d-flex flex-column pr-5 py-4'>
                                    <input
                                        className='slider'
                                        type="range"
                                        id="NumberOfWindows"
                                        name="NumberOfWindows"
                                        min="0"
                                        max={this.state.maxItemCount}
                                        onInput={this.handleInputChange}
                                        value={this.state.NumberOfWindows === '' ? 0 : this.state.NumberOfWindows}
                                        list="steplist"
                                    />
                                    <datalist id="steplist">
                                        <option>0</option>
                                        <option>5</option>
                                        <option>10</option>
                                        <option>15</option>
                                        <option>{this.state.maxItemCount}</option>
                                    </datalist>
                                </div>
                                <div className='d-flex flex-column'>
                                    <ColumnAndLabel
                                        ErrorMessage=""
                                        IconLabel=""
                                        Id="NumberOfWindows"
                                        InputType="number"
                                        IsRequired={true}
                                        SizeCss="sm"
                                        HintLabel=""
                                        TextLabel="Quantity"
                                        Validate={true}
                                        Value={this.state.NumberOfWindows}
                                        HandleInputChange={this.handleProductQuantityChange}
                                    />
                                </div>
                                <MDBBtn
                                    className="btn mdb-btn-resize add-btn"
                                    color="primary"
                                    icon=""
                                    type="button"
                                    size="sm"
                                    onClick={this.onProductAddClick}
                                    disabled={this.state.NumberOfWindows < 1 ||  this.state.NumberOfWindows > this.state.maxItemCount ? true:false}
                                >
                                    Add
                                </MDBBtn>
                            </div>
                            <div className='small-text text-muted pt-2 pb-5'>Set quantity and add. To add more go back to step 1.</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
