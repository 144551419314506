import React, { Component, Fragment } from "react";
import validator from 'validator';
import uuid from "uuid";

import AuthHelper from './AuthHelper';
import { ColumnAndLabel } from './ColumnAndLabel';
import { NotificationPanel } from './NotificationPanel';
import { ReadonlyItem } from './ReadonlyItem';
import { PleaseWait } from './PleaseWait';

import { MDBContainer, MDBBtn, MDBIcon, MDBCard, MDBCardBody } from "mdbreact";

const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;
export class AccountRegisterValidateCompany extends Component {
    displayName = AccountRegisterValidateCompany.name;

    constructor(props) {
        super(props);
        //const auth = new AuthHelper();
        this.state = {
            ...props,
            Company: null,
            CompanyId: 0,
            FensaNumber: '',
            Postcode: '',
            companyVerified: false,
            displayContent: '',
            formValid: false,
            formErrors: {
                FensaNumber: "FENSA number is required.",
                Postcode: "Postcode is required."
            },
            loading: false,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            validateComplete: false
        };
    }

    togglePleaseWait = status => {
        this.setState({
            loading: status
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        var value = target.value;
        if (value === undefined) {
            value = target.parentElement.value;
        }
        const name = target.name;
        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        },
            () => { this.validateField(name, value); }
        );
        //if (debugOn) {
        //console.log('state', this.state);
        //}
    }

    handleOnInputValidation = event => {

        let fieldValidationErrors = this.state.formErrors;
        let message = "";
        switch (event.target.name.toLowerCase()) {
            case 'fensanumber':
                fieldValidationErrors.FensaNumber = validator.isEmpty(event.target.value) ? 'FENSA number is required.' : '';
                if (fieldValidationErrors.FensaNumber === "") {
                    fieldValidationErrors.FensaNumber = validator.isNumeric(event.target.value) ? '' : 'FENSA number can only contain numbers.';
                }
                message = fieldValidationErrors.FensaNumber;
                break;
            case 'postcode':
                fieldValidationErrors.Postcode = validator.isEmpty(event.target.value) ? 'Postcode is required.' : '';
                if (fieldValidationErrors.Postcode === "") {
                    fieldValidationErrors.Postcode = event.target.value.indexOf(' ') <= 0 ? 'Postcode must contain a space.' : '';
                }
                message = fieldValidationErrors.Postcode;
                break;
            default:
                break;
        }

        if (message !== "") {
            this.setState({
                formErrors: fieldValidationErrors
            }, this.validateForm);//
            //set custom validation error
            event.target.setCustomValidity(message);
        } else {
            //clear custom validation errors
            event.target.setCustomValidity("");
        }
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        switch (fieldName.toLowerCase()) {
            case 'fensanumber':
                fieldValidationErrors.FensaNumber = validator.isEmpty(this.state.FensaNumber) ? 'FENSA number  is required.' : '';
                fieldValidationErrors.FensaNumber = validator.isNumeric(this.state.FensaNumber) ? '' : 'FENSA number can only contain numbers.';
                break;
            case 'postcode':
                fieldValidationErrors.Postcode = validator.isEmpty(this.state.Postcode) ? 'Postcode is required.' : '';
                fieldValidationErrors.Postcode = this.state.Postcode.indexOf(' ') <= 0 ? 'Postcode must contain a space.' : '';
                break;

            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm() {
        //console.log('FensaNumber: ', this.state.formErrors.FensaNumber, this.state.formErrors.FensaNumber.length === 0);
        //console.log('Postcode: ', this.state.formErrors.Postcode, this.state.formErrors.Postcode.length === 0);
        this.setState({
            formValid: this.state.formErrors
                && this.state.formErrors.FensaNumber.length === 0
                && this.state.formErrors.Postcode.length === 0
        });
        return this.state.formValid;
    }

    handleAbortClick = () => {
        this.props.handleAbortClick();
    }

    handleVerifiedCallback = () => {
        //join the company
        const company = this.state.Company;
        this.props.handleCompanyVerifiedClick(company);
    }

    renderCompany = (company) => {
        if (!company) {
            return '';
        }
        //console.log('renderCompany', company);
        return (
            <div>
                <div className="display-5 py-3">{company.CompanyName}</div>
                <div className="row">
                    <div className="col-md-4 py-2">FENSA Number</div>
                    <div className="col-md-6 py-2">{company.FensaNumber}</div>
                    <div className="col-md-4 py-2">Address</div>
                    <div className="col-md-6 py-2">{company.CompanyAddress}</div>
                    <div className="col-md-4 py-2">Postcode</div>
                    <div className="col-md-6 py-2">{company.Postcode}</div>
                    <div className="col-md-4 py-2">Registration</div>
                    <div className="col-md-6 py-2">{company.RegistrationStatusType}</div>
                </div>
                <div className='row'>
                    <div className="col-md-12 py-2">
                        <MDBBtn
                            className="pull-right mdb-btn-resize"
                            color="success"
                            type="button"
                            size="sm"
                            onClick={this.handleVerifiedCallback}
                        >
                            <MDBIcon icon="arrow-right" className="mr-2" />Continue
                        </MDBBtn>
                    </div>
                </div>
            </div>
        );
    }

    submitForm = (e) => {
        e.preventDefault();
        // Show please wait
        this.togglePleaseWait(true);

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.InstallerCompanyVerify",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    FensaNumber: this.state.FensaNumber,
                    Postcode: this.state.Postcode
                }),
                validate: false
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                console.log(this.displayName + '.submit[data]', data);
                if (data.returncode === 1) {
                    const companyData = JSON.parse(data.ReturnData.Payload);
                    if (debugOn) {
                        console.log(this.displayName + '.data', data);
                        console.log(this.displayName + '.json.Payload', JSON.parse(data.ReturnData.Payload));
                        console.log(this.displayName + '.companyData', companyData);
                    }
                    if (companyData === null) {
                        this.setState({
                            Company: null,
                            companyVerified: false,
                            displayContent: null,
                            loading: false,
                            notificationPostfix: 'danger show mt-3',
                            notificationMessage: ['Error - Please check your FENSA number and postcode are correct and match our records and your account is not cancelled'],
                            validateComplete: false
                        });
                    } else {
                        this.setState({
                            Company: companyData[0],
                            companyVerified: true,
                            displayContent: this.renderCompany(companyData[0]),
                            loading: false,
                            notificationPostfix: 'info hide',
                            notificationMessage: [],
                            validateComplete: true
                        });
                    }

                } else {
                    this.setState({
                        companyVerified: false,
                        loading: false,
                        notificationPostfix: 'danger show mt-3',
                        notificationMessage: ['Error - Please check your FENSA Company ID and postcode are correct and match our records'],
                        validateComplete: false
                    });
                }
            })
            .catch(data => {
                if (debugOn) {
                    console.log(this.displayName + '.submitForm[catch->update]', data);
                }
                this.setState({
                    companyVerified: false,
                    loading: false,
                    notificationPostfix: 'danger show mt-3',
                    notificationMessage: ['Bad Request - Failed to complete update'],
                    validateComplete: false
                });
            });
    }

    handleLogoutClick = () => {
        var auth = new AuthHelper();
        auth.LogOut();
        this.props.handleLogoutClick();
    }

    render() {
        return (
            <MDBContainer>
                <MDBCard>
                    <MDBCardBody>
                        <form className='demoForm was-validated' onSubmit={this.submitForm.bind(this)} noValidate>
                            <p className="h4 text-center py-4">Verify Company Information</p>
                            <div className="grey-text">
                                {!this.state.validateComplete ?
                                    (
                                        <Fragment>
                                            <ColumnAndLabel
                                                ErrorMessage={this.state.formErrors.FensaNumber}
                                                IconLabel=""
                                                Id="FensaNumber"
                                                InputType="text"
                                                IsRequired={true}
                                                SizeCss="md"
                                                HintLabel=""
                                                TextLabel="FENSA Number"
                                                Validate={true}
                                                Value={this.state.FensaNumber}
                                                HandleInputChange={this.handleInputChange}
                                                HandleOnInput={this.handleOnInputValidation}
                                            />
                                            <ColumnAndLabel
                                                ErrorMessage={this.state.formErrors.Postcode}
                                                IconLabel=""
                                                Id="Postcode"
                                                InputType="text"
                                                IsRequired={true}
                                                SizeCss="md"
                                                HintLabel=""
                                                TextLabel="Postcode"
                                                Validate={true}
                                                Value={this.state.Postcode}
                                                HandleInputChange={this.handleInputChange}
                                                HandleOnInput={this.handleOnInputValidation}
                                            />
                                        </Fragment>
                                    ):null
                                }
                                <PleaseWait IsLoading={this.state.loading} />
                                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                            </div>
                            {this.state.displayContent}
                            {!this.state.companyVerified ?
                                (
                                    <div className="text-right py-4 mt-3">
                                        <MDBBtn
                                            className="pull-right mdb-btn-resize"
                                            color="success"
                                            type="submit"
                                            size="sm"
                                            disabled={!this.state.formValid}>
                                            <MDBIcon icon="save" className="mr-2" />Verify
                                        </MDBBtn>
                                        <MDBBtn className="mdb-btn-resize"
                                            color="danger"
                                            type="button"
                                            size="sm"
                                            onClick={this.handleLogoutClick}>
                                            <MDBIcon icon="sign-out" className="pr-2" />Cancel
                                        </MDBBtn>
                                    </div>
                                ) : null
                            }
                        </form>
                    </MDBCardBody>
                </MDBCard>
            </MDBContainer>
        );
    }
}
