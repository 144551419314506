import React, { Component, Fragment } from 'react';
import Moment from 'react-moment';
import ReactTable from "react-table";
import uuid from "uuid";

import ReactGA from 'react-ga';

import AuthHelper from '../SharedComponents/AuthHelper';
import { CardBodyOnly } from './../SharedComponents/CardBodyOnly';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';
import { PleaseWait } from '../SharedComponents/PleaseWait';
import { ReactTableFilterToggler } from './../SharedComponents/ReactTableFilterToggler';
import { ReadonlyItem } from './../SharedComponents/ReadonlyItem';
//import ResponsiveTable from '../SharedComponents/ResponsiveListTable';

import Badge from "@material-ui/core/Badge";

export class Invoicing extends Component {
    displayName = Invoicing.name;

    constructor(props) {

        super(props);
        const auth = new AuthHelper(this.displayName);
        this.state = {
            InstallerStatement: [],
            InstallerStatementSummary: [],
            CompanyId: auth.CompanyId,
            displayContent: (<PleaseWait IsLoading={true} />),
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 10,
            totals: '',
            totalsLabel: ' item',
            useFiltering: (auth.TableFilter && auth.TableFilter === 'On' ? true : false)
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    handleToggleFilter = toggle => {
        this.setState({
            useFiltering: toggle
        });
        this.fetchData();
    }

    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    //renderTableR = (InstallerStatement, InstallerStatementSummary) => {
    //    return (
    //        <div className='form-group'>
    //            <div className='row'>
    //                <div className="col-xs-6 col-md-3">
    //                    <ColumnAndLable id="InstallerStatementSummary.PaymentTotal" textlable="Payment Total" value={"£" + InstallerStatementSummary[0].PaymentTotal} readonly="true" />
    //                </div>
    //                <div className="col-xs-6 col-md-3">
    //                    <ColumnAndLable id="InstallerStatementSummary.DebitTotal" textlable="Debit Total" value={"£" + InstallerStatementSummary[0].DebitTotal} readonly="true" />
    //                </div>
    //                <div className="col-xs-6 col-md-3">
    //                    <ColumnAndLable id="InstallerStatementSummary.CreditTotal" textlable="Credit Total" value={"£" + InstallerStatementSummary[0].CreditTotal} readonly="true" />
    //                </div>
    //                <div className="col-xs-6 col-md-3">
    //                    <ColumnAndLable id="InstallerStatementSummary.AccountBalance" textlable="Account Balance" value={"£" + InstallerStatementSummary[0].AccountBalance} readonly="true" />
    //                </div>
    //            </div>
    //            <div className='row  table-responsive'>
    //                <table className='table'>
    //                    <thead>
    //                        <tr>
    //                            <th>Invoice Date</th>
    //                            <th>Reference</th>
    //                            <th>Description</th>
    //                            <th>Invoiced</th>
    //                            <th>Payment/Credit Notes</th>
    //                        </tr>
    //                    </thead>
    //                    <tbody>
    //                        {
    //                            InstallerStatement.map(InstallerStatement =>
    //                                (
    //                                    <tr key={InstallerStatement.Id}>
    //                                        <td><Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={InstallerStatement.Date}></Moment></td>
    //                                        <td>{InstallerStatement.Reference}</td>
    //                                        <td>{InstallerStatement.Description}</td>
    //                                        <td>{InstallerStatement.Payment}</td>
    //                                        <td>{InstallerStatement.Debit}</td>
    //                                    </tr>
    //                                )
    //                            )
    //                        }
    //                    </tbody>
    //                </table>
    //            </div>
    //        </div>
    //    );
    //}

    renderTable = (payload, summary) => {
        return (
            <Fragment>
                <div className='form-group'>
                    <p>This screen show you your recent statements</p>
                    <CardBodyOnly>
                        <div className='row'>
                            <div className='col-md-3'>
                                <ReadonlyItem textLabel="Payment Total">£ {summary[0].PaymentTotal}</ReadonlyItem>
                            </div>
                            <div className='col-md-3'>
                                <ReadonlyItem textLabel="Debit Total">£ {summary[0].DebitTotal}</ReadonlyItem>
                            </div>
                            <div className='col-md-3'>
                                <ReadonlyItem textLabel="Credit Total">£ {summary[0].CreditTotal}</ReadonlyItem>
                            </div>
                            <div className='col-md-3'>
                                <ReadonlyItem textLabel="Account Balance">£ {summary[0].AccountBalance}</ReadonlyItem>
                            </div>
                        </div>
                    </CardBodyOnly>
                    <div className='py-5'>
                        <div>
                            <ReactTableFilterToggler
                                ParentDisplayName={this.displayName}
                                UseFiltering={this.state.useFiltering}
                                handleToggleFilter={this.handleToggleFilter}
                            />
                            <ReactTable data={payload}
                                columns=
                                {
                                    [
                                        {
                                            Header: 'Id', // Custom header components!
                                            accessor: 'Id',
                                            show: false
                                        },
                                        {
                                            Header: 'Reference',
                                            accessor: 'Reference',
                                            className: 'p-3',
                                            width: 120
                                        },
                                        {
                                            Header: 'Invoice Date', // Custom header components!
                                            accessor: 'Date',
                                            className: 'p-3',
                                            Cell: row => {
                                                return (
                                                    <Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.Date} />
                                                );
                                            }
                                        },
                                        {
                                            Header: 'Description',
                                            accessor: 'Description',
                                            className: 'p-3'
                                        },
                                        {
                                            Header: 'Invoiced',
                                            accessor: 'Payment',
                                            className: 'p-3'
                                        },
                                        {
                                            Header: 'Payment/Credit Notes',
                                            accessor: 'Debit',
                                            className: 'p-3'
                                        }
                                    ]
                                }
                                className="table-bordered table-striped table-light table-hover table-shadowed"
                                defaultPageSize={this.state.pageSize}
                                expanded={this.state.expanded}
                                filterable={this.state.useFiltering}
                                minRows={0}
                                nextText='>'
                                previousText='<'

                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    fetchData = () => {

        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerStatement_views",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                CompanyId: this.state.CompanyId
            })
        });

        var fetchStatement = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        });

        var summaryData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerStatementSummary_views",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                CompanyId: this.state.CompanyId
            })
        });

        var fetchSummary = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: summaryData
        });

        ///////////////////////////

        Promise.all([fetchStatement, fetchSummary])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                const data = JSON.parse(responses[0]);
                const summary = JSON.parse(responses[1]);

                const installerData = JSON.parse(data.ReturnData.Payload);
                const installerSummary = JSON.parse(summary.ReturnData.Payload);

                this.setState({
                    InstallerStatement: JSON.parse(JSON.parse(responses[0]).message),
                    InstallerStatementSummary: JSON.parse(JSON.parse(responses[1]).message),
                    displayContent: data.returncode === 0 ?
                        this.renderNoDataView() :
                        this.renderTable(installerData, installerSummary),
                    loading: false,
                    notificationPostfix: data.returncode === 0 ? 'info show mt-3' : 'info hide',
                    notificationMessage: data.returncode === 0 ? ['No Data'] : [],
                    totals: installerData.length
                });
            })
            .catch(data => {
                console.log(JSON.parse(data));
                this.setState({
                    InstallerStatement: [],
                    InstallerStatementSummary: [],
                    displayContent: '',
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    totals: 0,
                    totalsLabel: ' item'
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'Invoicing',
            action: 'Invoicing View'
        });

        return (
            <div className='fluid-container'>
                <div className='row'>
                    <div className='col-xs-12 col-sm-6 col-md-6'>
                        <h2>
                            <Badge badgeContent={this.state.totals} color="primary">Invoicing</Badge>
                        </h2>
                    </div>
                </div>
                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                {this.state.displayContent}
            </div>
        );
    }
}
