import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";

import ReactGA from 'react-ga';

import InformationView from './InformationView';
//import DatePicker from 'react-datepicker';

import AuthHelper from '../SharedComponents/AuthHelper';
import { PleaseWait } from './../SharedComponents/PleaseWait';
import { ReduxNotificationPanel } from './../SharedComponents/ReduxNotificationPanel';
import { NotificationPanel } from './../SharedComponents/NotificationPanel';

import { ClearApplicationError } from '../../actions/applicationErrors';
import { ClearApplicationInformation } from '../../actions/applicationInformation';

import {
    GetInstallerMaterialType,
    GetInstallerServiceType,
    GetInstallerPropertyType,
    GetInstallerRadiusType,
    GetInstallerMinimumbudgetType,
    InsertInstallerWorkData,
    GetInstallerWorkPropertyTypes,
    GetInstallerWorkServices,
    GetInstallerWorkMaterials,
    GetInstallerOpeningHours,
    GetInstallerWorkData,

    setWorkMaterials,
    setWorkServices,
    setPropertyType,
    setOpeningHours,
    setWorkData

} from "../../actions/installerWork";

import { ColumnAndLabel } from '../SharedComponents/ColumnAndLabel';

class MyLeadsInformation extends Component {
    displayName = "MyLeadsInformation";

    constructor(props) {
        super(props);
        const auth = new AuthHelper();

        this.state = {
            ...props,
            CompanyId: auth.CompanyId,            
            loading: false,            
            //useFiltering: auth.TableFilter && auth.TableFilter === 'On' ? true : false,
            //SelectedMaterial: [],
            //SelectedService: [],
            //SelectedPropertyType: [],
            //SelectedWorkRadius: -1,
            //SelectedMinimumHours: -1,
            //SelectedDays: [],
            //CompanyDescription: '',
            //ContactEmail: '',
            //AdditionalContactEmail: '',

            DaysOfWeek: [
                { DayId: 0, Name: 'Monday' },
                { DayId: 1, Name: 'Tuesday' },
                { DayId: 2, Name: 'Wednesday' },
                { DayId: 3, Name: 'Thursday' },
                { DayId: 4, Name: 'Friday' },
                { DayId: 5, Name: 'Saturday' },
                { DayId: 6, Name: 'Sunday' }
            ]
        };

    }

    componentDidMount = () => {

        // Get data lists
        this.props.GetInstallerMaterialType();        
        this.props.GetInstallerServiceType();        
        this.props.GetInstallerPropertyType();       
        this.props.GetInstallerRadiusType();        
        this.props.GetInstallerMinimumbudgetType();        

        // Get company data if it exists
        this.props.GetInstallerWorkPropertyTypes(this.state.CompanyId);
        this.props.GetInstallerWorkServices(this.state.CompanyId);
        this.props.GetInstallerWorkMaterials(this.state.CompanyId);
        this.props.GetInstallerOpeningHours(this.state.CompanyId);
        this.props.GetInstallerWorkData(this.state.CompanyId);

        this.props.ClearApplicationError();
        this.props.ClearApplicationInformation();
    }   

    submitForm = (e) => {
        e.preventDefault();

        let SelectedDays = this.props.SelectedDays;
        let OpeningHours = [];
        
        for (let i = 0; i < this.state.DaysOfWeek.length; i++) {

            let CurrentDay = SelectedDays.find(x => x.DayOfWeek === this.state.DaysOfWeek[i].DayId);

            if (CurrentDay === undefined) {
                OpeningHours.push({
                    __table__: "OpeningHours",
                    OT_CompanyId: this.state.CompanyId,
                    OT_DayOfWeek: this.state.DaysOfWeek[i].DayId,
                    OT_OpenTime: null,
                    OT_CloseTime: null,
                    OT_NotOpen: true
                });

            }
            else {

                OpeningHours.push({
                    __table__: "OpeningHours",
                    OT_Id: CurrentDay.Id,
                    OT_CompanyId: this.state.CompanyId,
                    OT_DayOfWeek: CurrentDay.DayOfWeek,
                    OT_OpenTime: CurrentDay.OpenTime,
                    OT_CloseTime: CurrentDay.CloseTime,
                    OT_NotOpen: CurrentDay.NotOpen
                });

            }            
        }

        let SelectedMaterial = this.props.SelectedMaterial;
        let Material = [];

        for (let i = 0; i < this.props.MaterialType.length; i++) {

            let MaterialExists = SelectedMaterial.find(x => x.MaterialId === this.props.MaterialType[i].ID);

            if (MaterialExists === undefined) {

                Material.push({
                    __table__: "WorkMaterial",
                    WM_CompanyId: this.state.CompanyId,
                    WM_MaterialId: this.props.MaterialType[i].ID,
                    WM_Deleted: true
                });
            }
            else
            {
                Material.push({
                    __table__: "WorkMaterial",
                    WM_Id: MaterialExists.Id,
                    WM_CompanyId: this.state.CompanyId,
                    WM_MaterialId: MaterialExists.MaterialId,
                    WM_Deleted: MaterialExists.Deleted
                });
            }
        }

        console.log('Materials Selected', Material);

        let SelectedService = this.props.SelectedService;      
        let Service = [];

        for (let i = 0; i < this.props.ServiceType.length; i++) {

            let ServiceExists = SelectedService.find(x => x.ServiceId === this.props.ServiceType[i].ID);

            if (ServiceExists === undefined) {

                Service.push({
                    __table__: "WorkService",
                    WS_CompanyId: this.state.CompanyId,
                    WS_ServiceId: this.props.ServiceType[i].ID,
                    WS_Deleted: true
                });
            }
            else {
                Service.push({
                    __table__: "WorkService",
                    WS_Id: ServiceExists.Id,
                    WS_CompanyId: this.state.CompanyId,
                    WS_ServiceId: ServiceExists.ServiceId,
                    WS_Deleted: ServiceExists.Deleted
                });
            }            
        }




        let SelectedPropertyType = this.props.SelectedPropertyType;
        let PropertyType = [];

        for (let i = 0; i < this.props.PropertyType.length; i++) {

            let PropertyTypeExists = SelectedPropertyType.find(x => x.PropertyTypeId === this.props.PropertyType[i].ID);

            if (PropertyTypeExists === undefined) {

                PropertyType.push({
                    __table__: "WorkPropertyType",
                    WPT_CompanyId: this.state.CompanyId,
                    WPT_PropertyTypeId: this.props.PropertyType[i].ID,
                    WPT_Deleted: true
                });
            }
            else {
                PropertyType.push({
                    __table__: "WorkPropertyType",
                    WPT_Id: PropertyTypeExists.Id,
                    WPT_CompanyId: this.state.CompanyId,
                    WPT_PropertyTypeId: PropertyTypeExists.PropertyTypeId,
                    WPT_Deleted: PropertyTypeExists.Deleted
                });
            }
        }

        let datavalues;

        if (this.props.InstallerWorkDataId === -1) {
            datavalues =
            {
                __table__: "Work",
                W_CompanyId: this.state.CompanyId,

                W_Description: this.props.CompanyDescription,
                W_IsManufacturer: this.props.IsManufacturer,
                W_LocalAuthorities: this.props.LocalAuthorities,
                W_HasShowroom: this.props.HasShowroom,
                W_OfferFinance: this.props.OfferFinance,
                W_CallOut24Hours: this.props.CallOut24Hours,
                W_ContactEmail: this.props.ContactEmail,
                W_AdditionalEmail: this.props.AdditionalContactEmail,
                W_RadiusId: this.props.SelectedWorkRadius,
                W_MinimumBudgetId: this.props.SelectedMinimumHours,


                OpeningHours_OT_Wid_collection: OpeningHours,
                WorkMaterial_WM_Wid_collection: Material,
                WorkService_WS_Wid_collection: Service,
                WorkPropertyType_WPT_Wid_collection: PropertyType

            };
        }
        else {
            datavalues =
            {
                __table__: "Work",
                W_Id: this.props.InstallerWorkDataId,
                W_CompanyId: this.state.CompanyId,

                W_Description: this.props.CompanyDescription,
                W_IsManufacturer: this.props.IsManufacturer,
                W_LocalAuthorities: this.props.LocalAuthorities,
                W_HasShowroom: this.props.HasShowroom,
                W_OfferFinance: this.props.OfferFinance,
                W_CallOut24Hours: this.props.CallOut24Hours,
                W_ContactEmail: this.props.ContactEmail,
                W_AdditionalEmail: this.props.AdditionalContactEmail,
                W_RadiusId: this.props.SelectedWorkRadius,
                W_MinimumBudgetId: this.props.SelectedMinimumHours,


                OpeningHours_OT_Wid_collection: OpeningHours,
                WorkMaterial_WM_Wid_collection: Material,
                WorkService_WS_Wid_collection: Service,
                WorkPropertyType_WPT_Wid_collection: PropertyType

            };
        }
        

        this.props.InsertInstallerWorkData(datavalues);

        console.log("submitForm - ", this.props.Result);

    }

    mapInternalDispatchToProps = (dispatch) => {
        return {

            InsertInstallerWorkData:
                (WorkData, URL) => {
                    dispatch(InsertInstallerWorkData(WorkData, URL));
                },

            setWorkMaterials:
                (name, value) => {
                    dispatch(setWorkMaterials(name, value));
                },

            setWorkServices:
                (name, value) => {
                    dispatch(setWorkServices(name, value));
                },

            setPropertyType:
                (name, value) => {
                    dispatch(setPropertyType(name, value));
                },

            setOpeningHours:
                (DayId, NotOpen, OpenTime, CloseTime) => {
                    dispatch(setOpeningHours(DayId, NotOpen, OpenTime, CloseTime));
                },

            setWorkData: (CompanyId, Description, IsManufacturer, LocalAuthorities, OfferFinance, HasShowroom, ContactEmail, AdditionalContactEmail, RadiusId, MinimumBudgetId, CallOut24Hours) => {
                dispatch(setWorkData(CompanyId, Description, IsManufacturer, LocalAuthorities, OfferFinance, HasShowroom, ContactEmail, AdditionalContactEmail, RadiusId, MinimumBudgetId, CallOut24Hours));
            }
        };
    };

    mapInternalStateToProps = (state) => {


        return {

            MaterialType: state.InstallerWork.MaterialType,
            ServiceType: state.InstallerWork.ServiceType,
            PropertyType: state.InstallerWork.PropertyType,
            RadiusType: state.InstallerWork.RadiusType,
            MinimumBudgetType: state.InstallerWork.MinimumBudgetType,
            Errors: state.applicationerror,
            Information: state.applicationinformation,
            Result: state.InstallerWork.Result,
            DaysOfWeek: this.state.DaysOfWeek,
            
            SelectedMaterial: state.InstallerWork.InstallerWorkMaterials,           //this.state.SelectedMaterial,
            SelectedService: state.InstallerWork.InstallerWorkServices,             //this.state.SelectedService,
            SelectedPropertyType: state.InstallerWork.InstallerWorkPropertyTypes,   //this.state.SelectedPropertyType,
           
            SelectedDays: state.InstallerWork.InstallerOpeningHours.length === 0 ? [] : state.InstallerWork.InstallerOpeningHours,                //this.state.SelectedDays,
            submitForm: this.submitForm,

            InstallerWorkDataId: state.InstallerWork.InstallerWorkData === null ? -1 : state.InstallerWork.InstallerWorkData.Id,

            CompanyDescription: state.InstallerWork.InstallerWorkData === null ? '' : state.InstallerWork.InstallerWorkData.Description,
            ContactEmail: state.InstallerWork.InstallerWorkData === null ? '' : state.InstallerWork.InstallerWorkData.ContactEmail,
            AdditionalContactEmail: state.InstallerWork.InstallerWorkData === null ? '' : state.InstallerWork.InstallerWorkData.AdditionalEmail,            
            SelectedWorkRadius: state.InstallerWork.InstallerWorkData === null ? -1 : state.InstallerWork.InstallerWorkData.RadiusId,
            SelectedMinimumHours: state.InstallerWork.InstallerWorkData === null ? -1 : state.InstallerWork.InstallerWorkData.MinimumBudgetId,
            IsManufacturer: state.InstallerWork.InstallerWorkData === null ? '' : state.InstallerWork.InstallerWorkData.IsManufacturer,
            LocalAuthorities: state.InstallerWork.InstallerWorkData === null ? '' : state.InstallerWork.InstallerWorkData.LocalAuthorities,
            HasShowroom: state.InstallerWork.InstallerWorkData === null ? '' : state.InstallerWork.InstallerWorkData.HasShowroom,
            OfferFinance: state.InstallerWork.InstallerWorkData === null ? '' : state.InstallerWork.InstallerWorkData.OfferFinance,
            CallOut24Hours: state.InstallerWork.InstallerWorkData === null ? '' : state.InstallerWork.InstallerWorkData.CallOut24Hours,

            CompanyId : this.state.CompanyId

        };
    };

    render() {

        let Information = connect(this.mapInternalStateToProps, this.mapInternalDispatchToProps)(InformationView);

        console.log('Information Render....');

        return (
            <div>               
                <div className="h2 h2-responsive">
                    Lead Generation Data

                    <PleaseWait IsLoading={this.state.loading} float={'right'} />        

                </div>

                <Information />
                
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        GetInstallerMaterialType:
            () => {
                dispatch(GetInstallerMaterialType());
            },
        GetInstallerServiceType:
            () => {
                dispatch(GetInstallerServiceType());
            },
        GetInstallerPropertyType:
            () => {
                dispatch(GetInstallerPropertyType());
            },
        GetInstallerRadiusType:
            () => {
                dispatch(GetInstallerRadiusType());
            },
        GetInstallerMinimumbudgetType:
            () => {
                dispatch(GetInstallerMinimumbudgetType());
            },

        //InsertInstallerWorkData:
        //    ( WorkData, URL) => {
        //        dispatch(InsertInstallerWorkData(WorkData, URL));
        //    },

        // Fetch Installers actual data
        GetInstallerWorkPropertyTypes:
            (CompanyId, URL) => {
                dispatch(GetInstallerWorkPropertyTypes(CompanyId, URL));
            },
        GetInstallerWorkServices:
            (CompanyId, URL) => {
                dispatch(GetInstallerWorkServices(CompanyId, URL));
            },
        GetInstallerWorkMaterials:
            (CompanyId, URL) => {
                dispatch(GetInstallerWorkMaterials(CompanyId, URL));
            },
        GetInstallerOpeningHours:
            (CompanyId, URL) => {
                dispatch(GetInstallerOpeningHours(CompanyId, URL));
            },
        GetInstallerWorkData:
            (CompanyId, URL) => {
                dispatch(GetInstallerWorkData(CompanyId, URL));
            },
        ClearApplicationError:
            () => {
                dispatch(ClearApplicationError());
            },
        ClearApplicationInformation:
            () => {
                dispatch(ClearApplicationInformation());
            }

    };
};


const mapStateToProps = (state) => {
    return {
        //MaterialType: state.InstallerWork.MaterialType,
        //ServiceType: state.InstallerWork.ServiceType,
        //PropertyType: state.InstallerWork.PropertyType,
        //RadiusType: state.InstallerWork.RadiusType,
        //MinimumBudgetType: state.InstallerWork.MinimumBudgetType,
        ////DaysOfWeek: [],
        ////OtherInformation: [],        
        Errors: state.applicationerror,
        Information: state.applicationinformation,

        //SelectedMaterial: state.InstallerWork.InstallerWorkMaterials,           
        //SelectedService: state.InstallerWork.InstallerWorkServices,             
        //SelectedPropertyType: state.InstallerWork.InstallerWorkPropertyTypes,

        //SelectedDays: state.InstallerWork.InstallerOpeningHours.length === 0 ? [] : state.InstallerWork.InstallerOpeningHours,                //this.state.SelectedDays,

        //InstallerWorkDataId: state.InstallerWork.InstallerWorkData === null ? -1 : state.InstallerWork.InstallerWorkData.Id,

        //CompanyDescription: state.InstallerWork.InstallerWorkData === null ? '' : state.InstallerWork.InstallerWorkData.Description,
        //ContactEmail: state.InstallerWork.InstallerWorkData === null ? '' : state.InstallerWork.InstallerWorkData.ContactEmail,
        //AdditionalContactEmail: state.InstallerWork.InstallerWorkData === null ? '' : state.InstallerWork.InstallerWorkData.AdditionalEmail,
        //SelectedWorkRadius: state.InstallerWork.InstallerWorkData === null ? -1 : state.InstallerWork.InstallerWorkData.RadiusId,
        //SelectedMinimumHours: state.InstallerWork.InstallerWorkData === null ? -1 : state.InstallerWork.InstallerWorkData.MinimumBudgetId,
        //IsManufacturer: state.InstallerWork.InstallerWorkData === null ? '' : state.InstallerWork.InstallerWorkData.IsManufacturer,
        //LocalAuthorities: state.InstallerWork.InstallerWorkData === null ? '' : state.InstallerWork.InstallerWorkData.LocalAuthorities,
        //HasShowroom: state.InstallerWork.InstallerWorkData === null ? '' : state.InstallerWork.InstallerWorkData.HasShowroom,
        //OfferFinance: state.InstallerWork.InstallerWorkData === null ? '' : state.InstallerWork.InstallerWorkData.OfferFinance,
        //CallOut24Hours: state.InstallerWork.InstallerWorkData === null ? '' : state.InstallerWork.InstallerWorkData.CallOut24Hours,

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyLeadsInformation);