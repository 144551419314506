
import React, { Component, Fragment } from 'react';
import uuid from "uuid";

import AuthHelper from '../SharedComponents/AuthHelper';
import { CardBodyOnly } from '../SharedComponents/CardBodyOnly';
import { NotificationPanel } from '../SharedComponents/NotificationPanel';
import { PleaseWait } from '../SharedComponents/PleaseWait';

const debugOn = process.env.REACT_APP_DEBUG_MODE_ON;

export class LatestNotice extends Component {

    displayName = LatestNotice.name;

    constructor(props) {
        super(props);

        const auth = new AuthHelper();
        this.state = {
            //...props,
            CompanyId: auth.CompanyId,
            LatestNotice: [],
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide'
        };
    }

    componentWillMount() {
        this.fetchData();
    }

    renderTable = (payload) => {
        return (
            <Fragment>
                {
                    payload ?

                        payload.map((item, index) =>
                            (
                                <div className="py-3" key={index}>
                                    <CardBodyOnly>
                                        <h2 className="h2-responsive">Latest Notices</h2>

                                        <div className="text-center pt-3"><strong>{item.subject}</strong></div>
                                        <div dangerouslySetInnerHTML={{ __html: item.Content }} />
                                    </CardBodyOnly>
                                </div>

                                )
                            )
                        :
                        ''
                }
            </Fragment>
        );
    }

    
    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    fetchData = () => {

        var MemberDeclarationData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetInstallerLatestNotices",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                CompanyId: this.state.CompanyId
            })
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: MemberDeclarationData
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                const declarationData = JSON.parse(data.ReturnData.Payload);
                //console.log(this.displayName + 'fetchData[data]', data);
                //console.log(this.displayName + 'fetchData[declarationData]', declarationData);
                this.setState({
                    LatestNotice: declarationData,
                    loading: false
                });
            })
            .catch(data => {
                if (debugOn) {
                    console.log(data);
                }
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - No Data or service not available']
                });
            });
    };

    render() {
        let contents = this.state.loading
            ? <PleaseWait IsLoading={true} />
            : this.renderTable(this.state.LatestNotice);

        return (
            <div className='fluid-container'>
                <div>
                    <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                    {contents}
                </div>
            </div>
        );
    }
}
