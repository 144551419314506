
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import uuid from "uuid";
import ReactGA from 'react-ga';
import AuthHelper from '../SharedComponents/AuthHelper';
import { AddressCardBody } from './../SharedComponents/AddressCardBody';
import { ColumnAndLabelRadioOptions } from './../SharedComponents/ColumnAndLabelRadioOptions';
//import { ColumnAndLabelSelect } from './../SharedComponents/ColumnAndLabelSelect';
import { DocumentView } from '../SharedComponents/DocumentView';
import { ImageView } from '../SharedComponents/ImageView';
import { PleaseWait } from './../SharedComponents/PleaseWait';
import { ReadonlyItem } from './../SharedComponents/ReadonlyItem';

import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer } from 'mdbreact';


const OperativesHomeUrl = "/Operatives/OurOperatives";

export class OperativeView extends Component {

    displayName = "OperativeView";
    constructor(props) {
        super(props);
        const auth = new AuthHelper();
        const deleting = this.getViewingPref(props);
        //const newOperative = props.match.params.id === '-1';
        //console.log('newOperative ', newOperative);
        this.state = {
            Address1: '',
            Address2: '',
            Address3: '',
            CanEdit: auth.CanEditData,
            CityTown: '',
            CompanyId: auth.CompanyId,
            CompanyReference: auth.CompanyReference,
            Competency: '',
            CompetencyType: [
                { ID: -1, Name: 'Select Option...' },
                { ID: 0, Name: 'Competancy' },
                { ID: 1, Name: 'Qualification' }
            ],
            County: '',
            DateOfBirth: '',
            DocumentDescription: '',
            Email: '',
            FileProcessing: '',
            FirstName: '',
            LastName: '',
            MtcPreRegistrationDetailId: '',
            NINumber: '',
            OperativeDocuments: [],
            OperativeEmployerType: [],
            OperativeEmployerTypeValue: '',
            OperativeId: props.match.params.id,
            OperativePhoto: '',
            OperativePhotos: [],
            OperativeStatusType: [],
            OperativeType: [],
            OperativeTypeValue: '',
            OperativeVerified: true,
            PersonId: -1,
            Postcode: '',
            SearchPostcode: '',
            Status: '',
            Telephone: '',
            Title: '',
            UploadErrors: [],
            UploadFile: '',
            UserId: auth.UserId,
            UserName: auth.Username,
            VerifiedDate: '',

            documentContent: '',
            formValid: true,
            isNewOperative: props.match.params.id === '-1' ? true : false,
            loading: true,
            loadingPhotos: true,
            loadingDocs: true,
            pageTitle: deleting ? 'Delete Operative' : 'View Operative',
            photoCarouselImages: [],
            photoCarouselContent: (<PleaseWait IsLoading={true} />),
            photoCarouselImageCount: 0
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    currentPageTitle = () => {
        return this.state.pageTitle;
    }

    getViewingPref = data => {
        const pathName = data.match.path;
        return (pathName !== undefined && pathName.toString().toLowerCase().indexOf('delete') > 0);
    }

    handleAddressNotFound = msg => {
        //show the message if not found
        //console.log(msg);
        this.setState({
            loading: false,
            notificationPostfix: 'danger show',
            notificationMessage: msg
        });
    }

    handleAddressSelected = address => {
        this.setState({
            Address1: address.Address1,
            Address2: address.Address2,
            Address3: address.Address3,
            CityTown: address.CityTown,
            County: address.County,
            Postcode: address.Postcode,
            hideAddress: false,
            notificationPostfix: 'info hide',
            notificationMessage: ''
        });
    }

    handlePostcodeChange = event => {
        const target = event.target;
        var value = target.value;

        if (value === undefined) {
            value = target.parentElement.value;
        }

        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            //Postcode: value,
            SearchPostcode: value
        });
    }

    handleLookupClick = status => {
        //if (debugOn) {
        //    console.log(this.displayName + '.handleLookupClick[start]', status);
        //}
        this.togglePleaseWait(status);
        //clear errors
    }

    togglePleaseWait = status => {
        this.setState({
            loading: status
        });
    }

    fetchPhotos = () => {
        // Now fetch any photos / documents associated with the operative
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.HelixAdmin.GetInstaller_MTC_Person_Photo",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyReference: this.state.CompanyReference,
                    OperativeId: this.state.OperativeId,
                    MtcPreRegistrationDetailId: this.state.MtcPreRegistrationDetailId
                }),
                validate: false
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                const photoData = data.ReturnData.Payload === null ? [] : JSON.parse(data.ReturnData.Payload);
                this.setState({
                    OperativePhotos: photoData,
                    loadingPhotos: false,
                    photoCarouselImageCount: photoData.length
                });

                //// load each image now
                //const companyRef = this.state.CompanyReference;
                //const operativeId = this.state.OperativeId;
                //let photos = this.state.photoCarouselImages;
                //photoData.map(photo => {
                //    //console.log('photo.ContentIdentifier', photo.ContentIdentifier);
                //    // load the image
                //    fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/DownloadImage', {
                //        method: 'POST',
                //        headers: {
                //            'Accept': 'application/json',
                //            'Content-Type': 'application/json'
                //        },
                //        body: JSON.stringify({
                //            seqno: uuid.v4(),
                //            datatype: "BIT.Fensa.InstallerPortal.MongoDB.Photo",
                //            origin: "Installer Portal",
                //            operation: "Search",
                //            datavalues: JSON.stringify({
                //                CompanyId: companyRef,
                //                OperativeId: operativeId,
                //                Identifier: photo.ContentIdentifier
                //            })
                //        })
                //    })
                //        .then(response => response.json())
                //        .then(data => JSON.parse(data))
                //        .then(data => {
                //            photos.push({ ...photo, imageData: data.message });
                //            //all loaded?
                //            if (photos.length === photoData.length) {
                //                this.setState({
                //                    loading: false,
                //                    photoCarouselImageCount: photos.length,
                //                    photoCarouselImages: photos,
                //                    photoCarouselContent: this.renderPhotoCarousel(photos)
                //                });
                //                console.log('fetchPhotos.state: ', this.state);
                //            } else {
                //                this.setState({
                //                    loading: false,
                //                    photoCarouselImageCount: photos.length,
                //                    photoCarouselImages: photos
                //                });
                //            }
                //        });
                //});
            });
    }

    fetchDocuments = () => {
        // Now fetch any photos / documents associated with the operative
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.HelixAdmin.GetInstaller_MTC_Person_Document",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: JSON.stringify({
                    CompanyId: this.state.CompanyReference,
                    OperativeId: this.state.OperativeId,
                    MtcPreRegistrationDetailId: this.state.MtcPreRegistrationDetailId
                }),
                validate: false
            })
        })
            .then(response => response.json())
            .then(data => JSON.parse(data))
            .then(data => {
                this.setState({
                    OperativeDocuments: data.ReturnData.Payload === null ? [] : JSON.parse(data.ReturnData.Payload),
                    loadingDocs: false
                });
            });
    }

    fetchData = () => {

        var datavalues = JSON.stringify({
            CompanyId: this.state.CompanyReference,
            OperativeId: this.state.OperativeId,
            Identifier: '5cc8362d6ad7d07a94456019'
        });

        var fetchOperativeItem = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.HelixAdmin.GetInstaller_MTC_OperativeItem",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: datavalues
            })
        });

        var fetchOperativeType = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.HelixAdmin.GetInstaller_MTC_Type",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: datavalues
            })
        });

        var fetchOperativeEmployerType = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.InstallerOperativeEmployerType",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: datavalues
            })
        });

        var fetchOperativeStatusType = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.HelixAdmin.GetInstaller_MTC_RegistrationStatus",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: datavalues
            })
        });

        var fetchCompetancyType = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.HelixAdmin.GetInstaller_MTC_Route",
                origin: "Installer Portal",
                operation: "Search",
                datavalues: datavalues
            })
        });

        Promise.all([fetchOperativeType, fetchOperativeEmployerType, fetchOperativeStatusType, fetchOperativeItem, fetchCompetancyType])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                console.log(this.displayName + '.fetchData', responses);
                const operativeTypeData = JSON.parse(JSON.parse(responses[0]).ReturnData.Payload);
                const operativeEmployerTypeData = JSON.parse(JSON.parse(responses[1]).ReturnData.Payload);
                const operativeStatusTypeData = JSON.parse(JSON.parse(responses[2]).ReturnData.Payload);
                let operativeItemData = JSON.parse(JSON.parse(responses[3]).ReturnData.Payload);
                if (operativeItemData && operativeItemData.length > 0) {
                    operativeItemData = operativeItemData[0];
                }
                const competencyTypeData = JSON.parse(JSON.parse(responses[4]).ReturnData.Payload);

                //console.log(this.displayName + '.operativeTypeData', operativeTypeData);
                //console.log(this.displayName + '.operativeEmployerTypeData', operativeEmployerTypeData);
                //console.log(this.displayName + '.operativeStatusTypeData', operativeStatusTypeData);
                //console.log(this.displayName + '.operativeItemData', operativeItemData);
                //console.log(this.displayName + '.competencyTypeData', competencyTypeData);

                //extract text value from arrays
                let operativeItemDataTypeValueText = 'Not Set';
                if (operativeItemData !== null && operativeTypeData !== null) {
                    const filter = operativeTypeData.find((operativeType) => operativeType.ID === operativeItemData.CardTypeID);
                    operativeItemDataTypeValueText = filter ? filter.TypeDescription : 'Not Set';
                }

                let operativeItemDataEmployerTypeValueText = 'Not Set';
                if (operativeItemData !== null && operativeEmployerTypeData !== null) {
                    const filter = operativeEmployerTypeData.find((employerType) => employerType.ID === operativeItemData.OperativeEmployerTypeId);
                    operativeItemDataEmployerTypeValueText = filter ? filter.Name : 'Not Set';
                }

                let operativeStatusTypeText = 'Not Set';
                if (operativeItemData !== null && operativeStatusTypeData !== null) {
                    const filter = operativeStatusTypeData.find((statusType) => statusType.ID === operativeItemData.RegistrationStatusId);
                    operativeStatusTypeText = filter ? filter.StatusName : 'Not Set';
                }

                let competencyTypeText = 'Not Set';
                if (operativeItemData !== null && competencyTypeData !== null) {
                    const filter = competencyTypeData.find((competencyType) => competencyType.ID === operativeItemData.RouteID);
                    competencyTypeText = filter ? filter.MtcRouteDescription : 'Not Set';
                }

                this.setState({
                    Address1: operativeItemData === null ? '' : operativeItemData.AddressBuildingOrNumber,
                    Address2: operativeItemData === null ? '' : operativeItemData.AddressLine1,
                    Address3: operativeItemData === null ? '' : operativeItemData.AddressLine2,
                    CityTown: operativeItemData === null ? '' : operativeItemData.City,
                    Competency: operativeItemData === null ? '' : operativeItemData.RouteID,
                    CompetencyType: competencyTypeData,
                    CompetencyTypeText: competencyTypeText,
                    County: operativeItemData === null ? '' : operativeItemData.CountyTown,
                    DateOfBirth: operativeItemData === null ? '' : operativeItemData.DateOfBirth,
                    Email: operativeItemData === null ? '' : operativeItemData.EmailAddress,
                    FirstName: operativeItemData === null ? '' : operativeItemData.FirstName,
                    LastName: operativeItemData === null ? '' : operativeItemData.LastName,
                    MtcPreRegistrationDetailId: operativeItemData === null ? '' : operativeItemData.MtcPreRegistrationDetailId,
                    NINumber: operativeItemData === null ? '' : operativeItemData.NationalInsuranceNo,
                    OperativeEmployerType: operativeEmployerTypeData,
                    OperativeEmployerTypeValue: operativeItemData === null ? '' : operativeItemData.OperativeEmployerTypeId,
                    OperativeEmployerTypeValueText: operativeItemDataEmployerTypeValueText,
                    OperativeStatusType: operativeStatusTypeData,
                    OperativeStatusTypeText: operativeStatusTypeText,
                    OperativeType: operativeTypeData,
                    OperativeTypeValue: operativeItemData === null ? '' : operativeItemData.CardTypeID,
                    OperativeTypeValueText: operativeItemDataTypeValueText,
                    OperativeVerified: operativeItemData === null ? '' : operativeItemData.VerifiedByFensa,
                    PersonId: operativeItemData === null ? '' : operativeItemData.PersonId,
                    Postcode: operativeItemData === null ? '' : operativeItemData.Postcode,
                    SearchPostcode: operativeItemData === null ? '' : operativeItemData.Postcode,
                    Status: operativeItemData === null ? '' : operativeItemData.RegistrationStatusId,
                    Telephone: operativeItemData === null ? '' : operativeItemData.PhoneNumber,
                    Title: operativeItemData === null ? '' : operativeItemData.Salutation,
                    VerifiedDate: operativeItemData === null || !operativeItemData.VerifiedDate ? '' : operativeItemData.VerifiedDate,
                    loading: false
                });
                // Now fetch any photos / documents associated with the operative
                this.fetchPhotos();
                this.fetchDocuments();

                //var ddddd = operativeTypeData.find((operativeType) => operativeType.ID === operativeItemData.CardTypeID);
                //console.log('ddddd', ddddd);
                console.log(this.displayName + '.fetchData[state]', this.state);
            });
    }

    OnFileUpload(e) {
        this.setState({ UploadFile: e.target.files[0] });
    }

    //renderPhotoCarousel = (photos) => {
    //    console.log('renderPhotoCarousel.photos', photos);
    //    return (
    //        <MDBContainer>
    //            <MDBCarousel
    //                activeItem={1}
    //                length={photos.length}
    //                showControls={true}
    //                showIndicators={true}
    //                className="z-depth-1"
    //            >
    //                <MDBCarouselInner>
    //                    {
    //                        photos.map(photo =>
    //                            (
    //                                <MDBCarouselItem itemId={photo.MTC_Person_Photo_Id}>
    //                                    <MDBView>
    //                                        <img className="d-block w-100"
    //                                            src={`data:${photo.ContentType};base64,${photo.imageData}`}
    //                                            alt={photo.FileName}
    //                                            id={photo.ContentIdentifier}
    //                                        />
    //                                    </MDBView>
    //                                </MDBCarouselItem>
    //                            )
    //                        )
    //                    }
    //                </MDBCarouselInner>
    //            </MDBCarousel>
    //        </MDBContainer>
    //    );
    //}

    renderPhotoCarousel = (photos) => {
        console.log('renderPhotoCarousel.photos', photos);
        return (
            photos.map(photo =>
                (
                    <MDBCarouselItem itemId={photo.MTC_Person_Photo_Id}>
                        <MDBView>
                            <img className="d-block w-50"
                                src={`data:${photo.ContentType};base64,${photo.imageData}`}
                                alt={photo.FileName}
                                id={photo.ContentIdentifier}
                            />
                        </MDBView>
                    </MDBCarouselItem>
                )
            )
        );
    }

    getCarouselImages = () => {
        return this.state.photoCarouselContent;
    }

    render() {

        ReactGA.event({
            category: 'Operative View',
            action: 'Operative View'
        });

        return (
            <div>
                <div>
                    <form className='demoForm'>
                        <div className='form-group-container mr-auto ml-auto'>
                            <div className='form-group-title'>{this.currentPageTitle()}</div>
                            <div className='row py-3'>
                                <div className='col'>                                    
                                    <Link className='btn btn-sm btn-dark pull-right mr-2 mdb-btn-resize' to={OperativesHomeUrl}>
                                        <i className='fa fa-arrow-left pr-2' />Back to Operatives</Link>
                                </div>
                            </div>
                            <PleaseWait IsLoading={this.state.loading} />
                            <div className='row'>
                               {/*  <div className='col-xs-12 col-md-2'>
                                    <ReadonlyItem textLabel="Person Id">{this.state.PersonId}</ReadonlyItem>
                                </div>
                                <div className='col-xs-12 col-md-4'>
                                    <ReadonlyItem textLabel="NI Number">{this.state.NINumber}</ReadonlyItem>
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                    <ReadonlyItem textLabel="Date Of Birth">{moment(this.state.DateOfBirth).format('DD MMM YYYY')}</ReadonlyItem>
                                </div> */}
                                <div className='col-xs-12 col-md-6'>
                                    <ReadonlyItem textLabel="First Name">{this.state.FirstName}</ReadonlyItem>
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                    <ReadonlyItem textLabel="Last Name">{this.state.LastName}</ReadonlyItem>
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                    <ReadonlyItem textLabel="Email">{this.state.Email}</ReadonlyItem>
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                    <ReadonlyItem textLabel="Telephone">{this.state.Telephone}</ReadonlyItem>
                                </div>
                                <div className='col-xs-12 col-md-6'>                                   
                                    <ReadonlyItem textLabel="Occupation Type">{this.state.OperativeTypeValueText}</ReadonlyItem>
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                   
                                    <ReadonlyItem textLabel="Operative Employer Type">{this.state.OperativeEmployerTypeValueText}</ReadonlyItem>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12 col-md-6'>
                                   
                                    <ReadonlyItem textLabel="Status">{this.state.OperativeStatusTypeText}</ReadonlyItem>
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                  
                                    <ReadonlyItem textLabel="Competency / Qualification">{this.state.CompetencyTypeText}</ReadonlyItem>
                                </div>
                            </div>

                            <div className='row pb-1 pt-3' hidden={this.state.hideAddress}>
                                <div className='col-12 text-muted'>
                                    <AddressCardBody
                                        AddressLabel={"Address"}
                                        Address1={this.state.Address1}
                                        Address2={this.state.Address2}
                                        Address3={this.state.Address3}
                                        CityTown={this.state.CityTown}
                                        County={this.state.County}
                                        Postcode={this.state.Postcode}
                                    />
                                </div>
                            </div>
                            <div className='row py-3'>
                                <div className='col-xs-12 col-md-2' />
                                <div className='col-xs-12 col-md-4 py-4'>
                                    <ColumnAndLabelRadioOptions
                                        id="OperativeVerified"
                                        name="OperativeVerified"
                                        textLabel="Verified By Fensa"
                                        optionNo={'No'}
                                        optionYes={'Yes'}
                                        value={this.state.OperativeVerified}
                                        handleChange={this.handleInputChange}
                                        readOnly="true" />
                                </div>
                                <div className='col-xs-12 col-md-4 py-0'>
                                    <ReadonlyItem textLabel="Verified Date">{!this.state.VerifiedDate ? 'Pending' : moment(this.state.VerifiedDate).format('DD MMM YYYY')}</ReadonlyItem>
                                </div>
                            </div>
                            <div className=''>
                                <div className='pt-3'>
                                    <h3>Photos</h3>
                                    <PleaseWait IsLoading={this.state.loadingPhotos} />
                                    {
                                        this.state.OperativePhotos.map((UploadResult) =>
                                            (
                                                <ImageView Identifier={UploadResult.ContentIdentifier}
                                                    id={UploadResult.MTC_Person_Photo_Id}
                                                    FileName={UploadResult.FileName}
                                                    OperativeId={this.state.OperativeId}
                                                    ContentType={UploadResult.ContentType}
                                                />
                                            )
                                        )
                                    }
                                </div>
                                <div className='pt-4'>
                                    <h3 className='pb-3'>Evidence</h3>
                                    <PleaseWait IsLoading={this.state.loadingDocs} />
                                    {
                                        this.state.OperativeDocuments.map((UploadResult) =>
                                            (
                                                <DocumentView
                                                    Identifier={UploadResult.ContentIdentifier}
                                                    id={UploadResult.MTC_Person_Document_Id}
                                                    FileName={UploadResult.FileName}
                                                    OperativeId={this.state.OperativeId}
                                                    DocumentDescription={UploadResult.ContentDescription}
                                                    ContentType={UploadResult.ContentType}
                                                />
                                            )
                                        )
                                    }
                                </div>
                            </div>
                            <div className='row py-3'>
                                <div className='col'>
                                    <Link className={this.state.CanEdit ? 'btn btn-sm btn-primary pull-right mdb-btn-resize' : 'hide'} to={'/Operatives/OurOperatives/Update/' + this.state.OperativeId}>
                                        <i className="fa fa-edit pr-2" />Edit</Link>
                                    <Link className='btn btn-sm btn-dark pull-right mr-2 mdb-btn-resize' to={OperativesHomeUrl}>
                                        <i className='fa fa-arrow-left pr-2' />Back to Operatives</Link>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
