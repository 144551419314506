import React, { Component } from 'react';
import { MenuItems } from '../SharedComponents/MenuItems';

import ReactGA from 'react-ga';

export class Inspections extends Component {
    displayName = Inspections.name

    render() {

        ReactGA.event({
            category: 'Assessments',
            action: 'Assessments Home'
        });


        return (
            <MenuItems ParentName="Assessments" />
        );
    }
}
