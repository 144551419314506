export const SET_APPLICATION_ERROR = "SET_APPLICATION_ERROR";
export const CLEAR_APPLICATION_ERROR = "CLEAR_APPLICATION_ERROR";

export const setApplicationError = (Url, Value) => ({
    type: SET_APPLICATION_ERROR,
    Url,
    Value
});

export const ClearApplicationError = (Url, Value) => ({
    type: CLEAR_APPLICATION_ERROR,
    Url,
    Value
});





