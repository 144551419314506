import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import Moment from 'react-moment';
import uuid from "uuid";
import { CSVLink } from "react-csv";

import ReactGA from 'react-ga';

import 'react-datepicker/dist/react-datepicker.css';

import AuthHelper from '../SharedComponents/AuthHelper';
import { CardBodyOnly } from './../SharedComponents/CardBodyOnly';
import { CardFooterButtonsIcons } from './../SharedComponents/CardFooterButtonsIcons';
import { InspectionSearch } from './InspectionSearch';
import { PleaseWait } from './../SharedComponents/PleaseWait';
import { ReactTableFilterToggler } from './../SharedComponents/ReactTableFilterToggler';

import Badge from "@material-ui/core/Badge";

export class InspectionsHistory extends Component {
    displayName = "InspectionsHistory";

    constructor(props) {
        super(props);

       
        this.reactTable = React.createRef();  

        const auth = new AuthHelper(this.displayName);
        this.state = {
            CompanyId: auth.CompanyId,
            InsepectionStatusType: [],
            InspectionType: [],
            Month: null,
            MonthsArray: [
                { ID: -1, Name: 'Assessment Month' },
                { ID: 1, Name: 'January' },
                { ID: 2, Name: 'February' },
                { ID: 3, Name: 'March' },
                { ID: 4, Name: 'April' },
                { ID: 5, Name: 'May' },
                { ID: 6, Name: 'June' },
                { ID: 7, Name: 'July' },
                { ID: 8, Name: 'August' },
                { ID: 9, Name: 'September' },
                { ID: 10, Name: 'October' },
                { ID: 11, Name: 'November' },
                { ID: 12, Name: 'December' }
            ],
            Postcode: "",
            Reference: "",
            ShowEditButton: false,
            ShowDeleteButton: false,
            ShowViewButton: true,
            Status: null,
            Type: null,
            UsePaging: true,
            Year: null,
            YearArray: [],
            displayContent: (<PleaseWait IsLoading={true} />),
            installation: [],
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            page: 0,
            pageSize: 10,
            resized: [],
            sorted: [],
            totals: 'No',
            useFiltering: (auth.TableFilter && auth.TableFilter === 'On' ? true : false),
            dataToDownload: [],
           
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }

    componentDidMount = () => {
        this.fetchData();
    }

    filterCaseInsensitive = ({ id, value }, row) => {
        return row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : false;
    }

    handleSearch(event) {
        this.searchData();
        event.preventDefault();
    }

    handleClearSearch(event) {
        event.preventDefault();
        this.setState({
            Postcode: '',
            Reference: '',
            Year: "-1",
            Month: "-1",
            Status: "-1",
            Type: "-1"
        }, () => {
            this.searchData();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleToggleFilter = toggle => {
        this.setState({
            useFiltering: toggle
        });
        this.fetchData();
    }

    renderNoDataView = () => {
        return <p><em>No data to display</em></p>;
    }

    renderTable = installation => {
        return (
            <div className='py-5'>

                <div className='row'>
                    <div className='col-6'>
                    <ReactTableFilterToggler
                    ParentDisplayName={this.displayName}
                    UseFiltering={this.state.useFiltering}
                    handleToggleFilter={this.handleToggleFilter}
                />               
                
                <small className='text-muted'>When filtering by date fields use year-month-day format <code>e.g.2021-11-27</code></small>
                    </div>
                    <div className='col-6'>
                        <button className='btn btn-sm btn-success float-right ' onClick={this.download}> <i className= "fa fa-download" /> download</button>
                    </div>
                </div>

                <ReactTable data={installation}
                    columns={[
                        {
                            key: 'Id',
                            Header: 'Id',
                            accessor: 'Id',
                            show: false
                        },
                        {
                            Header: 'Reference',
                            accessor: 'InspectionReference'
                        },
                        {
                            Header: 'Installation Reference',
                            accessor: 'InstallationReference,WorkCompletionDate',
                            width: 200,
                            Cell: row => {                               
                                return (
                                    <div>
                                        <Link to={"/Installations/View/" + row.original.InstallationId}>{row.original.InstallationReference}</Link><br />
                                        <div>
                                            Installed on <Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.WorkCompletionDate}></Moment>
                                        </div>
                                    </div>
                                )
                            }
                        },
                        {
                            Header: 'Postcode',
                            accessor: 'Postcode'
                        },

                        {
                            Header: 'Completion Date', 
                            accessor: 'WorkCompletionDate',
                            Cell: row => {
                                return (
                                    <div>
                                        <Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.WorkCompletionDate}></Moment>
                                    </div>
                                )
                            }
                        },
                        {
                            Header: 'Due Assessment', 
                            accessor: 'DueInspection',
                            Cell: row => {
                                return (
                                    <div>
                                        <Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.DueInspection}></Moment>
                                    </div>
                                )
                            }
                        },
                        {
                            Header: 'Assessed On', 
                            accessor: 'InspectedOn',
                            Cell: row => {
                                return (
                                    <div>
                                        <Moment parse='YYYY-MM-DD HH:mm' format="DD MMM YYYY" date={row.original.InspectedOn}></Moment>
                                    </div>
                                )
                            }
                        },
                        {
                            Header: 'Email', 
                            accessor: 'Email'
                        },

                        {
                            Header: 'Assessment Type', 
                            accessor: 'InspectionTypeName'
                        },
                        {
                            Header: 'Assessment Status', 
                            accessor: 'InspectionStatus'
                        },
                        {
                            Header: '',
                            accessor: 'Edit',
                            filterable: false,
                            Cell: row => {
                                return (
                                    <Fragment>
                                        <div className='text-center py-1 pr-5'>
                                            <CardFooterButtonsIcons
                                                Id={row.original.Id}
                                                ViewUrl={'/Inspections/View/' + row.original.Id}
                                                ShowViewButton={this.state.ShowViewButton} 
                                                ShowEditButton={this.state.ShowEditButton} 
                                                ShowDeleteButton={this.state.ShowDeleteButton}
                                            />
                                        </div>
                                    </Fragment>
                                );
                            }
                        }

                    ]}
                    className="table-bordered table-striped table-hover table-shadowed"
                    defaultPageSize={this.state.pageSize}
                    expanded={this.state.expanded}
                    filterable={this.state.useFiltering}
                    minRows={0}
                    nextText='>'
                    previousText='<'
                    defaultFilterMethod={this.filterCaseInsensitive}
                    ref = {this.reactTable}
                />
                
            </div>
        );

    }

    searchData = () => {

        this.setState({
            loading: true
        });

        var datavalues = JSON.stringify({
            CompanyId: this.state.CompanyId,
            Reference: this.state.Reference,
            Postcode: this.state.Postcode,
            Year: this.state.Year === '-1' ? null : this.state.Year,
            Month: this.state.Month === '-1' ? null : this.state.Month,
            Type: this.state.Type === '-1' ? null : this.state.Type,
            Status: this.state.Status === '-1' ? null : this.state.Status
        });

        var Data = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerInspections",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: datavalues
        });

        var fetchSearch = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: Data
        });

        Promise.all([fetchSearch])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                const data = JSON.parse(responses[0]);
                const installationData = JSON.parse(data.ReturnData.Payload);

                this.setState({
                    installation: installationData,
                    loading: false,
                    totals: installationData.length,
                    totalsLabel: installationData.length > 1 ? ' items' : ' item',
                    displayContent: (this.state.installationData === null) ?
                        this.renderNoDataView :
                        this.renderTable(installationData)
                });
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData[catch]', data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    displayContent: ''
                });
            });
    }

    fetchData = () => {

        this.setState({
            loading: true
        });

        var datavalues = JSON.stringify({
            CompanyId: this.state.CompanyId,
            Reference: this.state.Reference,
            Postcode: this.state.Postcode,
            Year: this.state.Year === '-1' ? null : this.state.Year,
            Month: this.state.Month === '-1' ? null : this.state.Month,
            Type: this.state.Type === '-1' ? null : this.state.Type,
            Status: this.state.Status === '-1' ? null : this.state.Status
        });

        var Data = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerInspections",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: datavalues
        });

        var fetchSearch = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: Data
        });

        var InsepectionStatusType = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerInspectionStatus",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: datavalues
        });

        var fetchInsepectionStatusType = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: InsepectionStatusType
        });

        var InspectionType = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.InstallerInspectionType",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: datavalues
        });

        var fetchInspectionType = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: InspectionType
        });

        Promise.all([fetchSearch, fetchInsepectionStatusType, fetchInspectionType])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                const installationData = JSON.parse(JSON.parse(responses[0]).message);
                const insepectionStatusTypeData = JSON.parse(JSON.parse(responses[1]).message);
                const inspectionTypeData = JSON.parse(JSON.parse(responses[2]).message);

                const currentYear = new Date().getFullYear();
                const yearArrayData = [];

                for (var i = 2015; i <= currentYear; i++) {
                    yearArrayData.push({ ID: i, Name: i.toString() });
                }
                this.setState({
                    installation: installationData,
                    InsepectionStatusType: insepectionStatusTypeData,
                    InspectionType: inspectionTypeData,
                    YearArray: yearArrayData.concat({ ID: -1, Name: 'Assessment Year' }).sort(i => i.ID),
                    loading: false,
                    totals: installationData.length,
                    totalsLabel: installationData.length > 1 ? ' items' : ' item',
                    displayContent: (this.state.installationData === null) ?
                        this.renderNoDataView :
                        this.renderTable(installationData)
                });

                this.setState({
                    InsepectionStatusType: this.state.InsepectionStatusType.concat({ ID: -1, Name: 'Assessment Status' }).sort(id => id.ID),
                    InspectionType: this.state.InspectionType.concat({ ID: -1, Name: 'Assessment Type' }).sort(id => id.ID)
                });
            })
            .catch(data => {
                console.log(this.displayName + '.fetchData[catch]', data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'warning show mt-3',
                    notificationMessage: ['No Data'],
                    displayContent: ''
                });
            });
    }


    download=(event) =>{

        const currentRecords = this.reactTable.current.getResolvedState().sortedData;
        var data_to_download = this.state.installation; 
        this.setState({ dataToDownload: data_to_download }, () => {
          this.csvLink.link.click();
        });
      }

    render() {

        ReactGA.event({
            category: 'Assessment History',
            action: 'Assessment History List'
        });


        return (
            <div>
                <h2>
                    <Badge badgeContent={this.state.totals} color="primary">Assessment History</Badge>
                </h2>
                <p className='lead'>You will find all your past assessments listed here. Click on each assessment for detailed information.</p>
                <CardBodyOnly>
                    <InspectionSearch handleSearch={this.handleSearch}
                        handleChange={this.handleInputChange}
                        InspectionType={this.state.InspectionType}
                        InsepectionStatusType={this.state.InsepectionStatusType}
                        Months={this.state.MonthsArray}
                        Years={this.state.YearArray}
                        loading={this.state.loading}
                        handleClear={this.handleClearSearch}
                        DataProps={this.state}
                    />
                </CardBodyOnly>              
                {this.state.displayContent}
                
                <div>
                <CSVLink
                    data={this.state.dataToDownload}
                    filename="data.csv"
                    className="hidden"
                    ref={(r) => (this.csvLink = r)}
                    target="_blank"
                />
                </div>
                
            </div>
        );
    }
}
