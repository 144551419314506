import React, { Component } from 'react';

export class ReduxNotificationPanel extends Component {
    displayName = ReduxNotificationPanel.name;

    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
        //console.log('NotificationPanel.ctor', this.state);
    }

    componentWillReceiveProps = nextProps => {
        //console.log('NotificationPanel.componentWillReceiveProps', nextProps);
        this.setState({
            ...nextProps
        });
    }

    render() {
        return (
            <div className={'call-out alert alert-' + this.props.CssClassPostFix}>
                The following Errors have occured:
                <div>
                    {
                        this.props.NotificationMessage.map((msg, index) =>
                            (<div className='row'>
                                <div className='col-xs-5 col-md-5' >
                                    <p key={index}>{msg.DatatypeRequested}</p>
                                </div>
                                <div className='col-xs-3 col-md-3' >
                                    <p key={index}>{msg.Function}</p>
                                </div>
                                <div className='col-xs-4 col-md-4' >
                                    <p key={index}>{msg.Message}</p>

                                    {msg.Trace.map((trc) => <p>{trc}</p>)}
                                </div>
                            </div>
                                )
                        )
                    }
                </div>
                {/*If additional child elements render here...*/}
                {this.state.children}
            </div>
        );
    }
}