import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import uuid from "uuid";

import ReactGA from 'react-ga';



import AuthHelper from '../SharedComponents/AuthHelper';
import { PleaseWait } from './../SharedComponents/PleaseWait';
import { ReadonlyItem } from './../SharedComponents/ReadonlyItem';

import moment from 'moment';
import Moment from 'react-moment';
import {
    MDBBtn,
    MDBIcon
} from "mdbreact";


const contactHomeUrl = '/Notices/Home';

export class NoticesView extends Component {

    displayName = NoticesView.name

    constructor(props) {
        super(props);

        const auth = new AuthHelper();
        const deleting = this.getViewingPref(props);

        this.state = {
            CompanyId: auth.CompanyId,
            ContactEmail: '',
            NoticeId: props.match.params.id,
            NoticeContent: '',
            NoticeSubject: '',
            NoticePublishDate: '',
            NoticeExpiryDate: '',

            UserStatus: 0,
            deleteActionMessage: '',
            isDeletePage: deleting,
            loading: true,
            modal: false,
            notificationPostfix: 'info hide',
            notificationMessage: [],
            pageTitle: deleting ? 'Delete Contact' : 'View Contact Details'
        };

    }

    componentDidMount() {
        this.fetchData();
    }

    getViewingPref = data => {
        const pathName = data.match.path;
        return (pathName !== undefined && pathName.toString().toLowerCase().indexOf('delete') > 0);
    }

    isDeletePage = () => {
        return this.state.isDeletePage;
    }

    currentPageTitle = () => {
        return this.state.pageTitle;
    }

    toggleModal = toggle => {
        this.setState({
            modal: toggle
        });
    }

    togglePleaseWait = status => {
        this.setState({
            loading: status
        });
    }

 
    fetchData = () => {



        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetNoticeItem",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: JSON.stringify({
                NoticeId: this.state.NoticeId
            })
        });

        var fetchContact = fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        });

        Promise.all([fetchContact])
            .then(responses => Promise.all(
                responses.map(r => r.json())
            ))
            .then(responses => {
                const data = JSON.parse(JSON.parse(responses[0]).message)[0];
                //console.log('fetchData', data);
                this.setState({

                    NoticeContent: data.Content,
                    NoticeSubject: data.Subject,
                    NoticePublishDate: data.PublishDate,
                    NoticeExpiryDate: data.ExpiryDate,


                    UserStatus: !data.UserStatus ? 1 : 0,
                    UserStatusText: !data.UserStatus ? 'Yes' : 'No',
                    deleteActionMessage: "You are about to delete " + data.Name + ". Click Ok to continue otherwise cancel to abort.",
                    loading: false
                });
            })
            .catch(data => {
                console.log('fetchData.error', data);
                this.setState({
                    loading: false,
                    notificationPostfix: 'danger show',
                    notificationMessage: ['No Data']
                });
            });
    }

    render() {

        ReactGA.event({
            category: 'View Notices',
            action: 'Notices List'
        });


        return (
            <div>

                
                    <div className='form-group-container mr-auto ml-auto'>
                    <div className='form-group-title'>View Notice</div>
                    
                        <div className='form-group'>                        
                            <div className='row pb-3'>
                                <div className='col-xs-12 col-md-12'>
                                    <ReadonlyItem textLabel="Title">{this.state.NoticeSubject}</ReadonlyItem>
                                </div>                                
                            </div>
                        <div className='row pb-3'>
                            <div className='col-xs-12 col-md-6'>
                                <ReadonlyItem textLabel="Published On">
                                    {moment(this.state.NoticePublishDate).format('DD MMM YYYY')}
                                </ReadonlyItem>
                            </div>
                            <div className='col-xs-12 col-md-6'>
                                
                                <ReadonlyItem textLabel="Expired On">
                                    {moment(this.state.NoticeExpiryDate).format('DD MMM YYYY')}
                                </ReadonlyItem>
                            </div>
                        </div>

                            <div className='row pb-12'>
                            <div className='col-sm-12 col-md-12'>
                                <div dangerouslySetInnerHTML={{ __html: this.state.NoticeContent }} />                                   
                                </div>
                            </div>                                                        
                            <div className='row pb-2'>
                                <div className='col'>
                                    <PleaseWait IsLoading={this.state.loading} />
                                    {
                                        this.isDeletePage() ?
                                        (
                                            <MDBBtn
                                                className="pull-right mdb-btn-resize"
                                                color="danger"
                                                type="button"
                                                size="sm"
                                                onClick={this.toggleModal}
                                            >
                                                <MDBIcon icon="trash" className="mr-2" />Delete
                                                </MDBBtn>
                                        ) : null
                                    }
                                    <Link className='btn btn-sm btn-dark pull-right mr-2 mdb-btn-resize' to={contactHomeUrl}>
                                        <i className='fa fa-arrow-left pr-2' />
                                        {this.isDeletePage() ? 'Cancel' : 'Back to List'}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }
}
