import React, { Component } from 'react';
import ReactGA from 'react-ga';
import uuid from "uuid";
import Moment from 'moment';
import '../Fensa.css';





export class Webinars extends Component {

    displayName = Webinars.name

    constructor(props) {
        super(props);
        this.state = { Webinars: [], loading: true };

       

       
        var pdata = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetWebinars",
            origin: "Installer Portal",
            operation: "Search",
            datavalues: ""
        });

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: pdata
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    Webinars: JSON.parse(JSON.parse(data).ReturnData.Payload),
                    loading:false
                });
            });
    }
  
       

   


    render() {

        ReactGA.event({
            category: 'Webinars',
            action: 'Webinars Home'
        });

        let upcomingwebinars = this.state.Webinars.filter(x => new Date(x.WEB_PublishDate) >= new Date()  );
        let previouswebinars = this.state.Webinars.filter(x => new Date(x.WEB_PublishDate) < new Date()  );    

        return (
            <div>
                <h2><span class="MuiBadge-root-192">Webinars</span></h2>   
                <br />
                <h5><span class="MuiBadge-root-192">Upcoming Webinars</span></h5>  
                {upcomingwebinars.length <= 0?"Coming soon...":
                 <ul>
                    {
                        upcomingwebinars.map((itm,i) =>
                        (
                            <>
                                <li>{Moment(itm.WEB_PublishDate).format('DD/MM/YYYY')}&nbsp;&nbsp;&nbsp;<a href={itm.WEB_Link} target="_blank" title="play" ><b>{itm.WEB_Title}</b>&nbsp;&nbsp;&nbsp;<small><i class="fas fa-video" ></i></small></a></li>
                            </>
                        ))
                    }  

                  </ul>
                }
                <br /><br />
                <h5><span class="MuiBadge-root-192">Previous Webinars</span></h5>  
                    <ul>
                        {
                            previouswebinars.map((itm,i) =>
                            (
                                <>
                                    <li>{Moment(itm.WEB_PublishDate).format('DD/MM/YYYY')}&nbsp;&nbsp;&nbsp;<a href={itm.WEB_Link} target="_blank" title="play" ><b>{itm.WEB_Title}</b>&nbsp;&nbsp;&nbsp;<small><i class="fas fa-video" ></i></small></a></li>
                                </>
                            ))
                        }  

                    </ul>
            </div>
        );
    }
}