import React, { Component } from 'react';
import { ApprovedItem } from './ApprovedItem';

export class ApprovedDownloads extends Component {
    static DrawItems(MainMenu) {
        return (
            <div className="ui-layout-content">
                <div>
                    {
                        MainMenu.map(Menu => <ApprovedItem text={Menu.text} baseurl={Menu.baseurl} description={Menu.description} url={Menu.url} />)
                    }
                </div>
            </div>
        );
    }

    displayName = ApprovedDownloads.name

    constructor(props) {
        super(props);
        this.state = { HomeMenuItems: [], loading: true };

        fetch(process.env.REACT_APP_FENSA_WEB_API + 'Download/ApprovedDownloads')
            .then(response => response.json())
            .then(data => {
                this.setState({ UsefulLinkItem: data, loading: false });
            });
    }

   

    render() {

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : ApprovedDownloads.DrawItems(this.state.UsefulLinkItem);

        return (
            <div>
                {contents}
            </div>
        );
    }
}