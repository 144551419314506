import React, { Component, Fragment } from "react";
import validator from 'validator';
import uuid from "uuid";

import { MDBBtn, MDBIcon, MDBCard, MDBCardBody } from "mdbreact";

import AuthHelper from './AuthHelper';
import { ColumnAndLabel } from './ColumnAndLabel';
import { NotificationPanel } from './NotificationPanel';
import { ReadonlyItem } from './ReadonlyItem';

export class AccountVerify extends Component {
    displayName = "AccountVerify";

    constructor(props) {
        super(props);
        const auth = new AuthHelper();
        this.state = {
            ...props,
            EmailAddress: '',
            Password: '',
            PasswordConfirm: '',
            TempPassword: '',
            UserDisplayName: '',
            UserId: auth.ConfirmAccountUser,
            Username: '',
            expiredToken: false,
            formValid: false,
            formErrors: {
                Password: 'Password Is Required',
                PasswordConfirm: 'Password Must Match',
                TempPassword: 'Temp password is required'
            },
            loading: true,
            notificationMessage: [],
            notificationPostfix: 'info hide',
            verifyComplete: false
        };
        console.log(this.displayName + '.ctor[state]', this.state);
        console.log(this.displayName + '.ctor[props]', this.props);
    }

    componentDidMount = () => {
        this.fetchData();
    }

    toggleModal = toggle => {
        this.setState({
            modal: toggle
        });
    }

    togglePleaseWait = status => {
        this.setState({
            loading: status
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        var value = target.value;
        if (value === undefined) {
            value = target.parentElement.value;
        }
        const name = target.name;
        //this state is only relevant locally - so no need for put it in redux.
        this.setState({
            [name]: value
        },
            () => { this.validateField(name, value); }
        );
        //if (debugOn) {
        //console.log('state', this.state);
        //}
    }

    handleOnInputValidation = event => {

        let fieldValidationErrors = this.state.formErrors;
        let message = "";
        switch (event.target.name.toLowerCase()) {
            case 'temppassword':
                fieldValidationErrors.TempPassword = validator.isEmpty(event.target.value) ? 'Temp password is required.' : '';
                message = fieldValidationErrors.TempPassword;
                break;

            case 'password':
                fieldValidationErrors.Password = validator.isEmpty(event.target.value) ? 'Password is required.' : '';
                if (fieldValidationErrors.Password === "") {
                    fieldValidationErrors.Password = validator.isLength(event.target.value, { min: 8 }) ? '' : `Password must contain a minimum of 7 characters. Missing(${8 - (event.target.value.length)} ${(8 - (event.target.value.length) > 1 ? 'characters' : 'character')})`;
                }
                message = fieldValidationErrors.Password;
                break;

            case 'passwordconfirm':
                fieldValidationErrors.PasswordConfirm = validator.isEmpty(event.target.value) ? 'Passwords do not match..' : '';
                if (fieldValidationErrors.PasswordConfirm === "") {
                    fieldValidationErrors.PasswordConfirm = this.state.Password === event.target.value ? '' : 'Passwords do not match.';
                }
                message = fieldValidationErrors.PasswordConfirm;
                break;

            default:
                break;
        }

        if (message !== "") {
            this.setState({
                formErrors: fieldValidationErrors
            }, this.validateForm);
            //set custom validation error
            event.target.setCustomValidity(message);
        } else {
            //clear custom validation errors
            event.target.setCustomValidity("");
        }
    }


    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        switch (fieldName) {
            case 'TempPassword':
                fieldValidationErrors.TempPassword = validator.isEmpty(this.state.Password) ? 'Password is required.' : '';
                break;

            case 'Password':
                fieldValidationErrors.Password = validator.isEmpty(this.state.Password) ? 'Password is required.' : '';
                fieldValidationErrors.Password = validator.isLength(this.state.Password, { min: 8 }) ? '' : 'Password must be more than 7 characters.';
                break;

            case 'PasswordConfirm':
                fieldValidationErrors.PasswordConfirm = !validator.matches(this.state.Password) ? 'Passwords do not match.' : '';
                break;

            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid: this.state.formErrors.TempPassword.length === 0
                && this.state.formErrors.Password.length === 0
                && this.state.formErrors.PasswordConfirm.length === 0
        });
        return this.state.formValid;
    }

    handleAbortCallback = () => {
        const auth = new AuthHelper();
        auth.LogOut();
        var url = window.location.origin;
        console.log(this.displayName + '.handleAbortCallback', url, window.location);
        if (url) {
            //replace the querystring and go to home
            console.log(this.displayName + '.url', url, window.location.pathname);
            window.location.href = url;
        }
    }

    submitForm = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            // Show please wait
            this.togglePleaseWait(true);

            var postData = JSON.stringify({
                seqno: uuid.v4(),
                datatype: "BIT.Fensa.InstallerPortal.VerifyAccount",
                origin: "Installer Portal",
                operation: "verifyaccount",
                datavalues: JSON.stringify({
                    ConfirmPassword: this.state.PasswordConfirm,
                    CurrentPassword: this.state.TempPassword,
                    Email: this.state.EmailAddress,
                    Password: this.state.Password,
                    UserName: this.state.Username
                })
            });
            console.log(this.displayName + '.submitForm[postData]', postData);
            fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/VerifyAccount', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: postData
            })
                .then(response => response.json())
                //.then(data => JSON.parse(data))
                .then(data => {
                    console.log(this.displayName + '.submitForm(data)', data);
                    if (data.returncode === 1) {
                        const verifyData = JSON.parse(data.returnData.payload);
                        console.log(this.displayName + '.submitForm(verifyData)', verifyData);
                        //if (data.returncode == 1) {
                        //} //Password01!
                        this.setState({
                            loading: false,
                            notificationPostfix: 'success show',
                            notificationMessage: ['Successfully completed account verification. Please login'],
                            verifyComplete: true
                        });
                    } else {
                        //if (data.returncode == 1) {
                        //} //Password01!
                        this.setState({
                            loading: false,
                            notificationPostfix: 'danger show',
                            notificationMessage: [data.message],
                            verifyComplete: false
                        });
                    }

                })
                .catch(data => {
                    console.log(this.displayName + 'fetchData[err]', data);
                    this.setState({
                        loading: false,
                        ErrorMessage: 'No Data',
                        notificationPostfix: 'danger show',
                        notificationMessage: ['Bad Request - No data or service not available'],
                        verifyComplete: false
                    });
                });
        }
    }

    fetchData = () => {
        //get the user account details
        //perform account lookup from userId
        var postData = JSON.stringify({
            seqno: uuid.v4(),
            datatype: "BIT.Fensa.InstallerPortal.GetUserAccount",
            origin: "Installer Portal",
            operation: "identitygetuserbyid",
            datavalues: JSON.stringify({
                UserId: this.state.UserId
            })
        });
        console.log(this.displayName + '.submitForm[postData]', postData);
        fetch(process.env.REACT_APP_FENSA_WEB_API + 'BITEngine/FindUser', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        })
            .then(response => response.json())
            //.then(data => JSON.parse(data))
            .then(data => {
                console.log(this.displayName + '.fetchData(data)', data);
                //var user = JSON.parse(data.ReturnData.Payload);
                //this.setState({
                //    loading: false,
                //    notificationPostfix: (data.returncode === 1) ? 'success show' : 'danger show',
                //    notificationMessage: (data.returncode === 1) ? ['Successfully complete password reset'] : [JSON.parse(data.message)]
                //});
                if (data.returncode === 1) {
                    var user = JSON.parse(data.returnData.payload);
                    this.setState({
                        EmailAddress: user.Email,
                        UserDisplayName: user.DisplayName,
                        Username: user.UserName,
                        loading: false,
                        notificationPostfix: 'success hide',
                        notificationMessage: ['Successfully loaded user account']
                    });
                } else {
                    this.setState({
                        EmailAddress: '',
                        UserDisplayName: '',
                        Username: '',
                        expiredToken: true,
                        loading: false,
                        notificationPostfix: 'danger show',
                        notificationMessage: [data.message]
                        //expiredToken: true
                    });
                }
            })
            .catch(data => {
                console.log(this.displayName + 'fetchData[err]', data);
                this.setState({
                    loading: false,
                    ErrorMessage: 'No Data',
                    notificationPostfix: 'danger show',
                    notificationMessage: ['Bad Request - No data or service not available']
                });
            });
    }

    render() {
        return (
            <MDBCard>
                <MDBCardBody>
                    {!this.state.verifyComplete && !this.state.expiredToken ?
                        (
                            <Fragment>
                                <div className="h4 text-center py-4">Verify Account</div>
                                <div>
                                    <form className='demoForm was-validated' onSubmit={this.submitForm.bind(this)}>
                                        <div className='form-group'>
                                            <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                                            <div className="grey-text">
                                                <div className='form-group'>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <ReadonlyItem textLabel="Email">{this.state.EmailAddress}</ReadonlyItem>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <ColumnAndLabel
                                                    ErrorMessage={this.state.formErrors.TempPassword}
                                                    IconLabel="lock"
                                                    Id="TempPassword"
                                                    InputType="password"
                                                    IsRequired={true}
                                                    SizeCss="md"
                                                    HintLabel=""
                                                    TextLabel="Temporary Password"
                                                    Validate={true}
                                                    Value={this.state.TempPassword}
                                                    HandleInputChange={this.handleInputChange}
                                                    HandleOnInput={this.handleOnInputValidation}
                                                />
                                                <ColumnAndLabel
                                                    ErrorMessage={this.state.formErrors.Password}
                                                    IconLabel="lock"
                                                    Id="Password"
                                                    InputType="password"
                                                    IsRequired={true}
                                                    SizeCss="md"
                                                    HintLabel=""
                                                    TextLabel="Password"
                                                    Validate={true}
                                                    Value={this.state.Password}
                                                    HandleInputChange={this.handleInputChange}
                                                    HandleOnInput={this.handleOnInputValidation}
                                                />
                                                <ColumnAndLabel
                                                    ErrorMessage={this.state.formErrors.PasswordConfirm}
                                                    IconLabel="lock"
                                                    Id="PasswordConfirm"
                                                    InputType="password"
                                                    IsRequired={true}
                                                    SizeCss="md"
                                                    HintLabel=""
                                                    TextLabel="Confirm Password"
                                                    Validate={true}
                                                    Value={this.state.PasswordConfirm}
                                                    HandleInputChange={this.handleInputChange}
                                                    HandleOnInput={this.handleOnInputValidation}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-center py-4 mt-3">
                                            <MDBBtn
                                                className="pull-right mdb-btn-resize"
                                                color="primary"
                                                type="submit"
                                                size="sm"
                                                disabled={!this.state.formValid || this.state.processingSubmit}>
                                                <MDBIcon icon="save" className="mr-2" />Reset Password</MDBBtn>
                                            <MDBBtn
                                                className="pull-right mdb-btn-resize"
                                                color="dark"
                                                type="button"
                                                size="sm"
                                                onClick={this.handleAbortCallback}>
                                                <MDBIcon icon="arrow-left" className="mr-2" />Cancel</MDBBtn>
                                        </div>
                                    </form>

                                </div>
                            </Fragment>
                        ) :
                        (
                            <Fragment>
                                <NotificationPanel CssClassPostFix={this.state.notificationPostfix} NotificationMessage={this.state.notificationMessage} />
                                <div className="text-center py-4 mt-3">
                                    <MDBBtn
                                        className="mdb-btn-resize"
                                        color="info"
                                        type="button"
                                        size="sm"
                                        onClick={this.handleAbortCallback}>
                                        <MDBIcon icon="arrow-left" className="mr-2" />{this.state.expiredToken ? 'Restart' : 'Login'}</MDBBtn>
                                </div>
                            </Fragment>
                        )
                    }

                </MDBCardBody>
            </MDBCard>
        );
    }
}
