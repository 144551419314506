import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const initialState = {

    applicationerror: [],
    applicationinformation: [],

    branch: {
        List: [],
        Total: 0
    },

    InstallerWork : {
        MaterialType:[],
        ServiceType:[],
        PropertyType: [],
        RadiusType: [],
        MinimumBudgetType: [],
        Result: [],
        InstallerWorkData: [],
        InstallerOpeningHours: [],
        InstallerWorkMaterials: [],
        InstallerWorkServices: [],
        InstallerWorkPropertyTypes: []
    }
};

const middleware = [thunk];

const store = createStore(
    rootReducer,
    initialState,
    compose(
        applyMiddleware(...middleware)        
    )
);

export default store;